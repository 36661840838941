import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'

function WebsiteSchema() {
  return (
      <Fragment>
          <Helmet>
          <script type="application/ld+json">
            {`
              {
                "@context":"http://schema.org/",
                "@type": "WebSite",
                "url": "https://www.tataplaybinge.com/",
                "name": "Tata Play Binge",
                "potentialAction": [
                  {
                    "@type": "SearchAction",
                    "target": "https://www.tataplaybinge.com/search?q={search_term_string}",
                    "query-input": "required name=search_term_string"
                  }]
               }
           `}
          </script>
          </Helmet>
    </Fragment>
  )
}

export default React.memo(WebsiteSchema)