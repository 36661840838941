import React from "react";
import confetti from "canvas-confetti";
import CryptoJS from 'crypto-js';

import {
    accountDropDown,
    clearStore,
    fetchAnonymousId,
    notificationDropDown,
    recentSearch,
    refreshAccount,
    refreshAccountOldStack,
    setSearch,
    switchAccountDropDown,
    fetchGlobalConfig,
} from "@components/Header/APIs/actions";
import { updateSearchData, vootTokenapi } from "@containers/Search/APIs/actions";
import { URL, HelpCenterUrl, BingeAppUrl } from "@constants/routeConstants";
import { MODALS } from "@common/Modal/constants";
import {
    COMMON_ERROR,
    COMMON_HEADINGS,
    CONTENTTYPE,
    CONTRACT,
    DIRECTIONS,
    DTH_BINGE_POPUP,
    DTH_TYPE,
    MESSAGE,
    MINI_SUBSCRIPTION,
    MOBILE_BREAKPOINT,
    PACK_NAME,
    PACK_TYPE,
    PARAMS_TYPE,
    PARTNER_SUBSCRIPTION_TYPE,
    PLAY_STORE_URL,
    RAIL_TITLE,
    RENTAL_STATUS,
    SECTION_SOURCE,
    SECTION_TYPE,
    SUBSCRIPTION_TYPE,
    TA_MAX_CONTENT,
    TAB_BREAKPOINT,
    WEB_SMALL_PAYMENT_SOURCE,
    SUBSCRIPTION_TYPE_HEADER,
    REGEX,
    LEARN_ACTION_TYPE,
    CATEGORY_NAME,
    SUBSCRIPTION_MODTYPE,
    SESSION_STORAGE,
    UTM_SUPER_PROPERTIES,
    UTM_SOURCE,
    SUBSCRIPTION_EXTERNAL_SOURCES,
    HERO_BANNER_TYPE,
    MID_SCROLL_ARRAY,
    SCREEN_NAME,
    JOURNEY_INITIATED_SCREEN,
    SEARCH_PAGE_NAME,
    GLOBAL_CONFIG_SOURCE,
} from "@constants";
import ENV_CONFIG from "@config/environment/index";
import { getSystemDetails } from "@utils/browserEnvironment";
import { BROWSER_TYPE, OS } from "@constants/browser";
import md5 from "md5";
import { BOTTOM_SHEET, HEADER_CONSTANTS, LAYOUT_TYPE, LOCALSTORAGE, PLAY_ACTION, USELESS_WORDS, REVERSE_FORMATTED_CONTENT_TYPE } from "@utils/constants";

import mixPanelConfig from "@utils/mixpanel";
import MIXPANEL from "@constants/mixpanel";
import MOENGAGE from "@constants/moengage";
import get from "lodash/get";
import { clearKey, deleteKey, getKey, setKey } from "@utils/storage";
import moengageConfig from "@utils/moengage";
import store from "../store";

import isEmpty from "lodash/isEmpty";
import { dunningRecharge, getBalanceInfo, quickRechargeBeforeLogin } from "@containers/PackSelection/APIs/action";
import {
    getCurrentSubscriptionInfo,
    openMiniSubscription,
    getWebPortalLink,
    checkFallbackFlow,
    getCurrentSubscriptionMyplanVerbiages,
    getPrimeEntitlementDetails,
    getPartnerStatus,
} from "@containers/Subscription/APIs/action";
import { ACCOUNT_STATUS } from "@containers/BingeLogin/APIs/constants";
import { closePopup, openPopup } from "@common/Modal/action";
import { SUBSCRIPTION } from "@containers/PIDetail/API/constant";
import { fetchMixpanelId, hideMainLoader, loggedIn, showMainLoader, showMainLoaderImmediate } from "@src/action";
import { redirectToHomeScreen } from "@containers/BingeLogin/bingeLoginCommon";
import { ERROR_MESSAGES, PROVIDER_NAME, PLAY_AUTH_TYPE, SUBTITLE_TEXT, APPLE_REDEMPTION_STATUS, SHOW_APPLE_STATUS, PROVIDER_CW, PROVIDER_EVENT_TYPE, PRIME_ENTITLEMENT_STATUS, AMAZON_WEB_LARGE_VERBIAGE } from "@constants/playerConstant";
import { logOut } from "@containers/BingeLogin/APIs/action";
import { getAppleDeepLinkRedemptionUrl, setPlayerAPIError, viewCountLearnAction, partnerContinueWatching } from "@containers/PlayerWeb/APIs/actions";
import { CURRENT_SUBSCRIPTION } from "@containers/MySubscription/constant";
import mixpanel from "mixpanel-browser";
import { getProfileDetails, switchToAtvAccount } from "@containers/Profile/APIs/action";
import { toast } from "react-toastify";
import bingeMobileAsset from "@assets/images/binge-mobile-asset.png";
import bingeAsset from "@assets/images/binge-asset.svg";
import { postSwitchAccountReq } from "@containers/SwitchAccount/API/action";
import { getDeviceStatus, handleDeviceCancelledUser, setDeviceStatus } from "@utils/cancellationFlowCommon";
import SelectLanguage from "@containers/Languages/SelectLanguage";
import { fetchUserSelectedData, openMobilePopup } from "@containers/Languages/APIs/actions";
import Movie from "@containers/Movie";
import Login from "@containers/Login";
import { SUBSCRIPTION_STATUS, JOURNEY_SOURCE } from "@containers/Subscription/APIs/constant";
import { checkPlaybackEligibility, setLiveUserLogin } from "@containers/PIDetail/API/actions";
import { checkCurrentSubscription, getComponentList, checkPartnerSubscriptionType, renewSusbcription, isSubscriptionStatusEmpty, checkIsFirstSubscription, isSubscriptionFreemium } from "@containers/Subscription/APIs/subscriptionCommon";
import { getSubscriberDeviceList } from "@containers/DeviceManagement/APIs/action";
import { getClientIP, subscriberListing, isZeroAppLogin, setManualLoginSource } from "@containers/Login/APIs/actions";
import { history } from "@utils/history";
import { OPEL_STATUS } from "@containers/SubscriptionPayment/APIs/constants";
import { setPaymentStatusFromPubnub } from "@containers/SubscriptionPayment/APIs/action";
import { BROWSE_TYPE } from '@containers/BrowseByDetail/APIs/constants';
import { fetchTARecommendedFilterOrder, fetchTARecommendedSearchData } from '@containers/BrowseByDetail/APIs/action';
import trackEvent from "./trackEvent";
import appsFlyerConfig from "./appsFlyer";
import APPSFLYER, { APPSFLYER_CONTENT_PLAY_EVENTS } from "./constants/appsFlyer";
import PlanSelection from "@containers/Subscription/PlanSelection";
import ChangeTenureModal from '@containers/Subscription/ChangeTenureModal';
import SelectionDrawer from '@containers/Subscription/SelectionDrawer';
import { updateUser, onLoginSuccess } from "@containers/Login/LoginCommon";
import dataLayerConfig from "./dataLayer";
import DATALAYER from "./constants/dataLayer";
import { AUTO_SUGGESTION_SUGGESTOR, MIXPANEL_SUGGESTOR_TYPE, SIDE_MENU_HEADERS } from "@components/Header/APIs/constants";
import FIREBASE from "@utils/constants/firebase";
import { DRP_STATE } from "@containers/Home/APIs/constants";
import { getCommonAnalyticsAttributes, getTitleAndDesc, getTAUseCaseId, trackContentPlayFailure } from "@containers/PIDetail/PIDetailCommon";
import { TSAnalyticsMitigtionSDK } from "tatasky-analytics-mitigation";
// let TSAnalyticsMitigtionSDK = require('tatasky-analytics-mitigation');
import { BOTTOM_LIST } from "@components/Header/constants";
import { SPECIAL_SECTION_SOURCE } from "@common/ListingItem/constant";
import crownLogo from "@assets/images/crown-icon.svg";
import appleLogo from "@assets/images/apple-logo.svg";
import successTickIcon from '@assets/images/Success-tick.png';
import crossIcon from '@assets/images/cross.png';
import googleConversion from "./constants/googleConversion";
import googleConversionConfig from "./googleCoversion";
import { PAYMENT_METHOD } from "@containers/SubscriptionPayment/constant";
import moment from "moment";
import PlayerService from "@containers/PlayerWeb/APIs/services";
import { AppleActivate } from "@containers/PIDetail/components/AppleActivate";
import informationIcon from '@assets/images/Information Icon.svg';
import { checkNudgeVisiblity } from "@containers/RenewNudge/common";
import FlexiPlanModal from "@containers/Subscription/FlexiPlanModal";
import BackArrow from '@assets/images/back-arrow.svg';
import { ACCORDION_DELETE_ACCOUNT } from "@containers/HelpCenter/APIs/constants";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { removeAppEventListener , initiateAppEventListener } from '@config/bootup';

export const isUserUnSubscribed = (currentSubscription) => {
    return !!(isEmpty(currentSubscription) || (currentSubscription?.subscriptionStatus?.toUpperCase() === ACCOUNT_STATUS.DEACTIVE && currentSubscription?.freeTrialStatus));
}

export const showConfetti = (
    duration = 1000,
    delay = 0.25 * 1000,
    zIndex = 1301,
) => {
    const animationEnd = Date.now() + duration;
    const defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex };

    const randomInRange = (min, max) => Math.random() * (max - min) + min;
    const interval = setInterval(() => {
        const timeLeft = animationEnd - Date.now();
        if (timeLeft <= 0) return clearInterval(interval);
        const particleCount = 50 * (timeLeft / duration);
        confetti(
            Object.assign({}, defaults, {
                particleCount,
                origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
                colors: [
                    "#A0752B",
                    "#FDD551",
                    "#FFFFBF",
                    "#FDD551",
                    "#A0752B",
                    "#FDD551",
                    "#FDD551",
                ],
            }),
        );
        confetti(
            Object.assign({}, defaults, {
                particleCount,
                origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
                colors: [
                    "#A0752B",
                    "#FDD551",
                    "#FFFFBF",
                    "#FDD551",
                    "#A0752B",
                    "#FDD551",
                    "#FDD551",
                ],
            }),
        );
    }, delay);
};

export const removeClass = (element, ...classNames) => {
    classNames.forEach((className) => {
        element && element.classList !== undefined
            ? element.classList.contains(className) &&
            element.classList.remove(className)
            : console.log("Element not Found! Cannot remove " + className + " class");
    });
};

export const addClass = (element, ...classNames) => {
    classNames.forEach((className) => {
        element
            ? !element.classList.contains(className) &&
            element.classList.add(className)
            : console.log("Element not Found! Cannot add " + className + " class");
    });
};

export const capitalizeFirstLetter = (string) => {
    if (string && isNaN(string)) {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    } else {
        return string;
    }
};

export const formDataGenerator = (data) => {
    let formDataValue = new FormData();
    let keys = Object.keys(data);
    keys.forEach((key) => {
        formDataValue.append(key, data[key]);
    });

    return formDataValue;
};

export const getDeviceId = () => {
    if (!getKey(LOCALSTORAGE.DEVICE_ID)) {
        let deviceId = new Date().getTime();
        setKey(LOCALSTORAGE.DEVICE_ID, deviceId);
        setKey(LOCALSTORAGE.DAI_DEVICE_ID, generateUuidV4String());
        return deviceId;
    } else {
        return getKey(LOCALSTORAGE.DEVICE_ID);
    }
};

export const getSessionId = () => {
    if (!getKey(LOCALSTORAGE.DEVICE_SESSION_ID)) {
        let sessionId = generateUuidV4String();
        setKey(LOCALSTORAGE.DEVICE_SESSION_ID, sessionId);
        return sessionId;
    } else {
        return getKey(LOCALSTORAGE.DEVICE_SESSION_ID);
    }
};

export const getAnonymousId = async (isLoader = true) => {
    if (!getKey(LOCALSTORAGE.ANONYMOUS_ID)) {
        await store.dispatch(fetchAnonymousId(isLoader));
        const { headerDetails } = store.getState();
        let anonymousId = get(headerDetails, "anonymousUserData.anonymousId");
        setKey(LOCALSTORAGE.ANONYMOUS_ID, anonymousId);
        setKey(LOCALSTORAGE.G_AUTH_TOKEN, get(headerDetails, "anonymousUserData.gAuthToken"));
    } else {
        return getKey(LOCALSTORAGE.ANONYMOUS_ID);
    }
};

export const moviePopup = (width, props) => {
    if (width > MOBILE_BREAKPOINT) {
        store.dispatch(
            openPopup(MODALS.CUSTOM_MODAL, {
                modalClass: "alert-modal language-selection-container gradiant-bg movie-container",
                childComponent: <Movie {...props} />,
                closeModal: true,
                hideCloseIcon: true,
                movieSeries: true,
            }),
        );
    } else if (width <= MOBILE_BREAKPOINT) {
        store.dispatch(closePopup());
        store.dispatch(openMobilePopup());
    }
};

export const showLanguageOnboardingPopup = (width, history) => {
    let data = getVerbiages(CATEGORY_NAME.LANGUAGE_DRAWER);
    if (width > MOBILE_BREAKPOINT) {
        store.dispatch(
            openPopup(MODALS.CUSTOM_MODAL, {
                modalClass: "alert-modal language-selection-container ",
                htmlHeading: data?.subHeader || 'Select Content Languages',
                childComponent: <SelectLanguage />,
                closeModal: true,
                hideCloseIcon: true,
            }),
        );
    } else if (width <= MOBILE_BREAKPOINT) {
        safeNavigation(history, {
            pathname: `/${URL.SELECT_LANGUAGE}`,
        });
    }
};

export const getLayeredIcon = (iconName) => {
    iconName = iconName.split(" ")[0];

    switch (iconName) {
        case "icon-info":
            return (
                <i className="icon-info">
                    <span className="path1" />
                    <span className="path2" />
                </i>
            );
        case "icon-circle-copy":
            return <i className="icon-circle-copy" />;
        case "icon-play":
            return <span className="triangle-right" />;
        case "icon-download":
            return (
                <span className="icon-download">
                    <i className="path1" />
                    <i className="path2" />
                </span>
            );
        case "icon-delete":
            return (
                <span className="icon-delete">
                    <i className="path1" />
                    <i className="path2" />
                </span>
            );
        case "icon-camera":
            return (
                <span className="icon-camera">
                    <span className="path1" />
                    <span className="path2" />
                </span>
            );
        case "icon-play-icon":
            return (
                <span className="icon-play-icon">
                    <span className="path1" />
                    <span className="path2" />
                </span>
            );
        case "icon-replay":
            return (
                <span className="icon-replay">
                    <i className="path1" />
                    <i className="path2" />
                </span>
            );
        case "icon-notification_settings":
            return (
                <span className="icon-notification_settings">
                    <span className="path1" />
                    <span className="path2" />
                    <span className="path3" />
                    <span className="path4" />
                </span>
            );
        case "icon-Notification-Bell-upd":
            return (
                <span className="icon-Notification-Bell-upd">
                    <span className="path1" />
                    <span className="path2" />
                    <span className="path3" />
                </span>
            );
        case "icon-arrow-see-all":
            return (
                <span className="icon-arrow-see-all">
                    <span className="path1" />
                    <span className="path2" />
                </span>
            );
        case "icon-upgrade":
            return <i className="icon-upgrade" />;
        case "icon-my-subscription-1":
            return <i className="icon-my-subscription-1" />;
        case "icon-alert-upd":
            return <i className="icon-alert-upd" />;
        case "icon-radio_active":
            return (
                <span className="icon-radio_active">
                    <span className="path1" />
                    <span className="path2" />
                </span>
            );
        case "icon-remove-phon":
            return <i className="icon-remove-phon" />;
        default:
            return <i className={iconName} />;
    }
};

export const goToHome = (history, type) => {
    safeNavigation(history, { pathname: URL.DEFAULT, state: { type: type } });
};

export const getIconSuccessTick = () => {
    return (
        <span className="get-icon-success-tick">
            <i className={"icon-check"} />
        </span>
    );
};

export const getIconSuccessTickUpd = () => {
    return (
        <i className={"icon-success-upd"} />
    );
};

export const featureUnderDevelopment = (openPopup) => {
    openPopup(MODALS.ALERT_MODAL, {
        modalClass: "alert-modal ",
        headingMessage: COMMON_HEADINGS.FEATURE_UNDER_DEVELOPMENT,
        primaryButtonText: "Ok",
        closeModal: true,
        hideCloseIcon: true,
    });
};

export const getSmartUrl = (deepLinkUrl) => {
    let state = store.getState();
    let deepLinkUrlWithHttps = deepLinkUrl.includes('http:') ? deepLinkUrl.replace("http:", "http:") : deepLinkUrl;
    let urlConfigData = get(state, "subscriptionDetails.currentSubscription.data.shemarromeConfig");

    let entryId = urlConfigData?.entryId,
        params = `${deepLinkUrlWithHttps}?service_id=${urlConfigData?.serviceId}&play_url=yes&protocol=hls&us=`;
    let encryptedToken = md5(`${entryId}${params}`);
    return `${deepLinkUrlWithHttps}?service_id=${urlConfigData?.serviceId}&play_url=yes&protocol=hls&us=${encryptedToken}`;
};

export const getBaseUrl = () => {
    return ENV_CONFIG.API_BASE_URL;
};

export const getHCBaseUrl = () => {
    return ENV_CONFIG.HC_BASE_URL;
};

export const getEnvironmentConstants = () => {
    return ENV_CONFIG;
};

export const convertNumToString = (number) => {
    const numArr = [
        "zero",
        "one",
        "two",
        "three",
        "four",
        "five",
        "six",
        "seven",
        "eight",
        "nine",
        "ten",
    ];
    return numArr[number];
};

export const contentType = (contentType) => {
    if (
        contentType === CONTENTTYPE.TYPE_BRAND_CHILD ||
        contentType === CONTENTTYPE.TYPE_SERIES_CHILD ||
        contentType === CONTENTTYPE.TYPE_MOVIES ||
        contentType === CONTENTTYPE.TYPE_WEB_SHORTS ||
        contentType === CONTENTTYPE.TYPE_TV_SHOWS ||
        contentType === CONTENTTYPE.TYPE_CUSTOM_TV_SHOWS_DETAIL ||
        contentType === CONTENTTYPE.TYPE_CUSTOM_MOVIES_DETAIL ||
        contentType === CONTENTTYPE.TYPE_CUSTOM_WEB_SHORTS_DETAIL
    ) {
        return "vod";
    } else if (
        contentType === CONTENTTYPE.TYPE_BRAND ||
        contentType === CONTENTTYPE.TYPE_CUSTOM_BRAND_DETAIL
    ) {
        return "brand";
    } else if (
        contentType === CONTENTTYPE.TYPE_SERIES ||
        contentType === CONTENTTYPE.TYPE_CUSTOM_SERIES_DETAIL
    ) {
        return "series";
    } else if (
        contentType === CONTENTTYPE.TYPE_CATCH_UP ||
        contentType === CONTENTTYPE.TYPE_CUSTOM_CATCH_UP_DETAIL
    ) {
        return "catchupEpg";
    }
};

export const cloudinaryCarousalUrlforGenre = (
    height = 62,
) => {
    let url = getBaseImageUrl();

    url += "f_auto,fl_lossy,q_auto";
    return url + ",h_" + height + "/";
}

export const getMediaReadyConfig = () => {
    const { headerDetails } = store.getState();
    let configResponse = get(headerDetails, "configResponse.data.config"),
        mediaReadyConfig = configResponse?.mediaReady;

    return mediaReadyConfig;
}

export const getBaseImageUrl = () => {
    const { headerDetails } = store.getState();
    let configResponse = get(headerDetails, "configResponse.data.config"),
        cloudAccountUrl = get(configResponse, "url.image.cloudAccountUrl"),
        cloudAccountUrlMR = get(configResponse, "url.image.cloudAccountUrlMR"),
        isCloudinaryEnabledBingeWeb = JSON.parse(getKey(LOCALSTORAGE.CLOUDINARY_ENABLED));

    return isCloudinaryEnabledBingeWeb ? cloudAccountUrl : cloudAccountUrlMR;
}

export const cloudinaryCarousalUrl = (
    zoomed = false,
    view = "",
    width = 0,
    height = 0,
    isMobileView = false,
    renderWithoutDimension = false,
) => {

    let url = getBaseImageUrl();

    if (location.href.includes(URL.SEE_ALL)) {
        if (view === LAYOUT_TYPE.CIRCULAR) {
            if (isMobileView) {
                width = 128;
                height = 128;
            } else {
                width = 430;
                height = 430;
            }
        } else if (view === LAYOUT_TYPE.LANDSCAPE) {
            if (isMobileView) {
                width = 312;
                height = 178;
            } else {
                width = 428;
                height = 248;
            }
        } else if (view === LAYOUT_TYPE.PORTRAIT) {
            if (isMobileView) {
                width = 201;
                height = 306;
            } else {
                width = 400;
                height = 532;
            }
        } else if (view === LAYOUT_TYPE.SQUARE) {
            if (isMobileView) {
                width = 240;
                height = 240;
            } else {
                if (zoomed) {
                    width = 552;
                    height = 350;
                } else {
                    width = 312;
                    height = 312;
                }
            }
        } else if (view === LAYOUT_TYPE.TOP_PORTRAIT) {
            if (isMobileView) {
                width = 306;
                height = 460;
            } else {
                if (zoomed) {
                    width = 700;
                    height = 904;
                } else {
                    width = 620;
                    height = 824;
                }
            }
        }
    } else if (view === LAYOUT_TYPE.CIRCULAR) {
        if (isMobileView) {
            width = 128;
            height = 128;
        } else {
            if (zoomed) {
                width = 222;
                height = 222;
            } else {
                width = 180;
                height = 180;
            }
        }
    } else if (view === LAYOUT_TYPE.LANDSCAPE) {
        if (isMobileView) {
            width = 320;
            height = 182;
        } else {
            if (zoomed) {
                width = 552;
                height = 350;
            } else {
                width = 428;
                height = 248;
            }
        }
    } else if (view === LAYOUT_TYPE.PORTRAIT) {
        if (isMobileView) {
            width = 200;
            height = 300;
        } else {
            if (zoomed) {
                width = 500;
                height = 600;
            } else {
                width = 400;
                height = 532;
            }
        }
    } else if (view === LAYOUT_TYPE.TOP_PORTRAIT) {
        if (isMobileView) {
            width = 306;
            height = 460;
        } else {
            if (zoomed) {
                width = 500;
                height = 704;
            } else {
                width = 420;
                height = 624;
            }
        }
    } else if (view === LAYOUT_TYPE.SQUARE) {
        if (isMobileView) {
            width = 240;
            height = 240;
        } else {
            if (zoomed) {
                width = 552;
                height = 350;
            } else {
                width = 312;
                height = 312;
            }
        }
    }
    url += "f_auto,fl_lossy,q_auto";
    return renderWithoutDimension ? url + '/' : url + ",w_" + width + ",h_" + height + "/";
};

export const isUserloggedIn = () => {
    let userInfo = JSON.parse(getKey(LOCALSTORAGE.USER_INFO)) || {};
    return !!(userInfo.accessToken && userInfo.loggedIn);
};

export const resetSearchData = () => {
    store.dispatch(setSearch(false));
    store.dispatch(updateSearchData());
};

export const dropDownDismissalCases = (closeCondition) => {
    const { headerDetails, modal } = store.getState();
    let searchStatus = get(headerDetails, "search");
    let accountDropDownStatus = get(headerDetails, "accountDropDown");
    let notificationDropDownStatus = get(headerDetails, "notificationDropDown");
    let switchAccountDropDownStatus = get(headerDetails, "switchAccountDropDown");
    let recentSearchStatus = get(headerDetails, "recentSearch");
    let showModal = get(modal, "showModal");
    if (searchStatus) {
        !location.href.includes(URL.SEARCH) && store.dispatch(setSearch(false));
    }
    if (accountDropDownStatus) {
        store.dispatch(accountDropDown(false));
    }
    if (notificationDropDownStatus) {
        store.dispatch(notificationDropDown(false));
    }
    if (switchAccountDropDownStatus) {
        store.dispatch(switchAccountDropDown(false));
    }
    if (recentSearchStatus) {
        !location.href.includes(URL.SEARCH) && store.dispatch(recentSearch(false));
    }
    if (showModal && !closeCondition) {
        store.dispatch(closePopup());
    }
};

export const secondsToHms = (d) => {
    if (!d) {
        return "00:00";
    }
    const t = Number(d);
    const h = Math.floor(t / 3600);
    const m = Math.floor((t % 3600) / 60);
    const s = Math.floor((t % 3600) % 60);

    const hDisplay = h < 10 ? `0${h}` : h;
    const mDisplay = m < 10 ? `0${m}` : m;
    const sDisplay = s < 10 ? `0${s}` : s;
    if (h === 0) {
        return `${mDisplay}:${sDisplay}`;
    }
    return `${hDisplay}:${mDisplay}:${sDisplay}`;
};

export const time_convert = (d, secondAppend = true, isRetryScreen = false) => {
    const t = Number(d);
    const h = Math.floor(t / 3600);
    const m = Math.floor((t % 3600) / 60);
    const s = Math.floor((t % 3600) % 60);

    const hDisplay = h < 10 ? `0${h}` : h;
    const mDisplay = m < 10 ? `0${m}` : m;
    const sDisplay = s < 10 ? `0${s}` : s;
    if (h === 0) {
        if (secondAppend) {
            if (m === 0) {
                return isRetryScreen ? `00:${sDisplay}` : `${sDisplay}s`;
            }
            return isRetryScreen ? `${mDisplay}:${sDisplay}` : `${mDisplay}m ${sDisplay}s`;
        }
        return `${mDisplay}m`;
    }

    if (secondAppend) {
        return isRetryScreen ? `${hDisplay}:${mDisplay}:${sDisplay}` : `${hDisplay}h ${mDisplay}m ${sDisplay}s`;
    } else {
        return `${hDisplay}h ${mDisplay}m`;
    }
};

export const getSeeAllUrl = (isPiPage, contentType, id, railItem, partnerPage) => {
    let seeAllId = railItem?.heroBannerType === HERO_BANNER_TYPE.HB_SEE_ALL ? railItem?.railId : railItem?.id,
        urlLength = getUrlLength(`/${URL.SEE_ALL}/${getFormattedURLValue(railItem?.title)}/${seeAllId}`),
        url = `/${URL.SEE_ALL}/${getFormattedContentTitle(railItem?.title, urlLength)}/${seeAllId}`,
        formattedContentType = REVERSE_FORMATTED_CONTENT_TYPE[contentType] || contentType;

    if (isPiPage?.recommended) {
        urlLength = getUrlLength(`/${URL.RECOMMENDED_SEE_ALL}/${formattedContentType}/${getFormattedURLValue(isPiPage?.title)}/${id}`)
        url = `/${URL.RECOMMENDED_SEE_ALL}/${formattedContentType}/${getFormattedContentTitle(isPiPage?.title, urlLength)}/${id}`;
    }
    else if (partnerPage?.isPartnerPage) {
        urlLength = getUrlLength(`/${URL.SEE_ALL}/${getFormattedURLValue(railItem?.title)}/${partnerPage?.provider}/${seeAllId}`)
        url = `/${URL.SEE_ALL}/${getFormattedContentTitle(railItem?.title, urlLength)}/${partnerPage?.provider}/${seeAllId}`;
    }
    return url;
};

export const openResponseModal = (openPopup, message) => {
    openPopup(MODALS.ALERT_MODAL, {
        modalClass: "alert-modal",
        instructions: message,
        primaryButtonText: "Ok",
        closeModal: true,
        hideCloseIcon: true,
    });
};

export const getPlayAction = (totalDuration, watchedDuration) => {
    let { PIDetails } = store.getState(),
        lastWatch = get(PIDetails, "continueWatchingDetails.data"),
        meta = get(PIDetails, "data.meta"),
        appleProvider = meta?.provider?.toLowerCase() === PROVIDER_NAME.APPLE,
        verbiages = getVerbiages(CATEGORY_NAME.PLAY_BUTTON_CTA)?.others,
        playVerbiage = (!isNewPlayCtaJourney() || appleProvider) ? PLAY_ACTION.PLAY : (!isUserloggedIn() ? verbiages?.guestCTA : verbiages?.playCTA);

    if (lastWatch) {
        if (watchedDuration === 0) {
            if ((meta?.parentContentType === CONTENTTYPE.TYPE_BRAND || meta?.parentContentType === CONTENTTYPE.TYPE_SERIES) && ((isUserloggedIn() && isNewPlayCtaJourney()) || !isNewPlayCtaJourney())) {
                return `${playVerbiage || PLAY_ACTION.PLAY} ${lastWatch.season !== 0 ? `S${lastWatch.season}` : `${meta?.season ? `S${meta.season}` : ""}`
                    } ${lastWatch.episodeId !== 0 ? `E${lastWatch.episodeId}` : `${meta.episodeId ? `E${meta.episodeId}` : ""}`}`;
            } else {
                return playVerbiage || PLAY_ACTION.PLAY;
            }
        } else if (watchedDuration > 0) {
            if ((watchedDuration / totalDuration) * 100 >= 99) {
                if (meta?.parentContentType === CONTENTTYPE.TYPE_BRAND || meta?.parentContentType === CONTENTTYPE.TYPE_SERIES) {
                    return `${PLAY_ACTION.REPLAY} ${lastWatch.season ? `S${lastWatch.season}` : ""
                        } ${lastWatch.episodeId ? `E${lastWatch.episodeId}` : ""}`;
                } else {
                    return PLAY_ACTION.REPLAY;
                }
            } else {
                if (meta?.parentContentType === CONTENTTYPE.TYPE_BRAND || meta?.parentContentType === CONTENTTYPE.TYPE_SERIES) {
                    return `${PLAY_ACTION.RESUME} ${lastWatch.season ? `S${lastWatch.season}` : ""
                        } ${lastWatch.episodeId ? `E${lastWatch.episodeId}` : ""}`;
                } else {
                    return PLAY_ACTION.RESUME;
                }
            }
        } else {
            return playVerbiage || PLAY_ACTION.PLAY;
        }
    } else {
        if (!isUserloggedIn() && isNewPlayCtaJourney()) {
            return playVerbiage || PLAY_ACTION.PLAY;
        }
        return isMobile.any() ? `${playVerbiage || PLAY_ACTION.PLAY_MOBILE} ${meta?.season ? `S${meta.season}` : ""} ${meta?.episodeId ? `E${meta.episodeId}` : ""}`
            : `${playVerbiage || PLAY_ACTION.PLAY} ${meta?.season ? `S${meta.season}` : ""} ${meta?.episodeId ? `E${meta.episodeId}` : ""}`
    }
};

export const updateUserInfo = async (response, params, cb) => {
    // cb added to handle a flow during cancellation flow, to avoid wrong data passing in APIs like baId
    let callBackFunc = cb || function () {
    };
    let userInfo = JSON.parse(getKey(LOCALSTORAGE.USER_INFO)) || {};
    let deviceList = response?.data?.deviceDTOList?.slice(0, 4);
    let getQrSource = '';
    // let packDetails = response.data.partnerSubscriptionsDetails;
    // let packCreatedDate = packDetails ? packDetails.packCreatedDate : null;
    // let userInfo = {
    //     firstName: response.data.firstName,
    //     lastName: response.data.lastName,
    //     baId: response.data.baId,
    //     profileId: response.data.defaultProfile,
    //     bingeAccountStatus:
    //     response?.data?.subscriptionInformationDTO?.bingeAccountStatus,
    //     freeTrialAvailed: response.data.freeTrialAvailed,
    //     accessToken: response.data.userAuthenticateToken,
    //     deviceToken: response.data.deviceAuthenticateToken,
    //     alternatePackId: packDetails && packDetails.alternatePackId,
    //     packId: packDetails && packDetails.packId,
    //     expirationDate: packDetails && packDetails.expirationDate,
    //     sId: response.data.subscriberId,
    //     loggedIn: true,
    //     firstTimeLogin: response.data.firstTimeLogin,
    //     firstTimeLoginDate: response.data.firstTimeLoginDate,
    //     bingeAccountCount: parseInt(response.data.bingeAccountCount),
    //     dateOfSubscription: packCreatedDate,
    //     deviceLoginCount: parseInt(response.data.deviceLoginCount),
    //     dthStatus: response?.data?.subscriberAccountStatus,
    //     accountSubStatus: response.data.accountSubStatus,
    //     sufficientBalance: response.data.sufficientBalance
    //         ? response.data.sufficientBalance
    //         : true,
    //     subscriptionType:
    //     response?.data?.subscriptionInformationDTO?.subscriptionType,
    //     deviceSerialNumber: response.data.deviceSerialNumber,
    //     packCreationDate:
    //     response.data?.partnerSubscriptionsDetails?.packCreationDate,
    //     loginWith: "RMN" ,
    //     rmn: response.data.rmn,
    //     emailId: response.data.emailId,
    //     subscriptionInformationDTO: response?.data?.subscriptionInformationDTO,
    //     packPrice: packDetails && packDetails.packPrice,
    //     packName: packDetails && packDetails.packName,
    //     planType: response.data?.subscriptionInformationDTO?.planType,
    //     rechargeDue: packDetails && packDetails.rechargeDue,
    //     dummyUser: response.data?.partnerSubscriptionsDetails?.dummyUser,

    //     //Added new login key to ensure that dth/binge popup is shown on login screen
    //     showLoginScreen: true,
    // };
    if (params.type === PARAMS_TYPE.LOGIN) {
        userInfo = {
            ...userInfo,
            sId: response.data.subscriberId,
            bingeSubscriberId: response.data.bingeSubscriberId, // It is subscriber id of non dth user which is coming from conviva backend
            baId: response.data.baId,
            profileId: response.data.profileId,
            parentalPinExist: response.data.parentalPinExist,
            accessToken: response.data.userAuthenticateToken,
            deviceToken: response.data.deviceAuthenticateToken,
            subscriptionStatus: response.data.subscriptionStatus,
            rmn: params.rmn,
            dthStatus: response.data.dthStatus,
            fsTaken: response.data.fsTaken,
            loggedIn: true,
            showLoginScreen: true,
            freeTrialAvailed: response.data.freeTrialAvailed,
            loginFreeTrialAvailed: response.data.loginFreeTrialAvailed,
            helpCenterSilentLogin: params?.helpCenterSilentLogin,
            subscriptionType: response?.data?.subscriptionType,
            freemiumUser: response?.data?.subscriptionType.toUpperCase() === SUBSCRIPTION_TYPE.FREEMIUM,
            firstTimeLoginDate: response.data.firstTimeLoginDate,
            mixpanelId: response?.data?.mixpanelid,
            packCreationDate: response?.data?.firstPaidPackSubscriptionDate,
            lastPackType: response?.data?.lastPackType,
            lastPackName: response?.data?.lastPackName,
            lastPackPrice: response?.data?.lastPackPrice,
            packStartDate: response?.data?.packStartDate,
            totalPaidPackRenewal: response?.data?.totalPaidPackRenewal,
            lastBillingType: response?.data?.lastBillingType,
            referenceId: response?.data?.referenceId,
            subscriptionStatusInfo: response?.data?.subscriptionStatusInfo,
            firstPaidPackSubscriptionDate: response?.data?.firstPaidPackSubscriptionDate,
            deviceSerialNumber: response.data.deviceSerialNumber,
            deviceList: deviceList,
            qrSource: response?.data?.qrSource || '',
            isDelinkedUser: response?.data?.delinkedUser || false, // flag for identification of delinked ATV user which is converted to new stack
            delinkedUserSaID: response?.data?.saId,
            // comviva id of ATV delnked user which is converted to new stack
        };
        setKey(LOCALSTORAGE.APP_LAUNCH_COUNTER, JSON.stringify(response?.data?.appLaunchCounter));
    }
    if (params.type === PARAMS_TYPE.USER_DETAILS) {
        userInfo = {
            ...userInfo,
            email: response?.data?.email,
            firstName: response?.data?.firstName,
            lastName: response?.data?.lastName,
            rmn: response?.data?.rmn,
            languageList: response?.data?.languageList,
            autoPlayTrailer: response?.data?.autoPlayTrailer,
            aliasName: response?.data?.aliasName,
            profileImage: response?.data?.profileImage,
            transactionalNotification: response?.data?.transactionalNotification,
            watchNotification: response?.data?.watchNotification,
        };
    }
    if (params.type === PARAMS_TYPE.SUBSCRIPTION_DETAILS) {
        userInfo = {
            ...userInfo,
            subscriptionStatus: get(response, 'data.subscriptionStatus', userInfo?.subscriptionStatus),
            subscriptionType: get(response, 'data.subscriptionType', userInfo?.subscriptionType),
            freemiumUser: response?.data?.subscriptionType ? response?.data?.subscriptionType === SUBSCRIPTION_TYPE.FREEMIUM : userInfo?.freemiumUser,
            segment: response?.data?.segment,
            ispEnabled: response?.data?.ispEnabled,
        };
    }
    setKey(LOCALSTORAGE.USER_INFO, JSON.stringify(userInfo));
    params?.type === PARAMS_TYPE.LOGIN && await initializeQoeSdk();
    callBackFunc();
};

export const initializeQoeSdk = async () => {
    let sdk = new TSAnalyticsMitigtionSDK()
    let userInfo = JSON.parse(getKey(LOCALSTORAGE.USER_INFO)) || {};
    let appProperties = {
        ApplicationName: "bitmovin_based_react_app",
        PlayerName: "bitmovin player",
        UEID: userInfo.rmn,
        SubscriberID: userInfo.sId,
    };
    await sdk.registerApplication(appProperties);
}

export const getParamsAPICall = () => {
    const user_info = JSON.parse(getKey(LOCALSTORAGE.USER_INFO));
    const authorization = user_info && user_info.accessToken;
    const { baId, sId, dthStatus } = user_info ? user_info : {};
    return { authorization, baId, sId, dthStatus };
};

export const getCommonHeaders = (accessToken, subscriberId) => {
    const user_info = JSON.parse(getKey(LOCALSTORAGE.USER_INFO));
    const authorization = user_info && user_info.accessToken;
    const { baId, sId, dthStatus } = user_info ? user_info : {};
    return {
        authorization: accessToken ? accessToken : authorization,
        subscriberId: subscriberId ? subscriberId : sId,
        // baId: baIdExist ? baIdExist : baId,
        dthStatus
    }
}

export const getHomeUrlHeader = (homeData) => { //DRP API headers respect to conditions
    let userInfo = JSON.parse(getKey(LOCALSTORAGE.USER_INFO))
    let anonymousId = getKey(LOCALSTORAGE.ANONYMOUS_ID);
    let packName = getPackName();
    if (homeData.drpState === DRP_STATE.TA) {
        return {
            Authorization: `bearer ${userInfo.accessToken}`,
            subscriberId: userInfo.sId,
            profileId: userInfo.profileId,
            platform: HEADER_CONSTANTS.BINGE_ANYWHERE_WEB,
            dthStatus: userInfo?.dthStatus,
            baId: userInfo.baId,
            packName
        }
    }
    else if (homeData.drpState === DRP_STATE.TA_GUEST) {
        return {
            anonymousid: anonymousId,
            subscriberId: anonymousId,
            profileId: anonymousId,
            platform: HEADER_CONSTANTS.BINGE_ANYWHERE_WEB,
            dthStatus: "Guest",
            packName
        }
    }
    else {
        return {
            platform: HEADER_CONSTANTS.BINGE_ANYWHERE,
        }
    }
}

export const updatePackDetailStorage = async (
    data,
    updatePeopleProperty = false,
    checkSubscriptionChange = false,
) => {
    const user_info = JSON.parse(getKey(LOCALSTORAGE.USER_INFO));
    if (data.packId) user_info["packId"] = data.packId;
    if (data.alternatePackId) user_info["alternatePackId"] = data.alternatePackId;
    if (data.expirationDate) user_info["expirationDate"] = data.expirationDate;
    if (data?.subscriptionInformationDTO?.bingeAccountStatus)
        user_info["bingeAccountStatus"] =
            data?.subscriptionInformationDTO?.bingeAccountStatus;
    if (data?.subscriptionInformationDTO?.subscriptionType)
        user_info["subscriptionType"] =
            data?.subscriptionInformationDTO?.subscriptionType;
    if (data.packCreationDate)
        user_info["packCreationDate"] = data.packCreationDate;
    if (data?.subscriptionInformationDTO?.planType)
        user_info["planType"] = data?.subscriptionInformationDTO?.planType;
    if (data?.packName) user_info["packName"] = data?.packName;
    if (data?.packPrice) user_info["packPrice"] = data?.packPrice;

    checkSubscriptionChange && (await checkSubscriptionTypeChanged(data));

    if (data?.subscriptionInformationDTO?.subscriptionType)
        user_info["subscriptionType"] =
            data?.subscriptionInformationDTO?.subscriptionType;
    if (data?.rechargeDue) user_info["rechargeDue"] = data?.rechargeDue;
    if (data?.dummyUser) user_info["dummyUser"] = data.dummyUser;

    setKey(LOCALSTORAGE.USER_INFO, JSON.stringify(user_info));
    data && setDeviceStatus(data?.deviceCancellationFlag);
    if (updatePeopleProperty) {
        mixPanelConfig.subscriptionDetailChanges();
        moengageConfig.subscriptionDetailChanges();
    }
};

export const checkSubscriptionTypeChanged = async (data) => {
    const user_info = JSON.parse(getKey(LOCALSTORAGE.USER_INFO));
    let currentSubscriptionType = user_info["subscriptionType"];
    let updatedSubscriptionType =
        data?.subscriptionInformationDTO?.subscriptionType;
    if (
        !(isEmpty(currentSubscriptionType) || isEmpty(updatedSubscriptionType)) &&
        currentSubscriptionType !== updatedSubscriptionType
    ) {
        await store.dispatch(getProfileDetails());
    }
};

export const getDate = (initialDate) => {
    const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
    ];
    let date;
    if (initialDate) {
        let date_components = initialDate.split("/");
        let day = date_components[0];
        let month = date_components[1];
        let year = date_components[2];
        date = new Date(year, month - 1, day);
    }
    date = date ? date : new Date();
    return `${date.getDate()} ${monthNames[date.getMonth()]
        } ${date.getFullYear()}`;
};

export const getAnalyticsRailCategory = (type, analytics = MIXPANEL) => {
    if (type === SECTION_TYPE.RAIL) {
        return analytics.VALUE.REGULAR_RAIL
    }
    return type?.toUpperCase()
}

export const getAnalyticsRailCategoryRegular = (type, analytics = MIXPANEL) => {
    if (type === SECTION_TYPE.RAIL) {
        return analytics.VALUE.REGULAR
    }
    return type
}

export const trackFilterToggle = (analytics = MIXPANEL, freeToggle, browseType, currentSubscription) => {
    let pathname = window.location.pathname;
    let source = getAnalyticsSource(pathname);
    let valueMix = analytics.VALUE
    let data = {
        [`${analytics.PARAMETER.SOURCE}`]: source,
        [`${analytics.PARAMETER.STATE}`]: freeToggle ? valueMix.FREE : valueMix.ALL_CONTENT,
        [`${analytics.PARAMETER.PACK_NAME}`]: currentSubscription?.productName,
        [`${analytics.PARAMETER.PACK_PRICE}`]: currentSubscription?.amountValue,
        [`${analytics.PARAMETER.FILTER_SELECTED}`]: browseType,
    }
    mixPanelConfig.trackEvent(MIXPANEL.EVENT.FILTER_TOGGLE, data);

}

export const getAnalyticsSource = (route, analytics = MIXPANEL) => {
    route = route?.toLowerCase();
    let routeUrl = route?.split("/");
    let routeUpdated = routeUrl?.[1] || routeUrl?.[0];
    let isBBLBBGPage = routeUrl?.includes(URL.BROWSE_BY_GENRE) || routeUrl?.includes(URL.BROWSE_BY_LANGUAGE);
    let source = "",
        SEE_ALL_URLS = [URL.SEE_ALL, URL.CONTINUE_WATCHING_SEE_ALL, URL.WATCHLIST_SEE_ALL, URL.RECOMMENDED_SEE_ALL, URL.TVOD, URL.TA_SEE_ALL, URL.FAVOURITES_SEE_ALL, URL.CONTINUE_PLAYING_SEE_ALL];
    if (
        route === URL.DEFAULT ||
        (routeUpdated &&
            [URL.HOME, URL.DEFAULT, URL.PARTNER].includes(routeUpdated))
    ) {
        source = analytics.VALUE.HOME;
    }
    else if (isBBLBBGPage) {
        let browseByValue = getBrowseByKeyFromUrl(routeUrl);
        if (browseByValue === URL.BROWSE_BY_LANGUAGE) {
            source = analytics.VALUE.BROWSE_BY_LANGUAGE;
        } else if (browseByValue === URL.BROWSE_BY_GENRE && routeUrl?.[1] === URL.GAMES) {
            source = analytics.VALUE.GENRE_RAIL_FOR_GAMES;
        } else if (browseByValue === URL.BROWSE_BY_GENRE) {
            source = analytics.VALUE.BROWSE_BY_GENRE;
        }
    }
    else if (routeUpdated === URL.MOVIES) {
        source = analytics.VALUE.MOVIES;
    } else if (routeUpdated === URL.TV_Shows) {
        source = analytics.VALUE.TV_SHOWS;
    } else if (routeUpdated === URL.SPORTS) {
        source = analytics.VALUE.SPORTS;
    } else if (routeUpdated === URL.LIVE_TV) {
        source = analytics.VALUE.LIVE_TV;
    } else if (routeUpdated === URL.WATCHLIST) {
        source = analytics.VALUE.WATCHLIST;
    } else if (routeUpdated === URL.DETAIL) {
        source = analytics.VALUE.CONTENT_DETAIL;
    } else if (routeUpdated === URL.SEARCH) {
        source = analytics.VALUE.SEARCH;
    } else if (routeUrl?.includes(URL.PLAYER)) {
        source = analytics.VALUE.PLAYER
    } else if (routeUrl?.includes(URL.DEVICE_MANAGEMENT)) {
        source = analytics.VALUE.DEVICE_MANAGEMENT
    } else if (routeUpdated === URL.EPISODE) {
        source = analytics.VALUE.EPISODE;
    }
    else if (routeUpdated === URL.BROWSE_BY && routeUrl?.[2]) {
        if (routeUrl[2]?.toUpperCase() === SECTION_SOURCE.LANGUAGE) {
            source = analytics.VALUE.BROWSE_BY_LANGUAGE;
        } else if (routeUrl[2]?.toUpperCase() === SECTION_SOURCE.GENRE) {
            source = analytics.VALUE.BROWSE_BY_GENRE;
        } else if (routeUrl[2]?.toLowerCase() === getFormattedURLValue(SECTION_SOURCE.GENRE_RAIL_FOR_GAMES)) {
            source = analytics.VALUE.GENRE_RAIL_FOR_GAMES;
        }
    } else if (SEE_ALL_URLS.includes(routeUpdated)) {
        source = analytics.VALUE.SEE_ALL;
    }

    return source;
};

export const getContentDetailSource = (
    route,
    contentType,
    contractName,
    analytics = MIXPANEL,
) => {
    route = route && route.toLowerCase();
    let routeUrl = route && route.split("/");
    let routeUpdated = route && routeUrl?.[1];
    let source = "",
        SEE_ALL_URLS = [URL.SEE_ALL, URL.CONTINUE_WATCHING_SEE_ALL, URL.WATCHLIST_SEE_ALL, URL.RECOMMENDED_SEE_ALL, URL.TVOD, URL.TA_SEE_ALL, URL.FAVOURITES_SEE_ALL, URL.CONTINUE_PLAYING_SEE_ALL];
    let isBBLBBGPage = routeUrl?.includes(URL.BROWSE_BY_GENRE) || routeUrl?.includes(URL.BROWSE_BY_LANGUAGE);

    if (
        route === URL.DEFAULT ||
        (routeUpdated &&
            [URL.HOME, URL.DEFAULT, URL.PARTNER].includes(routeUpdated))
    ) {
        source = analytics.VALUE.HOME;
    } else if (isBBLBBGPage) {
        let browseByValue = getBrowseByKeyFromUrl(routeUrl);
        if (browseByValue === URL.BROWSE_BY_LANGUAGE) {
            source = analytics.VALUE.BROWSE_BY_LANGUAGE;
        } else if (browseByValue === URL.BROWSE_BY_GENRE) {
            source = analytics.VALUE.BROWSE_BY_GENRE;
        }
    } else if (routeUpdated === URL.MOVIES) {
        source = analytics.VALUE.MOVIES;
    } else if (routeUpdated === URL.TV_Shows) {
        source = analytics.VALUE.TV_SHOWS;
    } else if (routeUpdated === URL.SPORTS) {
        source = analytics.VALUE.SPORTS;
    } else if (routeUpdated === URL.LIVE_TV) {
        source = analytics.VALUE.LIVE_TV;
    } else if (routeUpdated === URL.SEARCH) {
        source = analytics.VALUE.SEARCH_RESULTS;
    } else if (routeUpdated === URL.WATCHLIST) {
        source = analytics.VALUE.WATCHLIST;
    } else if (routeUpdated === URL.DETAIL) {
        if (contractName?.toUpperCase() === CONTRACT.RENTAL) {
            source = analytics.VALUE.TVOD;
        } else {
            if (
                [
                    CONTENTTYPE.TYPE_BRAND,
                    CONTENTTYPE.TYPE_BRAND_CHILD,
                    CONTENTTYPE.TYPE_CUSTOM_BRAND_DETAIL,
                ].includes(contentType?.toUpperCase())
            ) {
                source = analytics.VALUE.BRAND_SEASON;
            } else if (
                [
                    CONTENTTYPE.TYPE_SERIES,
                    CONTENTTYPE.TYPE_SERIES_CHILD,
                    CONTENTTYPE.TYPE_CUSTOM_SERIES_DETAIL,
                ].includes(contentType?.toUpperCase())
            ) {
                source = analytics.VALUE.SERIES;
            } else {
                source = analytics.VALUE.CONTENT_DETAIL;
            }
        }
    } else if (SEE_ALL_URLS.includes(routeUpdated)) {
        source = analytics.VALUE.SEE_ALL;
    }
    return source;
};

export const getAnalyticsContentType = (contentType, analytics = MIXPANEL) => {
    let result = "";
    if (
        contentType === CONTENTTYPE.TYPE_BRAND ||
        contentType === CONTENTTYPE.TYPE_CUSTOM_BRAND_DETAIL
    ) {
        result = analytics.VALUE.BRAND;
    } else if (
        contentType === CONTENTTYPE.TYPE_SERIES ||
        contentType === CONTENTTYPE.TYPE_CUSTOM_SERIES_DETAIL
    ) {
        result = analytics.VALUE.SERIES;
    } else if (
        contentType === CONTENTTYPE.TYPE_TV_SHOWS ||
        contentType === CONTENTTYPE.TYPE_CUSTOM_TV_SHOWS_DETAIL
    ) {
        result = analytics.VALUE.TV_SHOWS;
    } else if (
        contentType === CONTENTTYPE.TYPE_MOVIES ||
        contentType === CONTENTTYPE.TYPE_CUSTOM_MOVIES_DETAIL
    ) {
        result = analytics.VALUE.MOVIES;
    } else if (
        contentType === CONTENTTYPE.TYPE_TVOD ||
        contentType === CONTENTTYPE.TYPE_CUSTOM_TVOD_DETAIL
    ) {
        result = analytics.VALUE.TVOD;
    } else if (
        contentType === CONTENTTYPE.TYPE_WEB_SHORTS ||
        contentType === CONTENTTYPE.TYPE_CUSTOM_WEB_SHORTS_DETAIL
    ) {
        result = analytics.VALUE.WEB_SHORTS;
    }
    return result;
};

export const analyticsRailClickEvent = ({ item, data, currentSubscription }) => {
    const {
        sectionSource,
        contentPosition,
        title: railTitle = "",
        railPosition,
        isPartnerPage,
        pathname,
        configType,
        isExplore,
    } = data;
    const {
        title,
    } = item;

    if ([SECTION_SOURCE.PROVIDER, SECTION_SOURCE.LANGUAGE, SECTION_SOURCE.GENRE, SECTION_SOURCE.GENRE_RAIL_FOR_GAMES, SECTION_SOURCE.PROVIDER_BROWSE_APPS].includes(sectionSource)) {
        let analyticsData = {
            [MIXPANEL.PARAMETER.PAGE_NAME]: isPartnerPage ?
                MIXPANEL.VALUE.PARTNER_HOME :
                getAnalyticsSource(pathname, MIXPANEL),
            [MIXPANEL.PARAMETER.RAIL_TITLE]: railTitle,
            [MIXPANEL.PARAMETER.RAIL_POSITION]: railPosition,
            [MIXPANEL.PARAMETER.DEVICE_TYPE]: MIXPANEL.VALUE.WEB,
            [MIXPANEL.PARAMETER.PACK_PRICE]: currentSubscription?.amountValue || MIXPANEL.VALUE.DEFAULT_AMOUNT_VALUE,
        };

        let languageGenreData = {
            [MIXPANEL.PARAMETER.PACK_NAME]: currentSubscription?.productName || MIXPANEL.VALUE.FREEMIUM,
            [MIXPANEL.PARAMETER.PAGE_RESULT_SWIPE]: getKey(LOCALSTORAGE.PAGE_RESULT_SWIPE) || 0,
            [MIXPANEL.PARAMETER.FILTER_SELECTED]: MIXPANEL.VALUE.ALL,
        };

        let eventName = ''
        switch (sectionSource) {
            case SECTION_SOURCE.PROVIDER:
                analyticsData = {
                    ...analyticsData,
                    [MIXPANEL.PARAMETER.CONTENT_PARTNER]: title,
                    [MIXPANEL.PARAMETER.CONTENT_PARTNER_POSITION]: contentPosition + 1,
                    [MIXPANEL.PARAMETER.PACK_NAME]: currentSubscription?.productName || MIXPANEL.VALUE.FREEMIUM,
                }
                eventName = MIXPANEL.EVENT.REGULAR_APPS_RAIL_CLICK;
                break;
            case SECTION_SOURCE.LANGUAGE:
                analyticsData = {
                    ...analyticsData,
                    ...languageGenreData,
                    [MIXPANEL.PARAMETER.LANGUAGE_SELECTED]: title,
                    [MIXPANEL.PARAMETER.RAIL_LANGUAGE_POSITION]: contentPosition,
                }
                eventName = MIXPANEL.EVENT.BROWSE_BY_LANGUAGE_RAIL_CLICK
                break;
            case SECTION_SOURCE.GENRE:
                analyticsData = {
                    ...analyticsData,
                    ...languageGenreData,
                    [MIXPANEL.PARAMETER.GENRE_SELECTED]: title,
                    [MIXPANEL.PARAMETER.RAIL_GENRE_POSITION]: contentPosition,
                }
                eventName = MIXPANEL.EVENT.BROWSE_BY_GENRE_RAIL_CLICK;
                break;
            case SECTION_SOURCE.GENRE_RAIL_FOR_GAMES:
                analyticsData = {
                    ...analyticsData,
                    [MIXPANEL.PARAMETER.GENRE_SELECTED]: title,
                    [MIXPANEL.PARAMETER.RAIL_GENRE_POSITION]: contentPosition + 1,
                    [MIXPANEL.PARAMETER.PACK_NAME]: currentSubscription?.productName || MIXPANEL.VALUE.FREEMIUM,
                    [MIXPANEL.PARAMETER.PAGE_RESULT_SWIPE]: getKey(LOCALSTORAGE.PAGE_RESULT_SWIPE_GAMES) || 0,
                }
                eventName = MIXPANEL.EVENT.GAME_BROWSE_BY_GENRE_RAIL_CLICK;
                break;
            case SECTION_SOURCE.PROVIDER_BROWSE_APPS:
                analyticsData = {
                    ...analyticsData,
                    [MIXPANEL.PARAMETER.CONTENT_PARTNER]: title,
                    [MIXPANEL.PARAMETER.CONTENT_PARTNER_POSITION]: contentPosition,
                    [MIXPANEL.PARAMETER.RAIL_TYPE]: configType,
                    [MIXPANEL.PARAMETER.PACK_NAME]: currentSubscription?.productName || MIXPANEL.VALUE.FREEMIUM,
                    [MIXPANEL.PARAMETER.CLICK_TYPE]: isExplore ? MIXPANEL.VALUE.EXPLORE_CTA_CLICK : MIXPANEL.VALUE.LOGO_CLICK
                }
                eventName = MIXPANEL.EVENT.APPS_RAIL_CLICK;
                break;
        }
        mixPanelConfig.trackEvent(eventName, analyticsData);
    }
}

export const analyticsHomeClickEvent = (item, data) => {
    let {
        sectionSource,
        sectionType,
        contentPosition,
        title: railTitle = "",
        railPosition,
        isPartnerPage = false,
        pathname,
    } = data;
    if (
        !(
            sectionType?.toUpperCase() === SECTION_TYPE.RAIL &&
            sectionSource === SECTION_SOURCE.PROVIDER
        )
    ) {
        let section =
            sectionType === SECTION_TYPE.HERO_BANNER
                ? MIXPANEL.VALUE.HERO
                : MIXPANEL.VALUE.RAIL;
        const { position, contentType, provider, title, genre, language, releaseYear } = item;
        let mixPanelConfigType;
        if (sectionSource === SECTION_SOURCE.RECOMMENDATION) {
            mixPanelConfigType = MIXPANEL.VALUE.RECOMMENDATION;
        } else {
            mixPanelConfigType = MIXPANEL.VALUE.EDITORIAL;
        }

        let data = {
            [`${MIXPANEL.PARAMETER.SECTION}`]: `${section}`,
            [`${MIXPANEL.PARAMETER.HERO_BANNER_NUMBER}`]:
                sectionType === SECTION_TYPE.HERO_BANNER ? position : "",
            [`${MIXPANEL.PARAMETER.CONFIG_TYPE}`]: `${mixPanelConfigType}`,
            [`${MIXPANEL.PARAMETER.CONTENT_TYPE}`]: item?.sectionSource,
            [`${MIXPANEL.PARAMETER.PARTNER}`]: contentType?.toUpperCase() === CONTENTTYPE.TYPE_LIVE ? MIXPANEL.VALUE.TATAPLAY : `${provider}`,
            [`${MIXPANEL.PARAMETER.RAIL_TITLE}`]: railTitle,
            [`${MIXPANEL.PARAMETER.CONTENT_TITLE}`]: `${title}`,
            [`${MIXPANEL.PARAMETER.CONTENT_GENRE}`]: genre?.join() || "",
            [`${MIXPANEL.PARAMETER.RAIL_POSITION}`]:
                sectionType === SECTION_TYPE.HERO_BANNER
                    ? 0
                    : parseInt(railPosition) + 1,
            [`${MIXPANEL.PARAMETER.CONTENT_POSITION}`]: parseInt(contentPosition) + 1,
            [`${MIXPANEL.PARAMETER.PARTNER_HOME}`]: isPartnerPage
                ? MIXPANEL.VALUE.YES
                : MIXPANEL.VALUE.NO,
            [`${MIXPANEL.PARAMETER.PAGE_NAME}`]: isPartnerPage
                ? MIXPANEL.VALUE.PARTNER_HOME
                : getAnalyticsSource(pathname, MIXPANEL),
            [`${MIXPANEL.PARAMETER.CONTENT_LANGUAGE}`]: getContentLanguage(language) || "",
            [`${MIXPANEL.PARAMETER.RELEASE}`]: releaseYear || '',

        };

        mixPanelConfig.trackEvent(MIXPANEL.EVENT.HOME_CLICK, data);
        moengageConfig.trackEvent(MOENGAGE.EVENT.HOME_CLICK, data);
    }
};

export const checkEmptyValue = (name, value, errorName, currentState) => {
    let element =
        document.getElementsByName(name) && document.getElementsByName(name)[0];
    if (!value.length) {
        currentState.errors[name] = `Please ${errorName}`;
        currentState.setState(
            {
                errors: currentState.errors,
            },
            () => {
                currentState.errors[name] && element.classList.add("error");
            },
        );
    }
};

export const getProviderLogo = () => {
    const { headerDetails } = store.getState();
    let configResponse = get(headerDetails, "configResponse");
    return get(configResponse, "data.config.providerLogo");
};

export const scrollToTop = () => {
    document.documentElement.scrollTop = 0;
};
export const convertEpochTimeStamp = (epochTimeMilliseconds, isLive) => {
    let currentDate = new Date(), // to get current date with timestamp
        currentTimeMilliseconds = currentDate.getTime(), // to get the number of milliseconds since currentDate
        timeDifference = epochTimeMilliseconds - currentTimeMilliseconds, // to get diff between epoch and current time
        timeLeft = timeDifference / 60000,
        timeLeftLabel,
        timeLeftInHours = timeLeft / 60, time,
        timeLeftInSeconds = timeLeft * 60;

    if (isLive) {
        return Math.ceil(timeLeftInSeconds);
    }

    if (timeLeft < 60) {
        time = Math.ceil(timeLeft);
        timeLeftLabel = (time < 0 ? 0 : time) + " min";
        return timeLeftLabel;
    } else if (timeLeftInHours <= 48) {
        timeLeftLabel = String(Math.ceil(timeLeftInHours)) + " hrs";
        return timeLeftLabel;
    } else {
        let timeLeftInDays = timeLeft / 1440;
        timeLeftLabel = Math.ceil(timeLeftInDays) + " Days";
        return timeLeftLabel;
    }
};

export const filterTVODContent = (list, tvodDetail) => {
    list = list?.filter((item, index) => {
        if (item.contractName === CONTRACT.RENTAL) {
            let id = item.id ? item.id : item.contentId;
            let data = tvodDetail?.items?.find((i) => i.id === id);
            item.rentalExpiry = data ? data.rentalExpiry : null;
            return !(data?.rentalStatus !== RENTAL_STATUS.ACTIVE || !data);
        } else {
            return true;
        }
    });
    return list;
};

export const showRail = (railItem) => {
    if (get(railItem, "contentList") && railItem.contentList.length) {
        return isUserloggedIn() ? !(get(railItem, "mixedRail") && !get(railItem, "paintMixedRail")) : true;
    } else if (
        ([SECTION_SOURCE.LANGUAGE_NUDGE, SECTION_SOURCE.PROMO_BANNER].includes(railItem.sectionSource))
    ) {
        return true;
    }
    return false;
};
export const getPackName = () => {
    let guestUser = !isUserloggedIn(),
        packName = PACK_NAME.FREEMIUM,
        { packExpired } = getPackInfo(),
        result = getPartnerSubscriptionInfo();
    if (guestUser) {
        packName = PACK_NAME.GUEST;
    } else {
        if (!packExpired) {
            packName = result ? result?.productName : packName;
        } else {
            packName = PACK_NAME.FREEMIUM;
        }
    }

    return packName;
}

export const getHomepageUrl = (url, isPartnerPage, providerId) => {
    let subscribed = checkIsUserSubscribed(isPartnerPage, providerId),
        guestUser = !isUserloggedIn(),
        packName = PACK_NAME.FREEMIUM,
        { packExpired } = getPackInfo(),
        result = getPartnerSubscriptionInfo();

    url = subscribed
        ? `${url}&Subscribed=${subscribed}`
        : `${url}&Subscribed=${subscribed}&UnSubscribed=${!subscribed}`;

    if (guestUser) {
        packName = PACK_NAME.GUEST;
    } else {
        if (!packExpired) {
            packName = result ? result?.productName : packName;
        } else {
            packName = PACK_NAME.FREEMIUM;
        }
    }

    url = `${url}&packName=${packName}`;

    return url;
};

/**
 * Checks if user is subscribed user or unsubscried user
 * @param isPartnerPage
 * @param providerId
 * @returns {boolean}
 */
export const checkIsUserSubscribed = (isPartnerPage, providerId) => {
    let result = getPartnerSubscriptionInfo();
    let subscribed = false, partnerList = getComponentList(result)?.partnerList;
    let state = store.getState();
    let subscriptionStatus = get(state, "subscriptionDetails.currentSubscription.data.subscriptionStatus");

    if (!isEmpty(partnerList)) {
        if (isPartnerPage) {
            subscribed =
                partnerList &&
                partnerList.some((i) => {
                    return parseInt(i.partnerId) === parseInt(providerId);
                });
        } else {
            subscribed = isUserloggedIn() ? partnerList?.length > 0 : false;
        }
    }
    if (isUserloggedIn() && ((subscriptionStatus?.toUpperCase() === SUBSCRIPTION_STATUS.DEACTIVE) || (subscriptionStatus?.toUpperCase() === SUBSCRIPTION_STATUS.EXPIRED))) {
        subscribed = false;
    }

    return subscribed;
};

export const getPartnerSubscriptionInfo = () => {
    let state = store.getState(),
        newUserLoginDetails = get(
            store.getState(),
            "loginReducer.newUser",
            {},
        ),
        existingUserLoginDetails = get(
            store.getState(),
            "loginReducer.existingUser",
            {},
        ),
        response = get(state, "subscriptionDetails.currentSubscription.data");

    if (!isEmpty(response)) {
        return response;
    } else {
        let result;
        if (!isEmpty(newUserLoginDetails)) {
            result = get(newUserLoginDetails, "data");
        } else if (!isEmpty(existingUserLoginDetails)) {
            result = get(existingUserLoginDetails, "data");
        }
        return result ? result : false;
    }
};

export async function getUserLoginDetails() {
    let state = store.getState();
    let newUserLoginDetails = get(state, "loginReducer.newUser", {}),
        existingUserLoginDetails = get(state, "loginReducer.existingUser", {}),
        response = get(state, "subscriptionDetails.currentSubscription.data");
    if (
        isEmpty(newUserLoginDetails) &&
        isEmpty(existingUserLoginDetails) &&
        isEmpty(response) &&
        isUserloggedIn()
    ) {
        await store.dispatch(getCurrentSubscriptionInfo(false));
    }
}

export const getPlayerHeaderParams = () => {
    let userInfo = JSON.parse(getKey(LOCALSTORAGE.USER_INFO)) || {};
    let accessToken = userInfo.accessToken;
    return {
        locale: "IND",
        deviceId: getDeviceId(),
        deviceType: "WEB",
        authorization: `bearer ${accessToken}`,
        platform: "BINGE_ANYWHERE",
        baId: userInfo?.baId,
        subscriberId: userInfo?.sId,
        dthStatus: userInfo?.dthStatus
    };
};

export const getPubnubChannelName = () => {
    let userInfo = JSON.parse(getKey(LOCALSTORAGE.USER_INFO)) || {};
    //return 'sub_3000583801';
    let dthStatus = userInfo.dthStatus;

    if (dthStatus === DTH_TYPE.DTH_W_BINGE_OLD_USER) {
        return userInfo.sId ? `sub_${userInfo.sId}` : null
    } else {
        let pubnubData = JSON.parse(getKey(LOCALSTORAGE.ACCOUNT_PUBNUB_DETAILS)),
            rmnChannelName = userInfo.rmn ? `rmn_${userInfo.rmn}` : null,
            channelName = pubnubData?.isAccountChannelSubscribed && pubnubData?.pubnubChannelName
                ? pubnubData?.pubnubChannelName
                : rmnChannelName;
        return channelName;
    }
};

export const getMediaReadyChannelName = () => {
    let mediaReadyConfig = getMediaReadyConfig();
    return get(mediaReadyConfig, 'binge_web.bingeWeb');
}

export const getDateFromString = (data) => {
    let selectedDate = data;
    if (typeof data === "string" && data.includes("/")) {
        const [d, m, y] = data.split("/");
        selectedDate = new Date(y, m - 1, d);
    }
    return selectedDate;
};

export const dateTimeConversion = (data) => {
    let selectedDate = data;
    if (typeof data === "string" && data.includes("/")) {
        const [d, m, y] = data.split("/");
        selectedDate = new Date(y, m - 1, d);
    }
    let year = selectedDate.getFullYear();
    let date = selectedDate.getDate();
    let month = selectedDate.getMonth() + 1;

    if (date < 10) date = "0" + date;

    if (month < 10) month = "0" + month;

    let cur_day = year + "-" + month + "-" + date;

    let hours = selectedDate.getHours();
    let minutes = selectedDate.getMinutes();
    let seconds = selectedDate.getSeconds();

    if (hours < 10) hours = "0" + hours;

    if (minutes < 10) minutes = "0" + minutes;

    if (seconds < 10) seconds = "0" + seconds;

    return cur_day + " " + hours + ":" + minutes + ":" + seconds;
};

export const checkPartnerSubscribed = (currentSubscription, partnerId, provider) => {
    let subscribed = false, componentList = getComponentList(currentSubscription);
    if (!isEmpty(componentList?.partnerList) && currentSubscription?.subscriptionStatus?.toUpperCase() === SUBSCRIPTION_STATUS.ACTIVE) {
        if (partnerId !== "" && partnerId !== undefined && partnerId !== null && partnerId !== 0) {
            subscribed = !!componentList?.partnerList.find(item => item.included && parseInt(item?.partnerId) === parseInt(partnerId));
        } else {
            subscribed = componentList?.partnerList.some(item => {
                if (item.included) {
                    let partnerName = [PROVIDER_NAME.ZEE5, PROVIDER_NAME.ZEE_FIVE].includes(item?.partnerName?.toLowerCase());
                    let providerName = [PROVIDER_NAME.ZEE5, PROVIDER_NAME.ZEE_FIVE].includes(provider?.toLowerCase());
                    if (partnerName && providerName) {
                        return true;
                    } else if (item?.partnerName?.toLowerCase() === provider?.toLowerCase()) {
                        return true;
                    }
                    return false;
                }
            })
        }
    }
    return subscribed;
};

export const checkPartnerPlayable = (partnerId, provider) => {
    let state = store.getState();
    let currentSubscription = get(state.subscriptionDetails, 'currentSubscription');
    let response = false;
    if (isEmpty(currentSubscription)) {
        return store
            .dispatch(getCurrentSubscriptionInfo(false, true))
            .then((resp) => {
                return checkSubscribedPartnerContent(resp, partnerId, provider);
            });
    } else {
        response = checkSubscribedPartnerContent(currentSubscription, partnerId, provider);
    }
    return response;
};

export const checkSubscribedPartnerContent = (currentSubscription, partnerId, provider) => {
    let data = currentSubscription?.data;
    return checkPartnerSubscribed(data, partnerId, provider);
    /* if (
       data?.dthStatus?.toUpperCase() === ACCOUNT_STATUS.ACTIVE &&
       data?.status?.toUpperCase() === ACCOUNT_STATUS.ACTIVE &&
       subscribed
   ) {
       return true;
   } else if (
       data?.status?.toUpperCase() === ACCOUNT_STATUS.ACTIVE &&
       data?.migrated &&
       data?.packType?.toLowerCase() === PACK_TYPE.PAID &&
       subscribed
   ) {
       return true;
   }
   return false;*/

};

export const loginInFreemium = async (props) => {
    const {
        openPopup,
        closePopup,
        source,
        ComponentName,
        selectedPlan,
        isfromMiniModal,
        partnerData,
        fromLogin,
        cartId,
        isFromCampaign,
        validateJourneySource,
        rmnValue,
        fireBaseSource,
    } = props;
    // if (ComponentName === MINI_SUBSCRIPTION.LOGIN) {
    //     let mixpanel = { [`${MIXPANEL.PARAMETER.SOURCE}`]: source || '', }
    //     trackEvent.loginEnter(source);
    //     source === MIXPANEL.VALUE.DISCOUNTING_PAGE && (mixpanel = { ...mixpanel, ...{ [`${MIXPANEL.PARAMETER.PAGE_NAME}`]: source || '', } })
    //     mixPanelConfig.trackEvent(MIXPANEL.EVENT.LOGIN_PAGE_VISIT, mixpanel);
    // }
    // moved this code to login main component because of distinct ID mismatch ap app launch when user coming from manage app for login
    if (isMobile.any()) {
        store.dispatch(openMiniSubscription({
            isfromMiniModal: isfromMiniModal,
            selectedPlan: selectedPlan,
            ComponentName: ComponentName,
            partnerData: partnerData,
            source: source,
            fromLogin: fromLogin,
            cartId: cartId,
            isFromCampaign: isFromCampaign,
            validateJourneySource: validateJourneySource,
            rmnValue: rmnValue,
        }));
    } else {
        store.dispatch(closePopup());
        let modalClass = "";
        let childComponent = null;
        if (ComponentName === MINI_SUBSCRIPTION.LOGIN) {
            modalClass = `alert-modal login-with-otp-modal ${source === 'DISCOUNTING PAGE' ? 'rm-overlay' : ''}`;
            childComponent = <Login
                source={source}
                selectedPlan={selectedPlan}
                isFromCampaign={isFromCampaign}
                cartId={cartId}
                ComponentName={MINI_SUBSCRIPTION.LOGIN}
                validateJourneySource={validateJourneySource}
                rmnValue={rmnValue}
                fireBaseSource={fireBaseSource}
            />;
        } else if (ComponentName === MINI_SUBSCRIPTION.PLAN_SELECT) {
            modalClass = "alert-modal plan-wrapper";
            childComponent = <PlanSelection source={source} partnerData={partnerData} selectedPlan={selectedPlan} isFromCampaign={isFromCampaign} />;
        } else if (ComponentName === MINI_SUBSCRIPTION.CHANGE_TENURE) {
            modalClass = "tenure-selection-modal";
            childComponent = <ChangeTenureModal selectedPlan={selectedPlan} isfromMiniModal={isfromMiniModal} isFromCampaign={isFromCampaign} source={source} />;
        } else if (ComponentName === MINI_SUBSCRIPTION.SELECTION_DRAWER) {
            modalClass = "alert-modal selection-drawer";
            childComponent = <SelectionDrawer source={source} partnerData={partnerData} />;
        } else {
            modalClass = "alert-modal plan-wrapper";
            childComponent = <PlanSelection source={source} partnerData={partnerData} />;
        }

        store.dispatch(
            openPopup(MODALS.CUSTOM_MODAL, {
                modalClass,
                childComponent,
                hideCloseIcon: true,
                isPadding: false,
            })
        );
    }
};

export const showLoginScreen = async (openPopup, closePopup, openLoginPopup, partnerData, componentName, loginSource) => {
    return await loginInFreemium({
        openPopup,
        closePopup,
        openLoginPopup,
        source: loginSource ? loginSource : MIXPANEL.VALUE.PLAYBACK,
        ComponentName: componentName,
        partnerData: partnerData,
        fireBaseSource: FIREBASE.VALUE.PLAYBACK,
    });
};

export const getPartnerSubscriptionType = (meta, lastWatch) => {
    let playMovieBtn = getPlayAction(get(lastWatch, 'durationInSeconds'), get(lastWatch, 'secondsWatched'));
    let isPlayButton = playMovieBtn?.includes(PLAY_ACTION.PLAY), isResumeButton = playMovieBtn?.includes(PLAY_ACTION.RESUME), partnerSubscriptionType;
    if (!isUserloggedIn()) {
        if (isPlayButton) {
            partnerSubscriptionType = meta?.firstEpisodeSubscriptionType || meta?.partnerSubscriptionType;
        } else {
            partnerSubscriptionType = meta?.partnerSubscriptionType;
        }
    }
    else {
        partnerSubscriptionType = isResumeButton && !meta?.isEpisodeContent ? lastWatch?.partnerSubscriptionType : (meta?.firstEpisodeSubscriptionType || meta?.partnerSubscriptionType);
    }
    return partnerSubscriptionType?.toUpperCase();
}

/**
 * @function trackChangeAppFDORaisedPopupMixpanel - function to track ChangeAppFDORaised popu specific events
 * @param {*} eventName 
 * @param {*} meta 
 */
export const trackChangeAppFDORaisedPopupMixpanel = (eventName, meta) => {
    mixPanelConfig.trackEvent(eventName, {
        [`${MIXPANEL.PARAMETER.CONTENT_PARENT_TITLE}`]: meta?.brandTitle ? meta?.brandTitle : meta?.title,
        [`${MIXPANEL.PARAMETER.CONTENT_TITLE}`]: meta?.title ? meta?.title : meta?.vodTitle,
        [`${MIXPANEL.PARAMETER.CONTENT_PARTNER}`]: meta?.provider,
    });
};

export const checkPlayBackEligibility = async (meta, openPopup, closePopup, openLoginPopup, history, lastWatch, contractName) => {
    let partnerSubscriptionType = getPartnerSubscriptionType(meta, lastWatch);
    let playMovieBtn = getPlayAction(get(lastWatch, 'durationInSeconds'), get(lastWatch, 'secondsWatched'));
    let isPlayButton = playMovieBtn?.includes(PLAY_ACTION.PLAY);
    let firstEpisodeSubscriptionType = isPlayButton && (partnerSubscriptionType === PARTNER_SUBSCRIPTION_TYPE.FREE || partnerSubscriptionType === PARTNER_SUBSCRIPTION_TYPE.FREE_ADVERTISEMENT);
    let isIVOD = meta?.provider?.toLowerCase() === PROVIDER_NAME.TATASKY && contractName !== CONTRACT.RENTAL;
    let state = store.getState();
    let isMxPlayer = meta?.provider?.toLowerCase() === PROVIDER_NAME.MX_PLAYER;
    await store.dispatch(checkFallbackFlow());
    isMxPlayer && store.dispatch(hideMainLoader());
    let isManagedApp = get(state.headerDetails, "isManagedApp"),
        flexiAppSelctionRequired = get(state.subscriptionDetails, 'currentSubscription.data.appSelectionRequired');

    if (!isUserloggedIn()) {
        if (isIVOD) {
            return await showLoginScreen(openPopup, closePopup, openLoginPopup, meta, MINI_SUBSCRIPTION.LOGIN);
        }
        if (partnerSubscriptionType === PARTNER_SUBSCRIPTION_TYPE.PREMIUM) {
            if (!isManagedApp) {
                return safeNavigation(history, {
                    pathname: `/${URL.SUBSCRIPTION}`,
                    state: { partnerName: meta, isFromPi: true, url: `${window.location.pathname}${window.location.search}` }
                });
            } else {
                const state = store.getState()
                const enableTickTickJourney = get(state.headerDetails, 'configResponse.data.config.enableTickTickJourney');
                if (enableTickTickJourney) {
                    await showLoginScreen(openPopup, closePopup, openLoginPopup, meta, MINI_SUBSCRIPTION.SELECTION_DRAWER)
                } else {
                    handlePiRedirection(history);
                    store.dispatch(getWebPortalLink({ initiateSubscription: JOURNEY_SOURCE.CONTENT_PLAY, journeySource: JOURNEY_SOURCE.HOME_CONTENT, journeySourceRefId: get(meta, 'partnerId'), analyticSource: MIXPANEL.VALUE.PLAYBACK }))
                }
            }

        }
        else if (partnerSubscriptionType === PARTNER_SUBSCRIPTION_TYPE.FREE || firstEpisodeSubscriptionType) {
            return await showLoginScreen(openPopup, closePopup, openLoginPopup, meta, MINI_SUBSCRIPTION.LOGIN);
        }
        else {
            await store.dispatch(checkPlaybackEligibility(true));
            const { PIDetails } = store.getState();
            let eligibleForFreePlayback = get(PIDetails, 'eligibleForFreePlayback');
            if (eligibleForFreePlayback?.code === 0) {
                if (!eligibleForFreePlayback?.data?.contentPlayBackAllowed) {
                    return await showLoginScreen(openPopup, closePopup, openLoginPopup, meta, MINI_SUBSCRIPTION.LOGIN);
                } else {
                    return true;
                }
            } else {
                return await showLoginScreen(openPopup, closePopup, openLoginPopup, meta, MINI_SUBSCRIPTION.LOGIN);
            }
        }
    }
    else {
        if (firstEpisodeSubscriptionType || partnerSubscriptionType !== PARTNER_SUBSCRIPTION_TYPE.PREMIUM) {
            return true;
        }
        else {
            //to check partner subscribed or not
            let partnerPlayable = (meta?.partnerId || meta?.provider) && (await checkPartnerPlayable(meta?.partnerId, meta?.provider));
            // partnerPlayable = true;
            let isTataSkyProvider = meta?.provider?.toLowerCase() === PROVIDER_NAME.TATASKY;

            if (partnerPlayable || isTataSkyProvider) {
                return true;
            }
            // checksum code commented
            // else if (partnerPlayable) {
            //     let checkSomeValidated = validateChecksomeValue();
            //     return checkSomeValidated ? true : showChecksomeError(history);
            // }
            else {
                await store.dispatch(getCurrentSubscriptionMyplanVerbiages());
                const state = store.getState();
                let myPlanVerbiagesResponse = get(state.subscriptionDetails, "myPlanVerbiages", {}),
                    myPlanVerbiages = myPlanVerbiagesResponse?.myPlanVerbiages,
                    isChangeAppFDORaised = myPlanVerbiages?.partnerChangeFDORaised && myPlanVerbiages?.partnerForFDORaised?.toLowerCase() === meta?.provider?.toLowerCase(),
                    appSelectionPopupVerbiages = myPlanVerbiagesResponse?.appSelectionPopupVerbiages;

                // isChangeAppFDORaised - flag maintained for case in which user is on active tick tick plan and partner change FDO is raised from change app option
                let currentSubscription = get(state, "subscriptionDetails.currentSubscription.data");
                if (flexiAppSelctionRequired && !isEmpty(appSelectionPopupVerbiages)) {
                    store.dispatch(openPopup(MODALS.CUSTOM_MODAL, {
                        modalClass: "alert-modal flexi-plan-container ",
                        childComponent: (
                            <FlexiPlanModal AnalyticSource={MIXPANEL.VALUE.CONTENT_PLAYBACK} source={MIXPANEL.VALUE.CONTENTPLAYBACK}
                            />
                        ),
                        closeModal: true,
                        hideCloseIcon: false,
                        onCloseAction: () => {
                            mixPanelConfig.trackEvent(MIXPANEL.EVENT.ZERO_APPS_NUDGE_CLOSE, {
                                [`${MIXPANEL.PARAMETER.SOURCE}`]: MIXPANEL.VALUE.CONTENTPLAYBACK
                            })
                        }
                    }))
                    return false;
                }
                await openSubscriptionPopup({ currentSubscription, meta, history, isManagedApp, isChangeAppFDORaised });
                if (!partnerPlayable && currentSubscription == null) {
                    mixPanelConfig.trackEvent(MIXPANEL.EVENT.SUBSCRIBE_POPUP);
                } else {
                    isChangeAppFDORaised ?
                        trackChangeAppFDORaisedPopupMixpanel(MIXPANEL.EVENT.PLAN_UPGRADE_CONFIRMATION_VIEW, meta) :
                        mixPanelConfig.trackEvent(MIXPANEL.EVENT.UPGRADE_POPUP);
                }
            }
        }
    }
}


export const showChecksomeError = (history) => {
    trackContentPlayFailure(history);
    let checksomeMismatchVerbiages = getVerbiages(CATEGORY_NAME.CHECKSOME_MISMATCH);

    store.dispatch(
        openPopup(MODALS.ALERT_MODAL, {
            modalClass: 'alert-modal error-alert',
            instructions: get(checksomeMismatchVerbiages, "others.restrictPlayBackUIAlert") || "You are not authorized to play this content",
            primaryButtonText: "Ok",
            primaryButtonAction: () => {
                store.dispatch(closePopup());
            },
            hideCloseIcon: true,
        }),
    );
};

/**
 * 
 * @returns Check if checksome coming from BE and checksome created on FE is same or
 * refer: https://gist.github.com/kcak11/86f73703eff5bbd2f7bd6b6b3efded34
 */
export const validateChecksomeValue = () => {
    const state = store.getState();
    let currentSubscription = get(state, "subscriptionDetails.currentSubscription.data");
    let checkSumKey = get(state, "headerDetails.configResponse.data.config.checkSumKey");
    let currentChecksome = currentSubscription?.checkSum;
    let userInfo = JSON.parse(getKey(LOCALSTORAGE.USER_INFO)) || {};
    let baId = userInfo?.baId,
        profileId = userInfo?.profileId,
        rmn = userInfo?.rmn,
        packId = currentSubscription?.productId,
        packStatus = currentSubscription?.subscriptionStatus;

    let dataToEncrypt = `${packId}_${baId}_${profileId}_${rmn}_${packStatus}`;
    let key = CryptoJS.enc.Utf8.parse(checkSumKey);
    let iv = CryptoJS.enc.Utf8.parse(checkSumKey?.substring(0, 16));

    /*-- Encryption --*/
    let encryptedChecksome = CryptoJS.AES.encrypt(dataToEncrypt, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    }).toString();

    console.log(encryptedChecksome, "encryptedChecksome");
    return encryptedChecksome == currentChecksome;
}

export const checkLivePlaybackEligibility = async (openPopup, closePopup, openLoginPopup, meta, isFromPi = false) => {
    if (isUserloggedIn()) {
        return true;
    } else {
        await showLoginScreen(openPopup, closePopup, openLoginPopup, meta, MINI_SUBSCRIPTION.LOGIN);
        return false;
    }
}


export const playContent = async (props, type) => {
    const {
        history,
        checkForNonIntegratedPartner,
        contentType,
        id,
        meta,
        mixpanelData,
        playZee5Content,
        playHotStarContent,
        deepLinkContent,
        redirectionHandling,
        isTVOD,
        tvodExpiry,
        lastWatch,
        openPopup,
        closePopup,
        openLoginPopup,
        contractName,
        meta: { taShowType },
        handleGenericRedirectionPopup,
        playPrimeDeeplink,
        seriesList
    } = props;

    let checkedId = lastWatch.secondsWatched > 0 ? id : (meta.vodId || id),
        checkedContentType = lastWatch.secondsWatched > 0 ? lastWatch.contentType : meta.vodContentType || meta.contentType,
        refUseCaseId = get(props, 'history.location.state.refUseCase', ''),
        taRecommendedRail = get(props, 'history.location.state.taRecommendedRail', false);
    const refUseCase = getRefUseCase(refUseCaseId, taRecommendedRail, taShowType);
    const seriesId = seriesList.length > 0  && seriesList?.[meta?.season - 1]?.id;
    let learnData = {
        contentType: checkedContentType,
        learnActionType: LEARN_ACTION_TYPE.CLICK,
        id: checkedId,
        provider: meta.provider,
        refUseCase,
        seriesId: seriesId,
        mixPanelData: trackContentPlayFailure(history, true)
    }

    if (!checkForNonIntegratedPartner(meta?.provider, false)) return;

    if (getKey(LOCALSTORAGE.SUBTITLE)) {
        deleteKey(LOCALSTORAGE.SUBTITLE);
    }
    const systemDetail = getSystemDetails();
    let providerLowerCase = meta?.provider?.toLowerCase();

    let playbackAllowed = await checkPlayBackEligibility(meta, openPopup, closePopup, openLoginPopup, history, lastWatch, contractName);
    let checkSubscription = checkPartnerSubscriptionType(meta?.partnerId);
    let playMovieBtn = getPlayAction(get(lastWatch, 'durationInSeconds'), get(lastWatch, 'secondsWatched'));
    let isPlayButton = playMovieBtn?.includes(PLAY_ACTION.PLAY) || playMovieBtn?.includes(PLAY_ACTION.RESUME);
    let firstEpisodeSubscriptionType = isPlayButton && [PARTNER_SUBSCRIPTION_TYPE.FREE, PARTNER_SUBSCRIPTION_TYPE.FREE_ADVERTISEMENT].includes(meta?.firstEpisodeSubscriptionType?.toUpperCase());
    let isIVOD = providerLowerCase === PROVIDER_NAME.TATASKY && contractName !== CONTRACT.RENTAL && isMobile.any() && (systemDetail.browser === BROWSER_TYPE.CHROME || systemDetail.browser === BROWSER_TYPE.FIREFOX);
    let isVootSafari = (providerLowerCase === PROVIDER_NAME.VOOTSELECT || providerLowerCase === PROVIDER_NAME.VOOTKIDS) && systemDetail.browser === BROWSER_TYPE.SAFARI;
    let { genericAuthType } = getGenericAuthType(providerLowerCase);

    const state = store.getState();
    let cwEventProviders = get(state.headerDetails, 'configResponse.data.config.cwEventProviders'),
        isProviderPresent = cwEventProviders?.find(i => i.toLowerCase() === providerLowerCase);

    if (isProviderPresent) {
        await addPartnerToContinueWatching(PROVIDER_EVENT_TYPE.PLAYBACK_START, meta?.provider);
    }

    if (playbackAllowed) {
        const handleCancelledUser = getDeviceStatus();

        if (handleCancelledUser) {
            await handleDeviceCancelledUser(history, false, MIXPANEL.VALUE.CONTENT);
        } else {
            let { useCWData, cwData } = getCWData(type, meta);
            if (providerLowerCase === PROVIDER_NAME.ZEE5) {
                let deeplink = meta?.isEpisodeContent
                    ? meta?.partnerWebUrl
                    : lastWatch?.partnerWebUrl
                        ? lastWatch?.partnerWebUrl
                        : meta?.partnerWebUrl;
                playZee5Content(deeplink, useCWData, cwData, learnData);
            } else if (providerLowerCase === PROVIDER_NAME.HOTSTAR) {
                let deeplink = meta?.isEpisodeContent
                    ? meta?.hotstarWebDeeplink
                    : lastWatch?.hotstarWebDeeplink
                        ? lastWatch?.hotstarWebDeeplink
                        : meta?.hotstarWebDeeplink
                playHotstarDeepLink(deeplink, true, playHotStarContent, learnData, useCWData, cwData);
            } else if (isVootSafari || isIVOD) {
                let msg = 'Voot is currently not available on Safari. Download the Tata Play Binge app or switch to Chrome/Firefox/Edge to watch Voot content.';
                msg = isIVOD ? 'Tata Play is currently not available on Mobile Chrome. Download the Tata Play Binge app to watch content.' : msg;
                deepLinkContent(msg);
            } else if (providerLowerCase === PROVIDER_NAME.VOOTSELECT && meta?.partnerSubscriptionType?.toUpperCase() === PARTNER_SUBSCRIPTION_TYPE.FREE && checkSubscription !== SUBSCRIPTION_TYPE_HEADER.SUBSCRIBED || (firstEpisodeSubscriptionType && meta?.provider?.toLowerCase() === PROVIDER_NAME.VOOTSELECT && checkSubscription !== SUBSCRIPTION_TYPE_HEADER.SUBSCRIBED)) {
                let userInfo = JSON.parse(getKey(LOCALSTORAGE.USER_INFO)) || {};
                const { rmn: mobileNumber, profileId } = userInfo;
                let data = { "rmn": mobileNumber, "uniqueId": profileId }
                await store.dispatch(vootTokenapi(data))
                const state = store.getState();
                let vootContentparams = get(state.searchReducer, 'vootContentparams');
                let finalDeepLinkURL = lastWatch?.partnerDeepLinkUrl ? lastWatch?.partnerDeepLinkUrl : meta?.partnerDeepLinkUrl;
                let contentData = vootContentparams.data;
                for (var res in contentData) {
                    finalDeepLinkURL = `${finalDeepLinkURL}&${res}=${vootContentparams.data[res]}`;
                }
                redirectionHandling(finalDeepLinkURL, true, learnData, useCWData, cwData);
            } else if ([PROVIDER_NAME.CHAUPAL, PROVIDER_NAME.AHA, PROVIDER_NAME.TRAVEL_XP, PROVIDER_NAME.VROTT].includes(providerLowerCase) && systemDetail.os.toLowerCase() === OS.IOS.toLowerCase() && systemDetail.browser === BROWSER_TYPE.CHROME) {
                deeplinkToPIPage();
            } else if (providerLowerCase === PROVIDER_NAME.APPLE) {
                //For apple tv content play event will be fired on play button click only and not on redirection
                //mixpanelPlayContent(props, true);
                handleApplePIBtnCta(props, learnData, useCWData, cwData);
            } else if (genericAuthType === PLAY_AUTH_TYPE.DEEP_LINK) {
                if (providerLowerCase === PROVIDER_NAME.PRIME) {
                    let deeplink = meta?.isEpisodeContent ? meta?.primeWebDeeplink : lastWatch?.primeWebDeeplink !== null ? lastWatch?.primeWebDeeplink : meta?.primeWebDeeplink;
                    handlePrimePlaybility(deeplink, playPrimeDeeplink, learnData, useCWData, cwData, history);
                } else {
                    let continueWatching = !(providerLowerCase === PROVIDER_NAME.JIO_CINEMA && meta?.isLiveContent);
                    redirectionHandling(meta?.partnerWebUrl, continueWatching, learnData, useCWData, cwData);
                }
            } else if (meta?.runtimePlaybackURLGenerationRequired) {
                const state = store.getState();
                const deepLinkUrl = get(state.playerWatchlist, 'getGenericDrmUrl.data.playerDetail.deepLinkUrl');
                playGenericDeepLink(deepLinkUrl, handleGenericRedirectionPopup, learnData, useCWData, cwData, meta);
            }
            else {
                let mixpanelState = {
                    railTitle: mixpanelData?.railTitle,
                    source: mixpanelData?.source,
                    origin: mixpanelData?.origin,
                    railPosition: mixpanelData?.railPosition,
                    conPosition: mixpanelData?.conPosition,
                    sectionSource: mixpanelData?.sectionSource,
                    configType: mixpanelData?.configType,
                    sectionType: mixpanelData?.sectionType,
                    contentSectionSource: mixpanelData?.contentSectionSource,
                    refUseCase: refUseCase,
                };
                if (type === "seasonsType") {
                    safeNavigation(history, {
                        pathname: `/${URL.PLAYER}/${meta.contentType}/${meta.id}`,
                        state: mixpanelState,
                    });
                } else {
                    isTVOD && await tvodExpiry();
                    type === "movieType" && safeNavigation(history, {
                        pathname: `/${URL.PLAYER}/${contentType}/${id}`,
                        state: mixpanelState,
                    });
                    if (type === "seriesType") {
                        let hasLastWatchID = !isEmpty(lastWatch) && (lastWatch?.vodId !== null && lastWatch?.vodId !== undefined) && lastWatch?.vodId !== meta?.vodId;
                        if (hasLastWatchID && !meta?.isEpisodeContent) {
                            let path = `/${URL.PLAYER}/${lastWatch.durationInSeconds > 0 ? lastWatch.contentType : meta.vodContentType ? meta.vodContentType : meta.contentType}/${lastWatch?.vodId}`
                            safeNavigation(history, {
                                pathname: path,
                                state: mixpanelState,
                            });
                        } else {
                            let path = `/${URL.PLAYER}/${meta.vodContentType ? meta.vodContentType : meta.contentType}/${meta?.vodId}`
                            safeNavigation(history, {
                                pathname: path,
                                state: mixpanelState,
                            });
                        }
                    }
                }
            }
        }
    }
};

export const mixpanelPlayContent = (props, isApple = false, directPlayback = false) => {
    const data = getCommonAnalyticsAttributes(MIXPANEL.EVENT.PLAY_CONTENT, props, props, isApple);
    if (isApple) {
        data.mixpanelData[`${MIXPANEL.PARAMETER.VIA_POPUP}`] = directPlayback ? MIXPANEL.VALUE.NO : MIXPANEL.VALUE.PLAY_POPUP;
    }
    mixPanelConfig.trackEvent(MIXPANEL.EVENT.PLAY_CONTENT, data.mixpanelData);
}
/**
 * 
 * @param {*} type 
 * @param {*} meta 
 * @returns cwData - checks if season type content is watched then use cwdata otherwise if its movie don't use cw data for CW purpose
 */
export const getCWData = (type, meta) => {
    if (type === "seasonsType") {
        let durationInSeconds = get(meta, 'totalDuration', 0);
        let cwData = {
            id: meta.id,
            contentType: meta.contentType,
            totalDuration: durationInSeconds,
        }
        return { useCWData: true, cwData: cwData };
    } else {
        return { useCWData: false, cwData: "" };
    }
}

export const playHotstarDeepLink = async (hotstarWebDeeplink, setCW, playHotStarContent, learnData, useCWData, cwData) => {
    let hotstarFrequency = JSON.parse(getKey(LOCALSTORAGE.HOTSTAR_LAUNCH_FREQUENCY)) || 0
    let periodicFrequency = JSON.parse(getKey(LOCALSTORAGE.HOTSTAR_PERIODIC_FREQUENCY)) || 0
    const state = store.getState()
    const hotstarpopUp = get(state.headerDetails, 'configResponse.data.config.hotstarPopUp.web')
    setKey(LOCALSTORAGE.HOTSTAR_LAUNCH_FREQUENCY, ++hotstarFrequency)
    hotstarFrequency > hotstarpopUp?.launchFrequency && setKey(LOCALSTORAGE.HOTSTAR_PERIODIC_FREQUENCY, ++periodicFrequency)

    if (hotstarFrequency <= hotstarpopUp?.launchFrequency) {
        return playHotStarContent(hotstarWebDeeplink, setCW, true, learnData, useCWData, cwData)
    } else if (periodicFrequency <= hotstarpopUp?.periodicFrequency) {
        if (periodicFrequency === hotstarpopUp?.periodicFrequency) {
            deleteKey(LOCALSTORAGE.HOTSTAR_LAUNCH_FREQUENCY)
            deleteKey(LOCALSTORAGE.HOTSTAR_PERIODIC_FREQUENCY)
        }
        return playHotStarContent(hotstarWebDeeplink, setCW, false, learnData, useCWData, cwData)
    }
}

export const getUserPackInfo = (data) => {
    const { currentSubscription, meta } = data;
    let subscribed = checkPartnerSubscribed(currentSubscription, meta?.partnerId, meta?.provider);
    let currentSubscriptionStatus = get(currentSubscription, 'subscriptionStatus');
    let noPack = checkCurrentSubscription(currentSubscription);
    let expired = !checkCurrentSubscription(currentSubscription) && currentSubscriptionStatus?.toUpperCase() === SUBSCRIPTION_STATUS.DEACTIVE;
    let activePlan = currentSubscriptionStatus?.toUpperCase() === SUBSCRIPTION_STATUS.ACTIVE;
    let lowerPack = activePlan && !subscribed;

    return { subscribed, currentSubscriptionStatus, noPack, expired, activePlan, lowerPack };
}

export const openSubscriptionPopup = async (data) => {
    const { currentSubscription, meta, history, isManagedApp, isChangeAppFDORaised } = data;
    let headingMessage,
        instructions,
        errorIcon,
        primaryBtnText,
        imageUrl,
        secondaryButtonText,
        isHtml = false,
        source = MIXPANEL.VALUE.CONTENT,
        popupClassName = "";

    let { subscribed, currentSubscriptionStatus, noPack, expired, activePlan, lowerPack } = getUserPackInfo(data);
    let appleProvider = meta?.provider?.toLowerCase() === PROVIDER_NAME.APPLE;

    if (meta?.contentType?.toUpperCase() === CONTENTTYPE.TYPE_LIVE) {
        if (isEmpty(currentSubscriptionStatus)) {
            noPack = true;
        } else if (activePlan) {
            lowerPack = true
        } else if (currentSubscriptionStatus?.toUpperCase() === SUBSCRIPTION_STATUS.DEACTIVE) {
            expired = true;
        }
    }

    if (currentSubscription?.downgradeRequested) {
        errorIcon = "icon-alert-upd";
        primaryBtnText = SUBSCRIPTION.DONE;
        instructions = currentSubscription?.downgradeRequestedMessage;
    } else if (currentSubscription?.upgradeFDOCheck) {
        if (currentSubscription?.ispEnabled) {
            let providerName = getProviderDisplayName(data?.meta?.provider);
            instructions = currentSubscription?.ispHeaderVerbiage?.replace("[partnername]", providerName);
            errorIcon = "icon-alert-upd";
            primaryBtnText = SUBSCRIPTION.DONE;
            popupClassName = "fibre-restrict-popup"
            isHtml = true;
        } else {
            headingMessage = currentSubscription?.upgradeFDOHeader;
            errorIcon = "icon-alert-upd";
            primaryBtnText = SUBSCRIPTION.DONE;
            instructions = currentSubscription?.upgradeFDOMessage;
        }
    } else if (noPack) {
        headingMessage = SUBSCRIPTION.SELECT_SUBSCRIPTION;
        errorIcon = "icon-my-subscription-1";
        primaryBtnText = SUBSCRIPTION.PROCEED;
        instructions = SUBSCRIPTION.SELECT_SUBSCRIPTION_DETAIL;
    } else if (expired) {
        //TODO: show Nudge for renew plan
        headingMessage = SUBSCRIPTION.EXPIRED_SUBSCRIPTION;
        errorIcon = "icon-my-subscription-1";
        primaryBtnText = SUBSCRIPTION.RENEW;
        instructions = SUBSCRIPTION.EXPIRED_SUBSCRIPTION_DETAIL;
    } else if (lowerPack) {
        if (currentSubscription?.fdoRequested) {
            return safeNavigation(history, {
                pathname: `/${URL.SUBSCRIPTION}`,
            });
        } else if (isChangeAppFDORaised) {
            const state = store.getState();
            let myPlanVerbiagesResponse = get(state.subscriptionDetails, "myPlanVerbiages", {}),
                popupVerbiages = myPlanVerbiagesResponse?.myPlanVerbiages?.upgradePopupConfirmationOnPartnerFDO;

            headingMessage = popupVerbiages?.title,
                isHtml = true,
                primaryBtnText = get(popupVerbiages, 'primaryButtonText', 'Upgrade your plan'),
                secondaryButtonText = get(popupVerbiages, 'secondaryButtonText', 'Not Now')
            errorIcon = "icon-alert-upd";
        } else {
            if (!isManagedApp) {
                headingMessage = SUBSCRIPTION.UPGRADE_SUBSCRIPTION;
                errorIcon = "icon-upgrade1";
                primaryBtnText = SUBSCRIPTION.UPGRADE;

                if (meta?.contentType === CONTENTTYPE.TYPE_MOVIES) {
                    instructions =
                        SUBSCRIPTION.UPGRADE_SUBSCRIPTION_DETAIL_1 +
                        "movie" +
                        SUBSCRIPTION.UPGRADE_SUBSCRIPTION_DETAIL_2;
                } else {
                    instructions =
                        SUBSCRIPTION.UPGRADE_SUBSCRIPTION_DETAIL_1 +
                        "show" +
                        SUBSCRIPTION.UPGRADE_SUBSCRIPTION_DETAIL_2;
                }
            } else {
                if (!isManagedApp) {
                    headingMessage = SUBSCRIPTION.UPGRADE_SUBSCRIPTION;
                    errorIcon = "icon-upgrade1";
                    primaryBtnText = SUBSCRIPTION.UPGRADE;

                    if (meta?.contentType === CONTENTTYPE.TYPE_MOVIES) {
                        instructions =
                            SUBSCRIPTION.UPGRADE_SUBSCRIPTION_DETAIL_1 +
                            "movie" +
                            SUBSCRIPTION.UPGRADE_SUBSCRIPTION_DETAIL_2;
                    } else {
                        instructions =
                            SUBSCRIPTION.UPGRADE_SUBSCRIPTION_DETAIL_1 +
                            "show" +
                            SUBSCRIPTION.UPGRADE_SUBSCRIPTION_DETAIL_2;
                    }
                } else {
                    handlePiRedirection(history);
                    return await store.dispatch(
                        getWebPortalLink({
                            initiateSubscription: JOURNEY_SOURCE.CONTENT_PLAY,
                            journeySource: JOURNEY_SOURCE.HOME_CONTENT,
                            journeySourceRefId: get(meta, "partnerId"),
                            analyticSource: MIXPANEL.VALUE.PLAYBACK,
                        })
                    );
                }
            }
        }
    }


    openPopupSubscriptionUpgrade({
        headingMessage,
        instructions,
        primaryBtnText,
        errorIcon,
        history,
        meta,
        source,
        currentSubscription,
        noPack,
        isManagedApp,
        imageUrl,
        secondaryButtonText,
        isHtml,
        isChangeAppFDORaised,
        popupClassName
    });
};

export const setTVODData = (item) => {
    let tvodInfo = JSON.parse(getKey(LOCALSTORAGE.TVOD_DATA)) || [];
    let data =
        tvodInfo && tvodInfo.find && tvodInfo.find((i) => i.id === item.id);
    !data &&
        item.rentalExpiry &&
        tvodInfo.push({
            id: item.id,
            rentalExpiry: item.rentalExpiry,
        });
    tvodInfo.length > 0 &&
        setKey(LOCALSTORAGE.TVOD_DATA, JSON.stringify(tvodInfo));
};

export const isMobile = {
    Android: function () {
        return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function () {
        return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function () {
        return navigator.userAgent.match(/iPhone|iPod/i);
    },
    Opera: function () {
        return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function () {
        return navigator.userAgent.match(/IEMobile/i);
    },
    otherMobileDevice: function () {
        return (
            navigator.userAgent.match(/Mobi/i) &&
            navigator.platform !== "iPad" &&
            !navigator.userAgent.match(/iPad/i)
        );
    },
    ipad: function () {
        return navigator.userAgent.match(/iPad/i);
    },
    any: function () {
        return (
            isMobile.BlackBerry() ||
            isMobile.iOS() ||
            isMobile.Opera() ||
            isMobile.Windows() ||
            isMobile.otherMobileDevice()
        );
    },
};

export const isIpadWebView = () => {
    let standalone = window.navigator.standalone,
        userAgent = window.navigator.userAgent.toLowerCase(),
        safari = /safari/.test(userAgent),
        ios = /iphone|ipod|ipad/.test(userAgent),
        appWidth = document.getElementById("app").clientWidth;
    return ios && !standalone && !safari && appWidth >= 768;
}

export const isMobileBrowser = () => {
    return !!isMobile.any();
};

export const redirectToApp = (pathname) => {
    let envUrl = `${getEnvironmentConstants().ENV_URL}`;
    if (navigator.userAgent.toLowerCase().indexOf("iphone") > -1) {
        window.location.href = pathname ? `tataplaybinge://${pathname}` : "tataplaybinge://app-launch";
        setTimeout(function () {
            if (confirm('Safari cannot open this page visit app store to check app')) {
                window.location.href = PLAY_STORE_URL.IOS;
            }
        }, 2000);
    } else if (navigator.userAgent.toLowerCase().indexOf("android") > -1) {
        window.location.href = pathname ? `tataplaybinge://*${pathname}` : "tataplaybinge://app-launch";
        setTimeout(function () {
            window.location.replace(PLAY_STORE_URL.ANDROID);
        }, 2000);
    } else {
        setTimeout(function () {
            window.location.href = PLAY_STORE_URL.ANDROID;
        }, 2000);
        window.open(`${envUrl}`, "_blank");
    }
};

export const deeplinkToPIPage = (pathForContentPi) => {
    let envUrl = `${getEnvironmentConstants().ENV_URL}`;
    const currentPath = !isEmpty(pathForContentPi) ? pathForContentPi : getKey(LOCALSTORAGE.CURRENT_PATH);
    if (navigator.userAgent.toLowerCase().indexOf("iphone") > -1) {
        window.location.href = currentPath ? `tataplaybinge://*${currentPath}` : "tataplaybinge://app-launch";
        setTimeout(function () {
            if (confirm('Safari cannot open this page because address is invalid')) {
                window.location.href = PLAY_STORE_URL.IOS;
            }
        }, 2000);
    } else if (navigator.userAgent.toLowerCase().indexOf("android") > -1) {
        setTimeout(function () {
            window.location.replace(PLAY_STORE_URL.ANDROID);
        }, 2000);
        window.location.href = currentPath ? `tataplaybinge://*${currentPath}` : "tataplaybinge://app-launch";
    } else {
        setTimeout(function () {
            window.location.href = PLAY_STORE_URL.ANDROID;
        }, 2000);
        window.open(`${envUrl}`, "_blank");
    }
};

export const redirectToAppStore = () => {
    if (navigator.userAgent.toLowerCase().indexOf("iphone") > -1) {
        window.location.href = PLAY_STORE_URL.IOS;
    } else if (navigator.userAgent.toLowerCase().indexOf("android") > -1) {
        window.location.href = `https://play.app.goo.gl/?link=${PLAY_STORE_URL.ANDROID}`;
    } else {
        // in iPhone this condition is running might be due to navigator.userAgent that is why used IOS constant in else
        window.location.href = PLAY_STORE_URL.IOS;
    }
};
export const getHeroBannerInfo = (pageType) => {
    const { headerDetails } = store.getState();
    let taHeroBanner = get(
        headerDetails,
        "configResponse.data.config.taHeroBanner",
    );
    return (
        taHeroBanner?.find((item) => item.pageType === pageType)
    );
};

export const getRailTitle = (contentType) => {
    let title = "";
    if (
        contentType === CONTENTTYPE.TYPE_MOVIES ||
        contentType === CONTENTTYPE.TYPE_CUSTOM_MOVIES_DETAIL
    ) {
        title = RAIL_TITLE.MOVIES;
    } else if (
        contentType === CONTENTTYPE.TYPE_TV_SHOWS ||
        contentType === CONTENTTYPE.TYPE_CUSTOM_TV_SHOWS_DETAIL ||
        contentType === CONTENTTYPE.TYPE_CATCH_UP ||
        contentType === CONTENTTYPE.TYPE_CUSTOM_CATCH_UP_DETAIL
    ) {
        title = RAIL_TITLE.SHOWS;
    } else if (
        contentType === CONTENTTYPE.TYPE_WEB_SHORTS ||
        contentType === CONTENTTYPE.TYPE_CUSTOM_WEB_SHORTS_DETAIL
    ) {
        title = RAIL_TITLE.SHORTS;
    } else if (
        contentType === CONTENTTYPE.TYPE_BRAND ||
        contentType === CONTENTTYPE.TYPE_BRAND_CHILD ||
        contentType === CONTENTTYPE.TYPE_CUSTOM_BRAND_DETAIL
    ) {
        title = RAIL_TITLE.BRAND;
    } else if (
        contentType === CONTENTTYPE.TYPE_SERIES ||
        contentType === CONTENTTYPE.TYPE_CUSTOM_SERIES_DETAIL ||
        contentType === CONTENTTYPE.TYPE_SERIES_CHILD
    ) {
        title = RAIL_TITLE.SERIES;
    }
    return title;
};

export const getTALanguageGenreTitle = (title, genreType, languageType) => {
    let newTitle = title;
    let pattern = /[0-9]/;
    let titleNo = newTitle.match(pattern);

    if (genreType) {
        let genreTitle = "Genre" + titleNo;
        let genreTitleSec = "Genre_" + titleNo;
        newTitle = newTitle.includes(genreTitle)
            ? newTitle.replace(new RegExp(genreTitle, 'gi'), genreType)
            : newTitle;
        newTitle = newTitle.includes(genreTitleSec)
            ? newTitle.replace(new RegExp(genreTitleSec, 'gi'), genreType)
            : newTitle;
    }
    if (languageType) {
        let langTitle = "Language" + titleNo;
        let langTitleSec = "Language_" + titleNo;

        newTitle = newTitle.includes(langTitle)
            ? newTitle.replace(new RegExp(langTitle, 'gi'), languageType)
            : newTitle;
        newTitle = newTitle.includes(langTitleSec)
            ? newTitle.replace(new RegExp(langTitleSec, 'gi'), languageType)
            : newTitle;
    }
    return newTitle;
};

export const triggerLearnAction = (
    { checkedContentType, learnAction, checkedId, provider, setLA, refUseCase, isLiveDetail, mixPanelData },
    detail,
    meta,
    showLoader,
) => {
    let isDistroTV = provider?.toLowerCase() === PROVIDER_NAME.DISTRO_TV;
    if (isDistroTV) {
        return false;
    }
    else if (detail?.contractName !== CONTRACT.RENTAL) {
        let data = {
            learnActionType: learnAction,
            contentType: checkedContentType,
            id: meta?.seriesId ? meta?.seriesId : checkedId,
            provider: provider,
            refUseCase: refUseCase || '',
            isLiveDetail: isLiveDetail,
            seriesId: meta?.seriesId
        };
        if ((data?.contentType == CONTENTTYPE.TYPE_TV_SHOWS && !isLiveDetail) && !meta?.seriesId ) {
            let mixpanel = mixPanelData
            mixpanel[MIXPANEL.PARAMETER.REASON] = MIXPANEL.VALUE.SERIES_ID_NOT_FOUND;
            mixPanelConfig.trackEvent(MIXPANEL.EVENT.LA_NOT_TRIGGERED, mixpanel);
            removeLAId(checkedId)
          }else{
            setLA(data, showLoader);
          }
    } else {
        store.dispatch(viewCountLearnAction(checkedContentType, checkedId));
    }
};

export const compareDate = (contentWatchedTime, today) => {
    let contentWatchedDate = contentWatchedTime.getDate();
    let contentWatchedMonth = contentWatchedTime.getMonth();
    let contentWatchedYear = contentWatchedTime.getFullYear();

    let tDate = today.getDate();
    let tMonth = today.getMonth();
    let tYear = today.getFullYear();

    let dateOne = new Date(
        contentWatchedYear,
        contentWatchedMonth,
        contentWatchedDate,
    );
    let dateTwo = new Date(tYear, tMonth, tDate);

    return dateOne < dateTwo;
};

export const removeLAExpiredData = () => {
    let laData = JSON.parse(getKey(LOCALSTORAGE.LA_FIRED_DATE));
    laData?.map((i, index) => {
        let contentWatchedTime = new Date(i.time);
        let today = new Date();
        if (compareDate(contentWatchedTime, today)) {
            laData.splice(index, 1);
        }
    });
    setKey(LOCALSTORAGE.LA_FIRED_DATE, laData);
};

export const openPopupSubscriptionUpgrade = async ({
    headingMessage,
    instructions,
    primaryBtnText,
    errorIcon,
    history,
    meta,
    source,
    currentSubscription,
    noPack,
    isManagedApp,
    imageUrl,
    secondaryButtonText,
    isHtml,
    isChangeAppFDORaised,
    popupClassName
}) => {
    let appleProvider = meta?.provider?.toLowerCase() === PROVIDER_NAME.APPLE;

    let secBtnDefaultValue = !appleProvider && (currentSubscription?.downgradeRequested ? "" : !currentSubscription?.upgradeFDOCheck && "Cancel")
    let secondaryButtonTextValue = isChangeAppFDORaised ? secondaryButtonText : secBtnDefaultValue;

    if (noPack) {
        if (!isManagedApp) {
            safeNavigation(history, {
                pathname: `/${URL.SUBSCRIPTION}`,
                state: {
                    upgradePack: primaryBtnText === SUBSCRIPTION.UPGRADE,
                    source: MIXPANEL.VALUE.PI_DETAIL,
                    planFocus: [SUBSCRIPTION.PROCEED, SUBSCRIPTION.UPGRADE].includes(primaryBtnText),
                    provider: meta?.provider,
                    partnerName: meta
                },
            });
        } else {
            // if enableTickTickJourney is false then directly send user to manageApp
            const state = store.getState();
            const enableTickTickJourney = get(state.headerDetails, 'configResponse.data.config.enableTickTickJourney');
            if (enableTickTickJourney) {
                await loginInFreemium({ openPopup, closePopup, ComponentName: MINI_SUBSCRIPTION.SELECTION_DRAWER, source: MIXPANEL.VALUE.CONTENT_PLAYBACK, partnerData: meta });
            }
            else {
                handlePiRedirection(history);
                await store.dispatch(
                    getWebPortalLink({ initiateSubscription: JOURNEY_SOURCE.CONTENT_PLAY, journeySource: JOURNEY_SOURCE.HOME_CONTENT, journeySourceRefId: get(meta, 'partnerId'), analyticSource: MIXPANEL.VALUE.CONTENT_PLAYBACK })
                );
            }
        }
    } else {
        store.dispatch(
            openPopup(MODALS.ALERT_MODAL, {
                modalClass: `alert-modal upgrade-modal inactive-alert ${appleProvider && imageUrl && 'apple-modal'} ${popupClassName}`,
                headingMessage: headingMessage,
                instructions: instructions,
                primaryButtonText: primaryBtnText,
                errorIcon: errorIcon,
                imageUrl: imageUrl,
                isAppleUpgradeModal: appleProvider && imageUrl,
                primaryButtonAction: () => handleSubsciptionPopupAction({
                    primaryBtnText,
                    history,
                    meta,
                    currentSubscription,
                    isManagedApp,
                    isChangeAppFDORaised
                }),
                secondaryButtonText: secondaryButtonTextValue,
                secondaryButtonAction: () => {
                    subscriptionUpgradeCloseAction(primaryBtnText, isChangeAppFDORaised, meta);
                },
                onCloseAction: () => {
                    subscriptionUpgradeCloseAction(primaryBtnText, isChangeAppFDORaised, meta);
                },
                isCloseModal: true,
                hideCloseIcon: !appleProvider,
                isAppleModal: appleProvider,
                isHtml: isHtml ? isHtml : false,
            }),
        );
    }
};

export const subscriptionUpgradeCloseAction = (primaryBtnText, isChangeAppFDORaised, meta) => {
    store.dispatch(closePopup());

    isChangeAppFDORaised ?
        trackChangeAppFDORaisedPopupMixpanel(MIXPANEL.EVENT.PLAN_UPGRADE_CONFIRMATION_NOTNOW, meta) :
        mixPanelConfig.trackEvent(primaryBtnText === SUBSCRIPTION.PROCEED ?
            MIXPANEL.EVENT.SUBSCRIBE_POPUP_CANCEL : MIXPANEL.EVENT.UPGRADE_POPUP_CANCEL);
}

const handleSubsciptionPopupAction = async ({
    primaryBtnText,
    history,
    meta,
    currentSubscription,
    isManagedApp,
    isChangeAppFDORaised
}) => {
    if ((currentSubscription?.upgradeFDOCheck && primaryBtnText === SUBSCRIPTION.OK) || currentSubscription?.downgradeRequested || (currentSubscription?.upgradeFDOCheck && primaryBtnText === SUBSCRIPTION.DONE)) { // last check applied for plan cancellation request raised
        store.dispatch(closePopup());
    } else if (currentSubscription?.fdoRequested) {
        safeNavigation(history, {
            pathname: `/${URL.SUBSCRIPTION}`
        });
    } else {
        setKey(LOCALSTORAGE.PI_DETAIL_URL, `${window.location.pathname}${window.location.search}`);
        setKey(LOCALSTORAGE.IS_SUBSCRIPTION_FROM_PI, `true`);
        if (primaryBtnText === SUBSCRIPTION.RENEW) {
            await renewSusbcription(history);
        } else {
            if (!isManagedApp) {
                safeNavigation(history, {
                    pathname: `/${URL.SUBSCRIPTION}`,
                    state: {
                        upgradePack: primaryBtnText === SUBSCRIPTION.UPGRADE,
                        source: MIXPANEL.VALUE.PI_DETAIL,
                        planFocus: [SUBSCRIPTION.PROCEED, SUBSCRIPTION.UPGRADE].includes(primaryBtnText),
                        provider: meta?.provider,
                        partnerName: meta
                    },
                });
            } else {
                handlePiRedirection(history);
                await store.dispatch(getWebPortalLink({ initiateSubscription: JOURNEY_SOURCE.CONTENT_PLAY, journeySource: JOURNEY_SOURCE.HOME_CONTENT, journeySourceRefId: get(meta, 'partnerId'), analyticSource: MIXPANEL.VALUE.PLAYBACK }))
            }
        }

    }
    let title = meta?.contentType === CONTENTTYPE.TYPE_MOVIES ? meta?.vodTitle : (meta?.brandTitle || meta?.seriesTitle || meta?.vodTitle || meta?.title)
    if (primaryBtnText === SUBSCRIPTION.PROCEED) {
        let mixpanel = {
            [`${MIXPANEL.PARAMETER.CONTENT_TITLE}`]: title
        }
        mixPanelConfig.trackEvent(MIXPANEL.EVENT.SUBSCRIBE_POPUPP_SUBSCRIBE, mixpanel);
    } else {
        let commonData = {
            [`${MIXPANEL.PARAMETER.CONTENT_TITLE}`]: title,
            [`${MIXPANEL.PARAMETER.CONTENT_PARTNER}`]: meta.provider,
        };

        isChangeAppFDORaised ? trackChangeAppFDORaisedPopupMixpanel(MIXPANEL.EVENT.PLAN_UPGRADE_CONFIRMATION_PROCEED, meta) : mixPanelConfig.trackEvent(MIXPANEL.EVENT.UPGRADE_POPUP_UPGRADE, commonData);
    }
}

export const getWeekNameFromDate = (date, fullName = false) => {
    let weekShotName = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
    let weekLongName = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday ",
        "Friday",
        "Saturday",
        "Sunday",
    ];
    return fullName ? weekLongName[date.getDay()] : weekShotName[date.getDay()];
};

export const getMonthNameFromDate = (date, fullName = false) => {
    let monthShortName = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
    ];
    let monthFullName = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];
    return fullName
        ? monthFullName[date.getMonth()]
        : monthShortName[date.getMonth()];
};

export const getOrdinalNum = (n) => {
    return (
        n +
        (n > 0
            ? ["th", "st", "nd", "rd"][(n > 3 && n < 21) || n % 10 > 3 ? 0 : n % 10]
            : "")
    );
};

export const showRechargePopup = (
    heading,
    instructions,
    rechargeBtn,
    skipBtn,
    openPopup,
    quickRecharge,
    history,
    sId = null,
    rechargeBeforeLogin,
    lowBalance = false,
) => {
    let { bingeLoginDetails } = store.getState();
    let isRMN = get(bingeLoginDetails, "accountDetailsFromRmn")
        ? Object.keys(bingeLoginDetails.accountDetailsFromRmn).length !== 0
        : false;
    store.dispatch(
        openPopup(MODALS.ALERT_MODAL, {
            modalClass: "alert-modal inactive-alert",
            headingMessage: heading,
            instructions: instructions,
            primaryButtonText: rechargeBtn ? "Recharge" : "",
            primaryButtonAction: async () => {
                if (rechargeBeforeLogin) {
                    store.dispatch(showMainLoader());
                    await store.dispatch(quickRechargeBeforeLogin(sId));
                    const { packSelectionDetail } = store.getState();
                    let quickRechargeSelfCareUrl = get(
                        packSelectionDetail,
                        "quickRechargeUrl",
                    );
                    if (quickRechargeSelfCareUrl.code === 0 && !isEmpty(quickRechargeSelfCareUrl.data)) {
                        trackAppsflyerOnRechargeRedirection();
                        window.location.assign(
                            `${quickRechargeSelfCareUrl.data.rechargeUrl}`,
                        );
                    } else {
                        store.dispatch(hideMainLoader());
                    }
                } else {
                    let packSelectionDetail = store.getState().packSelectionDetail;
                    let recommendedAmount =
                        packSelectionDetail?.balanceInfo?.data?.recommendedAmount;
                    store.dispatch(showMainLoader());
                    await quickRecharge(recommendedAmount, sId);

                    packSelectionDetail = store.getState().packSelectionDetail;
                    let quickRechargeSelfCareUrl = get(
                        packSelectionDetail,
                        "quickRecharge",
                    );
                    if (
                        quickRechargeSelfCareUrl.code === 0 &&
                        !isEmpty(quickRechargeSelfCareUrl.data)
                    ) {
                        trackAppsflyerOnRechargeRedirection();
                        window.location.assign(
                            `${quickRechargeSelfCareUrl.data.rechargeUrl}`,
                        );
                    } else {
                        store.dispatch(hideMainLoader());
                    }
                }
            },
            secondaryButtonText: skipBtn ? (lowBalance ? "Skip" : "Cancel") : "",
            secondaryButtonAction: () => {
                lowBalance ? safeNavigation(history, URL.DEFAULT) : callLogOut(history);
            },
            hideCloseIcon: !isRMN,
            errorIcon: "icon-alert-upd",
        }),
    );
};

export const queryStringToObject = (queryString = {}) => {
    if (isEmpty(queryString)) return queryString;
    let search = queryString.substring(1);
    return JSON.parse(
        '{"' +
        decodeURIComponent(search)
            .replace(/"/g, '\\"')
            .replace(/&/g, '","')
            .replace(/=/g, '":"') +
        '"}',
    );
};

export const rechargeBtnHandler = async (history, currentSubscription) => {
    const handleCancelledUser = getDeviceStatus();
    if (handleCancelledUser) {
        await handleDeviceCancelledUser(history, true, MIXPANEL.VALUE.MY_ACCOUNT);
    } else {
        if (isSubscriptionStatusEmpty() ||
            (!isSubscriptionStatusEmpty() && currentSubscription?.subscriptionInformationDTO?.bingeAccountStatus?.toUpperCase() === ACCOUNT_STATUS.WRITTEN_OFF)) {
            checkUserDTHStatus(currentSubscription, history, true, false, "");
        } else {
            await getBalanceInfo(get(currentSubscription, "packId"));

            safeNavigation(history, {
                pathname: `/${URL.PACK_SELECTION}`,
                search: `?source=${MIXPANEL.VALUE.MY_ACCOUNT}&aboutSubscription=true&contentRecharge=true`,
                state: {
                    subscription: "recharge",
                    accountDropDown: true,
                    source: MIXPANEL.VALUE.MY_ACCOUNT,
                },
            });
        }
    }
};

/**
 *
 * @param data
 * @param history
 * @param rechargeBtn - method called on click of recharge btn of my account screen/dropdown.
 * @param skipToHome
 * @param freeTrialDthActive
 * @param nudgeSource
 */
export const checkUserDTHStatus = (
    data,
    history,
    rechargeBtn,
    skipToHome,
    nudgeSource,
    freeTrialDthActive = false,
) => {
    const { dthStatus, accountSubStatus } = JSON.parse(
        getKey(LOCALSTORAGE.USER_INFO),
    );
    let popupData = {};

    let updatedDthStatus = data
        ? data?.dthStatus?.toUpperCase()
        : dthStatus?.toUpperCase();
    let updatedAccountSubStatus = data
        ? data?.accountSubStatus?.toUpperCase()
        : accountSubStatus?.toUpperCase();

    if (
        [
            ACCOUNT_STATUS.DEACTIVATED,
            ACCOUNT_STATUS.DEACTIVE,
            ACCOUNT_STATUS.INACTIVE,
        ].includes(updatedDthStatus)
    ) {
        popupData = {
            header: DTH_BINGE_POPUP.DTH_INACTIVE_BINGE_WRITTEN_OFF_INACTIVE.HEADER,
            instructions:
                DTH_BINGE_POPUP.DTH_INACTIVE_BINGE_WRITTEN_OFF_INACTIVE.INSTRUCTIONS,
            primaryBtnText:
                DTH_BINGE_POPUP.DTH_INACTIVE_BINGE_WRITTEN_OFF_INACTIVE
                    .PRIMARY_BTN_TEXT,
            secondaryBtnText:
                DTH_BINGE_POPUP.DTH_INACTIVE_BINGE_WRITTEN_OFF_INACTIVE
                    .SECONDARY_BTN_TEXT,
        };
        dthBingePopup(history, popupData, skipToHome);
    } else if (updatedDthStatus === ACCOUNT_STATUS.TEMP_SUSPENSION) {
        popupData = {
            header:
                DTH_BINGE_POPUP.DTH_TEMP_SUSPENDED_BINGE_WRITTEN_OFF_INACTIVE.HEADER,
            instructions:
                DTH_BINGE_POPUP.DTH_TEMP_SUSPENDED_BINGE_WRITTEN_OFF_INACTIVE
                    .INSTRUCTIONS,
            primaryBtnText:
                DTH_BINGE_POPUP.DTH_TEMP_SUSPENDED_BINGE_WRITTEN_OFF_INACTIVE
                    .PRIMARY_BTN_TEXT,
        };
        tempSuspendedPopup(history, popupData, skipToHome);
    } else if (
        updatedDthStatus === ACCOUNT_STATUS.ACTIVE &&
        updatedAccountSubStatus === ACCOUNT_STATUS.SUB_STATUS_PARTIALLY_DUNNED
    ) {
        popupData = {
            header: DTH_BINGE_POPUP.DTH_DUNNED_BINGE_WRITTEN_OFF_INACTIVE.HEADER,
            instructions:
                DTH_BINGE_POPUP.DTH_DUNNED_BINGE_WRITTEN_OFF_INACTIVE.INSTRUCTIONS,
            primaryBtnText:
                DTH_BINGE_POPUP.DTH_DUNNED_BINGE_WRITTEN_OFF_INACTIVE.PRIMARY_BTN_TEXT,
            secondaryBtnText:
                DTH_BINGE_POPUP.DTH_DUNNED_BINGE_WRITTEN_OFF_INACTIVE
                    .SECONDARY_BTN_TEXT,
        };
        dthBingePopup(history, popupData, skipToHome);
    } else {
        if (!freeTrialDthActive) {
            if (rechargeBtn) {
                safeNavigation(history, {
                    pathname: `/${URL.PACK_SELECTION}`,
                    search: `?source=${MIXPANEL.VALUE.MY_ACCOUNT}&aboutSubscription=true`,
                });
            } else {
                let packSelectionSource = nudgeSource
                    ? MIXPANEL.VALUE.HOME
                    : MIXPANEL.VALUE.MY_ACCOUNT;
                nudgeSource === SECTION_SOURCE.FREE_TRIAL_UPGRADE
                    ? safeNavigation(history, {
                        pathname: `/${URL.UPGRADE_FREE_TRIAL}`,
                        state: {
                            from: history?.location?.pathname,
                        },
                        search: `?source=${MIXPANEL.VALUE.HOME}`,
                    })
                    : safeNavigation(
                        history,
                        `/${URL.PACK_SELECTION}?source=${packSelectionSource}`,
                    );
            }
        }
    }
    //}
};

export const openTimeoutPopup = (history) => {
    const state = store.getState();
    let logoutToast = get(state.headerDetails, "configResponse.data.config.logout.success");
    store.dispatch(
        openPopup(MODALS.ALERT_MODAL, {
            modalClass: "alert-modal error-state-modal",
            headingMessage: ERROR_MESSAGES.DEVICE_REMOVED,
            instructions: ERROR_MESSAGES.DEVICE_REMOVED_MESSAGE,
            primaryButtonText: "Ok",
            primaryButtonAction: async (bingeCheck, primeCheck, newHistory) => {
                showlogoutToast(logoutToast || MESSAGE.LOGOUT_SUCCESS);
                await logoutHandling();
                getDeviceId();
                await getAnonymousId();
                redirectToHomeScreen(!isEmpty(newHistory) ? newHistory : history);
            },
            errorIcon: "icon-alert-upd",
            hideCloseIcon: true,
        }),
    );
};

export const logOutUser = () => {
    store.dispatch(loggedIn(false));
    clearKey();
    mixpanel.reset();
    //Moengage.destroy_session();
};

export const initialArrowState = (elementId, currentState, right) => {
    const element = document.getElementById(elementId);

    if (
        element &&
        element.scrollLeft + element.clientWidth < element.scrollWidth
    ) {
        currentState.setState({ [right]: true });
    }
};

export const horizontalScroll = (elementId, currentState, left, right) => {
    const element = document.getElementById(elementId);
    let val = element?.scrollLeft;

    if (element?.scrollLeft + element?.clientWidth + 1 >= element?.scrollWidth) {
        currentState.setState({
            [right]: false,
        });
    } else {
        currentState.setState({
            [right]: true,
        });
    }

    if (val === 0) {
        currentState.setState({
            [left]: false,
        });
    } else {
        currentState.setState({
            [left]: true,
        });
    }
};

export const updateSearchList = (searchVal) => {
    const RECENT_SEARCH_MAX_LIMIT = 10;
    let list = JSON.parse(getKey(LOCALSTORAGE.SEARCH));
    let itemExist;
    if (searchVal) {
        if (list)
            itemExist =
                list &&
                list.find((item) => item.toLowerCase() === searchVal.toLowerCase());
        if (list && list.length < RECENT_SEARCH_MAX_LIMIT && !itemExist) {
            list.unshift(searchVal);
        } else if (list && list.length >= RECENT_SEARCH_MAX_LIMIT && !itemExist) {
            list.splice(list.length - 1, 1);
            list.unshift(searchVal);
        } else if (itemExist) {
            list.splice(list.indexOf(itemExist), 1);
            list.unshift(searchVal);
        } else {
            list = [];
            list.push(searchVal);
        }
        setKey(LOCALSTORAGE.SEARCH, JSON.stringify(list));
    }
};

export const openErrorPopUp = (currentProps, apiResp) => {
    currentProps.openPopup(MODALS.ALERT_MODAL, {
        modalClass: "alert-modal",
        instructions:
            apiResp && apiResp.message ? apiResp.message : MESSAGE.ERROR_OCCURRED,
        primaryButtonText: "Ok",
        closeModal: true,
        hideCloseIcon: true,
    });
};

export const deepEqual = (object1, object2) => {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (const key of keys1) {
        const val1 = object1[key];
        const val2 = object2[key];
        const areObjects = isObject(val1) && isObject(val2);
        if (
            (areObjects && !deepEqual(val1, val2)) ||
            (!areObjects && val1 !== val2)
        ) {
            return false;
        }
    }

    return true;
};

export const isObject = (object) => {
    return object != null && typeof object === "object";
};

export const providerImage = (provider, view, isBBA) => {
    let imageUrl = "";
    let imageType = !!isBBA ? 'logoProviderBBA' : (view === LAYOUT_TYPE.CIRCULAR ? "logoCircular" : "logoRectangular");
    provider = provider?.toLowerCase();
    const genericData = JSON.parse(getKey(LOCALSTORAGE.GENERIC_PROVIDERS)) ?? getAllGenricProvider();
    const genericProviders = genericData && genericData[provider];
    if (genericProviders != undefined) {
        imageUrl = genericProviders[imageType];
    }
    else {
        if (provider) {
            let providerLogo = getProviderLogo();
            provider = provider.toUpperCase();
            let providerImages = providerLogo && providerLogo[provider];
            imageUrl = providerImages ? providerImages[imageType] : "";
        }
    }
    return imageUrl;
};

export const filterPartnerContents = (list, sectionSource = "", isAutoSuggestion = false, isSearchResult = false) => {
    let allProvider = getAllIntegratedPartnerList();

    if ([...MID_SCROLL_ARRAY].includes(sectionSource)) {
        return filterMidScrollContent(list);
    }
    //Special check for providers filteration, as SPECIAL_SECTION_SOURCE has SECTION_SOURCE.PROVIDER and it is used at multiple places
    if (![SECTION_SOURCE.PROVIDER].includes(sectionSource) && SPECIAL_SECTION_SOURCE.includes(sectionSource)) {
        return list;
    } else {
        return (
            list?.filter((item) => {
                let providerLowerCase = item?.provider?.toLowerCase();

                if (item?.suggestor && isAutoSuggestion) {
                    if ([SECTION_SOURCE.BINGE_CHANNEL, SECTION_SOURCE.BINGE_DARSHAN_LIVE].includes(item?.sectionSource)) {
                        return true;
                    }
                    else if (item?.provider) {
                        return allProvider.includes(providerLowerCase);
                    }
                    return true;
                }
                if (isSearchResult && [SECTION_SOURCE.BINGE_CHANNEL, SECTION_SOURCE.BINGE_DARSHAN_LIVE].includes(item?.sectionSource)) {
                    return true;
                } else {
                    return allProvider.includes(providerLowerCase) || ([HERO_BANNER_TYPE.HB_SEE_ALL, HERO_BANNER_TYPE.HB_SEE_ALL_BINGE_CHANNEL].includes(item?.heroBannerType));
                }
            })
        );
    }
};

export const filterMidScrollContent = (list) => {
    let allProvider = getAllIntegratedPartnerList();
    return list?.filter((item) => {
        let providerLowerCase = item?.provider?.toLowerCase();
        if (item.screenName === SCREEN_NAME.MID_SCROLL_DETAIL_SCREEN) {
            return allProvider.includes(providerLowerCase);
        } else {
            return true;
        }
    })
}

export const callLogOut = async (showToast = true, history, redirectToHome = true, allUsers = false, clearStore = true) => {
    store.dispatch(showMainLoader());
    await store.dispatch(logOut(allUsers));
    store.dispatch(hideMainLoader());
    const { bingeLoginDetails } = store.getState();
    let logOutResponse = get(bingeLoginDetails, "logOutResponse");
    const { modal, headerDetails } = store.getState();
    let showModal = get(modal, "showModal");
    let logoutToast = get(headerDetails, "configResponse.data.config.logout.success");

    if (logOutResponse && logOutResponse.code === 0) {
        if (!isHelpCenterWebView()) {
            mixPanelConfig.trackEvent(MIXPANEL.EVENT.LOGOUT);
            moengageConfig.trackEvent(MOENGAGE.EVENT.MOE_LOGOUT);
        }
        showToast && showlogoutToast(logoutToast || MESSAGE.LOGOUT_SUCCESS);
        await logoutHandling(clearStore);
        redirectToHome && redirectToHomeScreen(history);
    } else if (logOutResponse && logOutResponse.error) {
        trackAnalyticsLogoutFailure(logOutResponse);
        await logoutHandling(clearStore);
        !showModal &&
            store.dispatch(
                openPopup(MODALS.ALERT_MODAL, {
                    modalClass: "alert-modal ",
                    headingMessage: logOutResponse.message,
                    primaryButtonText: "Ok",
                    primaryButtonAction: () => {
                        redirectToHomeScreen(history);
                    },
                    closeModal: true,
                    hideCloseIcon: true,
                }),
            );
    } else if (logOutResponse && logOutResponse.code === 200004) {
        trackAnalyticsLogoutFailure(logOutResponse);
        console.log(`Device removed: LOGOUT RESPONSE 200004`);
        setKey(LOCALSTORAGE.DEVICE_REMOVED, JSON.stringify(true));
        !showModal && openTimeoutPopup(history);
    } else {
        await logoutHandling(clearStore);
        !showModal &&
            store.dispatch(
                !showModal &&
                openPopup(MODALS.ALERT_MODAL, {
                    modalClass: "alert-modal ",
                    headingMessage: logOutResponse?.error
                        ? logOutResponse.error
                        : "Some Error Occurred",
                    primaryButtonText: "Ok",
                    primaryButtonAction: () => {
                        redirectToHomeScreen(history);
                    },
                    closeModal: true,
                    hideCloseIcon: true,
                }),
            );
    }

    getDeviceId();
    await getAnonymousId();
};

export const logoutHandling = async (shouldClearStore = true) => {
    //mixpanel.reset();
    // Moengage.destroy_session();
    store.dispatch(loggedIn(false));
    removeAppEventListener();
    await clearKey();
    shouldClearStore && store.dispatch(clearStore());
};

export const trackAnalyticsLogoutFailure = (logOutResponse) => {
    mixPanelConfig.trackEvent(MIXPANEL.EVENT.LOGOUT_FAILED, {
        [`${MIXPANEL.PARAMETER.REASON}`]: logOutResponse.message,
    });
    moengageConfig.trackEvent(MOENGAGE.EVENT.LOGOUT_FAILED, {
        [`${MOENGAGE.PARAMETER.REASON}`]: logOutResponse.message,
    });
};

function handleNetworkRetry() {
    store.dispatch(showMainLoaderImmediate());
    if (navigator.onLine) {
        store.dispatch(showMainLoaderImmediate());
        setTimeout(() => {
            store.dispatch(closePopup());
            store.dispatch(hideMainLoader());
        }, 2000);
    } else {
        setTimeout(() => {
            store.dispatch(hideMainLoader());
        }, 2000);
    }
}

export const showNoInternetPopup = () => {
    const isLogin = isUserloggedIn();
    let instructionText = isMobile.any()
        ? MESSAGE.NETWORK_MESSAGE_MOBILE
        : MESSAGE.NETWORK_MESSAGE_WEB;
    store.dispatch(
        openPopup(MODALS.ALERT_MODAL, {
            modalClass: "alert-modal error-state-modal",
            headingMessage: ERROR_MESSAGES.NO_INTERNET,
            hideCloseIcon: isLogin,
            instructions: instructionText,
            primaryButtonText: !isLogin ? "Retry" : "Ok",
            primaryButtonAction: !isLogin
                ? () => {
                    handleNetworkRetry();
                }
                : () => {
                    store.dispatch(closePopup());
                },
            isCloseModal: false,
            errorIcon: "icon-no-internet",
        }),
    );
};

export const safeNavigation = (history, path) => {
    if (navigator.onLine) {
        if (typeof path !== 'object') {
            const [pathname, search = ''] = path.split('?');
            path = { pathname, search: search ? `?${search}` : '' }
        }
        const { state = {} } = path;
        history.push({ ...path, state: { ...state, prevPath: history?.location?.pathname } });
    } else {
        showNoInternetPopup();
    }
};

export const filterRailContent = (response) => {
    response.data.items.filter((item, index) => {
        response.data.items[index].contentList = filterPartnerContents(
            item.contentList,
            item.sectionSource,
        );
    });
    return response;
};

export const getSubscriptionExpiryText = (currentSubscription) => {
    if (currentSubscription?.status?.toUpperCase() === ACCOUNT_STATUS.ACTIVE) {
        if (currentSubscription?.packType?.toLowerCase() === PACK_TYPE.FREE) {
            return `${CURRENT_SUBSCRIPTION.ENDS} ${currentSubscription?.expirationDate}`;
        } else if (
            currentSubscription?.packType?.toLowerCase() === PACK_TYPE.PAID
        ) {
            return `${CURRENT_SUBSCRIPTION.RENEWAL} ${currentSubscription?.expirationDate}`;
        }
    } else if (
        currentSubscription?.status?.toUpperCase() === ACCOUNT_STATUS.DEACTIVATED ||
        currentSubscription?.status === ACCOUNT_STATUS.DEACTIVE
    ) {
        return `${CURRENT_SUBSCRIPTION.EXPIRED} ${currentSubscription?.expirationDate}`;
    }
};

export const taDataFiltering = (list) => {
    list &&
        list.map((item) => {
            if (!isEmpty(item?.actionedItemTitle)) {
                item.title += " aa" + item?.actionedItemTitle;
            }
            if (!isEmpty(item?.seriesvrId)) {
                item.id = item.seriesvrId ? item.seriesvrId : item.id;
                item.title = item.seriesTitle ? item.seriesTitle : item.title;
                item.contentType = item.seriescontentType
                    ? item.seriescontentType
                    : item.contentType;
                item.image = item.seriesimage ? item.seriesimage : item.image;
            }
            item['sectionSource'] = SECTION_SOURCE.RECOMMENDATION;
            return item;
        }
        );
    return list;
};

export const checkBingeDTHStatus = (
    dthStatus,
    bingeStatus,
    migrated,
    packType,
    accountSubStatus,
    { history, expiryDate, detailPage = false },
) => {
    let isDBR = !migrated,
        isMBR = migrated,
        freePack = packType?.toLowerCase() === PACK_TYPE.FREE,
        paidPack = packType?.toLowerCase() === PACK_TYPE.PAID;

    let popupShown = false,
        skipToHome = !detailPage;

    if (
        [
            ACCOUNT_STATUS.DEACTIVATED,
            ACCOUNT_STATUS.DEACTIVE,
            ACCOUNT_STATUS.INACTIVE,
        ].includes(dthStatus?.toUpperCase()) ||
        (dthStatus?.toUpperCase() === ACCOUNT_STATUS.ACTIVE &&
            accountSubStatus?.toUpperCase() ===
            ACCOUNT_STATUS.SUB_STATUS_PARTIALLY_DUNNED)
    ) {
        let data = {};
        if (
            [
                ACCOUNT_STATUS.DEACTIVATED,
                ACCOUNT_STATUS.DEACTIVE,
                ACCOUNT_STATUS.INACTIVE,
            ].includes(bingeStatus?.toUpperCase()) &&
            (isDBR || isMBR)
        ) {
            //binge subscription type is (DBR FREE (Inactive), DBR PAID (Inactive), MBR FREE (Inactive), MBR PAID (Inactive))
            data = {
                header: DTH_BINGE_POPUP.DTH_ACTIVE_BINGE_INACTIVE.HEADER,
                instructions: DTH_BINGE_POPUP.DTH_ACTIVE_BINGE_INACTIVE.INSTRUCTIONS,
                primaryBtnText:
                    DTH_BINGE_POPUP.DTH_ACTIVE_BINGE_INACTIVE.PRIMARY_BTN_TEXT,
                secondaryBtnText:
                    DTH_BINGE_POPUP.DTH_ACTIVE_BINGE_INACTIVE.SECONDARY_BTN_TEXT,
                errorIcon: "icon-alert-upd",
                dunningInactive: false,
            };
            popupShown = true;
            openRechargePopUp(history, data, detailPage);
        }
    }

    if (
        [
            ACCOUNT_STATUS.DEACTIVATED,
            ACCOUNT_STATUS.DEACTIVE,
            ACCOUNT_STATUS.INACTIVE,
        ].includes(dthStatus?.toUpperCase())
    ) {
        //DTH status is inactive
        let data = {};
        if (
            bingeStatus?.toUpperCase() === ACCOUNT_STATUS.WRITTEN_OFF ||
            (bingeStatus?.toUpperCase() === ACCOUNT_STATUS.ACTIVE &&
                (isDBR || (isMBR && freePack)))
        ) {
            //binge subscription type is (written off, DBR FREE (Active), DBR PAID (Active), MBR FREE (Active))
            data = {
                header: DTH_BINGE_POPUP.DTH_INACTIVE_BINGE_WRITTEN_OFF_INACTIVE.HEADER,
                instructions:
                    DTH_BINGE_POPUP.DTH_INACTIVE_BINGE_WRITTEN_OFF_INACTIVE.INSTRUCTIONS,
                primaryBtnText:
                    DTH_BINGE_POPUP.DTH_INACTIVE_BINGE_WRITTEN_OFF_INACTIVE
                        .PRIMARY_BTN_TEXT,
                secondaryBtnText:
                    DTH_BINGE_POPUP.DTH_INACTIVE_BINGE_WRITTEN_OFF_INACTIVE
                        .SECONDARY_BTN_TEXT,
            };
            popupShown = true;
            dthBingePopup(history, data, skipToHome);
        } else if (
            isMBR &&
            paidPack &&
            bingeStatus?.toUpperCase() === ACCOUNT_STATUS.ACTIVE
        ) {
            //binge subscription type is MBR PAID (active)
            data = {
                header: DTH_BINGE_POPUP.DTH_INACTIVE_BINGE_MBR_PAID_ACTIVE.HEADER,
                instructions: `${DTH_BINGE_POPUP.DTH_INACTIVE_BINGE_MBR_PAID_ACTIVE.INSTRUCTIONS} ${expiryDate}.`,
                primaryBtnText:
                    DTH_BINGE_POPUP.DTH_INACTIVE_BINGE_MBR_PAID_ACTIVE.PRIMARY_BTN_TEXT,
                secondaryBtnText:
                    DTH_BINGE_POPUP.DTH_INACTIVE_BINGE_MBR_PAID_ACTIVE.SECONDARY_BTN_TEXT,
            };
            popupShown = !detailPage;
            !detailPage && dthBingePopup(history, data, skipToHome);
        }
    } else if (dthStatus?.toUpperCase() === ACCOUNT_STATUS.ACTIVE) {
        //DTH Status is active
        let data = {};
        if (
            accountSubStatus?.toUpperCase() ===
            ACCOUNT_STATUS.SUB_STATUS_PARTIALLY_DUNNED
        ) {
            //DTH Status is partially Dunned
            if (
                bingeStatus?.toUpperCase() === ACCOUNT_STATUS.WRITTEN_OFF ||
                (bingeStatus?.toUpperCase() === ACCOUNT_STATUS.ACTIVE &&
                    (isDBR || (isMBR && freePack)))
            ) {
                //binge subscription type is (written off, DBR FREE (Active), DBR PAID (Active), MBR FREE (Active))
                data = {
                    header: DTH_BINGE_POPUP.DTH_DUNNED_BINGE_WRITTEN_OFF_INACTIVE.HEADER,
                    instructions:
                        DTH_BINGE_POPUP.DTH_DUNNED_BINGE_WRITTEN_OFF_INACTIVE.INSTRUCTIONS,
                    primaryBtnText:
                        DTH_BINGE_POPUP.DTH_DUNNED_BINGE_WRITTEN_OFF_INACTIVE
                            .PRIMARY_BTN_TEXT,
                    secondaryBtnText:
                        DTH_BINGE_POPUP.DTH_DUNNED_BINGE_WRITTEN_OFF_INACTIVE
                            .SECONDARY_BTN_TEXT,
                };
                popupShown = true;
                dthBingePopup(history, data, skipToHome);
            } else if (
                isMBR &&
                paidPack &&
                bingeStatus?.toUpperCase() === ACCOUNT_STATUS.ACTIVE
            ) {
                //binge subscription type is MBR PAID (active)
                data = {
                    header: DTH_BINGE_POPUP.DTH_DUNNED_BINGE_MBR_PAID_ACTIVE.HEADER,
                    instructions: `${DTH_BINGE_POPUP.DTH_DUNNED_BINGE_MBR_PAID_ACTIVE.INSTRUCTIONS} ${expiryDate}.`,
                    primaryBtnText:
                        DTH_BINGE_POPUP.DTH_DUNNED_BINGE_MBR_PAID_ACTIVE.PRIMARY_BTN_TEXT,
                    secondaryBtnText:
                        DTH_BINGE_POPUP.DTH_DUNNED_BINGE_MBR_PAID_ACTIVE.SECONDARY_BTN_TEXT,
                };
                popupShown = !detailPage;
                !detailPage && dthBingePopup(history, data, skipToHome);
            }
        } else {
            if (
                [
                    ACCOUNT_STATUS.DEACTIVATED,
                    ACCOUNT_STATUS.DEACTIVE,
                    ACCOUNT_STATUS.INACTIVE,
                ].includes(bingeStatus?.toUpperCase()) &&
                isMBR
            ) {
                //binge subscription type is inactive(MBR/Free, MBR/PAID)
                data = {
                    header: DTH_BINGE_POPUP.DTH_ACTIVE_BINGE_INACTIVE.HEADER,
                    instructions: DTH_BINGE_POPUP.DTH_ACTIVE_BINGE_INACTIVE.INSTRUCTIONS,
                    primaryBtnText:
                        DTH_BINGE_POPUP.DTH_ACTIVE_BINGE_INACTIVE.PRIMARY_BTN_TEXT,
                    secondaryBtnText:
                        DTH_BINGE_POPUP.DTH_ACTIVE_BINGE_INACTIVE.SECONDARY_BTN_TEXT,
                    errorIcon: "icon-alert-upd",
                    dunningInactive: false,
                };
                popupShown = true;
                openRechargePopUp(history, data, detailPage);
            } else if (
                bingeStatus?.toUpperCase() === ACCOUNT_STATUS.ACTIVE &&
                isMBR
            ) {
                //binge subscription type is MBR FREE/ PAID (active)
                popupShown = !detailPage;
                let userInfo = JSON.parse(getKey(LOCALSTORAGE.USER_INFO)) || {};
                !detailPage &&
                    store
                        .dispatch(dunningRecharge(userInfo.sId, userInfo.baId, false))
                        .then((res) => {
                            if (res?.code === 0) {
                                store.dispatch(hideMainLoader());
                                data = {
                                    header: DTH_BINGE_POPUP.DTH_ACTIVE_BINGE_ACTIVE.HEADER,
                                    instructions: res?.data?.response
                                        ? res.data.response
                                        : DTH_BINGE_POPUP.DTH_ACTIVE_BINGE_ACTIVE.INSTRUCTIONS,
                                    primaryBtnText:
                                        DTH_BINGE_POPUP.DTH_ACTIVE_BINGE_ACTIVE.PRIMARY_BTN_TEXT,
                                    secondaryBtnText:
                                        DTH_BINGE_POPUP.DTH_ACTIVE_BINGE_ACTIVE.SECONDARY_BTN_TEXT,
                                    errorIcon: "icon-group-24",
                                    dunningInactive: true,
                                };
                                openRechargePopUp(history, data);
                            } else {
                                toggleLoginScreen();
                                safeNavigation(
                                    history,
                                    `/${URL.MY_SUBSCRIPTION}?source=${MIXPANEL.VALUE.LOGIN}`,
                                );
                                //loginUser(true, accountDetailList, openPopup, history, params);
                            }
                        })
                        .catch(() => {
                            toggleLoginScreen();
                            safeNavigation(
                                history,
                                `/${URL.MY_SUBSCRIPTION}?source=${MIXPANEL.VALUE.LOGIN}`,
                            );
                            //loginUser(true, accountDetailList, openPopup, history, params);
                        });
            } else {
                //binge is written off or any other case
                toggleLoginScreen();
                popupShown = !detailPage;

                !detailPage && bingeStatus?.toUpperCase() === ACCOUNT_STATUS.WRITTEN_OFF
                    ? safeNavigation(
                        history,
                        `/${URL.PACK_SELECTION}?source=${MIXPANEL.VALUE.LOGIN}`,
                    )
                    : safeNavigation(
                        history,
                        `/${URL.MY_SUBSCRIPTION}?source=${MIXPANEL.VALUE.LOGIN}`,
                    );
                //loginUser(true, accountDetailList, openPopup, history, params);
            }
        }
    } else if (dthStatus?.toUpperCase() === ACCOUNT_STATUS.TEMP_SUSPENSION) {
        let data = {};
        if (
            bingeStatus?.toUpperCase() === ACCOUNT_STATUS.WRITTEN_OFF ||
            isDBR ||
            (isMBR && freePack) ||
            (isMBR &&
                paidPack &&
                [
                    ACCOUNT_STATUS.DEACTIVATED,
                    ACCOUNT_STATUS.DEACTIVE,
                    ACCOUNT_STATUS.INACTIVE,
                ].includes(bingeStatus?.toUpperCase()))
        ) {
            //binge subscription type is (written off, DBR FREE (Active/ Inactive), DBR PAID (Active/ Inactive), MBR FREE (Active/ Inactive), MBR PAID (inactive))
            data = {
                header:
                    DTH_BINGE_POPUP.DTH_TEMP_SUSPENDED_BINGE_WRITTEN_OFF_INACTIVE.HEADER,
                instructions:
                    DTH_BINGE_POPUP.DTH_TEMP_SUSPENDED_BINGE_WRITTEN_OFF_INACTIVE
                        .INSTRUCTIONS,
                primaryBtnText:
                    DTH_BINGE_POPUP.DTH_TEMP_SUSPENDED_BINGE_WRITTEN_OFF_INACTIVE
                        .PRIMARY_BTN_TEXT,
            };
            popupShown = true;
            tempSuspendedPopup(history, data, skipToHome);
        } else if (
            isMBR &&
            paidPack &&
            bingeStatus?.toUpperCase() === ACCOUNT_STATUS.ACTIVE
        ) {
            //binge subscription type is MBR PAID (active)
            data = {
                header: DTH_BINGE_POPUP.DTH_TEMP_SUSPENDED_BINGE_MBR_PAID_ACTIVE.HEADER,
                instructions: `${DTH_BINGE_POPUP.DTH_TEMP_SUSPENDED_BINGE_MBR_PAID_ACTIVE.INSTRUCTIONS} ${expiryDate}.`,
                primaryBtnText:
                    DTH_BINGE_POPUP.DTH_TEMP_SUSPENDED_BINGE_MBR_PAID_ACTIVE
                        .PRIMARY_BTN_TEXT,
            };
            popupShown = !detailPage;
            !detailPage && tempSuspendedPopup(history, data, skipToHome);
        }
    } else {
        toggleLoginScreen();
        redirectToHomeScreen(history);
        //loginUser(true, accountDetailList, openPopup, history, params);
    }

    return popupShown;
};

export const dthBingePopup = (
    history,
    { header, instructions, primaryBtnText, secondaryBtnText },
    skipToHome,
) => {
    store.dispatch(
        openPopup(MODALS.ALERT_MODAL, {
            modalClass: "alert-modal inactive-alert",
            headingMessage: header,
            instructions: instructions,
            primaryButtonText: primaryBtnText,
            primaryButtonAction: async () => {
                store.dispatch(showMainLoader());
                let userInfo = JSON.parse(getKey(LOCALSTORAGE.USER_INFO)) || {};
                await store.dispatch(quickRechargeBeforeLogin(userInfo.sId));
                const { packSelectionDetail } = store.getState();
                let quickRechargeSelfCareUrl = get(
                    packSelectionDetail,
                    "quickRechargeUrl",
                );
                if (
                    quickRechargeSelfCareUrl.code === 0 &&
                    !isEmpty(quickRechargeSelfCareUrl.data)
                ) {
                    trackAppsflyerOnRechargeRedirection();
                    window.location.assign(
                        `${quickRechargeSelfCareUrl.data.rechargeUrl}`,
                    );
                    toggleLoginScreen();
                } else {
                    store.dispatch(hideMainLoader());
                }
            },
            secondaryButtonText: secondaryBtnText,
            secondaryButtonAction: () => {
                store.dispatch(closePopup());
                toggleLoginScreen();
                skipToHome && redirectToHomeScreen(history);
            },
            hideCloseIcon: true,
            errorIcon: "icon-alert-upd",
        }),
    );
};

export const openRechargePopUp = (
    history,
    {
        header,
        instructions,
        primaryBtnText,
        secondaryBtnText,
        errorIcon,
        dunningInactive,
    },
    fromPIPage,
) => {
    store.dispatch(
        openPopup(MODALS.ALERT_MODAL, {
            modalClass: "alert-modal inactive-alert",
            headingMessage: header,
            instructions: instructions,
            primaryButtonText: primaryBtnText,
            primaryButtonAction: /*isUserloggedIn() ? */ () => {
                let search, state;

                if (fromPIPage) {
                    let pathname = window.location.pathname;
                    let source = getAnalyticsSource(pathname);
                    search = `?source=${MIXPANEL.VALUE.CONTENT}&aboutSubscription=true`;
                    state = {
                        subscription: "recharge",
                        dunning: !!dunningInactive,
                        source: source,
                        accountDropDown: fromPIPage,
                    };
                } else {
                    search = `?source=${MIXPANEL.VALUE.LOGIN}&aboutSubscription=true&contentRecharge=true`;
                    state = {
                        subscription: "recharge",
                        source: MIXPANEL.VALUE.LOGIN,
                        accountDropDown: true,
                    };
                }

                toggleLoginScreen();
                safeNavigation(history, {
                    pathname: `${URL.PACK_SELECTION}`,
                    search: search,
                    state: state,
                });
            } /*: () => {
            loginUser(false, accountDetailList, openPopup, history, params)
        },*/,
            secondaryButtonText: secondaryBtnText,
            secondaryButtonAction: () => {
                store.dispatch(closePopup());
                toggleLoginScreen();
                !fromPIPage && redirectToHomeScreen(history);
            },
            hideCloseIcon: true,
            errorIcon: errorIcon,
        }),
    );
};

export const tempSuspendedPopup = (
    history,
    { header, instructions, primaryBtnText },
    skipToHome,
) => {
    store.dispatch(
        openPopup(MODALS.ALERT_MODAL, {
            modalClass: "alert-modal inactive-alert",
            headingMessage: header,
            instructions: instructions,
            primaryButtonText: primaryBtnText,
            primaryButtonAction: async () => {
                store.dispatch(closePopup());
                toggleLoginScreen();
                skipToHome && redirectToHomeScreen(history);
            },
            hideCloseIcon: true,
            errorIcon: "icon-alert-upd",
        }),
    );
};

/*export const closeAtvUpgradePopup = async() => {
    await store.dispatch(handleAtvUpgrade());
    closePopup();
};*/

export const switchAtvAcc = async (history) => {
    await store.dispatch(switchToAtvAccount());

    const { profileDetails } = store.getState();
    let switchAtcAccResponse = get(profileDetails, "switchAtcAccResponse");
    //after API call we will check if user has successfully switched or not,
    // if yes then we will update local store with new information

    if (
        switchAtcAccResponse &&
        switchAtcAccResponse?.code === 0 &&
        get(switchAtcAccResponse, "data.baId") !== null
    ) {
        let userInfo = JSON.parse(getKey(LOCALSTORAGE.USER_INFO)) || {};
        let baId = switchAtcAccResponse?.data?.baId;
        let defaultProfile = switchAtcAccResponse?.data?.defaultProfile
            ? switchAtcAccResponse.data.defaultProfile
            : userInfo.profileId;
        userInfo = {
            ...userInfo,
            baId: JSON.parse(baId),
            profileId: defaultProfile,
        };
        setKey(LOCALSTORAGE.USER_INFO, JSON.stringify(userInfo));
        setKey(LOCALSTORAGE.ATV_UPGRADE, JSON.stringify(false));
        await store.dispatch(getProfileDetails());
        history.push(`${URL.DEFAULT}`);
    } else {
        toast("Error in switching to upgraded subscription.");
    }
};

export const showAtvUpgradePopup = (history) => {
    const { packSelectionDetail: { currentSubscription } = {} } =
        store.getState();
    const currentPackPrice = get(currentSubscription, "data.packPrice");
    let instructions;

    if (currentSubscription) {
        const {
            data: { packType },
        } = currentSubscription;
        if (packType.toLowerCase() === PACK_TYPE.FREE) {
            parseFloat(currentPackPrice) === 149
                ? (instructions =
                    "You have been upgraded to Tata Binge Premium with 1 month free trial linked to your Tata Play Binge+.")
                : (instructions =
                    "Your Tata Binge Premium subscription is now linked to your Tata Play Binge+.");
        } else {
            parseFloat(currentPackPrice) === 149
                ? (instructions =
                    "You have been upgraded to Tata Binge Premium with 1 month free trial linked to your Tata Play Binge+.")
                : (instructions =
                    "Your Tata Binge Premium subscription is now linked to your Tata Play Binge+.");
        }
        store.dispatch(
            openPopup(MODALS.ALERT_MODAL, {
                modalClass: "alert-modal ",
                instructions: instructions,
                primaryButtonText: "OK",
                primaryButtonAction: () => switchAtvAcc(history),
                hideCloseIcon: true,
            }),
        );
    }
};

export const getMixpanelPeopleProperties = () => {
    const state = store.getState();
    let userData = get(state.profileDetails, "userProfileDetails", {});
    let currentSubscription = get(state.subscriptionDetails, "currentSubscription.data", {});
    let isFreemiumUser = checkCurrentSubscription(currentSubscription);

    let userInfo = JSON.parse(getKey(LOCALSTORAGE.USER_INFO)) || {},
        firstName = "",
        lastName = "",
        email = "",
        subscriptionType = "",
        packCreationDate = "",
        sid = "",
        bingeAccountCount = 0,
        subscribed = MIXPANEL.VALUE.NO,
        deviceLoginCount = 0,
        firstTimeLoginDate = "",
        loginWith = "",
        planType,
        packPrice = "",
        packName = "",
        migrated = "",
        freeTrialAvailed = MIXPANEL.VALUE.NO,
        packRenewalDate = "",
        packEndDate = "",
        profileId = "",
        packStartDate = "",
        lastPackType = "",
        lastPackPrice = "",
        lastPackName = "",
        lastBillingType = "",
        totalPaidPackRenewal = "",
        firstPaidPackSubscriptionDate = "",
        mixpanelId = ""

    let deviceList = {};
    for (var i = 0; i < 4; i++) {
        deviceList[`DEVICE-${i + 1}`] = userInfo?.deviceList?.[i]?.deviceName || "";
    }

    if (!isEmpty(userInfo)) {
        firstName = userInfo.firstName;
        lastName = userInfo.lastName;
        sid = userInfo.sId;
        bingeAccountCount = userInfo.bingeAccountCount;
        deviceLoginCount = userInfo.deviceLoginCount;
        firstTimeLoginDate = userInfo.firstTimeLoginDate;
        subscriptionType = currentSubscription?.subscriptionType || "";
        packCreationDate = userInfo?.packCreationDate;
        loginWith = userInfo?.loginWith || 'RMN';
        email = userInfo.emailId;
        planType = currentSubscription?.subscriptionType;
        packPrice = currentSubscription?.amountValue || "";
        packName = currentSubscription?.productName || "";
        migrated = currentSubscription?.migrated || "";
        freeTrialAvailed = userInfo?.freeTrialAvailed;
        packRenewalDate = currentSubscription?.rechargeDue || "";
        packEndDate = currentSubscription?.expiryDate || "";
        profileId = userInfo?.profileId;
        packStartDate = userInfo?.packStartDate || "";
        lastPackType = userInfo?.lastPackType;
        lastPackPrice = userInfo?.lastPackPrice;
        lastPackName = userInfo?.lastPackName;
        lastBillingType = userInfo?.lastBillingType;
        totalPaidPackRenewal = userInfo?.totalPaidPackRenewal;
        firstPaidPackSubscriptionDate = userInfo?.firstPaidPackSubscriptionDate || "";
        mixpanelId = userInfo?.mixpanelId;
    }

    let mixpanelPeopleProperties = {
        ...deviceList,
        "FIRST-NAME": `${firstName}`,
        "LAST-NAME": lastName,
        // "SID": sid,
        "RMN": loginWith === "RMN" ? userData?.rmn : userInfo?.rmn,
        "EMAIL": email,
        "FIRST-TIME-LOGIN": firstTimeLoginDate || "",
        "FIRST-LOGIN-DATE": firstTimeLoginDate || "",
        "BINGE-ACCOUNTS-COUNT": bingeAccountCount,
        "LOGGED-IN-DEVICE-COUNT": deviceLoginCount,
        "LAST-USED-AT": new Date(),
        "LAST-APP-USAGE-DATE": new Date(),
        "PROFILE-ID": profileId,
        "MIXPANEL-ID": mixpanelId,
        $distinctId: sid,
        $CleverTap_user_id: sid,
        [MIXPANEL.PARAMETER.ACQUISITION_SOURCE]: get(currentSubscription, "acquisitionSource", ""),
    };

    let idAnalyticsData = getIdAnalyticsData(),
        preparedMixpanelPeopleProp = { ...mixpanelPeopleProperties, ...idAnalyticsData };

    console.log('mixpanel People Properties', mixpanelPeopleProperties)
    return mixpanelPeopleProperties = { ...preparedMixpanelPeopleProp, ...idAnalyticsData };
};

export const getIconInfo = (subscriptionInfo) => {
    let subscriptionType = get(subscriptionInfo, "subscriptionType"),
        complementaryPack = get(subscriptionInfo, "complementaryPlan");
    if (
        subscriptionType?.toUpperCase() === SUBSCRIPTION_TYPE.ANYWHERE &&
        (complementaryPack === "" || complementaryPack === null)
    ) {
        return bingeMobileAsset;
    } else {
        return bingeAsset;
    }
};

export const getPackInfo = () => {
    let result = getPartnerSubscriptionInfo();
    if (result) {
        let userAccountStatus = result?.subscriptionStatus;
        let nonSubscribedPartnerList = get(result, 'nonSubscribedPartnerList');
        let packExpired = userAccountStatus?.toUpperCase() !== SUBSCRIPTION_STATUS.ACTIVE;
        let partnerSubscription;
        if (result && result.hasOwnProperty("providers")) {
            partnerSubscription = get(result, "providers");
        } else if (result && result.hasOwnProperty("partnerSubscriptionsDetails")) {
            partnerSubscription = get(result, "partnerSubscriptionsDetails.providers");
        }
        return { packExpired, partnerSubscription, nonSubscribedPartnerList };
    }
    return false;
};

export const isPartnerSubscribed = (providerId) => {
    let subscribed = false;
    let { packExpired, nonSubscribedPartnerList } = getPackInfo();

    if (!packExpired && isEmpty(nonSubscribedPartnerList)) {
        subscribed = true;
    } else if (!packExpired && !isEmpty(nonSubscribedPartnerList)) {
        let nonSubscribedPartners = [];
        nonSubscribedPartnerList.map((i) => {
            nonSubscribedPartners.push(parseInt(i.partnerId));
        });
        subscribed = !nonSubscribedPartners.includes(parseInt(providerId));
    }
    return subscribed;
}

export const handlePubnubData = async (data, location) => {
    console.log("Received app pubnub data", location);
    console.log("Received app pubnub data", data);
    let logOutFired = JSON.parse(getKey(LOCALSTORAGE.LOGOUT_FIRED));
    let isSilentLoginInProgress = JSON.parse(getKey(LOCALSTORAGE.SILENT_LOGIN_INPROGRESS)) === true;
    let referenceId = "";
    if (data && isUserloggedIn() && !logOutFired && !isSilentLoginInProgress) {
        /** --- start --- set dthStatus of local storage whenever a notification received from Pubnub**/
        let userInfo = JSON.parse(getKey(LOCALSTORAGE.USER_INFO)) || {};
        let dthStatus = userInfo.dthStatus;
        let deviceInfo = data?.deviceInfo, deviceIndex;

        // setKey(LOCALSTORAGE.APP_LAUNCH_COUNTER, JSON.stringify(data?.appLaunchCounter));
        // setKey(LOCALSTORAGE.RECORD_APP_LAUNCH, JSON.stringify(data?.recordAppLaunch));

        if (isSilentLogin(data)) {
            await handleSilentLogin(data);
        }
        else if (dthStatus !== DTH_TYPE.DTH_W_BINGE_OLD_USER && accountChannelToBeSubscribed(data) && !location?.includes("history")) {
            switchToAccountChannel(data?.channelName);
            await store.dispatch(fetchGlobalConfig(GLOBAL_CONFIG_SOURCE.PUBNUB));
        }
        else {
            if (dthStatus !== DTH_TYPE.DTH_W_BINGE_OLD_USER) { // RMN Channel Push
                userInfo = JSON.parse(getKey(LOCALSTORAGE.USER_INFO)) || {};
                userInfo = data && {
                    ...userInfo,
                    rmn: get(data, "mobileNumber"),
                    bingeSubscriberId: get(data, "accountId"),
                    dthStatus: get(data, "dthStatus"),
                    mixpanelId: get(data, "mixpanelId") || userInfo?.mixpanelId,
                };
                setKey(LOCALSTORAGE.APP_LAUNCH_COUNTER, JSON.stringify(data?.appLaunchCounter));
                setKey(LOCALSTORAGE.RECORD_APP_LAUNCH, JSON.stringify(data?.recordAppLaunch));
                setKey(LOCALSTORAGE.USER_INFO, JSON.stringify(userInfo));
                mixPanelConfig.setSuperProperties();
                mixPanelConfig.setGroup();
                mixPanelConfig.setGroupProperties();
                mixPanelConfig.setPeopleProperties();
                if (!isEmpty(deviceInfo)) {
                    let appDeviceId = getDeviceId();
                    deviceIndex = deviceInfo.findIndex(item => parseInt(item.deviceId) === parseInt(appDeviceId));
                    let beRegisteredDevice = JSON.parse(getKey(LOCALSTORAGE.BE_REGISTERED_DEVICE)) === true;
                    if (deviceIndex < 0 && !beRegisteredDevice) {
                        if (window.location.pathname.includes(`/${URL.PLAYER}`)) {
                            store.dispatch(setPlayerAPIError(true));
                        } else {
                            openTimeoutPopup(history);
                        }
                        console.log(`Device removed from pubnub: OTHER DTH STATUS || appDeviceId : ${appDeviceId}`)
                        console.log(`deviceInfo: ${deviceInfo}`)
                        setKey(LOCALSTORAGE.DEVICE_REMOVED, JSON.stringify(true));
                    }

                    let atvDeviceInfo = deviceInfo.find(
                        item => item.deviceId === appDeviceId,
                    );
                    atvDeviceInfo?.atvCancelled &&
                        setKey(LOCALSTORAGE.ATV_UPGRADE, atvDeviceInfo.atvCancelled);
                    referenceId = atvDeviceInfo?.referenceId;
                }
            }
            /** --- end --- **/

            if (dthStatus === DTH_TYPE.DTH_W_BINGE_OLD_USER) { // SID Channel push
                userInfo = data && {
                    ...userInfo,
                    rmn: get(data, "rmn"),
                    mixpanelId: get(data, "mixpanelId") || userInfo?.mixpanelId,
                };

                if (userInfo?.subscriptionType === SUBSCRIPTION_TYPE.ATV && !isEmpty(data?.saId)) {
                    userInfo = {
                        ...userInfo,
                        delinkedUserSaID: data?.saId,
                        isDelinkedUser: true,
                    }
                }
                setKey(LOCALSTORAGE.USER_INFO, JSON.stringify(userInfo));

                if (deviceInfo) {
                    let bingeData = deviceInfo.find(i => {
                        if (i.hasOwnProperty('baId')) {
                            return JSON.parse(i?.baId) === JSON.parse(userInfo.baId)
                        }
                        return false
                    });
                    setKey(LOCALSTORAGE.APP_LAUNCH_COUNTER, JSON.stringify(bingeData?.appLaunchCounter));
                    setKey(LOCALSTORAGE.RECORD_APP_LAUNCH, JSON.stringify(bingeData?.recordAppLaunch));

                    if (userInfo?.subscriptionType === SUBSCRIPTION_TYPE.ATV && !isEmpty(bingeData?.saId)) {
                        userInfo = {
                            ...userInfo,
                            delinkedUserSaID: bingeData?.saId,
                            isDelinkedUser: true,
                        };
                        setKey(LOCALSTORAGE.USER_INFO, JSON.stringify(userInfo));
                    };

                    let appDeviceId = getDeviceId();
                    deviceIndex = bingeData?.deviceList && bingeData.deviceList.findIndex(item => parseInt(item) === parseInt(appDeviceId));
                    let beRegisteredDevice = JSON.parse(getKey(LOCALSTORAGE.BE_REGISTERED_DEVICE)) === true;
                    if (deviceIndex < 0 && !beRegisteredDevice) {
                        if (window.location.pathname.includes(`/${URL.PLAYER}`)) {
                            store.dispatch(setPlayerAPIError(true));
                        } else {
                            openTimeoutPopup();
                        }
                        console.log(`Device removed from pubnub: ${DTH_TYPE.DTH_W_BINGE_OLD_USER} || appDeviceId : ${appDeviceId}`)
                        console.log(`deviceInfo: ${bingeData}`)
                        setKey(LOCALSTORAGE.DEVICE_REMOVED, JSON.stringify(true));
                    }
                    if (bingeData) {
                        referenceId = bingeData?.referenceId;
                        let atvDeviceInfo = bingeData.deviceDetails.find(item => item.deviceId === appDeviceId);
                        atvDeviceInfo?.atvCancelled && setKey(LOCALSTORAGE.ATV_UPGRADE, atvDeviceInfo.atvCancelled);
                    }
                }
            }

            await handleMixpanelOnPubnub(referenceId);
            await handleSubscriptionOnPubnub(data);
            await handleCancelPlanForTickTick();
            isEmpty(data?.bingeList) && isEmpty(data?.bingeFavouriteList) && checkNudgeVisiblity();
            handleApplePopupOnPubnub();
            handleDeviceOnPubnub();
            setPaymentStatusForPubnub(data);
            updateBingeListOnPubnub(data);
            showAmazonToast(data?.apv?.status)

            // Whenever we receive notification from Pubnub set FS_POPUP true, this will execute code to open FS popup on home screen -
            //commented code as now when user upgrades or purchase pack fs popup will be seen on payment page only
            //setKey(LOCALSTORAGE.SHOW_FS_POPUP, JSON.stringify(true));

        }
    }
};

export const handleCancelPlanForTickTick = async () => {
    const state = store.getState();
    let currentSubscription = get(state, "subscriptionDetails.currentSubscription.data"),
        isTickTick = get(currentSubscription, "flexiPlan");
    isTickTick && await store.dispatch(getCurrentSubscriptionMyplanVerbiages());
}

export const handleApplePopupOnPubnub = async () => {
    const state = store.getState();
    let currentSubscription = get(state, "subscriptionDetails.currentSubscription.data"),
        isSubscribed = checkPartnerSubscribed(currentSubscription,"", PROVIDER_NAME.APPLE),
        {appleStatus} = appleStatusJouney();
    if(appleStatus !== APPLE_REDEMPTION_STATUS.ACTIVATED && isSubscribed){
        await store.dispatch(getPartnerStatus())
    }
};

export const handleSilentLogin = async (data, history) => {
    console.log('Silent Login In-Progress: Migration Journey 2')
    setKey(LOCALSTORAGE.SILENT_LOGIN_INPROGRESS, JSON.stringify(true));
    removeAppEventListener();
    let userInfo = JSON.parse(getKey(LOCALSTORAGE.USER_INFO)) || {};
    const payload = {
        baId: get(data, "baId"),
        bingeSubscriberId: get(data, "accountId"),
        dthStatus: get(data, "dthStatus"),
        subscriberId: get(data, "dthSubscriberId"),
        rmn: userInfo.rmn,
        userAuthenticateToken: userInfo.accessToken,
        deviceAuthenticateToken: userInfo.deviceToken,
        helpCenterSilentLogin: false,
        silentLoginEvent: get(data, 'silentLoginEvent'),
        silentLoginTimestamp: get(data, 'silentLoginTimestamp'),
    };
    await updateUser(payload, onLoginSuccess, history);
    let isSilentLoginInProgress = JSON.parse(getKey(LOCALSTORAGE.SILENT_LOGIN_INPROGRESS)) === true;
    isSilentLoginInProgress && deleteKey(LOCALSTORAGE.SILENT_LOGIN_INPROGRESS);
    console.log('Silent Login Completed: Migration Journey 2')
}

export const isSilentLogin = (data) => {
    let silentLoginTimestamp = data?.silentLoginTimestamp,
        localLoginTimeStamp = getKey(LOCALSTORAGE.SILENT_LOGIN_TIMESTAMP);
    return silentLoginTimestamp && (isEmpty(localLoginTimeStamp) || (parseInt(localLoginTimeStamp) !== parseInt(silentLoginTimestamp)));
}

export const handleMixpanelOnPubnub = async (referenceId) => {
    // fetch mixpanelId if doesn't exist for current user
    let userInfo = JSON.parse(getKey(LOCALSTORAGE.USER_INFO)) || {};
    if (referenceId && !userInfo?.mixpanelId) {
        await store.dispatch(fetchMixpanelId(referenceId));
        mixPanelConfig.setSuperProperties();
        mixPanelConfig.setPeopleProperties();
        mixPanelConfig.identifyUser(true);
    }
}

export const handleSubscriptionOnPubnub = (data) => {
    let deviceRemoved = JSON.parse(getKey(LOCALSTORAGE.DEVICE_REMOVED)) === true;
    !deviceRemoved && store.dispatch(getCurrentSubscriptionInfo());
    if (isSilentLogin(data)) {
        store.dispatch(setManualLoginSource(MIXPANEL.VALUE.HOME));
        store.dispatch(setLoginManual(true));
    }
}

export const handleDeviceOnPubnub = () => {
    const currentLocation = window.location.pathname;
    if (currentLocation.includes(URL.DEVICE_MANAGEMENT)) {
        store.dispatch(getSubscriberDeviceList());
    }
}

export const setPaymentStatusForPubnub = (data) => {
    const currentLocation = window.location.pathname;
    if (currentLocation.includes(URL.SUBSCRIPTION_TRANSACTION)) {
        let paymentStatus = data?.paymentStatus;
        paymentStatus?.toUpperCase() === OPEL_STATUS.SUCCESS && store.dispatch(setPaymentStatusFromPubnub());
    }
}

export const updateBingeListOnPubnub = (data) => {
    if (!isEmpty(data?.bingeFavouriteList)) {
        let bingeFavouriteList = data.bingeFavouriteList;
        let added = bingeFavouriteList?.added;
        let removed = bingeFavouriteList?.removed;
        let savedList = JSON.parse(getKey(LOCALSTORAGE.GAMEZOP_WATCHLIST)) || [];

        if (!isEmpty(added)) {
            let duplicateData = savedList.find(i => i === parseInt(added));
            if (!duplicateData) {
                savedList = [...savedList, ...added];
            }
        }
        if (!isEmpty(removed)) {
            removed = removed.map(Number);
            savedList = savedList.filter(v => {
                return !removed.includes(v);
            });
        }
        savedList = savedList.map(Number);
        setKey(LOCALSTORAGE.GAMEZOP_WATCHLIST, JSON.stringify(savedList));
    }
    if (!isEmpty(data?.bingeList)) {
        let bingeList = data.bingeList;
        let added = bingeList?.added;
        let removed = bingeList?.removed;
        let savedList = JSON.parse(getKey(LOCALSTORAGE.WATCHLIST)) || [];

        if (!isEmpty(added)) {
            let duplicateData = savedList.find(i => i === parseInt(added));
            if (!duplicateData) {
                savedList = [...savedList, ...added];
            }
        }
        if (!isEmpty(removed)) {
            removed = removed.map(Number);
            savedList = savedList.filter(v => {
                return !removed.includes(v);
            });
        }
        savedList = savedList.map(Number);
        setKey(LOCALSTORAGE.WATCHLIST, JSON.stringify(savedList));
    }
}

export const getMaxHeightForMobile = () => {
    let appWidth = document.getElementById("app").clientWidth;
    let appHeight = screen.height,
        height;
    if (isMobile.any() && (appWidth <= 320 || appWidth <= 812)) {
        height = appHeight - 576;
        return height > 234 ? height : 234;
    }
};

export const switchAccount = async (
    selectedItemDetail,
    currentBingeAccount,
    history,
    setUpdatedBaId,
    switchAccountWebLarge = true,
    e,
    resetState,
) => {
    const handleCancelledUser = getDeviceStatus();
    handleCancelledUser && store.dispatch(closePopup());
    let payload = {
        sourceBaId: currentBingeAccount,
        targetBaId: selectedItemDetail?.baId,
        targetSID: selectedItemDetail?.subscriberId,  // Added header so that BE can generate new token and FE can use that token for SSO again after switch account
        deviceId: getDeviceId(),
    };
    if (selectedItemDetail.baId === null) {
        payload.dsn = selectedItemDetail.deviceSerialNumber;
    }
    removeAppEventListener();
    store.dispatch(postSwitchAccountReq(payload)).then(async (response) => {
        if (response && response.code === 0) {
            mixPanelConfig.unsetSuperProperties();
            deleteKey(LOCALSTORAGE.ACTIVATE_PRIME_BUTTON_CLICK)
            let baId = selectedItemDetail.baId;
            let defaultProfile = selectedItemDetail.defaultProfile;
            if (selectedItemDetail.baId === null) {
                baId = response?.data?.baId;
                defaultProfile = response?.data?.defaultProfile;
            }

            let loginPayload = {
                baId: baId,
                bingeSubscriberId: get(selectedItemDetail, "bingeSubscriberId"),
                dthStatus: get(selectedItemDetail, "dthStatus"),
                subscriberId: get(selectedItemDetail, "subscriberId"),
                rmn: get(selectedItemDetail, "mobileNumber"),
                referenceId: get(selectedItemDetail, "referenceId"),
                userAuthenticateToken: get(response, "data.userAuthenticateToken"),
                deviceAuthenticateToken: get(response, "data.deviceAuthenticateToken"),
            };
            await updateUser(loginPayload);
            // await store.dispatch(getCurrentSubscriptionInfo());
            let state = store.getState();
            let existingUserLogindata = get(state.loginReducer, "existingUser.data");
            if (!isEmpty(existingUserLogindata)) {
                const { mixpanelId } = response?.data;
                // let userInfo = JSON.parse(getKey(LOCALSTORAGE.USER_INFO)) || {};
                // userInfo = { ...userInfo, baId: baId, profileId: defaultProfile };
                // setKey(LOCALSTORAGE.USER_INFO, JSON.stringify(userInfo));

                !mixpanelId && await store.dispatch(fetchMixpanelId()); //fetch mixpanelId
                mixPanelConfig.setSuperProperties();
                mixPanelConfig.setPeopleProperties();
                mixPanelConfig.identifyUser(true);

                setUpdatedBaId && setUpdatedBaId(baId);
                await store.dispatch(getProfileDetails(true));
                await store.dispatch(getCurrentSubscriptionInfo());
                checkNudgeVisiblity();
                // store.dispatch(subscriberListing(userInfo.rmn));
                setDeviceStatus(false);
                trackSwitchAccountEvents();
                switchAccountWebLarge && resetState();
                redirectToHomeScreen(history);

                let aliasName = selectedItemDetail?.aliasName?.includes("Linked to") ? selectedItemDetail?.aliasName.split(' ').slice(2).join(' ') : selectedItemDetail?.aliasName;
                showSuccessToast(aliasName);
            } else {
                initiateAppEventListener();
            }
        } else if (response && response.code === 200007) {
            store.dispatch(
                openPopup(MODALS.ALERT_MODAL, {
                    modalClass: "alert-modal",
                    headingMessage: get(response, "message", COMMON_ERROR.SOME_ERROR),
                    primaryButtonText: "Ok",
                    primaryButtonAction: () =>
                        handleSwitchAccDeviceLimitError(
                            handleCancelledUser,
                            switchAccountWebLarge,
                            e,
                            history,
                        ),
                    closeModal: true,
                    onCloseAction: () => callLogOut(true, history),
                    hideCloseIcon: true,
                }),
            );
            initiateAppEventListener();
        } else {
            store.dispatch(
                openPopup(MODALS.ALERT_MODAL, {
                    modalClass: "alert-modal",
                    instructions: get(response, "message", COMMON_ERROR.SOME_ERROR),
                    primaryButtonText: "Ok",
                    closeModal: true,
                    hideCloseIcon: true,
                }),
            );
            initiateAppEventListener();
        }
    });
};

export const showSuccessToast = (deviceName) => {
    let state = store.getState();
    let switchAccountMsg = get(state.headerDetails, 'configResponse.data.config.switchAccount.successToast');
    !!deviceName &&
        toast(
            <div className="login-body-container">
                <div className="login-success-image">
                    <img src={successTickIcon} alt="" />
                </div>
                <div className="login-success-text">
                    <div>{`${switchAccountMsg} ${deviceName}`}</div>
                </div>
            </div>,
            {
                position: toast.POSITION.BOTTOM_CENTER,
                className: "login-toast-wrapper switch-toast",
                autoClose: 3000,
            },
        );
};

export const trackSwitchAccountEvents = () => {
    mixPanelConfig.profileChanges();
    moengageConfig.profileChanges();

    let userInformation = JSON.parse(getKey(LOCALSTORAGE.USER_INFO)) || {};
    mixPanelConfig.trackEvent(MIXPANEL.EVENT.SWITCH_PROFILE, {
        [`${MIXPANEL.PARAMETER.TO_BAID}`]: userInformation.baId,
    });
    moengageConfig.trackEvent(MOENGAGE.EVENT.SWITCH_PROFILE, {
        [`${MOENGAGE.PARAMETER.TO_BAID}`]: userInformation.baId,
    });
};

export const ftvWOEvents = (ftvOrder, installationMethod) => {
    let orderedFtv = ftvOrder ? MIXPANEL.VALUE.YES : MIXPANEL.VALUE.NO;
    let orderType = ftvOrder
        ? installationMethod === "fs-diy"
            ? MIXPANEL.VALUE.INSTALLER_DIY
            : MIXPANEL.VALUE.INSTALLER_FSD
        : "";

    let mixpanelData = {
        [`${MIXPANEL.PARAMETER.ORDERED_FTV}`]: orderedFtv,
        [`${MIXPANEL.PARAMETER.FTV_ORDER_TYPE}`]: orderType,
    },
        moengageData = {
            [`${MOENGAGE.PARAMETER.ORDERED_FTV}`]: orderedFtv,
            [`${MOENGAGE.PARAMETER.FTV_ORDER_TYPE}`]: orderType,
        };

    mixPanelConfig.trackEvent(MIXPANEL.EVENT.FTV_WO, mixpanelData);
    moengageConfig.trackEvent(MOENGAGE.EVENT.FTV_WO, moengageData);
};

export const handleSwitchAccDeviceLimitError = (
    handleCancelledUser,
    switchAccountWebLarge,
    e,
    history,
) => {
    if (handleCancelledUser) {
        callLogOut(true, history);
    } else {
        store.dispatch(closePopup());
    }
};

export const getShemarooUniqueId = () => {
    if (!getKey(LOCALSTORAGE.SHEMAROO_UNIQUE_ID)) {
        let uniqueId = new Date().getTime();
        setKey(LOCALSTORAGE.SHEMAROO_UNIQUE_ID, uniqueId);
    } else {
        return getKey(LOCALSTORAGE.SHEMAROO_UNIQUE_ID);
    }
};

export const getShemarooAnalyticsData = ({
    shemarooAnalyticsUserId,
    idSiteValue,
    smartUrl,
    e_n,
    e_a,
    e_v,
    dimension2,
    dimension5,
    dimension15,
    dimension16,
    dimension17,
    dimension23,
}) => {
    return {
        _id: shemarooAnalyticsUserId,
        idsite: idSiteValue,
        rand: new Date().getTime(),
        url: smartUrl,
        pv_id: getShemarooUniqueId(),
        e_n: e_n,
        e_a: e_a,
        e_v: e_v,
        e_c: "media",
        rec: 1,
        dimension1: "tata-sky",
        dimension2: dimension2,
        dimension5: dimension5,
        dimension15: dimension15,
        dimension16: dimension16,
        dimension17: dimension17,
        dimension23: dimension23,
        dimension25: "IN",
    };
};

export const toggleLoginScreen = () => {
    const user_info = JSON.parse(getKey(LOCALSTORAGE.USER_INFO));
    user_info["showLoginScreen"] = false;
    setKey(LOCALSTORAGE.USER_INFO, JSON.stringify(user_info));
};

export const detectSwipe = (className, func, deltaMin = 90, data) => {
    const swipe_det = {
        sX: 0,
        sY: 0,
        eX: 0,
        eY: 0,
    };
    let direction = null;
    let isScroll = false;
    const el = document.getElementsByClassName(className)[0];
    el?.addEventListener(
        "touchstart",
        function (e) {
            const t = e.touches[0];
            swipe_det.sX = t.screenX;
            swipe_det.sY = t.screenY;
            isScroll = true
        },
        false,
    );
    el?.addEventListener(
        "touchmove",
        function (e) {
            // Prevent default will stop user from scrolling, use with care
            // e.preventDefault();
            const t = e.touches[0];
            swipe_det.eX = t.screenX;
            swipe_det.eY = t.screenY;
            isScroll = true
        },
        false,
    );
    el?.addEventListener(
        "touchend",
        function (e) {
            const deltaX = swipe_det.eX - swipe_det.sX;
            const deltaY = swipe_det.eY - swipe_det.sY;
            isScroll = false

            // Min swipe distance, you could use absolute value rather
            // than square. It just felt better for personnal use
            if (deltaX ** 2 + deltaY ** 2 < deltaMin ** 2) return;
            // horizontal
            if (deltaY === 0 || Math.abs(deltaX / deltaY) > 1)
                direction = deltaX > 0 ? DIRECTIONS.RIGHT : DIRECTIONS.LEFT;
            // vertical
            else direction = deltaY > 0 ? DIRECTIONS.UP : DIRECTIONS.DOWN;

            if (direction && typeof func === "function") func(el, direction, data, isScroll);

            direction = null;
        },
        false,
    );
};

export const unmountSwipeEvents = (className) => {
    const el = document.getElementsByClassName(className)[0];
    el.removeEventListener("touchstart", (d) => {
    });
    el.removeEventListener("touchmove", (d) => {
    });
    el.removeEventListener("touchend", (d) => {
    });
};

export const convertTimeString = (duration) => {
    let time = duration && duration.split(':'); // split it at the colons

    // minutes are worth 60 seconds. Hours are worth 60 minutes.
    let seconds = +time[0] * 60 * 60 + +time[1] * 60 + +time[2];
    if (seconds === 0) {
        return "";
    }

    const hours = Math.floor(seconds / 3600);
    const minute = Math.floor((seconds % 3600) / 60);

    let returnString = "";
    if (hours > 0) {
        returnString += hours.toString() + "h ";
    }
    if (minute > 0) returnString += minute.toString() + "m ";

    return returnString;
};

export const settingsRedirection = (dimensions, history) => {
    let redirectionUrl =
        dimensions.width >= TAB_BREAKPOINT
            ? `/${URL.SETTING}/profile`
            : `/${URL.SETTING}`;
    safeNavigation(history, redirectionUrl);
};

export const handleRedirectionOnClick = async (item, history, openPopup, closePopup, openLoginPopup, dimensions) => {
    const currentPath = getKey(LOCALSTORAGE.CURRENT_PATH);
    const state = store.getState();
    let checkSubscriptionStatus = checkCurrentSubscription(get(state.subscriptionDetails, "currentSubscription.data"))

    if ((item.linkToRedirect === URL.SUBSCRIPTION || (item.linkToRedirect === URL.SUBSCRIPTION && isUserloggedIn())) && checkSubscriptionStatus && !isAtvFsUser()) {
        await store.dispatch(checkFallbackFlow())
        let isManagedApp = get(state.headerDetails, "isManagedApp")
        let enableTickTickJourney = get(state.headerDetails, "configResponse.data.config.enableTickTickJourney");
        !isManagedApp ? safeNavigation(history, {
            pathname: `/${item.linkToRedirect}`,
            state: {
                subscriptionRenderCallback: currentPath.includes(item.linkToRedirect),
                source: item?.pageName
            },
        }) : (
            (enableTickTickJourney ?
                await loginInFreemium({
                    openPopup,
                    closePopup,
                    openLoginPopup,
                    ComponentName: MINI_SUBSCRIPTION.SELECTION_DRAWER,
                    source: item.mobileView ? MIXPANEL.VALUE.HAMBURGER_SUBSCRIBE_CTA : MIXPANEL.VALUE.HOME_SUBSCRIBE_CTA,
                    fireBaseSource: item.mobileView ? FIREBASE.VALUE.HAMBURGER_SUBSCRIBE : FIREBASE.VALUE.HOME_SUBSCRIBE,
                })
                : redirectToMangeApp(item.mobileView ? FIREBASE.VALUE.HAMBURGER_SUBSCRIBE : FIREBASE.VALUE.HOME_SUBSCRIBE)
            ))
    } else if (isUserloggedIn()) {
        if (item.linkToRedirect) {
            if (item?.firstLabel === 'Settings') {
                settingsRedirection(dimensions, history);
            } else {
                if (item.linkToRedirect === URL.SUBSCRIPTION) {
                    safeNavigation(history, {
                        pathname: `/${item.linkToRedirect}`,
                        state: {
                            subscriptionRenderCallback: currentPath.includes(item.linkToRedirect),
                            source: item?.pageName
                        },
                    })
                } else if (item?.linkToRedirect === URL.APPLE_INTERSTITIAL) {
                    appleRedirection("", "", MIXPANEL.VALUE.HAMBURGER_MENU, true)
                }
                else {
                    safeNavigation(history, item.linkToRedirect == URL.HOME ? URL.DEFAULT : `/${item.linkToRedirect}`);
                }

            }
        } else if (!item.linkToRedirect) {
            if (item.funcClick) {
                item.funcClick();
            } else if (item.redirectionUrl) {
                window.open(item.redirectionUrl, '_blank');
            }
        }
    } else {
        if (item.linkToRedirect) {
            if (item.accessBeforeLogin) {
                safeNavigation(history, item.linkToRedirect == URL.HOME ? URL.DEFAULT : `/${item.linkToRedirect}`);
            } else {
                await loginInFreemium({
                    openPopup,
                    closePopup,
                    openLoginPopup,
                    source: item?.mixpanelLoginSource || MIXPANEL.VALUE.HOME,
                    ComponentName: MINI_SUBSCRIPTION.LOGIN,
                    fromLogin: true,
                    fireBaseSource: FIREBASE.VALUE.HOME,
                });
            }
        } else if (!item.linkToRedirect) {
            if (item.redirectionUrl) {
                window.open(item.redirectionUrl, '_blank');
            } else {
                await loginInFreemium({
                    openPopup,
                    closePopup,
                    openLoginPopup,
                    source: item?.mixpanelLoginSource || MIXPANEL.VALUE.HOME,
                    ComponentName: MINI_SUBSCRIPTION.LOGIN,
                    fireBaseSource: FIREBASE.VALUE.HOME,
                });
            }
        }
    }
};

export const showToast = (message) => {
    toast(message, {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        className: "hide-close login-toast-wrapper",
    });
};

export const showlogoutToast = (response) => {
    toast(
        <div className="login-body-container">
            <span className="icon-logout" />
            <div className="login-success-text">
                <div>{response}</div>
            </div>
        </div>
        ,
        {
            position: "bottom-center",
            autoClose: 2000,
            closeOnClick: true,
            className: "login-toast-wrapper",
        },
    );
};

export const accountRefresh = async () => {
    let userInfo = JSON.parse(getKey(LOCALSTORAGE.USER_INFO)) || {};
    let state = store.getState();
    let configResponse = get(state.headerDetails, "configResponse")
    if (userInfo.dthStatus === DTH_TYPE.NON_DTH_USER || userInfo.dthStatus === DTH_TYPE.DTH_W_BINGE_NEW_USER) {
        await store.dispatch(getProfileDetails(true));
        const accountRefreshCode = await store.dispatch(refreshAccount());
        let userData = get(state.profileDetails, "userProfileDetails", {});
        let accountRefreshText = get(configResponse, "data.config.accountRefresh")

        if (accountRefreshCode?.code === 0 && !isEmpty(userData)) {
            showToast(accountRefreshText || MESSAGE.ACCOUNT_REFRESH_SUCCESSFUL);
        }
    }
    if (userInfo.dthStatus === DTH_TYPE.DTH_W_BINGE_OLD_USER) {
        await store.dispatch(refreshAccountOldStack());
        await store.dispatch(getBalanceInfo(true));
        state = store.getState();
        let getBalanceCode = get(state.packSelectionDetail, "balanceInfo.code");
        let accountRefreshOldStackCode = get(state.headerDetails, "accountRefreshOldStack.code");
        if (accountRefreshOldStackCode === 0 && getBalanceCode === 0) {
            showToast(MESSAGE.ACCOUNT_REFRESH_SUCCESSFUL);
        }
    }
};

export const confirmLogoutPopup = ({ history }) => {
    let state = store.getState();
    let configResponse = get(state.headerDetails, "configResponse")
    store.dispatch(
        openPopup(MODALS.ALERT_MODAL, {
            modalClass: "logout-modal-container",
            headingMessage: get(configResponse, "data.config.logout.header") || MESSAGE.DEVICE_LOGOUT_HEADING,
            hideCloseIcon: true,
            instructions: get(configResponse, "data.config.logout.subHeader") || MESSAGE.LOGOUT_INSTRUCTION,
            primaryButtonText: get(configResponse, "data.config.logout.logout") || MESSAGE.LOGOUT_PRIMARY_BTN,
            imageUrl: MESSAGE.LOGOUT_ICON_URL,
            isCloseModal: false,
            secondaryButtonText: get(configResponse, "data.config.notNow") || MESSAGE.LOGOUT_SECONDARY_BTN,
            primaryButtonAction: async () => {
                await callLogOut(true, history);
                store.dispatch(closePopup());
            },
            secondaryButtonAction: () => {
                store.dispatch(closePopup());
            },
        }),
    );
};

export const showWatchlistToast = (response) => {
    toast(
        <div className="toast-wrapperr">
            <img src={successTickIcon} alt="" />
            <p>{`${response.data.count} items removed from Binge List`}</p>
        </div>,
        {
            position: "bottom-center",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            className: "login-toast-wrapper",
        },
    );
};

export let isNonSubscribedPartnerList = (provider) => {
    let { nonSubscribedPartnerList } = getPackInfo();
    const nonSubscribedPartnerLists = nonSubscribedPartnerList?.filter((val) => val.partnerName.toLowerCase() === provider.toLowerCase());
    return nonSubscribedPartnerLists?.length > 0;
};

export const showCrown = (item) => {
    let state = store.getState();
    let subscriptionDetails = get(state, "subscriptionDetails.currentSubscription.data");
    const isPartnerNotSubscribed = isEmpty(subscriptionDetails) ? true : !checkPartnerSubscribed(subscriptionDetails, item?.partnerId, item?.provider);
    const premiumSubscription = !item?.partnerSubscriptionType || (item?.partnerSubscriptionType?.toLowerCase() === PARTNER_SUBSCRIPTION_TYPE.PREMIUM.toLowerCase());
    // const isNotAppleSubscribed = (subscriptionDetails?.hasOwnProperty('appleRedemptionStatus') && subscriptionDetails?.appleRedemptionStatus?.toLowerCase() !== APPLE_REDEMPTION_STATUS.CONSUMED);
    const isHBSeeAll = [HERO_BANNER_TYPE.HB_SEE_ALL].includes(item?.heroBannerType);

    if ([HERO_BANNER_TYPE.HB_SEE_ALL_BINGE_CHANNEL].includes(item?.heroBannerType)) {
        return false;
    }
    else if (isHBSeeAll) {
        let activePlan = !checkCurrentSubscription(subscriptionDetails) && subscriptionDetails?.subscriptionStatus?.toUpperCase() === SUBSCRIPTION_STATUS.ACTIVE;
        return !activePlan;
    } else {
        return isPartnerNotSubscribed && ![PROVIDER_NAME.TATASKY, PROVIDER_NAME.GAMEZOP].includes(item.provider.toLowerCase()) &&
            (premiumSubscription);
    }
}

export const isFreeContentMixpanel = (item) => {
    const premiumSubscription = item?.partnerSubscriptionType?.toLowerCase() === PARTNER_SUBSCRIPTION_TYPE.PREMIUM.toLowerCase();
    return premiumSubscription ? MIXPANEL.VALUE.NO : MIXPANEL.VALUE.YES;
}

export const generateUuidV4String = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
};

export const getUserSelectedLanguages = async () => {
    let userInfo = JSON.parse(getKey(LOCALSTORAGE.USER_INFO)) || {};
    const { baId, rmn: mobileNumber, sId: bingeSubscriberId, profileId } = userInfo;
    const header = { profileId }
    const body = { baId, bingeSubscriberId, mobileNumber };

    await store.dispatch(fetchUserSelectedData(header, body));

    const state = store.getState();
    const selectedLanguages = get(state, 'languages.selectedLanguage.data');
    const filter = selectedLanguages?.profileList?.filter(filter => (
        filter.profileId === header.profileId
    ));
    return filter ? filter[0]?.preferredLanguages : null;
}

export const setSavedLanguages = async () => {
    if (isUserloggedIn()) {
        let savedLanguages = await getUserSelectedLanguages();
        setKey(LOCALSTORAGE.PREFERRED_LANGUAGES, savedLanguages)
    }
}

export const categoryDropdownHeader = (location) => {
    let { pathname } = location;
    const urlArr = pathname?.split("/");
    return urlArr[1] === BOTTOM_SHEET.CATEGORIES.toLowerCase() &&
        window.innerWidth <= TAB_BREAKPOINT;
};

export const deletePaymentKeysFromLocal = () => {
    deleteKey(LOCALSTORAGE.TRANSACTION_ID);
    deleteKey(LOCALSTORAGE.PAYMENT_STATUS_VERBIAGE);
    deleteKey(LOCALSTORAGE.PAYMENT_ERROR_STATUS_VERBIAGE);
    deleteKey(LOCALSTORAGE.SUBSCRIPTION_CHANGE_TYPE);
    // deleteKey(LOCALSTORAGE.PI_DETAIL_URL);
}

export const handlePaymentSDKPrefetch = () => {
    let state = store.getState();
    let configResponse = get(state.headerDetails, "configResponse")
    let paymentPayloadInfo = get(configResponse, 'data.config.paymentGatewayInfo');
    let uuid = generateUuidV4String();
    const preFetchPayload = {
        requestId: uuid,
        service: paymentPayloadInfo?.paymentServiceId,
        payload: {
            clientId: paymentPayloadInfo?.paymentClientId,
        },
    };
    console.log('preFetchPayload', preFetchPayload);
    window?.HyperServices.preFetch(preFetchPayload);
}


export const getTimeline = item => {
    let state = store.getState();
    let episodeDetailsData = get(state.seasonReducer, "episodeDetails.data", []);
    let episodeData = episodeDetailsData.find(episode => episode?.contentId === item?.id);
    if (episodeData) {
        let { durationInSeconds, secondsWatched } = episodeData;
        durationInSeconds = durationInSeconds ? parseInt(durationInSeconds) : 0;
        secondsWatched = secondsWatched ? parseInt(secondsWatched) : 0;
        return secondsWatched === 0 ? 0 : (secondsWatched / durationInSeconds) * 100;
    } else {
        return 0;
    }
}

/**
 * @function getTARecommendationHeader - Get header for All TA Recommendattion APIS
 */
export const getTARecommendationHeader = () => {
    const state = store.getState();
    let bingeProduct = get(state.subscriptionDetails, 'currentSubscription.data') === null
        ? 'FREE'
        : get(state.subscriptionDetails, 'currentSubscription.data.bingeProduct');
    let userInfo = JSON.parse(getKey(LOCALSTORAGE.USER_INFO)) || {};
    let header;
    if (isUserloggedIn()) {
        let baId = get(userInfo, 'dthStatus') === DTH_TYPE.NON_DTH_USER ? get(userInfo, 'baId') : null;
        header = {
            dthStatus: get(userInfo, 'dthStatus'),
            subscriberId: get(userInfo, 'sId'),
            profileId: get(userInfo, 'profileId'),
            baId,
            bingeProduct,
            platform: "binge_anywhere_web",
            authorization: get(userInfo, 'accessToken'),
            ...getTickTickHeaders()
        }
    } else {
        header = {
            dthStatus: DTH_TYPE.GUEST,
            profileId: getKey(LOCALSTORAGE.ANONYMOUS_ID),
            anonymousId: getKey(LOCALSTORAGE.ANONYMOUS_ID),
            platform: "binge_anywhere_web",
        }
    }
    return header;
}

export const getTickTickHeaders = () => {
    const state = store.getState();
    let currentSubscription = get(state.subscriptionDetails, 'currentSubscription.data')
    if (!isEmpty(currentSubscription) && currentSubscription !== null && currentSubscription?.flexiPlan) {
        let componentList = getComponentList(currentSubscription);
        let partnerList = componentList?.partnerList;
        let filterList = partnerList ? partnerList.map(data => data.partnerName) : [];
        return {
            ticktick: true,
            partners: filterList?.join(',')
        }
    }
    return { ticktick: false }
}


/**
 * @function getTARecommendationBaseURL - get Base URL for All TA Recommendattion APIS
 */
export const getTARecommendationBaseURL = () => {
    let TA_ROUTE = isUserloggedIn() ? ENV_CONFIG.TA_RECOMMENDATION : ENV_CONFIG.TA_GUEST_RECOMMENDATION;
    // return 'https://tatasky-uat-tsmore-kong.videoready.tv/'
    // return 'https://uat-tb.tapi.videoready.tv/'
    return `${ENV_CONFIG.API_BASE_URL}${TA_ROUTE}`;
}

/**
 * @function sortBrowseOrderByTARecommendation - Get sorted filters with Respect to TA Recommended filters
 */
export const sortBrowseOrderByTARecommendation = (array, myorder, key) => {
    let order = myorder.reduce((r, k, i) => {
        return r[k.toLowerCase()] = i + 1, r
    }, {});
    return array.sort((a, b) => (order[a[key]?.toLowerCase()] || Infinity) - (order[b[key]?.toLowerCase()] || Infinity))
}

/**
 * @function getBrowseByData - Get Browse by lang & Genre filters in a order suggested by TA
 * @param response : Array containing genre or language rails
 */
export const getBrowseByData = async (response) => {
    let languageIndex = response?.data?.items.findIndex(element => element.sectionSource === SECTION_SOURCE.LANGUAGE);
    let genreIndex = response?.data?.items.findIndex(element => element.sectionSource === SECTION_SOURCE.GENRE);
    response = await getBrowseByTAOrderedData(languageIndex, response, BROWSE_TYPE.LANGUAGE);
    response = await getBrowseByTAOrderedData(genreIndex, response, BROWSE_TYPE.GENRE);
    return response;
};

/**
 *
 * @param index
 * @param response
 * @param browseBy
 * @returns {Promise<*>}
 */
export const getBrowseByTAOrderedData = async (index, response, browseBy) => {
    if (index < 0) {
        return response;
    }
    // API CALL FOR TA FILTERS
    let payload = {
        browseByType: browseBy,
        layout: LAYOUT_TYPE.LANDSCAPE,
    }
    await fetchTARecommendedFilterOrder(payload);
    let state = store.getState();
    let isGenreFilter = browseBy?.toLowerCase() === BROWSE_TYPE.GENRE.toLowerCase();
    let taRecommendedFilterOrder = isGenreFilter ? get(state, 'browseBy.taRecommendedGenreOrder') : get(state, 'browseBy.taRecommendedLangOrder');
    let arrayToSort = [...get(response.data.items[index], 'contentList')];
    let expectedOrder = [...new Set(get(taRecommendedFilterOrder, 'contentList'))];
    let sortedFilterArr = sortBrowseOrderByTARecommendation(arrayToSort, expectedOrder, 'title');
    response.data.items[index].contentList = [...sortedFilterArr];
    return response;
};

/**
 * @function getSearchDataForLangOrGenre - Get BrowseBy data from TA API
 * @param langFilters : language filter
 * @param genreFilter : genre filter
 *
 */
const getSearchDataForLangOrGenre = (langFilters, genreFilter) => {
    let state = store.getState();
    let { taRecommendedSearchData, taRecommendedSearchGenre, taRecommendedSearchLang } = get(state, 'browseBy');
    let onlyBrowseByGenreData = !isEmpty(genreFilter) && isEmpty(langFilters);
    let onlyBrowseByLangData = isEmpty(genreFilter) && !isEmpty(langFilters);
    let taRecommededData = onlyBrowseByGenreData
        ? get(taRecommendedSearchGenre, 'contentList')
        : onlyBrowseByLangData
            ? get(taRecommendedSearchLang, 'contentList')
            : get(taRecommendedSearchData, 'contentList');
    return taRecommededData || [];
}

/**
 * @param searchResponseArr
 * @param filters
 * @returns {Promise<*[]>}
 */
export const appendTASearchContent = async (searchResponseArr, filters) => {
    let { filterGenre, filterLanguage, pageNumber, freeToggle } = filters;
    let langFilters = filterLanguage && filterLanguage[0], genreFilter = filterGenre && filterGenre[0];
    let browseByData = [];

    if (isEmpty(langFilters) && isEmpty(genreFilter)) {
        return searchResponseArr
    }
    //Fetch TA recommended data for filters
    let payload = {
        langFilters: langFilters,
        genreFilter: genreFilter,
        freeToggle,
        layout: LAYOUT_TYPE.LANDSCAPE,
        max: TA_MAX_CONTENT.TA_MAX,
    }
    pageNumber === 1 && await fetchTARecommendedSearchData(payload);

    let taRecommededData = langFilters || genreFilter ? getSearchDataForLangOrGenre(langFilters, genreFilter) : [];

    if (!isEmpty(taRecommededData) && pageNumber === 1) {
        browseByData = [...taRecommededData]
    }

    for (let i = 0; i < searchResponseArr.length; i++) {
        let item = searchResponseArr[i]
        let itemInBrowseList = taRecommededData.find(data => data.id === item.id)
        if (!itemInBrowseList) {
            item.sectionSource = SECTION_SOURCE.EDITORIAL
            browseByData.push(item);
        }
    }
    return browseByData;
}

export const getClientIPDetails = async () => {
    await store.dispatch(getClientIP(true));
}

/**
 * @function getContinueWatchingHeader - Get header for All Continue watching APIS
 */
export const getContinueWatchingHeader = () => {
    let userInfo = JSON.parse(getKey(LOCALSTORAGE.USER_INFO)) || {};
    let accessToken = userInfo.accessToken;
    let header;

    if (isUserloggedIn()) {
        header = {
            locale: "IND",
            deviceId: getDeviceId(),
            deviceType: "WEB",
            platform: HEADER_CONSTANTS.BINGE_ANYWHERE,
            ...getCommonHeaders(`bearer ${accessToken}`)
        };
    } else {
        header = {
            'g-auth-token': getKey(LOCALSTORAGE.G_AUTH_TOKEN),
            'anonymousId': getKey(LOCALSTORAGE.ANONYMOUS_ID),
        };
    }
    return header;
};

export const classNameToApply = (classname, viewType, view) => {
    if (view === viewType) {
        return classname
    }
}

export const trackAppsflyerOnRechargeRedirection = () => {
    appsFlyerConfig.trackEvent(APPSFLYER.PARAMETER.RECHARGE)
}

export const loadScript = (url, scriptId, onLoad = () => {
}) => {
    const loadedScript = document.getElementById(scriptId);
    if (loadedScript) {
        onLoad();
        return;
    }
    const scriptTag = document.createElement('script');
    scriptTag.src = url;
    scriptTag.id = scriptId;
    scriptTag.onload = onLoad;
    document.body.appendChild(scriptTag);
}
export const loadLinktag = (url, linkId, onLoad = () => {
}) => {
    const loadedLink = document.getElementById(linkId);
    if (loadedLink) {
        onLoad();
        return;
    }
    const linkTag = document.createElement('link');
    linkTag.href = url;
    linkTag.id = linkId;
    linkTag.rel = 'stylesheet';
    linkTag.type = 'text/css';
    linkTag.onload = onLoad;
    document.head.appendChild(linkTag);
}
export const playContentEventTrack = (props) => {
    const {
        taShowType,
        vodTitle,
        provider,
        duration,
        partnerSubscriptionType,
    } = props;
    appsFlyerConfig.trackEvent(
        APPSFLYER_CONTENT_PLAY_EVENTS[partnerSubscriptionType],
        {
            [APPSFLYER.PARAMETER.CONTENT_TITLE]: vodTitle,
            [APPSFLYER.PARAMETER.CONTENT_TYPE]: taShowType,
            [APPSFLYER.PARAMETER.DURATION_SECONDS]: duration,
            [APPSFLYER.PARAMETER.DURATION_MINUTES]: (
                Number(duration) / 60
            ).toFixed(2),
            [APPSFLYER.PARAMETER.PARTNER_NAME]: provider,
        }
    );
};

export const isFreeContentEvent = (contractName, subscribedEntitleMents, partnerId, subscriptionStatus) => {
    if (contractName) {
        switch (contractName) {
            case CONTRACT.RENTAL:
                return true;
            case CONTRACT.CLEAR:
            case CONTRACT.FREE:
            case CONTRACT.SUBSCRIPTION: {
                if (subscriptionStatus === ACCOUNT_STATUS.ACTIVE && !(subscribedEntitleMents.length < 0)) {
                    if (subscribedEntitleMents.some((val) => val.partnerId === partnerId)) {
                        return true
                    }
                }
            }
                break;
            default:
                return true
        }
    }
    return false
}

export const _base64ToArrayBuffer = (base64) => {
    const binary_string = window.atob(base64);
    console.log(binary_string)
    const len = binary_string.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
}

export const getPackModificationType = (selectedPlan, currentSubscription = {}, analytics = APPSFLYER) => {
    let userInfo = JSON.parse(getKey(LOCALSTORAGE.USER_INFO)) || {};
    let dummyUserCondition = ((userInfo?.dummyUser || !userInfo?.freeTrialAvailed) && userInfo?.bingeAccountStatus === ACCOUNT_STATUS.ACTIVE);
    let modType = '';
    let isRenew = ([ACCOUNT_STATUS.DEACTIVATED, ACCOUNT_STATUS.DEACTIVE, ACCOUNT_STATUS.WRITTEN_OFF].includes(currentSubscription?.status)
        && currentSubscription?.cancelled && currentSubscription?.expiredWithinSixtyDays) || dummyUserCondition || (parseFloat(currentSubscription?.amountValue) === parseFloat(selectedPlan?.amountValue))

    if (parseFloat(currentSubscription?.amountValue) > parseFloat(selectedPlan?.amountValue)) {
        modType = analytics.VALUE.DOWNGRADE;
    } else if (parseFloat(currentSubscription?.amountValue) < parseFloat(selectedPlan?.amountValue)) {
        modType = analytics.VALUE.UPGRADE;
    } else if (isRenew) {
        modType = analytics.VALUE.RENEW;
    } else {
        modType = MIXPANEL.VALUE.FRESH
    }
    return modType;

}

export const getModType = (value = "", analytics = APPSFLYER) => {
    if (value === SUBSCRIPTION_MODTYPE.RENEWAL) {
        return analytics.VALUE.RENEW
    }
    return value
}

export const getMixpanelSubscriptionAnalyticsData = (currentSubscription, source, analytics = MIXPANEL, isRetry = false) => {
    let state = store.getState(),
        isManagedApp = get(state.headerDetails, "isManagedApp");
    const isFirstSubscription = checkIsFirstSubscription();
    const subscriptionJourneySource = source || "";
    const subscriptionSource = getKey(LOCALSTORAGE.SUBCRIPTION_SOURCE) || "";
    const currentSelection = JSON.parse(getKey(LOCALSTORAGE.SUBSCRIPTION_SELECTED_PACK));
    const modType = getModType(currentSelection?.modificationType);
    let analyticSource = (subscriptionSource && isManagedApp) ? subscriptionSource : getAnalyticsSource(subscriptionJourneySource, analytics)
    let purchaseType = { [analytics.PARAMETER.PURCHASE_TYPE]: analytics.VALUE.FIRST_TIME },
        modTypeParam = { [analytics.PARAMETER.MOD_TYPE]: modType },
        channel = { [analytics.PARAMETER.CHANNEL]: currentSelection?.channel },
        orderId = { [analytics.PARAMETER.ORDER_ID]: getKey(LOCALSTORAGE.TRANSACTION_ID) },
        firstSubscriptionMixpanel = { [analytics.PARAMETER.FIRST_SUBSCRIPTION]: isFirstSubscription ? MIXPANEL.VALUE.YES : MIXPANEL.VALUE.NO },
        isCouponPage = !isEmpty(JSON.parse(getKey(LOCALSTORAGE.COUPON_DETAILS))),
        couponData = getmixpanelCouponDetails();

    return {
        [analytics.PARAMETER.PACK_NAME]: currentSelection?.productName,
        [analytics.PARAMETER.PACK_PRICE]: currentSelection?.selectedTenurePackPriceMobile,
        [analytics.PARAMETER.SOURCE]: analyticSource,
        [analytics.PARAMETER.PACK_TYPE]: currentSelection?.subscriptionPackType,
        [analytics.PARAMETER.EXISTING_PACK_NAME]: currentSubscription?.productName || MIXPANEL.VALUE.FREEMIUM,
        [analytics.PARAMETER.EXISTING_PACK_PRICE]: currentSubscription?.amountValue || MIXPANEL.VALUE.DEFAULT_AMOUNT_VALUE,
        [analytics.PARAMETER.EXISTING_PACK_TYPE]: currentSubscription?.subscriptionPackType || MIXPANEL.VALUE.FREEMIUM,
        [analytics.PARAMETER.EXISTING_PACK_TENURE]: getCurrentSubscriptionTenureType(currentSubscription),
        [analytics.PARAMETER.PACK_TENURE]: currentSelection?.selectedTenureTypeMobile,
        [analytics.PARAMETER.PACK_ID]: currentSelection?.productId,
        [analytics.PARAMETER.OFFER_ELIGIBILITY]: currentSelection?.offerEligibility || '',
        [analytics.PARAMETER.OFFER_CARD_SHOWN]: currentSelection?.offerShowPlan || '',
        [analytics.PARAMETER.SELECTED_PACK_TYPE]: currentSelection?.selectedPackType || '',
        [analytics.PARAMETER.APPS_SELECTED]: currentSelection?.selectedPartnerList || '',
        ...(!isRetry && firstSubscriptionMixpanel),
        ...(isFirstSubscription && !isRetry && purchaseType),
        ...(!isEmpty(modType) && !isRetry && modTypeParam),
        ...(!isEmpty(currentSelection?.channel) && channel),
        ...(!isEmpty(getKey(LOCALSTORAGE.TRANSACTION_ID)) && orderId),
        ...(isCouponPage && couponData),
    }
}

export const getSiMandatory = () => {
    let paymentPayload = JSON.parse(getKey(LOCALSTORAGE.PAYMENT_PAYLOAD));

    if (isEmpty(paymentPayload)) {
        let state = store.getState(),
            currentSubscription = get(state.subscriptionDetails, 'currentSubscription.data'),
            existingUser = get(state.loginReducer, 'existingUser'),
            ssoLoginJourney = JSON.parse(getKey(LOCALSTORAGE.SSO_LOGIN_JOURNEY)) === true,
            addNewPackRes = get(state.subscriptionDetails, 'addNewPackRes'),
            modifyPackRes = get(state.subscriptionDetails, 'modifyPackRes');

        let subscriptionResponse = checkCurrentSubscription(currentSubscription, true) ? get(addNewPackRes, 'data') : get(modifyPackRes, 'data');
        let response = ssoLoginJourney ? get(existingUser, 'data') : subscriptionResponse;
        paymentPayload = get(response, 'paymentPayload');
    }

    let orderDetails = !isEmpty(paymentPayload) ? JSON.parse(get(paymentPayload, 'payload.orderDetails')) : {};
    let createMandate = get(orderDetails, 'options.create_mandate');
    return createMandate?.toUpperCase() === "REQUIRED";
};

export const getPaymentTypeValue = () => {
    const state = store.getState();

    let opelResponse = get(state?.subscriptionPaymentReducer, 'opelResponse.data'),
        currentSubscription = get(state, "subscriptionDetails.currentSubscription.data"),
        currentSelection = JSON.parse(getKey(LOCALSTORAGE.SUBSCRIPTION_SELECTED_PACK)),
        userInfo = JSON.parse(getKey(LOCALSTORAGE.USER_INFO)) || {},
        isNonFreemiumDthUser = !isSubscriptionFreemium() && userInfo.dthStatus !== DTH_TYPE.NON_DTH_USER;

    let paymentMode = get(opelResponse, 'paymentMode') ?
        get(opelResponse, 'paymentMode') :
        (get(currentSubscription, 'paymentMode') ?
            get(currentSubscription, 'paymentMode') :
            get(currentSelection, 'paymentMode'));

    let paymentTypeVal = getPaymentType(paymentMode);

    return isNonFreemiumDthUser ? MIXPANEL.VALUE.RECURRING : paymentTypeVal;
};

export const trackMixpanelSubsciptionInitiate = (currentSubscription, source) => {
    const mixpanelData = getMixpanelSubscriptionAnalyticsData(currentSubscription, source, MIXPANEL);
    mixPanelConfig.trackEvent(MIXPANEL.EVENT.SUBSCRIPTION_INITIATE, mixpanelData);
}

export const trackMixpanelSubscriptionFailed = (instructions, paymentMethod = MIXPANEL.VALUE.PG) => {
    const previousSubscription = JSON.parse(getKey(LOCALSTORAGE.PREVIOUS_SUBSCRIPTION_DETAILS)),
        userInfo = JSON.parse(getKey(LOCALSTORAGE.USER_INFO)),
        siMandatoryRequired = getSiMandatory() ? { [MIXPANEL.PARAMETER.EXPERIMENT_TG]: MIXPANEL.VALUE.SI_MANDATORY } : {},
        analyticsData = getMixpanelSubscriptionAnalyticsData(previousSubscription, ''),
        isTSWallet = paymentMethod === PAYMENT_METHOD.TS_WALLET,
        currentSelection = JSON.parse(getKey(LOCALSTORAGE.SUBSCRIPTION_SELECTED_PACK)),
        state = store.getState(),
        currentSubscription = get(state, "subscriptionDetails.currentSubscription.data"),
        dataLayerAnalyticsData = getGoogleSubscriptionAnalyticsData(currentSelection, '', paymentMethod, currentSubscription);

    mixPanelConfig.trackEvent(MIXPANEL.EVENT.SUBSCRIBE_FAILED, {
        ...analyticsData,
        [MIXPANEL.PARAMETER.PAYMENT_TYPE]: getPaymentTypeValue(),
        [MIXPANEL.PARAMETER.PAYMENT_METHOD]: isTSWallet ? MIXPANEL.VALUE.TP_WALLET : MIXPANEL.VALUE.PG,
        [MIXPANEL.PARAMETER.REASON]: instructions,
        [MIXPANEL.PARAMETER.QR_SOURCE]: userInfo?.qrSource,
        [MIXPANEL.PARAMETER.PAYMENT_MODE]: isTSWallet ? MIXPANEL.VALUE.TP_WALLET : MIXPANEL.VALUE.PG,
        //[MIXPANEL.PARAMETER.PAYMENT_INSTRUMENT]: walletName , commented for TPB-721
        ...siMandatoryRequired,
    });
    dataLayerConfig.trackEvent(DATALAYER.EVENT.SUBSCRIPTION_FAILED, {
        ...dataLayerAnalyticsData,
        [DATALAYER.PARAMETER.REASON]: instructions,
    });
    deleteKey(LOCALSTORAGE.PAYMENT_PAYLOAD);
}


export const trackMixpanelSubscriptionSuccess = (currentSubscription, paymentMethod = MIXPANEL.VALUE.PG, walletName) => {
    const previousSubscription = JSON.parse(getKey(LOCALSTORAGE.PREVIOUS_SUBSCRIPTION_DETAILS));
    const userInfo = JSON.parse(getKey(LOCALSTORAGE.USER_INFO));
    const analyticsData = getMixpanelSubscriptionAnalyticsData(previousSubscription, '', MIXPANEL);
    const isSubscribeFromDiscount = getSubscriptionJourneySource() === MIXPANEL.VALUE.DISCOUNTING_PAGE ? { [MIXPANEL.PARAMETER.PAGE_NAME]: MIXPANEL.VALUE.DISCOUNTING_PAGE, [MIXPANEL.PARAMETER.SOURCE]: MIXPANEL.VALUE.DISCOUNTING_PAGE } : {};
    const siMandatoryRequired = getSiMandatory() ? { [MIXPANEL.PARAMETER.EXPERIMENT_TG]: MIXPANEL.VALUE.SI_MANDATORY } : {},
        isTSWallet = paymentMethod === PAYMENT_METHOD.TS_WALLET;

    mixPanelConfig.trackEvent(MIXPANEL.EVENT.SUBSCRIBE_SUCCESS, {
        ...analyticsData,
        [MIXPANEL.PARAMETER.PAYMENT_TYPE]: getPaymentTypeValue(),
        [MIXPANEL.PARAMETER.PAYMENT_METHOD]: isTSWallet ? MIXPANEL.VALUE.TP_WALLET : MIXPANEL.VALUE.PG,
        [MIXPANEL.PARAMETER.QR_SOURCE]: userInfo?.qrSource,
        [MIXPANEL.PARAMETER.PAYMENT_MODE]: isTSWallet ? MIXPANEL.VALUE.TP_WALLET : MIXPANEL.VALUE.PG,
        //[MIXPANEL.PARAMETER.PAYMENT_INSTRUMENT]: walletName, // opel response || currentSubscription key name paymentMethod commented for TPB-721
        ...isSubscribeFromDiscount,
        ...siMandatoryRequired,
    });
    deleteKey(LOCALSTORAGE.PAYMENT_PAYLOAD);
}

export const trackMixpanelOnPaymentInitiate = (currentSubscription) => {
    const previousSubscription = JSON.parse(getKey(LOCALSTORAGE.PREVIOUS_SUBSCRIPTION_DETAILS));
    let analyticsData = getMixpanelSubscriptionAnalyticsData(previousSubscription, '', MIXPANEL);
    const siMandatoryRequired = getSiMandatory() ? { [MIXPANEL.PARAMETER.EXPERIMENT_TG]: MIXPANEL.VALUE.SI_MANDATORY } : {};
    mixPanelConfig.trackEvent(MIXPANEL.EVENT.PAYMENT_INITIATE, {
        ...analyticsData,
        ...siMandatoryRequired,
    });
}

export const trackModifyPackSuccess = (currentSubscription) => {
    const previousSubscription = JSON.parse(getKey(LOCALSTORAGE.PREVIOUS_SUBSCRIPTION_DETAILS));
    const { productName, amountValue, productId, packDuration, promoCode, paymentMethod } = currentSubscription;
    const selectedPack = JSON.parse(getKey(LOCALSTORAGE.SUBSCRIPTION_SELECTED_PACK));
    const source = getSubscriptionJourneySource() || "";
    let data = {
        [APPSFLYER.PARAMETER.SOURCE]: getAnalyticsSource(source, MIXPANEL),
        [APPSFLYER.PARAMETER.PACK_NAME]: productName || "",
        [APPSFLYER.PARAMETER.PACK_PRICE]: selectedPack?.amountValue || "",
        [APPSFLYER.PARAMETER.PACK_ID]: productId,
        [APPSFLYER.PARAMETER.AF_REVENUE]: amountValue || "",
        [APPSFLYER.PARAMETER.AF_CURRENCY]: APPSFLYER.VALUE.INR,
        [APPSFLYER.PARAMETER.PACK_DURATION]: packDuration,
        [APPSFLYER.PARAMETER.PROMO_CODE]: promoCode,
        [APPSFLYER.PARAMETER.PAYMENT_MODE]: paymentMethod,
    }
    let fireBaseData = {
        [FIREBASE.PARAMETER.SOURCE]: getAnalyticsSource(source, MIXPANEL),
        [FIREBASE.PARAMETER.PACK_NAME]: productName || "",
        [FIREBASE.PARAMETER.PACK_PRICE]: amountValue || "",
        [FIREBASE.PARAMETER.PACK_ID]: productId,
        [FIREBASE.PARAMETER.PACK_DURATION]: packDuration,
        [FIREBASE.PARAMETER.PROMO_CODE]: promoCode,
        [FIREBASE.PARAMETER.PAYMENT_MODE]: paymentMethod,
        [FIREBASE.PARAMETER.OLD_PACK_ID]: previousSubscription?.productId,
        [FIREBASE.PARAMETER.PACK_TYPE]: FIREBASE.VALUE.PAID // tik tik related
    }
    if (previousSubscription?.subscriptionStatus === "ACTIVE") {
        appsFlyerConfig.trackEvent(APPSFLYER.EVENT.SUBSCRIBE_SUCCESS_MODIFY_PACK,
            {
                ...data,
                [APPSFLYER.PARAMETER.OLD_PACK_ID]: previousSubscription?.productId,
            })
        trackEvent.subscriptionSuccessModifyPack(fireBaseData)
    } else {
        appsFlyerConfig.trackEvent(APPSFLYER.EVENT.SUBSCRIBE_SUCCESS_REPEAT, data)
        trackEvent.subscriptionSuccessRepeat(fireBaseData)
    }
}


export const getPaymentType = (type) => {
    if (type?.toLowerCase().includes(MIXPANEL.VALUE.RECURRING.toLowerCase())) {
        return MIXPANEL.VALUE.RECURRING;
    } else {
        return MIXPANEL.VALUE.ONE_TIME;
    }
}

export const getSearchParamsAsObject = (url) => {
    const params = getSearchParams(url);
    if (!params) {
        return null;
    }
    return Object.fromEntries(params);
}

export const hasSearchParamKey = (key, url) => {
    return !!getSearchParam(key, url);
}

export const getSearchParams = (url) => {
    const urlString = url || window.location.href;
    const queryParamString = urlString.split('?')?.[1];
    if (!queryParamString) {
        return null;
    }
    const searchParams = new URLSearchParams(queryParamString);
    return searchParams;
}

export const getSearchParam = (key, url = "") => {
    const searchParams = getSearchParams(url);
    return searchParams?.get(key) || ""
}

export const hasSearchParam = (key) => {
    return getSearchParams()?.has(key)
}

export const setMixpanelId = (mixpanelId) => {
    setKey(LOCALSTORAGE.MIXPANEL_DISTINCT_ID, mixpanelId);
}

export const getMixpanelId = () => {
    return getKey(LOCALSTORAGE.MIXPANEL_DISTINCT_ID);
}

export const getSubscriptionJourneySource = () => {
    return getKey(LOCALSTORAGE.SUBSCRIPTION_JOURNEY_SOURCE)
}

export const setSubscriptionJourneySource = (source) => {
    if (source.includes('subscription-transaction')) {
        source = URL.HOME
    }
    setKey(LOCALSTORAGE.SUBSCRIPTION_JOURNEY_SOURCE, source);
}

export const getPackSelectionListingType = () => {
    if (location.pathname.includes(URL.SUBSCRIPTION)) {
        return APPSFLYER.VALUE.LANDING_PAGE;
    } else {
        if (isMobile.any()) {
            return APPSFLYER.VALUE.DRAWER;
        }
        return APPSFLYER.VALUE.POP_UP;
    }
}
export const isSafariBrowser = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /safari/.test(userAgent);
}

export const isAndroidWebview = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return userAgent.includes('wv'); //webview
}

export const isInAppBrowser = () => {
    if (!isMobile.any()) {
        return false;
    }
    const standalone = window.navigator.standalone;
    if (isMobile.iOS()) {
        if (!standalone && !isSafariBrowser()) {
            return true;
        }
    } else if (isAndroidWebview()) {
        return true;
    }
    return false;
}

export const isHelpCenterWebView = () => {
    return window.location.pathname.includes(URL.HELP_CENTER) && isInAppBrowser();
}

export const trackPackSelectionInitiate = (source) => {
    let mixpanel = {
        [`${MIXPANEL.PARAMETER.SOURCE}`]: source,
        // [`${MIXPANEL.PARAMETER.PAYMENT_METHOD}`] :  updatedTenure?.offeredPriceValue,
    }

    appsFlyerConfig.trackEvent(APPSFLYER.EVENT.PACK_SELECTION_INITIATE, {
        [APPSFLYER.PARAMETER.SOURCE]: getAnalyticsSource(getSubscriptionJourneySource(), MIXPANEL),
        [APPSFLYER.PARAMETER.USER_LOGIN_STATE]: isUserloggedIn() ? APPSFLYER.VALUE.LOGGED_IN : APPSFLYER.VALUE.NON_LOGGED_IN,
        [APPSFLYER.PARAMETER.LISTING_TYPE]: getPackSelectionListingType(),
    });
    const parameter = {
        [FIREBASE.PARAMETER.SOURCE]: getAnalyticsSource(getSubscriptionJourneySource(), MIXPANEL),
        [FIREBASE.PARAMETER.USER_LOGIN_STATE]: isUserloggedIn() ? FIREBASE.VALUE.LOGGED_IN : FIREBASE.VALUE.NON_LOGGED_IN,
        [FIREBASE.PARAMETER.LISTING_TYPE]: getPackSelectionListingType(),
    }
    trackEvent.packSelectionInitiate(parameter)
}

export const getContentLanguage = (language = [], shouldMerge = true) => {
    if (Array.isArray(language)) {
        let languages = language.filter(data => data.label !== "None")
            .map(data => data.label ? data.label : data);

        return shouldMerge ? languages.join(",") : languages;
    }

    return language;
}

export const getPrimaryLanguage = (language = []) => {
    let languages = getContentLanguage(language, false);
    return languages[0];
}

export const handleOverflowOnHtml = (isremove = false) => {
    let htmlElement = document.getElementsByTagName('html')[0];
    let bodyElement = document.getElementsByTagName('body')[0];
    if (isremove) {
        htmlElement.classList.remove("hide-overflow");
        bodyElement.classList.remove("hide-overflow");
    } else {
        htmlElement.classList.add("hide-overflow");
        bodyElement.classList.add("hide-overflow");
    }
};

/**
 * @function getPlatform - to get platform value 
 * - In case of mSales and mService if web is opened in their in-app browser pass WEB as platform always
 * @returns string value based on weather application is opened in in-app browser iOS or android
 */
export const getPlatform = () => {

    let sourceIsMSales = getKey(LOCALSTORAGE.PAYMENT_SOURCE_KEY) === WEB_SMALL_PAYMENT_SOURCE.NON_BINGE,
        sourceISMService = getKey(LOCALSTORAGE.SUBSCRIPTION_EXTERNAL_SOURCE) === SUBSCRIPTION_EXTERNAL_SOURCES.M_SERVICE;

    if (isInAppBrowser() && !sourceIsMSales && !sourceISMService) {
        const standalone = window.navigator.standalone;
        if (isMobile.iOS()) {
            if (!standalone && !isSafariBrowser()) {
                return MIXPANEL.VALUE.IOS;
            }
        } else if (isAndroidWebview()) {
            return MIXPANEL.VALUE.ANDROID;
        }
        else {
            return MIXPANEL.VALUE.WEB;
        }
    }
    else {
        return MIXPANEL.VALUE.WEB;
    }
};

export const getIdAnalyticsData = () => {
    let userInfo = JSON.parse(getKey(LOCALSTORAGE.USER_INFO)) || {};
    const persistentSuperProperties = JSON.parse(getKey(LOCALSTORAGE.MIXPANEL_SUPER_PROPERTIES));

    let preparedProperties = {};

    if (userInfo?.dthStatus === DTH_TYPE.DTH_W_BINGE_NEW_USER) {
        preparedProperties = {
            [MIXPANEL.PARAMETER.TS_SID]: userInfo?.sId || persistentSuperProperties?.[MIXPANEL.PARAMETER.TS_SID]
        };

        if (userInfo?.bingeSubscriberId !== userInfo?.sId) {
            // check added as in case user with DTH stack comes first time on freemium application sId comes 
            // in field of bingeSubscriberId and causing mixpanel data issues, it will be set with pubnub push when bingeSubscriberId is created 
            preparedProperties = {
                ...preparedProperties, ...{
                    [MIXPANEL.PARAMETER.C_ID]: userInfo?.bingeSubscriberId || persistentSuperProperties?.[MIXPANEL.PARAMETER.C_ID]
                }
            }
        }
    }

    else if (userInfo?.dthStatus === DTH_TYPE.DTH_W_BINGE_OLD_USER) {
        const delinkedUserData = { [MIXPANEL.PARAMETER.C_ID]: userInfo?.delinkedUserSaID };
        preparedProperties = {
            [MIXPANEL.PARAMETER.TS_SID]: userInfo?.sId || persistentSuperProperties?.[MIXPANEL.PARAMETER.TS_SID],
            ...((userInfo?.isDelinkedUser && !isEmpty(userInfo?.delinkedUserSaID)) && delinkedUserData),
        };
    }
    // in case of new user login when rmn comes in bingeSubscriberId no need to set this data, after pubnub push data will be set
    else if (userInfo?.bingeSubscriberId !== userInfo?.rmn) {
        preparedProperties = {
            [MIXPANEL.PARAMETER.C_ID]: userInfo?.bingeSubscriberId || persistentSuperProperties?.[MIXPANEL.PARAMETER.C_ID]
        };
    }

    return preparedProperties;
};

export const mixpanelGetSuperProperties = () => {
    let userInfo = JSON.parse(getKey(LOCALSTORAGE.USER_INFO)) || {};
    const persistentSuperProperties = JSON.parse(getKey(LOCALSTORAGE.MIXPANEL_SUPER_PROPERTIES));
    let superProperties = {
        [MIXPANEL.PARAMETER.PLATFORM]: getPlatform(),
        [MIXPANEL.PARAMETER.DEVICE_ID]: getDeviceId(),
        [MIXPANEL.PARAMETER.USER_TYPE]: persistentSuperProperties?.[MIXPANEL.PARAMETER.USER_TYPE] || MIXPANEL.VALUE.GUEST
    };
    let preparedProperties;
    // let appleStatus = getAppleStatus(); // not in use 

    if (isUserloggedIn()) {
        superProperties = loggedInUserSuperProperties(superProperties);
    };

    if (isUserloggedIn() || !isEmpty(persistentSuperProperties)) {
        preparedProperties = getIdAnalyticsData();

        if (isUserloggedIn()) {
            superProperties[MIXPANEL.PARAMETER.USER_TYPE] = userInfo?.dthStatus === DTH_TYPE.NON_DTH_USER ? MIXPANEL.VALUE.NON_TP : MIXPANEL.VALUE.TP;
        }
        superProperties[MIXPANEL.PARAMETER.RMN] = userInfo?.rmn || persistentSuperProperties?.[MIXPANEL.PARAMETER.RMN];
    };

    // superProperties[MIXPANEL.PARAMETER.APPLE_STATUS] = appleStatus;

    superProperties = { ...superProperties, ...preparedProperties };

    return superProperties;
};

// export const getAppleStatus = () => {
//     const state = store.getState();
//     let value = MIXPANEL.VALUE.NA,
//         currentSubscription = get(state, "subscriptionDetails.currentSubscription.data");
//     if (isUserloggedIn()) {
//         let { couponCodeActivated, userAppleActivated } = getBingeAppleState(),
//             { activePlan } = getUserPackInfo({ currentSubscription, meta: {} }),
//             { appleNotClaimed, appleCodeGenerated, appleRedeemed } = userAppleStatus(userAppleActivated, couponCodeActivated);

//         if (activePlan) {
//             if (appleNotClaimed) {
//                 value = MIXPANEL.VALUE.NOT_INITIATED;
//             } else if (appleCodeGenerated) {
//                 value = MIXPANEL.VALUE.CODE_GENERATED;
//             } else if (appleRedeemed) {
//                 value = MIXPANEL.VALUE.CODE_REDEEMDED;
//             }
//         }
//     }

//     return value;
// }

export const loggedInUserSuperProperties = (superProperties) => {
    const state = store.getState();
    let userInfo = JSON.parse(getKey(LOCALSTORAGE.USER_INFO)) || {};
    let currentSubscription = get(state, "subscriptionDetails.currentSubscription.data");
    let isDelinkedUser = (userInfo?.isDelinkedUser && !isEmpty(userInfo?.delinkedUserSaID));
    isEmpty(currentSubscription) && store.dispatch(getCurrentSubscriptionInfo(false));

    let packCreationDate = { [MIXPANEL.PARAMETER.PACK_START_DATE]: moment(currentSubscription?.packCreationDate).format('llll') };
    let expiryDateWithTime = { [MIXPANEL.PARAMETER.PACK_END_DATE]: moment(currentSubscription?.expiryDateWithTime).format('llll') };
    let subscriptionSuperProp = {
        [MIXPANEL.PARAMETER.STACK]: (userInfo?.dthStatus === DTH_TYPE.DTH_W_BINGE_OLD_USER && !isDelinkedUser) ? MIXPANEL.VALUE.SIEBEL : MIXPANEL.VALUE.COMVIVA,
        [MIXPANEL.PARAMETER.SUBSCRIBED]: currentSubscription?.subscriptionStatus === ACCOUNT_STATUS.ACTIVE ? MIXPANEL.VALUE.YES : MIXPANEL.VALUE.NO,
        [MIXPANEL.PARAMETER.PACK_TYPE]: get(currentSubscription, 'subscriptionPackType', MIXPANEL.VALUE.FREEMIUM),
        [MIXPANEL.PARAMETER.PACK_NAME]: get(currentSubscription, 'productName', MIXPANEL.VALUE.FREEMIUM),
        [MIXPANEL.PARAMETER.PACK_TENURE]: getCurrentSubscriptionTenureType(currentSubscription),
        [MIXPANEL.PARAMETER.PACK_PRICE]: get(currentSubscription, 'amountValue', MIXPANEL.VALUE.DEFAULT_AMOUNT_VALUE),
        [MIXPANEL.PARAMETER.PACK_ID]: get(currentSubscription, 'productId', MIXPANEL.VALUE.FREEMIUM),
        [MIXPANEL.PARAMETER.USER_IDENTITY]: userInfo?.mixpanelId,
        ...(!isEmpty(currentSubscription?.packCreationDate) && packCreationDate),
        ...(!isEmpty(currentSubscription?.expiryDateWithTime) && expiryDateWithTime),
    };

    superProperties = { ...superProperties, ...subscriptionSuperProp, };

    return superProperties;

};

export const handleLogoutAllDevices = (errorTitle, errorMessage, buttonVerbiage = "Ok") => {
    store.dispatch(
        openPopup(MODALS.ALERT_MODAL, {
            modalClass: "alert-modal",
            headingMessage: errorTitle,
            hideCloseIcon: true,
            instructions: errorMessage,
            primaryButtonText: buttonVerbiage,
            isCloseModal: false,
            primaryButtonAction: async () => {
                await callLogOut(true, history, true);
                store.dispatch(closePopup());
            },
        }),
    );
}

export const handlePackValidationFailed = (errorMessage) => {
    store.dispatch(
        openPopup(MODALS.ALERT_MODAL, {
            modalClass: "alert-modal",
            headingMessage: "",
            hideCloseIcon: true,
            instructions: errorMessage,
            primaryButtonText: "Ok",
            isCloseModal: false,
        }),
    )
}

export const delay = (time = 1000) => new Promise(resolve => {
    setTimeout(() => resolve(true), time)
})

export const noop = () => { };

export const returnUrlTickTick = (isCampaign) => {
    const state = store.getState();
    let isLiveRailId = get(state.PIDetails, 'liveRailId')
    let searchParams = new URLSearchParams(window.location.search)
    let isMsales = searchParams.get("nonBinge");
    searchParams.set("tickTick", true);
    isMsales && searchParams.set("mSales", true);
    isLiveRailId && searchParams.set("liveRailId", isLiveRailId);
    searchParams.delete('action'); // Deleting it for Deeplink back handling else user will be stuck in a loop
    searchParams.delete('status');
    searchParams.delete('cartId');
    searchParams.delete('nonBinge');
    !!isCampaign && searchParams.delete('packId');
    let newRelativePathQuery = (!!isCampaign ? '/' : window.location.pathname) + '?' + searchParams.toString();
    window.history.pushState(null, '', newRelativePathQuery);
    return window.location.href
}

export const getPIDetailPageMetaData = (meta, contentType) => {
    //passed parent content type so that when we fetch title we get title of the parent always
    let contentTitle = getTitleAndDesc(meta, meta?.parentContentType),
        free = meta?.partnerSubscriptionType?.toUpperCase() !== PARTNER_SUBSCRIPTION_TYPE.PREMIUM ? 'Free' : '', metaTitle, metaDescription;

    if ([CONTENTTYPE.TYPE_MOVIES, CONTENTTYPE.TYPE_MOVIE, CONTENTTYPE.TYPE_CUSTOM_MOVIES_DETAIL].includes(contentType)) {
        metaTitle = `Watch ${free} ${contentTitle} on Tata Play Binge`;
        metaDescription = `Watch ${free} ${contentTitle} on Tata Play Binge`
    }
    else if ([CONTENTTYPE.SERIES, CONTENTTYPE.TYPE_BRAND, CONTENTTYPE.TYPE_TV_SHOWS, CONTENTTYPE.TYPE_SERIES, CONTENTTYPE.TYPE_CUSTOM_SERIES_DETAIL, CONTENTTYPE.TYPE_CUSTOM_TV_SHOWS_DETAIL, CONTENTTYPE.TYPE_CUSTOM_BRAND_DETAIL, CONTENTTYPE.TYPE_CUSTOM_SERIES_DETAIL].includes(contentType)) {
        metaTitle = `Watch ${free} ${contentTitle} and other episodes on Tata Play Binge`;
        metaDescription = `Watch ${free} ${contentTitle} and other episodes on Tata Play Binge`
    }
    else {
        metaTitle = `Watch ${contentTitle} on Tata Play Binge`;
        metaDescription = `Watch ${contentTitle} on Tata Play Binge`;
    }
    return { metaTitle, metaDescription };
}


export const getAllGenricProvider = () => {
    const { headerDetails } = store.getState();
    let configResponse = get(headerDetails, "configResponse.data.config");
    let genericProvider = [];
    let filterData = configResponse?.availableProviders.filter((el) => el.platform.includes(HEADER_CONSTANTS.WEB));
    (filterData || []).map((el) => genericProvider = { ...genericProvider, [el.providerName.toLowerCase()]: el });
    setKey(LOCALSTORAGE.GENERIC_PROVIDERS, JSON.stringify(genericProvider));
    return genericProvider;
}

export const getGenericAuthType = (providerLowerCase) => {
    const genericData = JSON.parse(getKey(LOCALSTORAGE.GENERIC_PROVIDERS)) ?? getAllGenricProvider();
    const genericProviders = genericData && Object.keys(genericData) || [];
    const getGenericProvider = genericProviders.includes(providerLowerCase) ? providerLowerCase : "";
    let genericAuthType = genericData ? genericData[getGenericProvider]?.authType?.WEB?.playAuthType : '';


    return { genericAuthType, genericProviders };
};

export const getTrailerUrl = (detail, playDetail) => {
    let trailerPlaybackUrl, isPartnerTrailer = false,
        provider = get(detail, 'provider'), providerLowerCase = provider?.toLowerCase();

    if ([PROVIDER_NAME.EPICON, PROVIDER_NAME.DOCUBAY, PROVIDER_NAME.HOICHOI, PROVIDER_NAME.PLANET_MARATHI, PROVIDER_NAME.CHAUPAL, PROVIDER_NAME.CURIOSITY_STREAM, PROVIDER_NAME.LIONS_GATE, PROVIDER_NAME.SONYLIV].includes(providerLowerCase)) {
        trailerPlaybackUrl = playDetail?.trailerUrl;
    } else if (!isEmpty(detail?.partnerTrailerInfo)) {
        trailerPlaybackUrl = detail?.partnerTrailerInfo;
        isPartnerTrailer = true;
    } else {
        trailerPlaybackUrl = playDetail?.dashWidewineTrailerUrl;
    }
    return { trailerPlaybackUrl, isPartnerTrailer };
};

export const checkTrailer = (detail, playDetail) => {
    let trailerData = getTrailerUrl(detail, playDetail);
    let trailerUrl = trailerData.trailerPlaybackUrl;
    return !isEmpty(trailerUrl);
};

export const getAppsFlyerComvivaId = (eventName) => {
    const userInfo = JSON.parse(getKey(LOCALSTORAGE.USER_INFO));
    if (eventName === APPSFLYER.EVENT.SIGN_UP) {
        return userInfo?.rmn;
    }
    if (userInfo?.dthStatus === DTH_TYPE.DTH_W_BINGE_OLD_USER) {
        return (userInfo?.isDelinkedUser && !isEmpty(userInfo?.delinkedUserSaID)) ? userInfo?.delinkedUserSaID : userInfo?.sid;
    }
    return userInfo?.bingeSubscriberId;
}

export const isHomePage = (pathname) => {
    const urlArr = pathname.split("/");
    return [URL.DEFAULT].includes(urlArr[1]) || isEmpty(urlArr[1]);
};

export const isWebSmallPaymentLink = (location) => {
    let { webSmallRouteParam } = getWebSmallRouteValues();
    let searchPath = get(location, 'search');
    return webSmallRouteParam && searchPath?.includes(webSmallRouteParam);
};

export const getSidValue = () => {
    const userInfo = JSON.parse(getKey(LOCALSTORAGE.USER_INFO));
    return isUserloggedIn() ? (userInfo?.dthStatus === DTH_TYPE.DTH_W_BINGE_OLD_USER) ? { [DATALAYER.PARAMETER.TS_SID]: userInfo.sid } : { [DATALAYER.PARAMETER.C_ID]: userInfo?.bingeSubscriberId } : { [DATALAYER.PARAMETER.TS_SID]: getKey(LOCALSTORAGE.MIXPANEL_DISTINCT_ID) }
}

export const fireFooterClickEvent = (name) => {
    dataLayerConfig.trackEvent(DATALAYER.EVENT.FOOTER_CLICKS, {
        [DATALAYER.PARAMETER.FOOTER_BTN_NAME]: name,
    })
}

export const isSettingPage = () => {
    const currentPath = getKey(LOCALSTORAGE.CURRENT_PATH);
    return currentPath?.includes(URL.SETTING);
}

export const getCurrentSubscriptionPackTenure = (currentSubscription, tenures) => {
    let currentTenure,
        isSubscriptionDeactive = currentSubscription?.subscriptionStatus === SUBSCRIPTION_STATUS.DEACTIVE || currentSubscription?.subscriptionStatus === SUBSCRIPTION_STATUS.WRITTEN_OFF;
    isSubscriptionDeactive ?
        currentTenure = tenures?.find((t) => t.lastActiveTenure) :
        currentTenure = tenures?.find((t) => t.currentTenure);
    return currentTenure;
};

export const getCurrentSubscriptionTenureType = (currentSubscription) => {
    const currentTenure = !isEmpty(currentSubscription) && getCurrentSubscriptionPackTenure(currentSubscription, currentSubscription?.tenure);
    return currentTenure ? get(currentTenure, 'tenureType') : get(currentSubscription, "currentTenure", MIXPANEL.VALUE.FREEMIUM);
}

export const getUserType = () => {
    const userInfo = JSON.parse(getKey(LOCALSTORAGE.USER_INFO));
    return (!isEmpty(userInfo) ? userInfo?.dthStatus === DTH_TYPE.NON_DTH_USER ? MIXPANEL.VALUE.NON_TP : MIXPANEL.VALUE.TP : MIXPANEL.VALUE.GUEST)
}

export const getUserInfo = () => JSON.parse(getKey(LOCALSTORAGE.USER_INFO)) || {};

export const getLanguageGenreAPISource = (placeHolder) => {
    let source = getKey(LOCALSTORAGE.LANG_GENRE_PAGE_SOURCE);
    if (source === URL.MOVIES) {
        return `${placeHolder}_MOVIES`;
    } else if (source === URL.TV_Shows) {
        return `${placeHolder}_TVSHOWS`;
    } else {
        return placeHolder;
    }
}

export const getCookie = (name) => document.cookie?.split(';')
    ?.find(row => row.trim()?.startsWith(`${name}=`))
    ?.split('=')[1];

export const getWebSmallRouteValues = () => {
    let searchParams = getSearchParamsAsObject();
    let webSmallQueryParams = [WEB_SMALL_PAYMENT_SOURCE.DETAILS, WEB_SMALL_PAYMENT_SOURCE.INFO, WEB_SMALL_PAYMENT_SOURCE.NON_BINGE, WEB_SMALL_PAYMENT_SOURCE.TRANSACTION_ID, WEB_SMALL_PAYMENT_SOURCE.ID];
    let key = webSmallQueryParams.find(key => searchParams?.hasOwnProperty(key));
    return { webSmallPaymentRouteKey: key, webSmallRouteToken: searchParams?.[key] };
}

export const isWebSmallLinkPayment = (location) => {
    let { webSmallPaymentRouteKey } = getWebSmallRouteValues();
    let searchPath = get(location, 'search');
    return webSmallPaymentRouteKey && searchPath?.includes(webSmallPaymentRouteKey);
};

export const isMSalesPrevInfoExist = () => {
    let { webSmallPaymentRouteKey, webSmallRouteToken } = getWebSmallRouteValues();
    let sourceIsMSales = webSmallPaymentRouteKey === WEB_SMALL_PAYMENT_SOURCE.NON_BINGE,
        previousToken = getKey(LOCALSTORAGE.PAYMENT_SOURCE_TOKEN);
    return isUserloggedIn() && sourceIsMSales && webSmallRouteToken && (isEmpty(previousToken) || (previousToken && webSmallRouteToken !== previousToken));
}

export const isPaymentRedirectURL = (location) => {
    let { pathname } = location;
    const urlArr = pathname.split("/");
    return [URL.SUBSCRIPTION_TRANSACTION_REDIRECT, URL.SUBSCRIPTION_TRANSACTION].includes(urlArr[1]);
}

export const getVerbiages = (categoryName) => {
    const { headerDetails } = store.getState();
    let verbiages = get(headerDetails, "configResponse.data.config.verbiages", '');
    return verbiages?.length > 0 && verbiages?.filter(item => item.categoryName === categoryName)?.[0]?.data;
}

export const handleSilentLogout = () => {
    deleteKey(LOCALSTORAGE.IS_SILENT_LOGOUT);
    store.dispatch(clearStore());
}


export const getFormattedURLValue = (string) => {
    string = string?.trim()?.replace(REGEX.SPACES, " "); // Replaces extra spaces to one space
    string = string?.replace(REGEX.PUNCTUATUIONS, '-'); //Replace special Char with hyphen
    string = string?.replace(REGEX.SPACES, '-'); //Replace spcae with hyphen
    string = string?.replace(/[^A-Z0-9]+/ig, "-"); // Replace multiple hypens to one
    string = string?.replace(/^-|-$/, '');// Replace first and last char if it is hyphen
    return string?.toLowerCase();
}

export const getFormattedContentTitle = (string, urlLength = 0) => {
    let originalString;
    string = string?.toLowerCase();
    string = getFormattedURLValue(string);
    string = string?.split('-');
    originalString = string && string?.length > 0 ? JSON.parse(JSON.stringify(string)) : string;
    string = urlLength > 50 ? string.filter(item => !USELESS_WORDS.includes(item)) : string;
    string = !isEmpty(string) ? string : originalString;
    string = string?.join('-');
    return string;
}

export const handlePiRedirection = (history) => {
    const urlArr = history?.location?.pathname.split("/");
    if ([URL.DETAIL].includes(urlArr[1])) {
        setKey(LOCALSTORAGE.PI_DETAIL_URL, `${window.location.pathname}${window.location.search}`);
        setKey(LOCALSTORAGE.IS_SUBSCRIPTION_FROM_PI, true)
    } else if (![URL.DETAIL].includes(urlArr[1]) && getKey(LOCALSTORAGE.IS_SUBSCRIPTION_FROM_PI)) {
        deleteKey(LOCALSTORAGE.PI_DETAIL_URL)
        deleteKey(LOCALSTORAGE.IS_SUBSCRIPTION_FROM_PI)
    }
};

export const isSubscriptionDiscount = (history) => {
    const urlArr = history?.location?.pathname.split("/");
    return urlArr && urlArr[1] && [URL.SUBSCRIPTION_DISCOUNT].includes(urlArr[1]);
}

export const getEpisodeVerbiage = (isPIPage) => {
    const { headerDetails } = store.getState();
    let verbiages = get(headerDetails, "configResponse.data.config", '');
    let updatedVerbiages = isPIPage ? verbiages?.freeEpisodesAvailable : verbiages?.firstEpisodeFree
    return updatedVerbiages;
}

export const redirectToMangeApp = async (source, isCampaign, zeroAppParams) => {
    const isChooseApps = zeroAppParams?.isChooseApps, appSelection = zeroAppParams?.appSelection;
    const requestHeader = { initiateSubscription: JOURNEY_SOURCE.DRAWER_CYOP, journeySource: JOURNEY_SOURCE.DRAWER_CYOP, journeySourceRefId: "", analyticSource: source, zeroAppParams: zeroAppParams };
    isChooseApps && (requestHeader.journeySource = JOURNEY_SOURCE.DRAWER_ZEROAPPSELECTION, requestHeader.initiateSubscription = JOURNEY_SOURCE.DRAWER_ZEROAPPSELECTION);
    appSelection && (requestHeader.journeySource = JOURNEY_SOURCE.PLAN_APPSELECTION, requestHeader.initiateSubscription = JOURNEY_SOURCE.PLAN_APPSELECTION);
    await store.dispatch(getWebPortalLink(requestHeader, isCampaign));
};

export const isIspEnabledPlan = () => {
    let state = store.getState(),
        userInfo = JSON.parse(getKey(LOCALSTORAGE.USER_INFO));
    return get(state, "subscriptionDetails.currentSubscription.data.ispEnabled") || userInfo?.ispEnabled;
}

export const showFibreWelcomePopup = async (history) => {
    const state = store.getState();
    const userInfo = JSON.parse(getKey(LOCALSTORAGE.USER_INFO));
    let currentSubscription = get(state, "subscriptionDetails.currentSubscription.data");

    if (get(currentSubscription, 'appSelectionRequired')){
        const response = await store.dispatch(getCurrentSubscriptionMyplanVerbiages());
        let appSelectionPopupVerbiages = response?.data?.appSelectionPopupVerbiages;
        !isEmpty(appSelectionPopupVerbiages) && store.dispatch(
            openPopup(MODALS.CUSTOM_MODAL, {
                modalClass: "alert-modal flexi-plan-container ",
                childComponent: (
                    <FlexiPlanModal AnalyticSource={isMobile.any() ? FIREBASE.VALUE.HAMBURGER_SUBSCRIBE : FIREBASE.VALUE.HOME_SUBSCRIBE}
                        source={MIXPANEL.VALUE.LOGIN}
                    />
                ),
                closeModal: true,
                hideCloseIcon: false,
                onCloseAction: () => {
                    mixPanelConfig.trackEvent(MIXPANEL.EVENT.ZERO_APPS_NUDGE_CLOSE, {
                        [`${MIXPANEL.PARAMETER.SOURCE}`]: MIXPANEL.VALUE.LOGIN
                    })
                }
            })
        )
        store.dispatch(isZeroAppLogin(false))
    }else if (isIspEnabledPlan() && get(currentSubscription, 'showFibreMsg') &&
        !get(userInfo, 'subscriptionStatusInfo.nonSubscribedToSamePack') &&
        !get(userInfo, 'subscriptionStatusInfo.allowPG') && !get(currentSubscription, 'appSelectionRequired')) {
        store.dispatch(
            openPopup(MODALS.FIBRE_MODAL, {
                modalClass: "alert-modal fiber",
                hideCloseIcon: true,
                stopClosingOnUrlChange: true,
            }))
    }else {
        showFireStickPopup(history);
    }
}

export const showFireStickPopup = (history) => {
    const state = store.getState();
    let currentSubscription = get(state, "subscriptionDetails.currentSubscription.data");
    let packPrice = currentSubscription?.amountValue;
    let enableFsPopup = get(state.headerDetails, "configResponse.data.config.enableFsPopup");

    if (enableFsPopup && fireStickPopUpCheck(currentSubscription)) {
        mixPanelConfig.trackEvent(MIXPANEL.EVENT.FTV_UPSELL_VIEW, {
            [MIXPANEL.PARAMETER.PACK_PRICE]: packPrice
        });
        store.dispatch(openPopup(MODALS.ALERT_MODAL, {
            modalClass: "alert-modal fsEdition ",
            headingMessage: "<img src='../../assets/images/Firetv.png' alt=''/>",
            instructions: 'Get Amazon Fire TV Stick – Tata Play Edition at no extra cost',
            isHtml: true,
            hideCloseIcon: true,
            primaryButtonText: "Avail Now",
            primaryButtonAction: () => {
                fsDismissFrequency();
                safeNavigation(history, `/${URL.FIRE_TV_INSTALLATION}`);
            },
            secondaryButtonText: "Remind Me Later",
            secondaryButtonAction: () => {
                store.dispatch(closePopup());
                ftvWOEvents(false);
                fsDismissFrequency();
            },
            closeModal: true,
            stopClosingOnUrlChange: true,
        }));
    }
}

export const fsDismissFrequency = () => {
    let fsDismissFrequency = JSON.parse(getKey(LOCALSTORAGE.FS_DISMISS_FREQUENCY)) || 0;
    setKey(LOCALSTORAGE.FS_DISMISS_FREQUENCY, ++fsDismissFrequency);
}

export const fireStickPopUpCheck = (currentSubscription) => {
    let currentCheck = currentSubscription && currentSubscription?.fsEligibility &&
        !currentSubscription?.fsTaken && !currentSubscription?.fSRequestRaised &&
        !currentSubscription?.downgradeRequested;
    let isSubscriptionActive = currentSubscription?.subscriptionStatus === SUBSCRIPTION_STATUS.ACTIVE;
    return (currentCheck && isSubscriptionActive);
}

export const setLALogic = async (id) => {
    let laFlag = false;
    let laData = JSON.parse(getKey(LOCALSTORAGE.LA_FIRED_DATE)) || [];
    let data = laData && laData.find && laData.find(i => i.contentId === id);

    if (data) {
        let contentWatchedTime = new Date(data.time);
        let today = new Date();
        if (compareDate(contentWatchedTime, today)) {
            laFlag = true;
            data.time = new Date();
            let index = laData.findIndex(i => i.contentId === id);
            laData[index] = data;
        }
    } else {
        laData.push({
            contentId: id,
            time: new Date(),
        });
        laFlag = true;
    }
    setKey(LOCALSTORAGE.LA_FIRED_DATE, laData);
    return laFlag;
}

export const appleRedirection = (props, viaPopup, source, isFromInterstitial = false ) => {
    let mixpanelValue = {
        viaPopup: viaPopup,
        source: source,
        interstitialPage: source === MIXPANEL.VALUE.DEEPLINK ? MIXPANEL.VALUE.YES : MIXPANEL.VALUE.NO
    }
    if (isFromInterstitial) {
        setKey(LOCALSTORAGE.IS_APPLE_REDIRECTION, true)
    } else {
        let data = getCommonAnalyticsAttributes(MIXPANEL.EVENT.APPLE_ACTIVATE_NOW_CLICK, props, props, true);
        data.mixpanelData[`${MIXPANEL.PARAMETER.VIA_POPUP}`] = viaPopup;
        mixPanelConfig.trackEvent(MIXPANEL.EVENT.APPLE_ACTIVATE_NOW_CLICK, data?.mixpanelData);
        store.dispatch(closePopup());
    }
    store.dispatch(getAppleDeepLinkRedemptionUrl(mixpanelValue)).then(async (response) => {
        if(response?.code != 0){
            mixPanelConfig.trackEvent(MIXPANEL.EVENT.ERROR_POPUP,{
                [MIXPANEL.PARAMETER.ERROR_MESSAGE]: get(response, 'message') ?? "",
                [MIXPANEL.PARAMETER.ERROR_CODE]: get(response, 'code') ?? "",
                [MIXPANEL.PARAMETER.PLATFORM]: MIXPANEL.VALUE.WEB
            })
            store.dispatch(openPopup(MODALS.ALERT_MODAL, {
                modalClass: 'alert-modal error-state-modal',
                headingMessage: `${get(response, 'message' , "Something Went Wrong")}`,
                primaryButtonText: `${get(response?.data, 'ctaButton' , "Done")}`,
                errorIcon: 'icon-alert-upd',
                closeModal: true,
                hideCloseIcon: true,
                // errorCodeInstruction: `Error code : ${get(response, 'code')}`,
            }));
        } else if(!isEmpty(response?.data?.activation_url)){
            var a = document.createElement('a');
            a.href = response?.data?.activation_url;
            a.target = '_blank';
            a.click();
        }
    })
}

export const handleApplePIBtnCta = (props, learnData, useCWData, cwData) => {
    const { subscriptionDetails } = store.getState();
    let currentSubscription = get(subscriptionDetails, "currentSubscription.data"),
        reactivateExpiredVerbiage = get(subscriptionDetails, "myPlanVerbiages.appleSubscriptionVerbiage.reactivateExpiredVerbiage"),
        reactivateVerbiage = get(subscriptionDetails, "myPlanVerbiages.appleSubscriptionVerbiage.reactivateVerbiage"),
        pendingToastMessage = get(subscriptionDetails, "myPlanVerbiages.appleSubscriptionVerbiage.pendingToastMessage"),
        appleLogo = get(subscriptionDetails, "myPlanVerbiages.appleSubscriptionVerbiage.appleLogo"),
        {appleStatus, journey} = appleStatusJouney();
    const { meta, redirectionHandling, lastWatch } = props;

    if (journey === APPLE_REDEMPTION_STATUS.REACTIVATION_MIGRATION) {
        handleAppleReActivation(currentSubscription, MIXPANEL.VALUE.MIGRATION, reactivateExpiredVerbiage, props, appleLogo)
    } else if (journey === APPLE_REDEMPTION_STATUS.REACTIVATION_RENEW) {
        handleAppleReActivation(currentSubscription, MIXPANEL.VALUE.REACTIVATION, reactivateVerbiage, props, appleLogo)
    } else if (appleStatus === APPLE_REDEMPTION_STATUS.ENTITLED) {
        appleRedirection(props, "", MIXPANEL.VALUE.PI_PAGE)
    } else if (appleStatus === APPLE_REDEMPTION_STATUS.ACTIVATED) {
        //directly redirect to apple
        let deeplink = meta?.isEpisodeContent ? meta?.partnerDeepLinkUrl : lastWatch?.partnerDeepLinkUrl ? lastWatch?.partnerDeepLinkUrl : meta?.partnerDeepLinkUrl;
        redirectionHandling(deeplink, true, learnData, useCWData, cwData);
        mixpanelPlayContent(props, true, true);
    } else {
        showAmazonActivityToast(pendingToastMessage, true)
    }
};


export const trackApplePlayCTAnalytics = (props) => {
    let dataMixpanel = getCommonAnalyticsAttributes(MIXPANEL.EVENT.APPLE_PLAY_CTA_CLICK, props, props);
    delete dataMixpanel?.mixpanelData?.[MIXPANEL.PARAMETER.AUTO_PLAYED];
    delete dataMixpanel?.mixpanelData?.[MIXPANEL.PARAMETER.LIVE_CONTENT];

    mixPanelConfig.trackEvent(MIXPANEL.EVENT.APPLE_PLAY_CTA_CLICK, dataMixpanel?.mixpanelData);
}

// export const applePlayPopup = (props, learnData, useCWData, cwData) => {
//     const {
//         meta,
//         redirectionHandling,
//     } = props;
//     let applePlayPopup = getVerbiages(CATEGORY_NAME.APPLE_PLAY_POPUP_NEW),
//         imageUrl = applePlayPopup?.others?.appleverbiagelogo ? `${cloudinaryCarousalUrl("", "", 0, 0, false, true)}${applePlayPopup?.others?.appleverbiagelogo}` : appleLogo;
//     store.dispatch(
//         openPopup(MODALS.ALERT_MODAL, {
//             modalClass: `alert-modal apple-modal`,
//             headingMessage: applePlayPopup?.header,
//             extraInstructions: applePlayPopup?.subHeader,
//             instructions: applePlayPopup?.others?.message,
//             primaryButtonText: applePlayPopup?.others?.buttonHeader,
//             imageUrl: imageUrl,
//             primaryButtonAction: () => {
//                 mixpanelPlayContent(props, true, false);
//                 redirectionHandling(meta?.partnerDeepLinkUrl, true, learnData, useCWData, cwData);
//             },
//             secondaryButtonLinkText: applePlayPopup?.others?.buttonTitle,
//             secondaryButtonAction: () => {
//                 appleRedirection(props, MIXPANEL.VALUE.PLAY_POPUP);
//             },
//             isCloseModal: true,
//             hideCloseIcon: false,
//             isAppleModal: true,
//         }),
//     );
// }


export const convertCodeToString = (item) => {
    item = item?.replace(/_/g, "-")
    const languageNames = new Intl.DisplayNames(['en'], {
        type: 'language'
    });

    let updatedLang;
    try {
        updatedLang = languageNames.of(item)
    } catch (error) {
        updatedLang = item
    }
    return updatedLang;
}

export const getAudioSubtitleText = (item, isAudio = true) => {
    let updatedItem = (item.lang || item.label || (typeof item === "string" && item));

    if (isAudio) {
        return convertCodeToString(updatedItem);
    } else {
        return updatedItem?.toLowerCase() === "none" ? SUBTITLE_TEXT.NONE : convertCodeToString(updatedItem);
    }
}


export const removeDuplicateContent = (data) => {
    const uniques = Object.values(
        data.reduce((accumulator, currentValue) => {
            accumulator[currentValue.id + '|' + currentValue.contentType
            ] = currentValue;
            return accumulator
        }, {}))
    return uniques;
}

export const formatAMPM = (date, amPmRequire = true) => {
    if (date) {
        date = new Date(date);
        let hours = date.getHours(),
            minutes = date.getMinutes(),
            ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        let strTime = amPmRequire ? hours + ':' + minutes + ' ' + ampm : hours + ':' + minutes;
        return strTime;
    }
}

export const getSuggestorSectionSource = (suggestor) => {
    if (suggestor === AUTO_SUGGESTION_SUGGESTOR.GENRE_SUGGESTOR) {
        return SECTION_SOURCE.GENRE
    } else if (suggestor === AUTO_SUGGESTION_SUGGESTOR.LANGUAGE_SUGGESTOR) {
        return SECTION_SOURCE.LANGUAGE
    } else if (suggestor === AUTO_SUGGESTION_SUGGESTOR.PROVIDER_SUGGESTOR) {
        return SECTION_SOURCE.PROVIDER;
    }
}

export const getPartnerData = (item) => {
    let partnerInfo = JSON.parse(getKey(LOCALSTORAGE.PARTNER_INFO)) || [];
    //Check if any partner info is not saved
    let data =
        partnerInfo?.find(
            (i) => parseInt(i.partnerId) === parseInt(item.partnerId),
        );

    //if any unsaved partner found, sav its data
    !data &&
        partnerInfo.push({
            pageType: item?.pageType,
            title: item?.title,
            provider: item?.provider,
            partnerId: parseInt(item?.partnerId),
        });

    //Check if any saved partner has page type empty.
    let partnerPageTypeEmpty = partnerInfo?.findIndex(
        (i) => parseInt(i.partnerId) === parseInt(item.partnerId) && isEmpty(i.pageType),
    );

    //if match found replace its info.
    if (partnerPageTypeEmpty !== -1) {
        partnerInfo[partnerPageTypeEmpty].pageType = item?.pageType;
        partnerInfo[partnerPageTypeEmpty].title = item?.title;
        partnerInfo[partnerPageTypeEmpty].provider = item?.provider;
    }

    setKey(LOCALSTORAGE.PARTNER_INFO, JSON.stringify(partnerInfo));
};

export const getProviderLogoImage = (provider, isAutoSuggestion = false, isHeroBanner = false) => {
    if (provider) {
        let providerImageUrl;
        let providerImage = getProviderLogo();
        provider = provider?.toUpperCase();
        providerImageUrl = providerImage && (isAutoSuggestion ? providerImage[provider]?.logoProviderAutoSearch : providerImage[provider]?.logoRectangular);
        isHeroBanner && providerImage && (providerImageUrl = providerImage[provider]?.logoProviderBBA)
        return providerImageUrl;
    }
};

export const getMixpanelSuggestorType = (suggestor) => {
    switch (suggestor?.toLowerCase()) {
        case AUTO_SUGGESTION_SUGGESTOR.GENRE_SUGGESTOR:
            return MIXPANEL_SUGGESTOR_TYPE.GENRE
        case AUTO_SUGGESTION_SUGGESTOR.LANGUAGE_SUGGESTOR:
            return MIXPANEL_SUGGESTOR_TYPE.LANGUAGE
        case AUTO_SUGGESTION_SUGGESTOR.PROVIDER_SUGGESTOR:
            return MIXPANEL_SUGGESTOR_TYPE.PARTNER
        case AUTO_SUGGESTION_SUGGESTOR.KEYWORD_SUGGESTOR:
            return MIXPANEL_SUGGESTOR_TYPE.STRING
        case AUTO_SUGGESTION_SUGGESTOR.TITILE_SUGGESTOR:
            return MIXPANEL_SUGGESTOR_TYPE.CONTENT
        default:
            return ""
    }
};

export const showFavouriteToast = (text) => {
    toast(
        <div className="login-body-container">
            <div className="login-success-image">
                <img src={successTickIcon} alt="" />
            </div>
            <div className="favourite-text">
                <div>{text}</div>
            </div>
            {<div className="login-cross-image">
                <img src={crossIcon} alt="" />
            </div>}
        </div>,
        {
            position: toast.POSITION.BOTTOM_CENTER,
            className: "login-toast-wrapper switch-toast",
            autoClose: 3000,
        },
    );
};

export const isAppleActivate = (isShown, meta) => {
    const state = store.getState();
    let currentSubscription = get(state, "subscriptionDetails.currentSubscription.data");
    const {appleStatus, journey} = appleStatusJouney();
    if (isShown === SHOW_APPLE_STATUS.APPLE_CTA) {
        let showAppleCta = (meta?.provider?.toLowerCase() === PROVIDER_NAME.APPLE &&
            checkPartnerSubscribed(currentSubscription, meta.partnerId, meta.provider) &&
            APPLE_REDEMPTION_STATUS.ENTITLED === appleStatus && isEmpty(journey))
        return showAppleCta
    }
}

export const checkAppleStatus = (currentSubscription, meta) => {
    let { appleStatus, journey } = appleStatusJouney();
    let status = "";
    if (checkPartnerSubscribed(currentSubscription, meta.partnerId, meta.provider) &&
        appleStatus === APPLE_REDEMPTION_STATUS.ENTITLED && isEmpty(journey)) {
        status = appleStatus
    } else if (appleStatus === APPLE_REDEMPTION_STATUS.ACTIVATED) {
        status = appleStatus
    } else if (!isEmpty(journey)) {
        status = journey
    } else {
        status = MIXPANEL.VALUE.NULL
    }
    return status
}

export const getSubscribeText = () => {
    let state = store.getState();
    let subscribeText = get(state.headerDetails, "configResponse.data.config.hamburger.subscribe");

    return subscribeText || SIDE_MENU_HEADERS.SUBSCRIBE;
}

export const getTataPlayText = () => {
    let state = store.getState();
    let tataplayText = get(state.headerDetails, "configResponse.data.config.hamburger.tataPlayBalance");

    return tataplayText || SIDE_MENU_HEADERS.TATA_SKY_BALANCE;
}

export const getMyPlanText = () => {
    let state = store.getState();
    let myPlantext = get(state.headerDetails, "configResponse.data.config.hamburger.myPlan");

    return myPlantext || SIDE_MENU_HEADERS.MY_PLAN;
}

export const getBottomMenuItems = () => {
    let state = store.getState();
    let bottomText = get(state.headerDetails, "configResponse.data.config.hamburger.subText");
    let terms = BOTTOM_LIST.TERMS_AND_CONDITIONS;
    let privacy = BOTTOM_LIST.PRIVACY_POLICY;

    if (bottomText) {
        const [part1, part2] = bottomText.split("\n");
        terms = part1.trim() || BOTTOM_LIST.TERMS_AND_CONDITIONS;
        privacy = part2.trim() || BOTTOM_LIST.PRIVACY_POLICY;
    }

    return { terms, privacy };
}

export const isTabletView = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    const isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);
    return isTablet;
}

/**
* @function isUTMWebDeeplinkUrl - Detects if the user has come from UTM Deeplink url on web
*/
export const isUTMWebDeeplinkUrl = () => {
    let searchParams = getSearchParamsAsObject();
    return !!UTM_SUPER_PROPERTIES.find(key => searchParams?.hasOwnProperty(key?.toLowerCase()));
}

/**
* @function mixpanelGetUTMSuperProperties - return utm super properties to be send for corresponding mixpanel events for that particular session
*/
export const mixpanelGetUTMSuperProperties = () => {
    let isUTMWebDeeplink = JSON.parse(sessionStorage.getItem(SESSION_STORAGE.UTM_DEEPLINK_URL)),
        superProperties;

    if (isUTMWebDeeplink) {
        UTM_SUPER_PROPERTIES.map(key => {
            superProperties = {
                ...superProperties,
                [`${key}_LastTouch`]: getMappedValueForUTM(key),
            };
        });
    }
    return superProperties;
}

/**
* @function getMappedValueForUTM - return mapped value for utm super properties
*/
const getMappedValueForUTM = (key) => {
    let searchParams = getSearchParamsAsObject(),
        persistentSuperProperties = JSON.parse(sessionStorage.getItem(SESSION_STORAGE.UTM_SUPER_PROPERTIES)), keyToMap = key;
    if (key === MIXPANEL.PARAMETER.UTM_SOURCE) {
        keyToMap = searchParams?.pid ? 'pid' : key;
    }
    return searchParams?.[keyToMap?.toLowerCase()] ? searchParams?.[keyToMap?.toLowerCase()] : persistentSuperProperties?.[`${keyToMap}_LastTouch`] || ''
}

/**
 * @function getGoogleSubscriptionAnalyticsData - return data to be passed in GA/GTM events
 * @param selectedPack
 * @param source
 * @param paymentMethod
 */
export const getGoogleSubscriptionAnalyticsData = (selectedPack, source, paymentMethod, currentSubscription) => {
    let state = store.getState(),
        isManagedApp = get(state.headerDetails, "isManagedApp"),
        subscriptionJourneySource = source || "",
        subscriptionSource = getKey(LOCALSTORAGE.SUBCRIPTION_SOURCE) || "",
        analyticSource = (!isEmpty(subscriptionSource) && isManagedApp) ? subscriptionSource : getAnalyticsSource(subscriptionJourneySource),
        userInfo = JSON.parse(getKey(LOCALSTORAGE.USER_INFO)) || {},
        payableAmount = getKey(LOCALSTORAGE.PAYABLE_AMOUNT);

    return {
        [googleConversion.PARAMETER.SOURCE]: analyticSource,
        [googleConversion.PARAMETER.PACK_ID]: selectedPack?.productId,
        [googleConversion.PARAMETER.PACK_NAME]: selectedPack?.productName || '',
        [googleConversion.PARAMETER.PACK_PRICE]: selectedPack?.selectedTenurePackPriceMobile || selectedPack?.amountValue || '',
        [googleConversion.PARAMETER.PAYMENT_MODE]: paymentMethod,
        [googleConversion.PARAMETER.REVENUE]: payableAmount,
        [googleConversion.PARAMETER.CUID]: getMixpanelId(),
        [googleConversion.PARAMETER.C_ID]: userInfo?.bingeSubscriberId,
        [googleConversion.PARAMETER.PACK_TYPE]: selectedPack?.subscriptionPackType,
        [googleConversion.PARAMETER.PROMO_CODE]: currentSubscription?.promoCode || "",
    }
}

/**
 * @function trackGoogleConversionSuccess - track event SUBSCRIPTION_SUCCESS && SUBSCRIBE_SUCCESS_NEW on GA/GTM
 * @param currentSubscription
 * @param paymentMethod
 */
export const trackGoogleConversionSuccess = (currentSubscription, paymentMethod = MIXPANEL.VALUE.PG) => {
    const previousSubscription = JSON.parse(getKey(LOCALSTORAGE.PREVIOUS_SUBSCRIPTION_DETAILS));
    const currentSelection = JSON.parse(getKey(LOCALSTORAGE.SUBSCRIPTION_SELECTED_PACK));
    const analyticsData = getGoogleSubscriptionAnalyticsData(currentSelection, '', paymentMethod, currentSubscription);
    const isFirstSubscription = checkIsFirstSubscription();
    googleConversionConfig.trackEvent(googleConversion.EVENT.SUBSCRIPTION_SUCCESS, {
        [googleConversion.PARAMETER.VALUE]: currentSubscription?.amountValue || '',
        [googleConversion.PARAMETER.CURRENCY]: googleConversion.VALUE.CURRENCY,
        ...analyticsData
    });
    dataLayerConfig.trackEvent(DATALAYER.EVENT.SUBSCRIPTION_SUCCESS, analyticsData);

    // isFirstSubscription : Checks if the user has pusrchased its first ever pack on binge 
    // and SUBSCRIBE_SUCCESS_NEW event should be trigerred only it this condition is true 
    // i.e. only once in a lifetime of a user
    if (isFirstSubscription) {
        googleConversionConfig.trackEvent(googleConversion.EVENT.SUBSCRIBE_SUCCESS_NEW, {
            ...analyticsData
        });
        dataLayerConfig.trackEvent(DATALAYER.EVENT.SUBSCRIPTION_SUCCESS_NEW, analyticsData);
    }

    // Purcase event expect data in this format only, as it makes easier for GA to ready this.
    let packPurchaseData = {
        ecommerce: {
            transaction_id: getKey(LOCALSTORAGE.TRANSACTION_ID),
            value: analyticsData?.[googleConversion.PARAMETER.REVENUE],
            currency: "INR",
            paymentType: analyticsData?.[googleConversion.PARAMETER.PAYMENT_MODE],
            [DATALAYER.PARAMETER.CUID]: analyticsData?.[googleConversion.PARAMETER.CUID],
            [DATALAYER.PARAMETER.C_ID]: analyticsData?.[googleConversion.PARAMETER.C_ID],
            items: [
                {
                    item_id: analyticsData?.[googleConversion.PARAMETER.PACK_ID],
                    item_name: analyticsData?.[googleConversion.PARAMETER.PACK_NAME],
                    currency: "INR",
                    price: analyticsData?.[googleConversion.PARAMETER.PACK_PRICE],
                    item_variant: currentSelection?.selectedPartnerList,
                }]
        }
    }
    dataLayerConfig.trackEvent(DATALAYER.EVENT.PURCHASE, packPurchaseData);
    deleteKey(LOCALSTORAGE.PAYABLE_AMOUNT);
}

export const getJourneySource = () => {
    let journeySource = getKey(LOCALSTORAGE.JOURNEY_SOURCE);
    return isEmpty(journeySource) ? JOURNEY_SOURCE.DRAWER_CYOP : journeySource;
}


/**
 * @function setUTMAppsflyerSession - when user comes for payment on web from msales/mservice , 
 * utm_source will come in url and stored in session storage which will be passed further in appsflyer events.
 */
export const setUTMAppsflyerSession = () => {
    let searchParams = getSearchParamsAsObject(), searchKey = UTM_SOURCE;
    if (searchParams?.hasOwnProperty(searchKey)) {
        sessionStorage.setItem(SESSION_STORAGE.UTM_APPLSFLYER_SESSION, true);
        sessionStorage.setItem(SESSION_STORAGE.UTM_APPLSFLYER_CHANNEL, searchParams[searchKey]);
    }
};

export const trackMixpanelPaymentEventNonFreemiumDthUser = () => {
    const previousSubscription = JSON.parse(getKey(LOCALSTORAGE.PREVIOUS_SUBSCRIPTION_DETAILS));
    const state = store.getState();
    let currentSubscription = get(state, "subscriptionDetails.currentSubscription.data"),
        currentSelection = JSON.parse(getKey(LOCALSTORAGE.SUBSCRIPTION_SELECTED_PACK)),
        balanceAmount = get(state?.subscriptionPaymentReducer, "balanceInfo.data.balanceQueryRespDTO"),
        userInfo = JSON.parse(getKey(LOCALSTORAGE.USER_INFO)),
        transactionId = getKey(LOCALSTORAGE.TRANSACTION_ID);
    let paymentJourneyProperty = getMixpanelSubscriptionAnalyticsData(previousSubscription, '', MIXPANEL);
    let data = {
        [MIXPANEL.PARAMETER.SUBSCRIBER_BALANCE]: get(balanceAmount, "balance") || "",
        [MIXPANEL.PARAMETER.TRANSACTION_STATUS]: MIXPANEL.VALUE.COMPLETED,
        [MIXPANEL.PARAMETER.WALLET_NAME]: currentSubscription?.paymentMethod,
        [MIXPANEL.PARAMETER.AMOUNT]: currentSelection.amountValue || MIXPANEL.VALUE.DEFAULT_AMOUNT_VALUE,
        [MIXPANEL.PARAMETER.RENEWAL_MODE]: MIXPANEL.VALUE.TP_WALLET,
        [MIXPANEL.PARAMETER.PAYMENT_STATUS]: MIXPANEL.VALUE.SUCCESS,
        [MIXPANEL.PARAMETER.QR_SOURCE]: userInfo?.qrSource,
        [MIXPANEL.PARAMETER.PAYMENT_MODE]: MIXPANEL.VALUE.TP_WALLET,
        [MIXPANEL.PARAMETER.PAYMENT_TYPE]: getPaymentTypeValue(),
        [MIXPANEL.PARAMETER.PAYMENT_METHOD]: MIXPANEL.VALUE.TP_WALLET,
        [MIXPANEL.PARAMETER.TRANSACTION_ID]: transactionId,
    };
    let paymentMixpanelData = { ...data, ...paymentJourneyProperty }
    mixPanelConfig.trackEvent(MIXPANEL.EVENT.PAYMENT, paymentMixpanelData);
    trackMixpanelSubscriptionSuccess(currentSubscription, PAYMENT_METHOD.TS_WALLET)
}

export const addPartnerToContinueWatching = async (eventType, provider) => {
    let userInfo = JSON.parse(getKey(LOCALSTORAGE.USER_INFO)) || {};

    let payload = {
        deviceId: getDeviceId(),
        partner: provider,
        platform: HEADER_CONSTANTS.WEB,
        profileId: userInfo.profileId,
        subscriberId: userInfo.sId,
        referenceId: userInfo.referenceId,
        dsn: userInfo.deviceSerialNumber,
        baId: userInfo.baId,
    };

    await store.dispatch(partnerContinueWatching(eventType, payload));
}

export const appleRedemptionClaimedDate = () => {
    let state = store.getState();
    let currentSub = get(state, "subscriptionDetails.currentSubscription.data");
    let redeemptionDate = currentSub?.redeemptionDate && !["NA"].includes(currentSub?.redeemptionDate) && moment(currentSub?.redeemptionDate).format('llll');
    let claimedDate = currentSub?.claimedDate && !["NA"].includes(currentSub?.claimedDate) && moment(currentSub?.claimedDate).format('llll');
    return {
        [MIXPANEL.PARAMETER.REDEEMPTION_DATE]: !isEmpty(redeemptionDate) ? redeemptionDate :  MIXPANEL.VALUE.NA,
        [MIXPANEL.PARAMETER.CLAIMED_DATE]: !isEmpty(claimedDate) ? claimedDate :  MIXPANEL.VALUE.NA,
    }
}

export const appendBBAHoverElements = (id) => {
    const railContainer = document.querySelector(`.rail-${id}`);
    const leftShadow = document.createElement("div"),
        rightShadow = document.createElement("div");
    leftShadow.classList.add("box-shadow-slider");
    rightShadow.classList.add("box-shadow-slider-right-shadow");
    railContainer && (
        railContainer.querySelector('.slick-slider').append(leftShadow),
        railContainer.querySelector('.slick-slider').append(rightShadow))
}

export const isAtvFsUser = () => {
    let userInfo = JSON.parse(getKey(LOCALSTORAGE.USER_INFO)) || {};
    return [SUBSCRIPTION_TYPE.ANDROID_STICK, SUBSCRIPTION_TYPE.ATV].includes(userInfo?.subscriptionType);
};

export const downloadNudgeClickMixpanelEvent = (storeName, nudgeType) => {
    mixPanelConfig.trackEvent(MIXPANEL.EVENT.DOWNLOADNUDGE_CLICK, {
        [MIXPANEL.PARAMETER.STORE_NAME]: storeName,
        [MIXPANEL.PARAMETER.NUDGE_TYPE]: nudgeType,
    });
}

/**
 * @function setKeyOnAppConfigCall - called on App config API to update keys in local for media ready
 */
export const setKeyOnAppConfigCall = (configResponse) => {
    let mediaReadyConfig = getMediaReadyConfig(),
        isCloudinaryEnabledBingeWeb = get(mediaReadyConfig, 'binge_web.isCloudinaryEnabledBingeWeb'),
        durationfromMRConfig = get(mediaReadyConfig, 'binge_web.PollingDurationInMins'),
        enableEventRover = get(configResponse, "config.enableEventRover");
        
    setKey(LOCALSTORAGE.CLOUDINARY_ENABLED, JSON.stringify(isCloudinaryEnabledBingeWeb)); // set cloundinary key in local
    setKey(LOCALSTORAGE.MEDIAREADY_CONFIG_CALLED_TIME, new Date()); // set exact time when config was called
    setKey(LOCALSTORAGE.MEDIAREADY_CONFIG_CALLING_DURATION, JSON.stringify(durationfromMRConfig)); // set polling duration for media ready
    isUserloggedIn() && setKey(LOCALSTORAGE.ENABLE_EVENT_ROVER, JSON.stringify(enableEventRover))
}

/**
 * @function setKeysOnMediaReadyPollingCall - called on Media ready Pollig API , to update keys in local for MR
 */
export const setKeysOnMediaReadyPollingCall = () => {
    let { headerDetails } = store.getState();
    let mediaReadyPollingRes = get(headerDetails, "mediaReadyPollingRes.data"),
        isCloudinaryEnabledBingeWeb = get(mediaReadyPollingRes, 'binge_web.isCloudinaryEnabledBingeWeb');
    setKey(LOCALSTORAGE.CLOUDINARY_ENABLED, JSON.stringify(isCloudinaryEnabledBingeWeb)); // set cloundinary key in local
    setKey(LOCALSTORAGE.MEDIAREADY_CONFIG_CALLED_TIME, new Date());  // set updated time when MR config was called
}

/**
 * @function handleMediaReadyPubnubData - On pubnub push from MR channel
 */
export const handleMediaReadyPubnubData = (data) => {
    if (data) {
        console.log("Received media ready pubnub data", data);
        setKey(LOCALSTORAGE.CLOUDINARY_ENABLED, JSON.stringify(get(data, "isCloudinaryEnabled", false)));
    }
}
/**
 * 
 * @returns List of nongeneric partners
 */
export const getNonGenericPartnerList = () => {
    let { headerDetails } = store.getState();
    let configResponse = get(headerDetails, "configResponse.data.config"),
        nonGenericPartners = get(configResponse, "non_generic_partners.web");

    nonGenericPartners = nonGenericPartners?.map(p => p.toLowerCase());
    setKey(LOCALSTORAGE.NON_GENERIC_PROVIDERS, JSON.stringify(nonGenericPartners));

    return nonGenericPartners;
}

/**
 * 
 * @returns List of all integrated partners i.e generic and non generic
 */
export const getAllIntegratedPartnerList = () => {
    const genericProvider = JSON.parse(getKey(LOCALSTORAGE.GENERIC_PROVIDERS));
    const nonGenericProvider = JSON.parse(getKey(LOCALSTORAGE.NON_GENERIC_PROVIDERS));
    const genericProviders = genericProvider && Object.keys(genericProvider);
    return [PROVIDER_NAME.GAMEZOP, PROVIDER_NAME.ZEE_FIVE, ...(nonGenericProvider || []), ...(genericProviders || [])];
}

export const subscribeClickEvent = (item) => {
    if (item.linkToRedirect === URL.SUBSCRIPTION) {
        mixPanelConfig.trackEvent(MIXPANEL.EVENT.HOME_SUBSCRIBE_CLICK);
    }
}


/**
 * 
 * @returns Returns refuse Case for contents which are driven from ta recommended rail and have empty refuse case and sends them in LA for player and binge list, not for search
 */
export const getRefUseCase = (refUseCase, taRecommendedRail, taShowType) => {
    if (taRecommendedRail && isEmpty(refUseCase)) {
        const { headerDetails } = store.getState();
        let taRelatedRail = get(headerDetails, "configResponse.data.config.taRelatedRail"),
            showType = taShowType?.split('-'),
            contentType = showType?.[1],
            data = taRelatedRail?.filter(item => item.contentType === contentType);
        refUseCase = data?.[0]?.refUseCase;
    }

    return refUseCase;
}

/**
 * 
 * @param {*} routeUrl 
 * @param {*} sectionSource 
 * Checks if the journey triggered for detail page is from Partner, categories , BBL or BBG pages, in case of the below pages are triggered from 
 * search suggestion then they are not triggered as special rail
 */
export const specialRailUseCases = (routeUrl, sectionSource) => {
    let { isCategoriesPage, isPartnerPage, isBrowseByPage } = isPartnerCategoryBrowseSearchPage(routeUrl),
        screenName = getKey(LOCALSTORAGE.DETAIL_PAGE_JOURNEY_SOURCE),
        searchSuggestionJourney = screenName === JOURNEY_INITIATED_SCREEN.SEARCH_SUGGESTION,
        searchSuggestionTARail = screenName === JOURNEY_INITIATED_SCREEN.SEARCH_SUGGESTION_RAIL_TA;

    if (!(searchSuggestionJourney || searchSuggestionTARail)) {
        if (sectionSource === SECTION_SOURCE.RECOMMENDATION && (isCategoriesPage || isPartnerPage || isBrowseByPage)) {
            setKey(LOCALSTORAGE.DETAIL_PAGE_JOURNEY_SOURCE, JOURNEY_INITIATED_SCREEN.RAIL_TA);
        } else if (sectionSource !== SECTION_SOURCE.RECOMMENDATION && (isCategoriesPage || isPartnerPage || isBrowseByPage)) {
            setSpecialRailEditorialJourneySource(routeUrl);
        }
    } else if (searchSuggestionJourney || searchSuggestionTARail) {
        setSearchSuggestionJourneySource(routeUrl, sectionSource);
    }

}


/**
 * 
 * @param {*} routeUrl 
 * @param {*} sectionSource 
 * Checks if the journey triggered for detail page is from Partner, categories , BBL or BBG pages, which came from search suggestion
 */
export const setSearchSuggestionJourneySource = (routeUrl, sectionSource) => {
    let { isCategoriesPage, isPartnerPage, isBrowseByPage } = isPartnerCategoryBrowseSearchPage(routeUrl);
    if (sectionSource === SECTION_SOURCE.RECOMMENDATION && (isCategoriesPage || isPartnerPage || isBrowseByPage)) {
        setKey(LOCALSTORAGE.DETAIL_PAGE_JOURNEY_SOURCE, JOURNEY_INITIATED_SCREEN.SEARCH_SUGGESTION_RAIL_TA);
    } else if (sectionSource !== SECTION_SOURCE.RECOMMENDATION && (isCategoriesPage || isPartnerPage || isBrowseByPage)) {
        setKey(LOCALSTORAGE.DETAIL_PAGE_JOURNEY_SOURCE, JOURNEY_INITIATED_SCREEN.SEARCH_SUGGESTION);
    }
}

/**
 * 
 * @param {*} routeUrl 
 * Checks if the journey triggered for detail page is from Partner, categories , BBL or BBG pages and set the required journey source
 */
export const setSpecialRailEditorialJourneySource = (routeUrl) => {
    let { isCategoriesPage, isPartnerPage, isBrowseByPage } = isPartnerCategoryBrowseSearchPage(routeUrl);
    if (isCategoriesPage) {
        setKey(LOCALSTORAGE.DETAIL_PAGE_JOURNEY_SOURCE, JOURNEY_INITIATED_SCREEN.CATEGORIES);
    } else if (isPartnerPage) {
        setKey(LOCALSTORAGE.DETAIL_PAGE_JOURNEY_SOURCE, JOURNEY_INITIATED_SCREEN.PARTNER);
    } else if (isBrowseByPage) {
        setKey(LOCALSTORAGE.DETAIL_PAGE_JOURNEY_SOURCE, JOURNEY_INITIATED_SCREEN.BROWSE_BY);
    }
}

/**
 * 
 * @param {*} routeUrl 
 * @returns Checks if the current route is of Categories, partner, BBL, BBG or search page
 */
export const isPartnerCategoryBrowseSearchPage = (routeUrl) => {
    let browseByValue = getBrowseByKeyFromUrl(routeUrl);
    let isCategoriesPage = routeUrl?.[1]?.indexOf(URL.CATEGORIES) !== -1,
        isPartnerPage = routeUrl?.[1]?.indexOf(URL.PARTNER) !== -1,
        isBrowseByPage = browseByValue?.indexOf(URL.BROWSE_BY_LANGUAGE) !== -1 || browseByValue?.indexOf(URL.BROWSE_BY_GENRE) !== -1,
        isSearchPage = routeUrl?.[1]?.indexOf(URL.SEARCH) !== -1;
    return { isCategoriesPage, isPartnerPage, isBrowseByPage, isSearchPage };
}

/**
 * 
 * @param {*} sectionSource 
 * Checks if the hero banner is TA driven or editorial 
 */
export const setHeroBannerTypeForHome = (sectionSource) => {
    if (sectionSource === SECTION_SOURCE.RECOMMENDATION) {
        setKey(LOCALSTORAGE.DETAIL_PAGE_JOURNEY_SOURCE, JOURNEY_INITIATED_SCREEN.HERO_BANNER_TA);
    } else {
        setKey(LOCALSTORAGE.DETAIL_PAGE_JOURNEY_SOURCE, JOURNEY_INITIATED_SCREEN.HERO_BANNER_EDITORIAL);
    }
}

/**
 * 
 * @param {*} sectionSource 
 * Checks if the rail is TA driven or editorial 
 */
export const setRailTypeForHome = (sectionSource) => {
    if (sectionSource === SECTION_SOURCE.RECOMMENDATION) {
        setKey(LOCALSTORAGE.DETAIL_PAGE_JOURNEY_SOURCE, JOURNEY_INITIATED_SCREEN.RAIL_TA);
    } else {
        setKey(LOCALSTORAGE.DETAIL_PAGE_JOURNEY_SOURCE, JOURNEY_INITIATED_SCREEN.RAIL_EDITORIAL);
    }
}

/**
 * 
 * @param {*} routeUrl 
 * @returns true if the HB is of home page otherwise false
 */
export const isHomeHeroBanner = (routeUrl) => {
    let { isCategoriesPage, isPartnerPage } = isPartnerCategoryBrowseSearchPage(routeUrl);
    return !(isCategoriesPage || isPartnerPage);

}

/**
 * 
 * @param {*} routeUrl 
 * @returns true if the rail is of home page or PI Related rail otherwise false
 */
export const isHomePIRelatedRail = (routeUrl) => {
    let { isCategoriesPage, isPartnerPage, isBrowseByPage, isSearchPage } = isPartnerCategoryBrowseSearchPage(routeUrl);
    return !(isCategoriesPage || isPartnerPage || isBrowseByPage || isSearchPage);

}

/**
 * 
 * @param {*} location 
 * @param {*} taShowType 
 * @returns the mixpanel data including ta use case id
 */
export const setTaUseCaseId = (location, taShowType) => {
    let taUseCaseId = getTAUseCaseId(location, taShowType),
        data = { [`${MIXPANEL.PARAMETER.TA_USECASE_ID}`]: taUseCaseId };

    return data;
}

export const isBreadCrumbSourceValid = (source) => {
    return Object.values(BingeAppUrl).includes(source?.toLowerCase())
}

export const appleActivateVideoPopup = (props, openKnowMorePopup = false) => {
    store.dispatch(
        openPopup(MODALS.CUSTOM_MODAL, {
            modalClass: `alert-modal apple-activate-popup ${openKnowMorePopup && `apple-know-more-popup`}`,
            childComponent: <AppleActivate commonProps={props} openKnowMorePopup={openKnowMorePopup} />,
            closeModal: true,
            hideCloseIcon: false,
            isAppleModal: true,
        }),
    );
    let dataMixpanel = getCommonAnalyticsAttributes(MIXPANEL.EVENT.ACTIVATE_APPLE_TV_SUBSCRIPTION_CLICK, props, props);
    !openKnowMorePopup && mixPanelConfig.trackEvent(MIXPANEL.EVENT.ACTIVATE_APPLE_TV_SUBSCRIPTION_CLICK, dataMixpanel?.mixpanelData);
}

export const isNewPlayCtaJourney = () => {
    let state = store.getState(),
        isNewPlayJourney = get(state, "headerDetails.configResponse.data.config.configVerbiage");
    return isNewPlayJourney
};

export const getBreadcrumbContentType = (contentType) => {
    let state = store.getState();
    let headerItems = get(state.headerDetails, "headerItems.items");
    let pageNameArray = headerItems && headerItems?.map((item) => {
        return item?.pageName?.toLowerCase()
    });
    contentType = pageNameArray?.includes(contentType?.toLowerCase()) ? contentType?.toLowerCase() : URL.HOME;
    return contentType;
}

/**
 * 
 * @param {*} location 
 * @param {*} taShowType 
 * @returns return  browse by value from the route url array dependening on if the source is home/search or any other category like movie/tv-show/sports/games
 */
export const getBrowseByKeyFromUrl = (routeUrl = []) => {
    return routeUrl?.length === 3 ? routeUrl?.[1] : routeUrl?.[2];
};

export const calculateBreadCrumbWidth = () => {
    if (isMobile.any()) {
        let container = document.querySelector('.footer-top-wrapper')
        let breadCrumbList = document.querySelectorAll('.bread-crumb')
        if (breadCrumbList?.length > 2) {
            let lastBreadcrumb = breadCrumbList?.[2]
            let secondBreadcrumb = breadCrumbList?.[1]
            let width = Math.ceil((container?.getBoundingClientRect()?.width - secondBreadcrumb?.getBoundingClientRect()?.left) / 2) - 12
            let leftWidth = secondBreadcrumb?.getBoundingClientRect()?.width + lastBreadcrumb?.getBoundingClientRect()?.width
            let removeFirstBreadcrumbwidth = container?.getBoundingClientRect()?.width - secondBreadcrumb?.getBoundingClientRect()?.width
            let isWidthRequired = leftWidth > removeFirstBreadcrumbwidth
            if (isWidthRequired) {
                secondBreadcrumb?.getBoundingClientRect()?.width > width && secondBreadcrumb && (secondBreadcrumb.querySelector('.second').style.width = `${width}px`)
                lastBreadcrumb && (lastBreadcrumb.querySelector('.second').style.width = `${width}px`)
            }
        }
    }
}


export const getRandom4DigitNo = () => {
    return Math.floor(Math.random() * 9000 + 1000);
};

export const getSearchPageNameSource = (source, props) => {
    let state = store.getState(),
        categoriesList = get(state.headerDetails, "categoriesList"),
        { location: { pathname }, } = props,
        urlArr = pathname.split('/'),
        pageName;
    if (source === SEARCH_PAGE_NAME.CATEGORIES) {
        let selectedTabDetailCategory = categoriesList?.items && categoriesList?.items.find((item) => {
            let pageName = getFormattedURLValue(item?.pageName);
            if (pageName === urlArr[2]?.toLowerCase()) {
                return item;
            }
        });
        pageName = selectedTabDetailCategory?.pageName;
        return pageName;
    } else if (source === SEARCH_PAGE_NAME.PARTNER) {
        let providerId = urlArr[3];
        let partnerInfo = JSON.parse(getKey(LOCALSTORAGE.PARTNER_INFO));
        let data = partnerInfo && partnerInfo.find && partnerInfo.find(i => parseInt(i.partnerId) === parseInt(providerId));
        pageName = data?.provider;
        return pageName;
    } else if (source === SEARCH_PAGE_NAME.BROWSE_BY) {
        pageName = capitalizeFirstLetter(urlArr[3]);
        return pageName
    }
}

/**
 * 
 * @param {*} playbackUrl 
 * @returns checks if the playback url is of DASH type or HLS
 */
export const checkUrlPlaybackType = (playbackUrl) => {
    let isHlsStream = /.m3u8/.test(playbackUrl),
        isDashStream = /.mpd/.test(playbackUrl);

    return { isHlsStream, isDashStream };
}

/**
 * 
 * @param {*} accountChannelToBeSubscribed 
 * @returns checks if switch is required from rmn channel to account channel
 */
export const accountChannelToBeSubscribed = (payload) => {
    let userInfo = JSON.parse(getKey(LOCALSTORAGE.USER_INFO)) || {},
        switchAllowed = isUserloggedIn() && userInfo?.dthStatus !== DTH_TYPE.DTH_W_BINGE_OLD_USER && payload?.enableAccountPubnubChannel && payload?.channelName;

    if (switchAllowed) {
        let pubnubData = {
            ...pubnubData,
            pubnubChannelName: payload?.channelName,
            isAccountChannelSubscribed: true
        }
        setKey(LOCALSTORAGE.ACCOUNT_PUBNUB_DETAILS, JSON.stringify(pubnubData));
    }

    return switchAllowed;
}

/**
 * 
 * @param {*} switchToAccountChannel 
 * @returns Unsubscribe rmn channel while switching from rmn to account channel
 */
export const switchToAccountChannel = (newChannelName) => {
    let userInfo = JSON.parse(getKey(LOCALSTORAGE.USER_INFO)) || {},
        rmnChannelName = userInfo.rmn ? `rmn_${userInfo.rmn}` : null,
        currrentChannelName = getKey(LOCALSTORAGE.CURRENT_PUBNUB_CHANNEL);
    if (currrentChannelName !== newChannelName) {
        removeAppEventListener(rmnChannelName);
        initiateAppEventListener();
    }
}

/**
 * 
 * @param {*} switchToRMNChannel 
 * @returns Unsubscribe account channel while switching from account to rmn channel
 */
export const switchToRMNChannel = () => {
    let pubnubData = JSON.parse(getKey(LOCALSTORAGE.ACCOUNT_PUBNUB_DETAILS)),
        accountChannelSubscribed = isUserloggedIn() && pubnubData?.isAccountChannelSubscribed;
    if (accountChannelSubscribed) {
        removeAppEventListener();
        deleteKey(LOCALSTORAGE.ACCOUNT_PUBNUB_DETAILS);
        initiateAppEventListener();
    }
}


/**
 * 
 * @param {*} encryptedCipherText 
 * @returns  Encrypt a url using crypto js
 */
export const decryptUrl = (encryptedCipherText) => {
    let last2 = encryptedCipherText.slice(-2);
    let text = encryptedCipherText;
    text = text.slice(0, -3);
    let state = store.getState();
    let configValue = get(state.headerDetails, "configResponse.data.config");
    let secretKey = configValue.dd[last2];
    let parsedBase64Key = CryptoJS.enc.Base64.parse(secretKey);
    let decryptedData = CryptoJS.AES.decrypt(text, parsedBase64Key, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    });

    let url = decryptedData.toString(CryptoJS.enc.Utf8);
    console.log(" decryptUrl ", url, "secretKey", secretKey, text);
    return url;
}

export const getUserSelectedApp = (sectionSource = "", isSearch = false) => {
    const state = store.getState();
    let currentSubscription = get(state.subscriptionDetails, "currentSubscription.data") || "",
        isManagedApp = get(state.headerDetails, "isManagedApp"),
        isFlexiPlan = currentSubscription?.flexiPlan && isManagedApp,
        userSelectedApps = currentSubscription?.userSelectedApps,
        isActivePlan = !isSubscriptionStatusEmpty() && currentSubscription?.subscriptionStatus?.toUpperCase() === SUBSCRIPTION_STATUS.ACTIVE;

    return isActivePlan && isFlexiPlan && ([SECTION_SOURCE.PROVIDER, SECTION_SOURCE.PROVIDER_BROWSE_APPS].includes(sectionSource) || isSearch) ? userSelectedApps : false;
}

export const trackDataLayerEvent = (railData, item) => {
    let railTitle;
    if (railData?.sectionSource === SECTION_SOURCE.LANGUAGE) {
        railTitle = DATALAYER.VALUE.BROWSE_BY_LANGUAGE;
    } else if (railData?.sectionSource === SECTION_SOURCE.GENRE) {
        railTitle = DATALAYER.VALUE.BROWSE_BY_GENRE;
    } else if (railData?.sectionSource === SECTION_SOURCE.PROVIDER) {
        railTitle = DATALAYER.VALUE.BROWSE_BY_APP;
    }
    dataLayerConfig.trackEvent(DATALAYER.EVENT.BROWSING_RAIL, {
        [DATALAYER.PARAMETER.RAIL_TITLE]: railTitle || "",
        [DATALAYER.PARAMETER.BUTTON_NAME]: item?.title,
    });
}

export const getUrlLength = (url) => {
    let currentUrl = url
    return currentUrl.length
}

/**
 * @function handlePrimePlaybility - handle play button click for prime 
 * 
 */
export const handlePrimePlaybility = async (deeplink, playPrimeDeeplink, learnData, useCWData, cwData, history, isClaimNudge = false) => {
    const state = store.getState();
    let configResponse = get(state, "headerDetails.configResponse.data.config"),
        meta = get(state, "PIDetails.data.meta"),
        primeEntitlementDetails = get(state, "subscriptionDetails.primeEntitlementDetails"),
        amazonSubscriptionVerbiage = state.subscriptionDetails?.myPlanVerbiages?.amazonSubscriptionVerbiage,
        apvDetails = get(state, "subscriptionDetails.currentSubscription.data.apvDetails"),
        entitlementStatus = !isEmpty(primeEntitlementDetails?.data?.entitlement_status) ? primeEntitlementDetails?.data?.entitlement_status : apvDetails?.primePackStatus,
        isPrimeNudgePlayBack = apvDetails?.primeNudge && !isClaimNudge && apvDetails?.primePackStatus?.toLowerCase() === PRIME_ENTITLEMENT_STATUS.ENTITLED,
        refUseCaseId = get(history, 'location.state.refUseCase', ''),
        taRecommendedRail = get(history, 'location.state.taRecommendedRail', false),
        refUseCase = getRefUseCase(refUseCaseId, taRecommendedRail, meta?.taShowType);
    isEmpty(amazonSubscriptionVerbiage) && await store.dispatch(getCurrentSubscriptionMyplanVerbiages());
    if (apvDetails?.primePackStatus?.toLowerCase() === PRIME_ENTITLEMENT_STATUS.ACTIVATED) {
        entitlementStatus = apvDetails?.primePackStatus;
    }
    if (entitlementStatus?.toLowerCase() === PRIME_ENTITLEMENT_STATUS.ACTIVATED || isPrimeNudgePlayBack) {
        if (!isMobile.any()) {
            amazonWebLargePopup()
            return
        }
        playAmazonDeepLink(deeplink, true, playPrimeDeeplink, learnData, useCWData, cwData, isPrimeNudgePlayBack)
    }
    else if (entitlementStatus?.toLowerCase() === PRIME_ENTITLEMENT_STATUS.ENTITLED) {
        deleteKey(LOCALSTORAGE.IS_AMAZON_REDIRECTION)
        apvDetails?.primeNudge && isClaimNudge && mixPanelConfig.trackEvent(MIXPANEL.EVENT.ACTIVATE_PRIME, {
            [MIXPANEL.PARAMETER.SOURCE]: MIXPANEL.VALUE.PI_PAGE,
            ...(primeMixpanelParameter()),
        })
        if (isClaimNudge) {
            learnData = {
                contentType: meta?.vodContentType || meta?.contentType,
                learnActionType: LEARN_ACTION_TYPE.CLICK,
                id: meta?.id || meta?.vodId,
                provider: meta?.provider,
                refUseCase,
                seriesId: meta?.seriesId,
                mixPanelData: trackContentPlayFailure(history, true)
            };
            cwData = {
                id: meta?.id || meta?.vodId,
                contentType: meta?.vodContentType || meta?.contentType,
                totalDuration: meta?.duration || meta?.totalDuration,
            };
        }
        safeNavigation(history, {
            pathname: `/${URL.AMAZON_INTERSTITIAL}`,
            state: {
                isPiPage: true,
                primeWebDeeplink: meta?.primeWebDeeplink,
                learnData,
                cwData,
            },
        })
    }
    else if ([PRIME_ENTITLEMENT_STATUS.SUSPEND, PRIME_ENTITLEMENT_STATUS.CANCELLED, PRIME_ENTITLEMENT_STATUS.PENDING].includes(entitlementStatus?.toLowerCase())) {
        showAmazonActivityToast(get(configResponse, 'primeContentVerbiages.primeSubProcessMessage'), true);
    } else if (primeEntitlementDetails?.code !== 0) {
        store.dispatch(openPopup(MODALS.ALERT_MODAL, {
            modalClass: 'alert-modal error-state-modal',
            headingMessage: `Something Went Wrong`,
            instructions: primeEntitlementDetails?.message ? primeEntitlementDetails?.message : 'The operation couldn’t be completed.',
            primaryButtonText: 'Ok',
            errorIcon: 'icon-alert-upd',
            closeModal: true,
            hideCloseIcon: true,
            errorCodeInstruction: `Error code : ${get(primeEntitlementDetails, 'code')}`,
        }));
    } else {
        showAmazonActivityToast(get(configResponse, 'primeContentVerbiages.primeSubProcessMessage'), true);
    }
};

export const playAmazonDeepLink = async (primeWebDeeplink, setCW, playPrimeDeeplink, learnData, useCWData, cwData, nudgePlayback = false) => {
    let primeFrequency = JSON.parse(getKey(LOCALSTORAGE.PRIME_LAUNCH_FREQUENCY)) || 0
    let periodicFrequency = JSON.parse(getKey(LOCALSTORAGE.PRIME_PERIODIC_FREQUENCY)) || 0
    const state = store.getState();
    let fallbackFrequency = { launchFrequency: 2, periodicFrequency: 30 }
    const primePopUp = get(state.headerDetails, 'configResponse.data.config.primePopUp.web') || fallbackFrequency;
    setKey(LOCALSTORAGE.PRIME_LAUNCH_FREQUENCY, ++primeFrequency)
    primeFrequency > primePopUp?.launchFrequency && setKey(LOCALSTORAGE.PRIME_PERIODIC_FREQUENCY, ++periodicFrequency)
    if (primeFrequency <= primePopUp?.launchFrequency) {
        return playPrimeDeeplink(primeWebDeeplink, setCW, true, learnData, useCWData, cwData, nudgePlayback)
    } else if (periodicFrequency <= primePopUp?.periodicFrequency) {
        if (periodicFrequency === primePopUp?.periodicFrequency) {
            deleteKey(LOCALSTORAGE.PRIME_LAUNCH_FREQUENCY)
            deleteKey(LOCALSTORAGE.PRIME_PERIODIC_FREQUENCY)
        }
        return playPrimeDeeplink(primeWebDeeplink, setCW, false, learnData, useCWData, cwData, nudgePlayback)
    }

}

export const showAmazonActivityToast = (text, isPending = false) => {
    toast(
        <div className="login-body-container amazon_toast">
            <div className="login-success-image">
                <img src={isPending ? informationIcon : successTickIcon} alt="" />
            </div>
            <div className="favourite-text">
                <div>{text}</div>
            </div>
            {!isMobile.any() && <div className="login-cross-image">
                <img src={crossIcon} alt="" />
            </div>}
        </div>,
        {
            position: toast.POSITION.BOTTOM_CENTER,
            className: "login-toast-wrapper switch-toast amazon_toast_wrapper",
            autoClose: 5000,
        },
    );
};

export const amazonPromoBannerClick = async () => {
    setKey(LOCALSTORAGE.PRIME_BANNER_CLICK, true);
    const requestHeader = { initiateSubscription: JOURNEY_SOURCE.DRAWER_CYOP, journeySource: JOURNEY_SOURCE.DRAWER_CYOP, journeySourceRefId: "", analyticSource: isMobile.any() ? FIREBASE.VALUE.HAMBURGER_SUBSCRIBE : FIREBASE.VALUE.HOME_SUBSCRIBE }
    await store.dispatch(getWebPortalLink(requestHeader, false));
}

export const isAmazonClaimClick = (primePackStatus) => {
    let isClaimClcik = getKey(LOCALSTORAGE.ACTIVATE_PRIME_BUTTON_CLICK);
    return isClaimClcik && primePackStatus?.toLowerCase() === PRIME_ENTITLEMENT_STATUS.ACTIVATED
}

export const primeMixpanelParameter = () => {
    const state = store.getState()
    let apvDetails = get(state, "subscriptionDetails.currentSubscription.data.apvDetails");
    return {
        [MIXPANEL.PARAMETER.BINGE_PRIME_STATUS]: apvDetails?.primePackMixpanelStatus,
        [MIXPANEL.PARAMETER.EXISTING_PRIME]: apvDetails?.primeNudge ? MIXPANEL.VALUE.YES : MIXPANEL.VALUE.NO_INPUT
    }
}

export const showAmazonToast = (status) => {
    const state = store.getState();
    let configResponse = get(state, "headerDetails.configResponse.data.config");
    let isAmazonToastShow = getKey(LOCALSTORAGE.SHOW_AMAZON_TOAST)
    if (isAmazonClaimClick(status) && !isAmazonToastShow) {
        showAmazonActivityToast(get(configResponse, 'primeContentVerbiages.primeActivationVerbiage', MESSAGE.AMAZON_ACTIVATION_TEXT));
        deleteKey(LOCALSTORAGE.ACTIVATE_PRIME_BUTTON_CLICK);
        deleteKey(LOCALSTORAGE.PRIME_LAUNCH_FREQUENCY)
        deleteKey(LOCALSTORAGE.PRIME_PERIODIC_FREQUENCY)
        deleteKey(LOCALSTORAGE.SHOW_AMAZON_TOAST)
    }
}

/**
 * 
 * @param {*} provider 
 * @returns Provider name to be dispalyed on UI
 */
export const getProviderDisplayName = (provider) => {
    const { headerDetails } = store.getState();
    let configResponse = get(headerDetails, "configResponse");
    let providerDisplayNames = get(configResponse, "data.config.providerDisplayNames", []);
    let providerData = providerDisplayNames.find(item => item?.provider?.toLowerCase() === provider?.toLowerCase());
    return providerData?.providerDisplayName || provider;
};

export const getPiPageUrlContentType = (contentType) => {
    console.log(contentType, 'ContentType coming in API')
    return REVERSE_FORMATTED_CONTENT_TYPE[contentType] ? REVERSE_FORMATTED_CONTENT_TYPE[contentType] : getFormattedURLValue(contentType);
};

export const playGenericDeepLink = async (deeplinkUrl, handleGenericRedirectionPopup, learnData, useCWData, cwData, meta) => {
    let genericPopupFrequency = JSON.parse(getKey(LOCALSTORAGE.GENERIC_POPUP_FREQUENCY)) || {},
        partnerPopupFrequency = genericPopupFrequency?.[meta?.provider?.toLowerCase()];
    let genericFrequency = partnerPopupFrequency?.genericLaunchFrequency || 0;
    let periodicFrequency = partnerPopupFrequency?.genericPeriodicFrequency || 0;
    const state = store.getState();
    const genericPopupBEFrequency = get(state.headerDetails, 'configResponse.data.config.genericPopup.web');
    genericPopupFrequency = {
        ...genericPopupFrequency,
        [meta?.provider?.toLowerCase()]: {
            genericLaunchFrequency: ++genericFrequency,
            genericPeriodicFrequency: genericFrequency > genericPopupBEFrequency?.launchFrequency ? ++periodicFrequency : periodicFrequency
        }
    }
    setKey(LOCALSTORAGE.GENERIC_POPUP_FREQUENCY, genericPopupFrequency);
    if (genericFrequency <= genericPopupBEFrequency?.launchFrequency) {
        return handleGenericRedirectionPopup(deeplinkUrl, true, learnData, useCWData, cwData)
    } else if (periodicFrequency <= genericPopupBEFrequency?.periodicFrequency) {
        if (periodicFrequency === genericPopupBEFrequency?.periodicFrequency) {
            delete genericPopupFrequency?.[meta?.provider?.toLowerCase()];
            setKey(LOCALSTORAGE.GENERIC_POPUP_FREQUENCY, genericPopupFrequency);
        }
        return handleGenericRedirectionPopup(deeplinkUrl, false, learnData, useCWData, cwData)
    }
}

export const amazonWebLargePopup = () => {
    let provider = PROVIDER_NAME.PRIME;
    store.dispatch(openPopup(MODALS.ALERT_MODAL, {
        modalClass: `alert-modal amazon_popup web_large`,
        instructions: AMAZON_WEB_LARGE_VERBIAGE.SUB_HEADER,
        primaryButtonText: AMAZON_WEB_LARGE_VERBIAGE.BUTTOM_CTA,
        imageUrl: providerImage(provider?.toLowerCase(), LAYOUT_TYPE.SQUARE),
        primaryButtonAction: async () => {
            store.dispatch(closePopup());
        },
        hideCloseIcon: false,
    }))
}

export const backArrowIcon = (history) => {
    return (
        <div className='back_arrow' onClick={() => history.goBack()}>
            <img src={BackArrow} />
        </div>
    )
}

export const deleteAccountHelpCenter = (item) => {
    const state = store.getState();
    const accountDeleteInitiated = get(state.profileDetails, 'userProfileDetails.accountDeleteInitiated');
    return (
        item?.btnAction === ACCORDION_DELETE_ACCOUNT.DELETE_ACCOUNT && <div className={`delete-account ${accountDeleteInitiated ? 'delete-acc-disable' : ''}`}>
            {!isUserloggedIn() ? <p><span onClick={() => handleLoginPopup(true)}>Delete Account</span></p>
                : <Link className={accountDeleteInitiated ? 'link-disable' : ''} to={{ pathname: `/${URL.HELP_CENTER}/${URL.HC_DELETE_ACCOUNT}`, state: { isFromDeletAccountFlow: true } }} onClick={handleLoginPopup} >Delete Account</Link>}
        </div>
    )
}

export const handleLoginPopup = (isLogoutView) => {
    mixPanelConfig.trackEvent(MIXPANEL.EVENT.DELETE_ACCOUNT_FAQ_CLICK, '');
    if (!isLogoutView) {
        return
    }
    store.dispatch(openPopup(MODALS.ALERT_MODAL, {
        modalClass: 'alert-modal delete-login',
        headingMessage: `Please login first to delete the account`,
        hideCloseIcon: false,
        isCloseModal: false,
    }));
}

export const getActivatePartnerPopupVerbiages = () => {
    const state = store.getState();
    let primeKey, appleKey,
        currentSubscription = state?.subscriptionDetails?.currentSubscription?.data,
        statusResponse = state?.subscriptionDetails?.applePrimeStatus,
        isPackActivate = get(currentSubscription, 'subscriptionStatus')?.toUpperCase() === SUBSCRIPTION_STATUS.ACTIVE && !get(currentSubscription, 'downgradeRequested') ;
    primeKey = get(statusResponse, "primeStatus.entitlement_status");
    appleKey = appleStatusJouney()?.appleStatus;
    primeKey = isEmpty(primeKey) ? get(currentSubscription, "apvDetails.primePackStatus") : primeKey;

    let popUpVerbiages = state?.subscriptionDetails?.myPlanVerbiages?.appleSubscriptionVerbiage?.applePrime,
        isAppleActivate = appleKey === APPLE_REDEMPTION_STATUS.ENTITLED && isPackActivate && checkPartnerSubscribed(currentSubscription,"",PROVIDER_NAME.APPLE),
        isPrimeActivate = primeKey?.toLowerCase() === PRIME_ENTITLEMENT_STATUS.ENTITLED && isPackActivate,
        isBothActivate = isAppleActivate && isPrimeActivate,
        showVerbiages = {
            imageUrl: '',
            imageUrl2: '',
            header: '',
            subHeader: '',
            primaryButton: '',
            secondaryButton: '',
            linkToRedirect: '',
            linkToRedirect2: '',
            isOneActivate: false,
            providerName: ''
        };
    deleteKey(LOCALSTORAGE.START_WATCHING_NOW)
    if (isBothActivate) {
        return showVerbiages = {
            imageUrl: popUpVerbiages?.primelogo,
            imageUrl2: popUpVerbiages?.applelogo,
            header: popUpVerbiages?.applePrimeHeader,
            subHeader: popUpVerbiages?.applePrimeSubHeader,
            primaryButton: popUpVerbiages?.primeActivateButton,
            secondaryButton: popUpVerbiages?.appleActivateButton,
            linkToRedirect: URL.AMAZON_INTERSTITIAL,
            linkToRedirect2: URL.APPLE_INTERSTITIAL,
            providerName: `${MIXPANEL.VALUE.PRIME},${MIXPANEL.VALUE.APPLE_TV}`,
        }
    } else if (isPrimeActivate) {
        deleteKey(LOCALSTORAGE.IS_PARTNER_POPUP_CLICK)
        return showVerbiages = {
            imageUrl: popUpVerbiages?.primelogo,
            header: popUpVerbiages?.primeHeader,
            subHeader: popUpVerbiages?.primeSubHeader,
            primaryButton: popUpVerbiages?.primeActivateButton,
            linkToRedirect: URL.AMAZON_INTERSTITIAL,
            isOneActivate: true,
            providerName: MIXPANEL.VALUE.PRIME,
        }
    } else if (isAppleActivate) {
        deleteKey(LOCALSTORAGE.IS_PARTNER_POPUP_CLICK)
        return showVerbiages = {
            imageUrl: popUpVerbiages?.applelogo,
            header: popUpVerbiages?.appleHeader,
            subHeader: popUpVerbiages?.appleSubHeader,
            primaryButton: popUpVerbiages?.appleActivateButton,
            linkToRedirect: URL.APPLE_INTERSTITIAL,
            isOneActivate: true,
            providerName: MIXPANEL.VALUE.APPLE_TV,
        }
    }  else {
        deleteKey(LOCALSTORAGE.IS_PARTNER_POPUP_CLICK)
        return ''
    }
}

export const handleAppleReActivation = (currentSubscription, viaPopup, verbiage, props, appleLogo) => {
    let redeemptionDate = currentSubscription?.redeemptionDate && !["NA"].includes(currentSubscription?.redeemptionDate) && moment(currentSubscription?.redeemptionDate).format('llll');
    mixPanelConfig.trackEvent(MIXPANEL.EVENT.ACTIVATE_APPLE_TV_POPUP, {
        [MIXPANEL.PARAMETER.PLATFORM]: MIXPANEL.VALUE.WEB,
        [MIXPANEL.PARAMETER.VIA_POPUP]: viaPopup,
        [MIXPANEL.PARAMETER.SOURCE]: MIXPANEL.VALUE.PI_PAGE,
        [MIXPANEL.PARAMETER.INTERSTITIAL_PAGE]: MIXPANEL.VALUE.NO,
        [MIXPANEL.PARAMETER.REDEEMPTION_DATE]:  !isEmpty(redeemptionDate) ? redeemptionDate :  MIXPANEL.VALUE.NA,
    });
    store.dispatch(
        openPopup(MODALS.ALERT_MODAL, {
            modalClass: `alert-modal reactivation_popup`,
            headingMessage: verbiage?.header,
            instructions: verbiage?.primaryHeader,
            primaryButtonText: verbiage?.primaryBtnCta,
            imageUrl: appleLogo,
            primaryButtonAction: async () => {
                appleRedirection(props, viaPopup, MIXPANEL.VALUE.PI_PAGE)
            },
            hideCloseIcon: false,
        }),
    );
}

export const appleStatusJouney = () => {
    let appleDetails = {
        appleStatus:'',
        journey: '',
    };
    const state = store.getState();
    let currentAppleStatus = get(state?.subscriptionDetails?.currentSubscription, "data.appleDetails.entitlementStatus"),
    currentJourney = get(state?.subscriptionDetails?.currentSubscription, "data.appleDetails.journey");
    if(currentAppleStatus === APPLE_REDEMPTION_STATUS.ACTIVATED){
        return appleDetails = {
            appleStatus: currentAppleStatus,
            journey: currentJourney,
        }
    }
    let appleStatus = get(state?.subscriptionDetails, "applePrimeStatus.appleStatus.entitlementStatus"), // from status api
    currentStatus = isEmpty(appleStatus) ?  currentAppleStatus : appleStatus, // from current subscription as fallback
    journey = get(state?.subscriptionDetails, "applePrimeStatus.appleStatus.journey"),
    newJourney = isEmpty(journey) ?  currentJourney : get(state?.subscriptionDetails, "applePrimeStatus.appleStatus.journey");
    return appleDetails = {
        appleStatus: currentStatus,
        journey: newJourney,
    }
};


export const trackRechargeFailedEvents = (reason) => {
    mixPanelConfig.trackEvent(MIXPANEL.EVENT.TS_RECHARGE_FAILED, {
        [`${MOENGAGE.PARAMETER.REASON}`]: reason || MIXPANEL.VALUE.FAILED,
    });
    appsFlyerConfig.trackEvent(APPSFLYER.EVENT.RECHARGE_FAILED, {
        [APPSFLYER.PARAMETER.REASON]: reason || APPSFLYER.VALUE.REASON,
    });
    // moengageConfig.trackEvent(MOENGAGE.EVENT.RECHARGE_FAILED, {
    //     [`${MOENGAGE.PARAMETER.REASON}`]: reason || MOENGAGE.VALUE.FAILED,
    // });
};

export const trackRechargeInitiateEvents = (appsFlyerSource, mixpanelSource, amount) => {
    appsFlyerConfig.trackEvent(APPSFLYER.EVENT.RECHARGE_INITIATE, {
        [APPSFLYER.PARAMETER.SOURCE]: appsFlyerSource
    });
    mixPanelConfig.trackEvent(MIXPANEL.EVENT.TS_RECHARGE_INITIATE, {
        [MIXPANEL.PARAMETER.SOURCE]: mixpanelSource,
        [MIXPANEL.PARAMETER.AMOUNT]: amount || MIXPANEL.VALUE.DEFAULT_AMOUNT_VALUE
    });
}

export const amazonTryAgainModal = (handleForgetAccount, count, isPollingFailed, playPrimeDeeplink, trackPrimeMixpanel) => {
    const state = store.getState();
    let amazonPopUpVerbiages = state.subscriptionDetails?.myPlanVerbiages?.amazonSubscriptionVerbiage?.lsAccountRecoveryFailurePopup;
    let configResponse = get(state.headerDetails, 'configResponse'),
        tryAgainFrequency = get(configResponse.data.config, 'tryAgainFrequency');
    let errorImage = `${cloudinaryCarousalUrl('', '', "80", "80")}${amazonPopUpVerbiages?.image}`

    if (count > tryAgainFrequency) {
        store.dispatch(closePopup())
        return
    }
    trackPrimeMixpanel(MIXPANEL.EVENT.PRIME_FORGOTACCOUNT_FAIL)
    store.dispatch(
        openPopup(MODALS.ALERT_MODAL, {
            modalClass: "alert-modal amazon-try-again-popup",
            headingMessage: amazonPopUpVerbiages?.title,
            primaryButtonText: amazonPopUpVerbiages?.primaryCTA,
            closeModal: false,
            isCloseModal: false,
            hideCloseIcon: true,
            instructions: amazonPopUpVerbiages?.subTitle,
            imageUrl: errorImage,
            primaryButtonAction: () => {
                playPrimeDeeplink(false, false, true, false, false, false, false)
                handleForgetAccount(count, isPollingFailed)
                trackPrimeMixpanel(MIXPANEL.EVENT.PRIME_FORGOTACCOUNT_RETRY)
            }
        }))
};

export const removeLAId = (id) => {
    let laData = JSON.parse(getKey(LOCALSTORAGE.LA_FIRED_DATE)) || [];
    let filteredLA = laData?.filter(data => data?.contentId != id);
    setKey(LOCALSTORAGE.LA_FIRED_DATE, filteredLA);
};

/**
* @function getmixpanelCouponDetails : to set common coupon prop in mixpanel event
* @returns object
*/
export const getmixpanelCouponDetails = () => {
    let couponDetails = JSON.parse(getKey(LOCALSTORAGE.COUPON_DETAILS)),
        mixpanelCouponData = {
            [MIXPANEL.PARAMETER.COUPON_TYPE]: couponDetails?.couponType,
            [MIXPANEL.PARAMETER.COUPON_CODE]: couponDetails?.couponCode,
            [MIXPANEL.PARAMETER.CAMPAIGN_TYPE]: couponDetails?.campaignType,
            [MIXPANEL.PARAMETER.PACK_PRICE]: couponDetails?.packPrice,
            [MIXPANEL.PARAMETER.DISCOUNT_TYPE]: couponDetails?.discountType,
            [MIXPANEL.PARAMETER.DISCOUNT_TYPE_VALUE]: couponDetails?.discountTypeValue,
            [MIXPANEL.PARAMETER.SEGMENTED]: couponDetails?.segmented,
            [MIXPANEL.PARAMETER.STATUS]: couponDetails?.status,
            [MIXPANEL.PARAMETER.COUPON_PARTNER_NAME]: couponDetails?.partnerName,
        };

    return mixpanelCouponData;
};

export const getEventRoverCommonHeaders = () => {
    let sessionId = getSessionId(),
        deviceId = getDeviceId(),
        systemDetail = getSystemDetails();

    let xDevHeaders = JSON.stringify({
        ...systemDetail,
        "did": deviceId, // Device ID
        "sid": sessionId, // Session ID
        "cai": ENV_CONFIG.EVENT_ROVER_CAI, //Client App ID
        "cav": "1.0.0", // Client App Version
        "sdkv": "1.0.0", //  SDK Version
    });
    return xDevHeaders;
}
