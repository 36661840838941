import React from 'react'
import FooterCard from '../FooterCard'
import './style.scss';

const PageFooter = ({ pageFooterData = [] }) => {
    return !!pageFooterData?.length  ? (
        <article className='page-footer-container'>
            <div className="horizontal-line page-line" />
            <FooterCard footerData={pageFooterData} />
        </article>
    ) : null;
}
export default PageFooter