import React, { useRef, useLayoutEffect, useEffect } from "react";
import usePrevious from "../../../Hooks/usePrevious";
import PropTypes from "prop-types";
import { LENGTH_CHECK } from "@utils/constants";
import EditableContainer from "@common/EditableContainer";
import { VALIDATE_SOURCE } from "@utils/constants";

function OtpInpuBox(props) {
  const { focus, autoFocus, value, otpValue, handlePasteValue, otpValidateSource, maxLength, onBlur, onChange, onKeyDown, otpInputLength, trackEventOnAutofill, ...rest } = props;

  const inputRef = useRef();
  const prevFocus = usePrevious(!!focus);
  const inputTypeMode = otpValidateSource === VALIDATE_SOURCE.SMART_TV ? 'text' : 'decimal';

  useLayoutEffect(() => {
    if (inputRef.current) {
      if (focus && autoFocus) {
        inputRef.current.focus();
      }
      if (focus && autoFocus && focus !== prevFocus) {
        inputRef.current.focus();
      }
    }
  }, [autoFocus, focus, prevFocus]);

  useEffect(() => {
    const currentValue = inputRef?.current?.innerHTML;
    if (currentValue !== value) {
      inputRef.current.innerHTML = value;
    }
  }, [value])

  useEffect(() => {
    if ([LENGTH_CHECK.OTP, LENGTH_CHECK.OTP_4].includes(otpValue.length)) {
      inputRef.current.blur();
    }
  }, [otpValue]);

  const handleOnChange = (event) => {
    let value = event.target.innerHTML || '';
    if ((value).toString().length === otpInputLength && Number(value)) {
      trackEventOnAutofill();
      handlePasteValue(value)
      return
    }
    if (value?.length > maxLength) {
      value = value.slice(-1);
      inputRef.current.innerHTML = value;
    }
    onChange(event, value)
  }

  return otpValidateSource === VALIDATE_SOURCE.SMART_TV ?
    <EditableContainer
      maxLength={maxLength}
      onChange={onChange}
      handlePasteValue={handlePasteValue}
      inputRef={inputRef}
      inputMode={inputTypeMode}
      value={value}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      {...rest} />
    : <input ref={inputRef}
      maxLength={maxLength}
      contentEditable="true"
      tabIndex={"0"}
      onBlur={onBlur}
      onInput={handleOnChange}
      onKeyDown={onKeyDown}
      value={value}
      autoComplete="one-time-code"
      inputMode={inputTypeMode}
      {...rest} />;
}

export default OtpInpuBox;

OtpInpuBox.propTypes = {
  focus: PropTypes.bool,
  autoFocus: PropTypes.bool,
  handlePasteValue: PropTypes.func,
  value: PropTypes.string,
  otpValue: PropTypes.string,
  maxLength: PropTypes.number,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
};
