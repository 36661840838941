import React, { useEffect, useRef, useState } from "react";

import mixPanelConfig from "@utils/mixpanel";
import MIXPANEL from "@constants/mixpanel";

import SuccessTick from "@assets/images/Success-tick.png"

const MAX_CHAR = 255; //Mixpanel accepting max limit
const FeedbackForm = ({
    showthumbsUpMsg
}) => {
    const [textData, setTextData] = useState("");
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [error, setError] = useState("")

    useEffect(() => {
        resetState();
    }, [showthumbsUpMsg]);

    const resetState = () => {
        setTextData("");
        setFormSubmitted(false);
    }

    const validate = (value) => {
        if (value.length > MAX_CHAR) {
            setError("Only 255 characters are allowed. Please shorten your comments.")
        } else {
            setError("");
        }
    }

    const handleWordCount = (event) => {
        let value = event.target.value;

        validate(value)
        setTextData(value);
    };

    const onSubmitFeedback = () => {
        let feedback = textData?.trim();
        if (feedback?.length) {
            let event = showthumbsUpMsg ? MIXPANEL.EVENT.HC_LIKE_FEEDBACK : MIXPANEL.EVENT.HC_DISLIKE_FEEDBACK;
            mixPanelConfig.trackEvent(event, {
                [`${MIXPANEL.PARAMETER.FEEDBACK_COMMENT}`]: feedback,
            });
            setFormSubmitted(true);
        }
    }

    return (formSubmitted
        ? <div class="feedback-success-card">
            <div class="card-body">
                <div class="icon-contr">
                    <img src={SuccessTick} alt="success-tick-image" />
                </div>
                <div class="text-contr">
                    <p class="bold">Thanks for your feedback</p>
                    <p>We'll definitely work on it.</p>
                </div>
            </div>
        </div>
        : <div class="hc-feedback-form">
            {showthumbsUpMsg ?
                <>
                    <p>Yay, thanks!</p>
                    <p>How can we help you better?</p>
                </>
                : <p>Oops...What didn't work?</p>
            }
            <textarea
                placeholder="Give us your suggestions"
                className="form-control"
                id="field"
                type="text"
                rows="5"
                cols="50"
                value={textData}
                onChange={(e) => handleWordCount(e)}
            ></textarea>
            <div>
                {error && <div className="error">{error}</div>}
            </div>
            <div className="bottom">
                <button
                    className="button"
                    onClick={() => onSubmitFeedback()}
                    disabled={!textData?.trim()?.length || error}
                >
                    Submit
                </button>
            </div>
        </div>
    )
}

export default FeedbackForm;