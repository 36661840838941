import React, { useEffect, useRef, useState, } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { LazyLoadImage } from "react-lazy-load-image-component";

import { URL } from '@utils/constants/routeConstants';
import searchIcon from "@assets/images/search-keyword.svg"
import { cloudinaryCarousalUrl, getFormattedContentTitle, getFormattedURLValue, getSuggestorSectionSource, safeNavigation, getPartnerData, getProviderLogoImage, getMixpanelSuggestorType, isMobile, cloudinaryCarousalUrlforGenre, isUserloggedIn, getUrlLength, getPiPageUrlContentType } from '@utils/common';
import mixPanelConfig from '@utils/mixpanel';
import MIXPANEL from "@constants/mixpanel";
import GenreItem from '@common/GenreItems';
import { LOCALSTORAGE, RAIL_TITLE, JOURNEY_INITIATED_SCREEN, SECTION_SOURCE } from "@utils/constants"
import { PROVIDER_NAME } from '@utils/constants/playerConstant';
import { gameClick } from '@common/ListingItem/listingItemCommon';
import { isLivePlayable } from "@containers/PIDetail/PIDetailCommon";
import { setKey } from '@utils/storage';
import { BROWSE_TYPE } from '@containers/BrowseByDetail/APIs/constants';

import { AUTO_SUGGESTION_SUGGESTOR, AUTO_SUGGESTION_SUGGESTOR_ARRAY } from '../APIs/constants';
import { showAutoSuggestionEnabledData } from '../APIs/actions';
import "./style.scss";


const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const AutoSuggestion = (props) => {

  const contentList = useSelector(state => state.headerDetails?.autoSuggestionData?.data?.contentList)
  const autoSuggestionSearchVal = useSelector(state => state.headerDetails?.isAutoSuggestionEnabled)
  const searchValue = autoSuggestionSearchVal?.searchValue.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
  const prevContentList = usePrevious({ contentList });
  let oldScrollY = 0;
  let minKeyboardHeight = 300;
  const [isKeyboardOpen, setIsKeyboardOpen] = useState(true);



  const controlDirection = () => {
    if (window.scrollY > 0 && oldScrollY < 1) {
      let keyBoardState = isKeyboardOpen ? MIXPANEL.VALUE.KEYBOARD_STATE_OPEN : MIXPANEL.VALUE.KEYBOARD_STATE_CLOSED
      mixPanelConfig.trackEvent(MIXPANEL.EVENT.AUTO_SUGGESTION_SCROLLED, {
        [MIXPANEL.PARAMETER.KEYWORD]: searchValue,
        [MIXPANEL.PARAMETER.SCROLL_DIRECTION]: MIXPANEL.VALUE.SCROLL_DOWN,
        [MIXPANEL.PARAMETER.KEYBOARD_STATE]: keyBoardState,
      });
      oldScrollY += 1
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', controlDirection);
    if (prevContentList?.contentList !== contentList) {
      isMobile.any() && window.scrollTo(0, 0)
      oldScrollY = 0;
    }
    const listener = async () => {
      const newState = window.screen.height - minKeyboardHeight > window.visualViewport.height
      if (isKeyboardOpen !== newState) {
        await setIsKeyboardOpen(newState);
      }
    };
    if (typeof visualViewport !== 'undefined') {
      window.visualViewport.addEventListener('resize', listener);
    }
    return () => {
      window.removeEventListener('scroll', controlDirection);
      if (typeof visualViewport !== 'undefined') {
        window.visualViewport.removeEventListener('resize', listener);
      }
    };
  }, [contentList, isKeyboardOpen]);

  const safeHTML = (item) => {
    return item?.replace(new RegExp(searchValue, 'gi'), '<span class = "highlight-text">$&</span>') // $& insert the matched string
  }

  const getHighlighted = (item) => { //highlight the search string
    return (
      <p key={item} className="title-wrapper"
        dangerouslySetInnerHTML={{
          __html: safeHTML(item)
        }} />
    )
  }


  const handleMixpanelEvent = (item, index) => {
    mixPanelConfig.trackEvent(MIXPANEL.EVENT.AUTO_SUGGESTION_CLICKED, {
      [MIXPANEL.PARAMETER.KEYWORD]: searchValue,
      [MIXPANEL.PARAMETER.CLICK_POSITION]: index + 1,
      [MIXPANEL.PARAMETER.TITLE]: item?.title || "",
      [MIXPANEL.PARAMETER.SUGGESTOR_TYPE]: getMixpanelSuggestorType(item?.suggestor) || "",
      [MIXPANEL.PARAMETER.CONTENT_ID]: item?.id || "",
    });
  }
  const getImageTag = (item) => {
    let providerFlag = item?.suggestor?.toLowerCase() === AUTO_SUGGESTION_SUGGESTOR.PROVIDER_SUGGESTOR
    let isLanguageSuggestor = item?.suggestor?.toLowerCase() === AUTO_SUGGESTION_SUGGESTOR.LANGUAGE_SUGGESTOR
    let isGenreSuggestor = item?.suggestor?.toLowerCase() === AUTO_SUGGESTION_SUGGESTOR.GENRE_SUGGESTOR

    let logoWidth = providerFlag ? "400" : isGenreSuggestor ? "200" : "428";
    let logoHeight = providerFlag ? "320" : isGenreSuggestor ? "200" : "248";

    let url = providerFlag ? getProviderLogoImage(item?.provider, true) : isLanguageSuggestor ? item?.backgroundImage : isGenreSuggestor ? item.newImage : item?.image;

    return (
      <div className={`image-wrapper ${providerFlag && 'provider-image'} ${isGenreSuggestor && 'genre-image'}`}>
        {isGenreSuggestor ? <GenreItem Img={`${isGenreSuggestor ? cloudinaryCarousalUrlforGenre() : cloudinaryCarousalUrl('', '', 140, 82)}${url}`} title={item?.title} /> :
          <React.Fragment>
            <LazyLoadImage
              className="content-image"
              src={`${cloudinaryCarousalUrl('', '', logoWidth, logoHeight)}${url}`}
              alt=""
              onError={(e) => {
                e.target.onerror = null;
                e.target.className = `${e.target.src === "" ? 'broken-image' : ""}`
              }}
            />
            {isLanguageSuggestor && <div className='language-symbol'>
              <img src={`${cloudinaryCarousalUrl('', '', "24", "24")}${item?.image}`}
                alt=""
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = "";
                  e.target.className = "broken-symbol";
                }} />
            </div>}
          </React.Fragment>}
      </div>
    )
  }

  const capitalizedFirstLetter = (item) => {
    const str = item?.toLowerCase();
    const str2 = str?.charAt(0)?.toUpperCase() + str?.slice(1);
    return str2
  }

  const history = useHistory();
  const dispatch = useDispatch();

  const redirectionOnClick = (item, index, e) => {
    handleMixpanelEvent(item, index);
    dispatch(showAutoSuggestionEnabledData({
      isAutoSuggestion: false,
      searchValue: ""
    }))
    const { location: { pathname } } = history
    let routeUrl = pathname?.split("/");
    let breadCrumbSource = pathname && routeUrl[1];
    breadCrumbSource = isEmpty(breadCrumbSource) ? URL.HOME : breadCrumbSource;
    let sourcePath = routeUrl.includes("breadCrumbSource")
    let pathValid = "";
    if (sourcePath === false) {
      pathValid = history.location;
    }

    let islanguageGenreSuggestor = [AUTO_SUGGESTION_SUGGESTOR.GENRE_SUGGESTOR, AUTO_SUGGESTION_SUGGESTOR.LANGUAGE_SUGGESTOR].includes(item?.suggestor?.toLowerCase()),
      isGenreSuggestor = AUTO_SUGGESTION_SUGGESTOR.GENRE_SUGGESTOR === item?.suggestor?.toLowerCase();

    let gameContentData = {
      sectionSource: "",
      sectionType: "",
      contentPosition: index,
      title: item?.title,
      railPosition: "",
      location: history?.location,
    }
    let source = MIXPANEL.VALUE.SEARCH_SUGGESTION;
    let suggestor = getSuggestorSectionSource(item?.suggestor?.toLowerCase());
    if (islanguageGenreSuggestor) {
      setKey(LOCALSTORAGE.DETAIL_PAGE_JOURNEY_SOURCE, JOURNEY_INITIATED_SCREEN.SEARCH_SUGGESTION);
      let browseType = suggestor === BROWSE_TYPE.LANGUAGE ? URL.BROWSE_BY_LANGUAGE : URL.BROWSE_BY_GENRE;
      let pathname = `/${browseType}/${getFormattedURLValue(item?.title)}`;
      safeNavigation(history, {
        pathname,
        state: {
          bannerImg: isGenreSuggestor ? item?.newBackgroundImage : item?.backgroundImage,
          bannerLogoImg: isGenreSuggestor ? item?.newImage : item?.image,
          breadCrumbSource: breadCrumbSource,
          title: item?.title,
          railTitle: item.title,
          source,
        },
      });
    } else if (item?.suggestor?.toLowerCase() === AUTO_SUGGESTION_SUGGESTOR.PROVIDER_SUGGESTOR) {
      setKey(LOCALSTORAGE.DETAIL_PAGE_JOURNEY_SOURCE, JOURNEY_INITIATED_SCREEN.SEARCH_SUGGESTION);
      getPartnerData(item)
      let pathname = `/${URL.PARTNER}/${getFormattedURLValue(item?.provider)}/${item?.partnerId}`
      safeNavigation(history, {
        pathname,
        state: { partnerName: item?.provider, pageType: item?.pageType, source, },
        search: `?breadCrumbSource=${breadCrumbSource}`,
        railTitle: RAIL_TITLE.SEARCH_SUGGESTIONS,
      })
    } else if (item?.suggestor?.toLowerCase() === AUTO_SUGGESTION_SUGGESTOR.TITILE_SUGGESTOR) {
      if (item?.provider?.toLowerCase() === PROVIDER_NAME.GAMEZOP) {
        gameClick(item, gameContentData)
      } else {
        let checkLivePlayability = isUserloggedIn() && ([SECTION_SOURCE.BINGE_CHANNEL, SECTION_SOURCE.BINGE_DARSHAN_LIVE].includes(item?.sectionSource)) && isLivePlayable(item?.id.toString()),
          cType = getPiPageUrlContentType(item?.contentType),
          urlLength = getUrlLength(`/${URL.DETAIL}/${cType}/${getFormattedURLValue(item?.title)}/${item?.id}`),
          pathForContentPi = `/${URL.DETAIL}/${cType}/${getFormattedContentTitle(item?.title, urlLength)}/${item?.id}`,
          pathname = checkLivePlayability ? `/${URL.PLAYER}/${item.contentType}/${item.id}` : pathForContentPi;

        setKey(LOCALSTORAGE.DETAIL_PAGE_JOURNEY_SOURCE, JOURNEY_INITIATED_SCREEN.SEARCH_SUGGESTION);

        safeNavigation(history, {
          pathname,
          state: {
            title: item?.title,
            setPathIs: pathValid,
            suggestorType: getMixpanelSuggestorType(item?.suggestor) || '',
            searchValue,
            railTitle: RAIL_TITLE.SEARCH_SUGGESTIONS,
            source,
            selectedContentSectionSource: item?.sectionSource,
            isLiveAutoPlay: checkLivePlayability,
            pathForContentPi,
            genre: item?.genre.toString(),
            language: item?.language.toString(),
            contentId: item?.id,
            provider: item?.provider,
            liveProvider: checkLivePlayability ? item?.provider : null,
            providerContentId: item?.providerContentId,
            //this is section source of selected content
          },
          search: `?breadCrumbSource=${cType}`,
          isLiveAutoPlay: checkLivePlayability,
        })
      }
    } else {
      props.searchChange(e, item?.title)
    }
  }

  const handleSlash = (data) => {
    return data && (` | ${data}`)
  }

  return (
    <li className='auto-Suggestion-container'>
      <div>
        {contentList?.map((contentData, index) => {
          let dataSuggestor = contentData?.suggestor?.toLowerCase(),
            isSpecilaSuggestor = [AUTO_SUGGESTION_SUGGESTOR.GENRE_SUGGESTOR, AUTO_SUGGESTION_SUGGESTOR.LANGUAGE_SUGGESTOR, AUTO_SUGGESTION_SUGGESTOR.PROVIDER_SUGGESTOR].includes(dataSuggestor),
            isTitleSuggestor = [AUTO_SUGGESTION_SUGGESTOR.TITILE_SUGGESTOR].includes(dataSuggestor),
            isdeafultTitleSuggestor = AUTO_SUGGESTION_SUGGESTOR_ARRAY.includes(dataSuggestor),
            isLiveOrDarshanContent = [SECTION_SOURCE.BINGE_CHANNEL, SECTION_SOURCE.BINGE_DARSHAN_LIVE].includes(contentData?.sectionSource);

          if (isTitleSuggestor || isSpecilaSuggestor || !isdeafultTitleSuggestor) {
            return (
              <>
                <div className='content-container' key={index} onClick={(e) => { redirectionOnClick(contentData, index, e) }}>
                  {getImageTag(contentData)}
                  <div className='content-wrapper' >
                    {getHighlighted(contentData.title)}
                    <div className='text-wrapper'>
                      {isTitleSuggestor && !isLiveOrDarshanContent ?
                        <p className='bottom-text'>
                          <>{contentData?.liveContent && <span className='live-tag'>Live | </span>}
                            {!isEmpty(contentData?.genre[0]) && capitalizedFirstLetter(contentData?.genre[0])}
                            {handleSlash(contentData?.suggestionContentType)}
                            {contentData?.releaseYear !== 0 && handleSlash(contentData?.releaseYear)}
                            {!isEmpty(contentData?.language[0]) && handleSlash(capitalizedFirstLetter(contentData?.language[0]))}
                          </>
                        </p> :
                        <>
                          {
                            isLiveOrDarshanContent ?
                              <p className='bottom-text'><span className='live-tag'>Live</span> | {contentData?.subText}</p> :
                              <p className='bottom-text'>{contentData?.subText}</p>
                          }
                        </>
                      }
                    </div>
                  </div>
                </div>
              </>)
          } else if (AUTO_SUGGESTION_SUGGESTOR.KEYWORD_SUGGESTOR.includes(dataSuggestor)) {
            return (
              <>
                <div className='content-container keyword' key={index} onClick={(e) => { redirectionOnClick(contentData, index, e) }}>
                  <img src={searchIcon} className="content-icon" />
                  <p>{getHighlighted(contentData.title)}</p>
                </div>
              </>)
          }
        })}
      </div>
    </li >

  )
}
AutoSuggestion.propTypes = {
  searchChange: PropTypes.func,
}

export default AutoSuggestion