import React, { Component } from "react";
import { bindActionCreators, compose } from "redux";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import get from "lodash/get";
import isUndefined from "lodash/isUndefined";

import mixPanelConfig from "@utils/mixpanel";
import MIXPANEL from "@constants/mixpanel";
import moengageConfig from "@utils/moengage";
import MOENGAGE from "@constants/moengage";
import { isUserloggedIn, isMobile, handleOverflowOnHtml, showToast, getDeviceId, isIspEnabledPlan } from "@utils/common";
import { getKey, setKey } from "@utils/storage";
import { LOCALSTORAGE, DTH_TYPE } from "@constants";
import { hideMainLoader, showMainLoader, showMainLoaderImmediate } from "@src/action";
import { getHCChatBotType } from "@containers/HelpCenter/utils/index.js";

import femaleUser from "@assets/images/chatbot.png";
import chatBotIcon from "@assets/images/chat_icon1.png";

import { fetchChatNowUrlApiResp, triggerChatFlag } from "../../APIs/action";
import { CHATBOT_TYPE, ERROR_MSG, TRIGGER_CHAT_LOC_CONST } from "@containers/HelpCenter/APIs/constants";
import FeedbackForm from "./feedback";

import "./style.scss";

class HelpfulTracker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      thumbsUp: false,
      thumbsDown: false,
    };
  }

  componentDidUpdate(prevProps) {
    let { location } = this.props
    if (prevProps.location !== this.props.location) {
      if (location?.state?.prevPath !== location?.pathname) {
        this.setState({ thumbsUp: false, thumbsDown: false })
      }
    }
    if (prevProps.triggerChatFlagVal !== this.props.triggerChatFlagVal && this.props.triggerChatFlagVal && this.props.triggerChatLocation === TRIGGER_CHAT_LOC_CONST.PRO_ACTIVE) {
      this.handleProActiveChatReq();
    }
  }

  handleProActiveChatReq = (e) => {
    this.chatNowClick(e, true);
    // setKey(LOCALSTORAGE.PRO_ACTIVE_CHAT_TRIGGER_COUNT, true)
    window.sessionStorage.setItem(LOCALSTORAGE.PRO_ACTIVE_CHAT_TRIGGER_COUNT, true)
  };

  handleThumbsClick = (thumbsUp = false) => {
    this.setState(
      {
        thumbsDown: !thumbsUp,
        thumbsUp: thumbsUp,
      },
      () => {
        this.trackEvents(
          thumbsUp ? MIXPANEL.EVENT.HC_LIKE : MIXPANEL.EVENT.HC_DISLIKE
        );
      }
    );
  };

  chatNowClick = async (event, isFromProActiveChat = false) => {
    event && event.preventDefault();
    let trackEventsParam = "";

    let { location, isSubTypePlacement, fetchChatNowUrlApiResp, showMainLoaderImmediate, configResponse, hideMainLoader, onChatbotClick, currentSubscription } = this.props,
      queryObj = {},
      userInfo = JSON.parse(getKey(LOCALSTORAGE.USER_INFO)) || {},
      chatbotType = getHCChatBotType();
    queryObj = {
      subId: userInfo?.bingeSubscriberId,
      name: userInfo?.firstName,
      deviceType: isMobile.any() ? MIXPANEL.VALUE.MOBILE : MIXPANEL.VALUE.WEB
    };

    if (isSubTypePlacement && !isFromProActiveChat) {
      let { category, subCategory } = this.props.trackDetails;
      queryObj = { ...queryObj, category, subCat: subCategory };

      if (location.pathname.includes("video-detail")) {
        trackEventsParam = MIXPANEL.EVENT.HC_CHAT_VIDEO;
      } else {
        trackEventsParam = MIXPANEL.EVENT.HC_CHAT_FAQ;
      }
    } else {
      trackEventsParam = MIXPANEL.EVENT.HC_CHAT_DEFAULT;
    };

    this.trackEvents(trackEventsParam);
    showMainLoaderImmediate();
    if (chatbotType === CHATBOT_TYPE.S360) {
      await fetchChatNowUrlApiResp(chatbotType);
      let encryptedSid = this.props.helpCenterChatNowResp?.data;
      let chatConfig = {
        subScriberId: encryptedSid,
        Authenticated: true,
        Action: 5,
        hideLoading: true,
        launchOnButton: true
      }
      Simplify360Chat?.init(chatConfig);
      let isS360ChatabotOpen = Simplify360Chat?.isChatIFrameOpen();
      isS360ChatabotOpen && isMobile.any() && handleOverflowOnHtml();
      this.props.triggerChatFlag({ location: TRIGGER_CHAT_LOC_CONST.BTN_CLK, value: true });
    }
    else {
      if (userInfo?.bingeSubscriberId && userInfo?.bingeSubscriberId !== undefined) {
        onChatbotClick && onChatbotClick();
        setTimeout(async () => {
          queryObj = { ...queryObj, dthStatus: userInfo?.dthStatus, mobileNumber: userInfo?.rmn };
          let isFibreSegment = currentSubscription?.segment === "TPF" || userInfo?.segment === "TPF";
          if (isIspEnabledPlan() && isFibreSegment) {
            queryObj = { ...queryObj, segment: "TPF" };
          };
          let queryParamString = new URLSearchParams(queryObj).toString();
          await fetchChatNowUrlApiResp(chatbotType, queryParamString);
          
          window.location.href = `${this.props.helpCenterChatNowResp?.data}`;
        }, 4000);
      }
      else {
        let toastMsg = get(configResponse, 'data.config.helpCenterInfo.chatBotErrorText', ERROR_MSG.CHATBOT_ERROR),
          deviceID = getDeviceId(),
          eventParams = {
            "BINGE-SUB-ID": userInfo?.bingeSubscriberId,
            "BA-ID": userInfo?.baId,
            "SID": userInfo?.sId,
            "RMN": userInfo?.rmn,
            "DEVICE-ID": deviceID
          };
        mixPanelConfig.trackEvent(MIXPANEL.EVENT.HC_CHAT_FAIL, eventParams);
        hideMainLoader();
        showToast(toastMsg);
      }
    }
  };


  analyticsEvent = (eventName, params) => {
    mixPanelConfig.trackEvent(eventName, params);
    // moengageConfig.trackEvent(eventName, params);
  };

  trackEvents = (eventName) => {
    if (this.props.trackDetails) {
      let { id, category, subCategory, title } = this.props.trackDetails;
      let { location } = this.props;
      let commonParam = { "CATEGORY": category, "SUB-CATEGORY": subCategory };
      let userInfo = JSON.parse(getKey(LOCALSTORAGE.USER_INFO)) || {};


      if ((eventName === MIXPANEL.EVENT.HC_LIKE || eventName === MIXPANEL.EVENT.HC_DISLIKE) && location.pathname.includes("video-detail")) {
        this.analyticsEvent(eventName, { "VIDEO-ID": id, ...commonParam });
      } else if (eventName === MIXPANEL.EVENT.HC_LIKE || eventName === MIXPANEL.EVENT.HC_DISLIKE) {
        this.analyticsEvent(eventName, { "FAQ-ID": id, "SID": userInfo?.sId || "", "RMN": userInfo?.rmn, ...commonParam, "TITLE": title });
      } else if (eventName === MIXPANEL.EVENT.HC_CHAT_FAQ) {
        this.analyticsEvent(eventName, { "FAQ-ID": id, "TITLE": title });
      } else if (eventName === MIXPANEL.EVENT.HC_CHAT_VIDEO) {
        this.analyticsEvent(eventName, { "VIDEO-ID": id });
      }
    } else if (eventName === MIXPANEL.EVENT.HC_CHAT_DEFAULT) {
      mixPanelConfig.trackEvent(MIXPANEL.EVENT.HC_CHAT_DEFAULT);
      moengageConfig.trackEvent(MOENGAGE.EVENT.HC_CHAT_DEFAULT);
    }
  };

  render() {
    let { thumbsUp, thumbsDown } = this.state;
    let { isSubTypePlacement, showFeedbackForm } = this.props;
    const login = isUserloggedIn();
    return (
      <React.Fragment>
        {(isSubTypePlacement || login) && (
          <div className={`helpful-tracker-container sticky-chatnow ${(!isSubTypePlacement) ? "container" : ""}`}>
            <div className={`${(isSubTypePlacement) ? "resolve-contr " : "more-help-contr"}`}>
              {/* result resolve start */}
              {isSubTypePlacement && (
                <div className="issue-resolve-wrapper">
                  <span className="label-text">Was this helpful?</span>
                  <ul className="helpful">
                    <li
                      className={thumbsUp ? `active` : ""}
                      onClick={() => this.handleThumbsClick(true)}
                    >
                      <div className="thumb-up">&nbsp;</div>
                    </li>
                    <li
                      className={thumbsDown ? "active" : ""}
                      onClick={() => this.handleThumbsClick(false)}
                    >
                      <div className="thumb-down">&nbsp;</div>
                    </li>
                  </ul>
                </div>
              )}
              {/* result resolve end */}
              {thumbsUp && (
                showFeedbackForm ? <FeedbackForm showthumbsUpMsg={thumbsUp} />
                  : <div className="solve">
                    Great! We&apos;re happy we could help you.
                  </div>
              )}
              {(thumbsDown && isSubTypePlacement) && (
                <>
                  {login && <div className="solve">
                    <div className="solve-down">
                      <div className="topic">
                        <div className="icon-contr">
                          <img src={femaleUser} />
                          <span className="active" />
                        </div>
                        <div className="text-contr">
                          <div
                            // className={`text ${
                            //   isSubTypePlacement ? "text-ellipsis" : ""
                            // }`}
                            className={`text`}
                          >
                            {isSubTypePlacement
                              ? "Lets chat and resolve this issue"
                              : "Do you need more help to resolve your query?"}
                          </div>
                          {login && (
                            <div className="cta-contr">
                              <a
                                href="#!"
                                className="btn btn-tertiary btn-sm waves-effect waves-light w-auto"
                                onClick={this.chatNowClick}
                              >
                                Chat Now
                              </a>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>}
                  {showFeedbackForm && <FeedbackForm showthumbsUpMsg={thumbsUp} />}
                </>
              )}
              {(login && !isSubTypePlacement) && (
                <div className="chatboticon">
                  <img onClick={this.chatNowClick} src={chatBotIcon} />
                </div>
              )
              }
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    helpCenterChatNowResp: get(state.helpCenterReducer, "helpCenterChatNowResp"),
    triggerChatFlagVal: get(state.helpCenterReducer, "triggerChatFlagVal"),
    triggerChatLocation: get(state.helpCenterReducer, "triggerChatLoc"),
    configResponse: get(state.headerDetails, 'configResponse'),
    currentSubscription: get(state.subscriptionDetails, "currentSubscription.data")
  };
};

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(
      {
        fetchChatNowUrlApiResp,
        hideMainLoader,
        showMainLoader,
        showMainLoaderImmediate,
        triggerChatFlag,
      },
      dispatch
    ),
  };
}

HelpfulTracker.propTypes = {
  isSubTypePlacement: PropTypes.bool,
  trackDetails: PropTypes.object,
  location: PropTypes.object,
  fetchChatNowUrlApiResp: PropTypes.func,
  helpCenterChatNowResp: PropTypes.object,
  hideMainLoader: PropTypes.func,
  showMainLoader: PropTypes.func,
  configResponse: PropTypes.object,
  triggerChatFlagVal: PropTypes.bool,
  triggerChatFlag: PropTypes.func,
  triggerChatLocation: PropTypes.string,
};

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(HelpfulTracker);
