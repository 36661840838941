import React, { useCallback, useMemo, memo, Fragment } from 'react'
import { Helmet } from 'react-helmet'
import { isObject } from 'lodash'


function BreadCrumbSchema({breadCrumb}) {

  const findItem = useCallback((path) => {
    if (path == 'id') {
      return {}
    } else if (isObject(path)) {
      return { "item": `${window.location.origin}${path?.pathname?.split('?')?.[0]}` }
    } else {
      return { "item": `${window.location.origin}${path?.split('?')?.[0]}` }
    }
  },[])

  const listItemSchema = useMemo(() => {
        const schema = breadCrumb.map((data, index) => ({
            "@type": "ListItem",
            "position": index + 2,
            "name": data?.props?.link,
             ...findItem(data?.props?.path)
        }));
        schema.unshift({
            "@type": "ListItem",
            "position": 1,
            "name": "Home",
            "item": `${window.location.origin}`
        });
        return schema;
  }, [breadCrumb]);

  return (
    <Fragment>
    {breadCrumb?.length > 0 ?
      <Helmet>
          <script type="application/ld+json">
          {`
          {
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement": ${JSON.stringify(listItemSchema, null, 2)}
          }
        `}
          </script>
          </Helmet>
          : null
        }
        </Fragment>
  )
}

export default memo(BreadCrumbSchema)