export const ACTION = {
  GET_OTP_WITH_RNM: "GET_OTP_WITH_RNM",
  VALIDATE_OTP: "VALIDATE_OTP",
  SUBSCRIBER_LIST: 'SUBSCRIBER_LIST',
  CREATE_BINGE_USER: 'CREATE_NEW_USER',
  UPDATE_BINGE_USER: 'UPDATE_BINGE_USER',
  OPEN_LOGIN_POPUP: 'OPEN_LOGIN_POPUP',
  CLOSE_LOGIN_POPUP: 'CLOSE_LOGIN_POPUP',
  LOGIN_STEP_NUMBER: 'LOGIN_STEP_NUMBER',
  RESET_LOGIN_STATE: 'RESET_LOGIN_STATE',
  VALIDATE_SSO_TOKEN: 'VALIDATE_SSO_TOKEN',
  GET_CLIENT_IP: 'GET_CLIENT_IP',
  MANUAL_LOGIN_CHECK: "MANUAL_LOGIN_CHECK",
  SILENT_LOGIN_FAILED: 'SILENT_LOGIN_FAILED',
  SET_LOGIN_SOURCE: "SET_LOGIN_SOURCE",
  FORCE_LOGOUT: "FORCE_LOGOUT",
  SET_IS_LOGIN_POPUP_VISIBLE: "SET_IS_LOGIN_POPUP_VISIBLE",
  VALIDATE_OFS_SMART_TV_CODE: 'VALIDATE_OFS_SMART_TV_CODE',
  IS_ZERO_APP_LOGIN: 'IS_ZERO_APP_LOGIN'
}

export const SUBSCRIBED_MESSAGE = {
  ALREADY_SUBSCRIBED: 'Already Subscribed',
  SUCCESSFULLY_LOGGED_IN: 'Successfully logged in'
}

