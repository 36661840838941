const apiVersion = 'v1';
const apiVersion2 = 'v2';
const apiVersion3 = 'v3';
const apiVersion4 = 'v4';

module.exports = {
  API_BASE_URL: "https://uat-tb.tapi.videoready.tv/",
  HC_BASE_URL: 'https://uat-tb.tapi.videoready.tv/',
  // NEW_BASE_URL: 'https://uat-binge-api.videoready.tv/',
  SUBSCRIBER_URL: "user-service/pub/api/v1/rmn/",
  REGISTRATION_URL: "user-service/pub/api/v1/user/registration",
  REGISTRATION_OTP_URL: "user-service/pub/api/v1/registration/validate/otp",
  HEADER_MENU_URL: `homescreen-client/pub/api/${apiVersion3}/pages/BINGE_ANYWHERE`,
  HOME_MENU_URL: "homescreen-client/pub/api/v1/page/",
  HOME_MENU_URL_VR: `homescreen-client/pub/api/${apiVersion2}/hierarchy/`,
  HOME_MENU_URL_TA: `ta-recommendation/api/${apiVersion}/binge/hierarchy/`,
  HOME_MENU_URL_TA_GUEST: `ta-recommendation/api/${apiVersion}/binge/guest/hierarchy/`,
  RAIL_CONTENT_URL: `homescreen-client/pub/api/${apiVersion3}/rail?id=`,
  PI_DETAIL_URL: "content-subscriber-detail/api/content/info/",
  PI_RECOMMENDED_URL: "search-connector/binge/recommendations/",
  SEE_ALL_URL: "homescreen-client/pub/api/v4/rail/seeAll?id=", // 'homescreen-client/pub/api/v2/rail?id=',
  VOOT_URL: "binge-mobile-services/voot/playback",
  VOOT_SELECT_URL: "voot-select-playback-api/voot/v1/playback",
  VOOT_KIDS_URL: "voot-kids-playback-api/s/partner/playback",
  SEARCH_URL: "search-connector/binge/anywhere/search?queryString=",
  SEARCH_FILTER_URL: "portal-search/pub/api/v1/channels/filter",
  SEASON_URL: "content-subscriber-detail/api/series/list/",
  WATCHLIST_URL: "action-data-provider/subscriber/favourite",
  CHECK_WATCHLIST_URL: "action-data-provider/content/lookup?profileId=",
  PREV_NEXT_EPISODE_URL:
    "content-subscriber-detail/api/content/episode/info?subscriberId=",
  OTP_URL: "binge-mobile-services/pub/api/v2/auth/rmn/",
  VALIDATE_OTP_URL: "binge-mobile-services/pub/api/v2/auth/validate/otp",
  VALIDATE_OTP_SID_URL:
    "binge-mobile-services/pub/api/v2/auth/subscriber/validate/otp",
  VALIDATE_PASSWORD_URL: "binge-mobile-services/pub/api/v2/auth/validate/pwd",
  SID_OTP_URL: "binge-mobile-services/pub/api/v3/auth/subscriber/",
  FORGET_PASSWORD_URL: "binge-mobile-services/pub/api/v2/subscribers/forgot/",
  CHANGE_PASSWORD_WITHOUT_AUTH_URL: "binge-mobile-services/pub/api/v2/subscribers/",
  PACK_LISTING_URL: `/subscription/packs/`,
  GET_BALANCE: "binge-mobile-services/api/v3/accountInfo/getBalance",
  CREATE_SUBSCRIPTION: "binge-mobile-services/api/v2/binge/mobile/subscription",
  // CANCEL_SUBSCRIPTION: 'binge-mobile-services/api/v2/binge/subscription/cancel',
  RESUME_SUBSCRIPTION: "binge-mobile-services/api/v2/revoke/subscription/",
  CURRENT_SUBSCRIPTION: `/subscription/current`,
  // MODIFY_SUBSCRIPTION: 'binge-mobile-services/api/v2/binge/modify/subscription',
  REACTIVATE_SUBSCRIPTION:
    "binge-mobile-services/api/v2/binge/reactivate/subscription",
  ACCOUNT_DETAILS_URL:
    "binge-mobile-services/api/v2/subscriber/account/details",
  ACCOUNT_DETAILS_WEB_LARGE_URL:
    "binge-mobile-services/api/v2/migration/account/details",
  QUICK_RECHARGE: "binge-mobile-services/api/v2/subscribers/",
  CREATE_USER: "binge-mobile-services/api/v2/create/user/new/journey",
  LOGIN_ACCOUNT: "binge-mobile-services/api/v2/login/user",
  LOGOUT_API: "binge-mobile-services/api/v2/logout/",
  LOGOUT_ALL_USERS_API: "binge-mobile-services/pub/api/v2/logout/users/",
  TVOD_URL: "content-detail/api/v1/monetization/tvod/subscriber/list/",
  RRM_SESSION: "binge-mobile-services/api/v1/subscribers",
  TA_RECOMMENDATION: "ta-recommendation/api/v1/binge/recommend/",
  TA_GUEST_RECOMMENDATION: "ta-recommendation/api/v1/binge/guest/recommend/",
  TA_LA_RECOMMENDATION: "ta-recommendation/api/v1/binge/learn/",
  CREATE_WO: "binge-mobile-services/api/v2/create/wo",
  ZEE5_URL: "zee5-playback-api/v2/tag/fetch",
  PAYMENT_URL: "binge-mobile-services/pub/api/v2/payment",
  CONFIG_URL: "binge-mobile-config/pub/v1/api/config/binge/mobile",
  TOKEN_URL: "auth-service/v1/oauth/token-service/token",
  VIEW_COUNT: "la-proxy-app/ts/composer/learnAction",
  UPGRADE_TRANSITION_DETAILS: "binge-mobile-services/api/v2/subscriber/upgrade/transition/details",
  ENV_URL: "https://uat.tataplaybinge.com/",
  // ENV_CALLBACK_URL: "https://uat-binge-neu.videoready.tv",
  CLOUDINARY_URL: "https://res.cloudinary.com/tatasky/image/fetch/",
  MIXPANEL: {
    KEY: "9b3d83bf6e332e5bf15e6491bd23644e",
    DEBUG: true,
  },
  GOOGLE_CONVERSION: {
    KEY: "AW-10806098977",
    EVENT: {
      LOGIN_GET_OTP: "zNf0CPCbo84DEKH436Ao",
      LOGIN_SUCCESS: "c-AwCNXuos4DEKH436Ao",
      PACK_SELECTION_INITIATE: "bf5DCNjuos4DEKH436Ao",
      PAYMENT_INITATION: "Yc_vCIjvos4DEKH436Ao",
      SUBSCRIPTION_SUCCESS: "Ku7fCOihtMoDEKH436Ao",
      SUBSCRIBE_SUCCESS_NEW: 'XA2KCPSBmp8YEKH436Ao',
    },
  },
  PUBNUB: {
    PUBLISH_KEY: "pub-c-3a5f1ed7-9f13-4b1a-a0b7-985378992199",
    SUBSCRIBE_KEY: "sub-c-2e37b748-8d74-11ec-b19d-ca42df2d7d16",
  },
  GTM_ID: "GTM-KGNBPFV",
  FAIRPLAY_CERTIFICATE_URL:
    "https://tatasky.stage.ott.irdeto.com/Streaming/getCertificate",

  //FREEMIUM API URLS

  SUBSCRIBER_LIST: `binge-mobile-services/api/${apiVersion4}/subscriber/details`,
  GENERATE_ANONYMOUS_ID: `binge-mobile-services/pub/api/${apiVersion}/user/guest/register`,
  CHECK_PLAYBACK_ELIGIBILITY: `binge-mobile-services/pub/api/${apiVersion}/user/guest/playback/check-eligibility`,
  GET_PHONE_NUMBERS_FOR_DEVICE: `binge-mobile-services/pub/api/${apiVersion}/user/login/rmn`,
  GENERATE_OTP_RMN: `api/${apiVersion}/user/authentication/generateOTP`,
  VERIFY_OTP_RMN: `api/${apiVersion}/user/authentication/validateOTP`,
  SAVE_PARENTAL_LOCK_URL: `binge-mobile-services/api/${apiVersion}/save/parental/pin`,
  CREATE_NEW_USER: `binge-mobile-services/api/${apiVersion3}/create/new/user`,
  UPDATE_BINGE_USER: `binge-mobile-services/api/${apiVersion3}/update/exist/user`,
  SAVE_PREFERRED_LANGUAGES_GUEST_USER: `binge-mobile-services/pub/api/${apiVersion}/user/login/preferredLanguage`,
  SAVE_PREFERRED_LANGUAGES_LOGGEDIN_USER: `binge-mobile-services/api/${apiVersion}/loggedIn/user/preferredLanguage`,
  GET_USER_PREFERRED_LANGUAGES: `binge-mobile-services/api/${apiVersion}/user/profile`,
  GET_GENRE_LANGUAGE: `homescreen-client/pub/api/${apiVersion2}/search/landing`,
  SEARCH_CONTENT: `search-connector/freemium/search/results`,
  GET_TENURE_BAL: `binge-mobile-services/api/${apiVersion}/account/prorated/balance`,
  ADD_SUBSCRIPTION: `binge-mobile-services/api/${apiVersion}/subscription/add/pack`,
  MODIFY_SUBSCRIPTION: `binge-mobile-services/api/${apiVersion}/subscription/modify/pack`,
  CANCEL_SUBSCRIPTION: `binge-mobile-services/api/${apiVersion}/subscription/cancel`,
  FETCH_PROFILE_DETAILS: `binge-mobile-services/api/${apiVersion3}/subscriber/fetch/profile`,
  UPDATE_PROFILE_NON_DTH_USER_AND_BINGE_NEW_STACK: `binge-mobile-services/api/${apiVersion3}/subscriber/update/email/name`,
  UPDATE_PROFILE_IMG: `binge-mobile-services/api/${apiVersion3}/subscribers`,
  TRENDING_TITLES: `search-connector/freemium/search/results`,
  ACCOUNT_REFRESH: `binge-mobile-services/api/${apiVersion}/accountRefresh`,
  ACCOUNT_REFRESH_OLD_STACK: `binge-mobile-services/api/${apiVersion}/refresh`,
  GET_CLIENT_IP: "https://api.ipify.org?format=json",
  TRANSACTION_INVOICE_DOWNLOAD: `binge-mobile-services/api/${apiVersion}/subscription/invoice/download`,
  TA_LA_RECOMMENDATION_GUEST: `ta-recommendation/api/${apiVersion}/binge/guest/learn/`,
  AUTO_SUGGESTION: `search-connector/pub/freemium/search/autocomplete?queryString=`,
  MIGRATE_USER_INFO: `binge-mobile-services/pub/api/${apiVersion}/subscription/migrate/user/info`,
  MIGRATE_USER_INFO_PVT: 'binge-mobile-services/api/v2/subscription/migrate/user/info',
  LIVE_DETAIL: `content-detail/pub/api/v6/channels`,
  DIGITAL_FEED: `digital-feed-services/api/partner/player/details`,
  MEDIA_READY_POLLING_API: `job-configurations/pub/${apiVersion}/api/fetch/mediaReadyConfig`,
  FETCH_GLOBAL_CONFIG: "binge-mobile-services/api/v1/global/config",

  //Continue watching APIS
  CONTINUE_WATCHING: `action-listener/api/watching`,
  GUEST_CONTINUE_WATCHING: `action-listener/pub/api/guest/watching`,
  CONTINUE_WATCHING_HOME_URL: 'action-data-provider/recently/watched',
  GUEST_CONTINUE_WATCHING_HOME_URL: 'action-data-provider/pub/guest/recently/watched',
  CONTINUE_WATCHING_HISTORY: 'action-data-provider/episode/listing/histroy',
  GUEST_CONTINUE_WATCHING_HISTORY: 'action-data-provider/pub/guest/episode/listing/history',
  CONTINUE_WATCHING_LAST_WATCH: `action-data-provider/api/${apiVersion}/last-watch`,
  GUEST_CONTINUE_WATCHING_LAST_WATCH: 'action-data-provider/pub/guest/last-watch',
  GET_SONY_TOKEN: `zee5-playback-api/sony/fetch/token`,
  GET_HOI_CHOI_TOKEN: `binge-service/api/${apiVersion}/akamai/getToken?partner=hoichoi`,
  GET_PALNET_MARATHI_URL: `binge-mobile-services/api/${apiVersion}/pm/play/url`,
  GET_CHAUPAL_ENDPOINT: `binge-mobile-services/api/${apiVersion}/content/playback/`,
  GET_LIONSGATE_URL: `zee5-playback-api/lionsgate-api/fetch-playback-api/token`,
  PLANET_MARATHI_ANALYTICS: `partner-content-analytics/pm/media/analytics`,
  VOOT_TOKEN_URL: 'voot-select-playback-api/voot/freemium/fetch/token',
  GET_LIONSGATE_ANALYTICS: `partner-content-analytics/lionsgate-api/analytic/event-data`,
  GET_GENERIC_DRM_ENDPOINT: `zee5-playback-api/generic-playback-Info-api/token`,
  GAMEZOP_WATCHLIST_URL: 'action-data-provider/gamezop/subscriber/favourite',
  APPLE_REDEMPTION_DEEPLINK_URL: `binge-mobile-services/api/${apiVersion}/appletv/activate`,
  PARTNER_CW_API: `action-listener/api/${apiVersion2}/watch/partnerEvent`,
  POST_PUBNUB_SYNC_BE_AND_LS: 'binge-mobile-services/api/v1/pubnub/sync',

  //Help center APIs
  HC_VERSION_URL: "/api/v3/help-center",
  GET_MIXPANEL_ID: "binge-mobile-services/pub/api/mixpanel/unique-id/fetch",
  MX_SCRIPT: "https://playersdk.mxplay.com/static/web/beta/v1/mxplayer-sdk.js",
  MX_IDENTIFIER: "tataplay_identifier",
  CHATBOT_URL: {
    script: "d2yjce5oayglmo.cloudfront.net",
    link: "d2w1s20s54h788.cloudfront.net",
  },

  FB_ID: "113443927341316",
  APPSFLYER_WEB_ID: "65234de3-dc33-4a17-b6a8-3520af784d8b",
  FIREBASE_CONFIG: {
    apiKey: "AIzaSyC7jrTD7M4GdZREUY4NCeIxutVzYwhEbws",
    authDomain: "b-anywhere.firebaseapp.com",
    databaseURL: "https://b-anywhere.firebaseio.com",
    projectId: "b-anywhere",
    storageBucket: "b-anywhere.appspot.com",
    messagingSenderId: "954420610390",
    appId: "1:954420610390:web:c5ad73dfe51c62851d42f7",
    measurementId: "G-1W6H1HZ1SX",
  },
  SOURCE_APP: "",
  BINGE_TRIAL_STATUS: `binge-mobile-services/api/${apiVersion3}/user/status`,
  GET_WEB_PORTAL_LINK_NON_LOGGED_IN: `binge-mobile-services/pub/api/${apiVersion2}/account/access/info`,
  GET_WEB_PORTAL_LINK_LOGGED_IN: `binge-mobile-services/api/${apiVersion2}/account/access/info`,
  APP_VERSION: '1.0.0',
  LIVE_CONTENT_PLAY_BACK_URL: `content-detail/api/partner/cdn/player/details`,
  GET_MY_PLAN_VERBIAGES: `binge-mobile-services/api/${apiVersion}/verbiages/details`,
  STATIC_PAGE: `homescreen-client/pub/api/${apiVersion}/staticPage`,
  FOOTER: `homescreen-client/pub/api/${apiVersion}/fetchFooterByIds`,
  FAQ: `search-connector/pub/freemium/help/center/fetchFaqByIds`,
  APP_LAUNCH_COUNTER: `binge-mobile-services/api/v1/applaunch/counter`,
  PRIME_ENTITLEMENT_DETAILS: 'binge-mobile-services/api/v1/prime/entitlement/apv-status',
  PRIME_WATCH_EXISTING_SUBSCRIPTION_FLAG: 'binge-mobile-services/api/v1/prime/entitlement/prime-nudge',
  PRIME_APPLE_STATUS: `binge-mobile-services/api/${apiVersion2}/entitlement/status`,
  ACCOUNT_RECOVERY_REQUEST: `binge-mobile-services/api/${apiVersion}/prime/account-recovery`,
  FETCH_RECOVERY_URL: `binge-mobile-services/api/${apiVersion}/prime/fetch-recovery`,

  EVENT_ROVER_BASE_URL: "https://uat-event-rover.videoready.tv/",
  EVENT_ROVER_HOST_URL: "https://uat-subscriber-event-rover.videoready.tv/",
  EVENT_ROVER_CAI: "7s7oNdqc4kLPDmtaE5q8",
};