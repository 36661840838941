import React, { Component } from "react";
import { bindActionCreators, compose } from "redux";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import { toast } from "react-toastify";

import Buttons from "@common/Buttons";
import { closePopup, openPopup } from "@common/Modal/action";
import { CATEGORY_NAME, LOCALSTORAGE, MOBILE_BREAKPOINT } from "@constants";
import { getKey, setKey } from "@utils/storage";
import { fetchAnonymousId } from "@components/Header/APIs/actions";
import {
    getUserSelectedLanguages,
    isMobile,
    isUserloggedIn,
    safeNavigation,
    showToast,
    getVerbiages,
    cloudinaryCarousalUrl,
} from "@utils/common";
import { URL } from "@constants/routeConstants";
import mixPanelConfig from "@utils/mixpanel";
import MIXPANEL from "@constants/mixpanel";
import dataLayerConfig from "@utils/dataLayer";
import DATALAYER from "@utils/constants/dataLayer";
import RadioButton from "@common/RadioButton";

import SuccessTick from "@assets/images/Success-tick.png";
import ErrorIcon from "@assets/images/alert-icon.svg";
import crossIcon from '@assets/images/cross.png';

import { closeMobilePopup, fetchUserSelectedData, getLanguageListing, savePreferredLanguages } from "../APIs/actions";
import "./style.scss";


const SelectLanguageToast = ({ customToastMsg = "", successToast = false, successToastMessage, failureToastMessage }) => {
    let icon = successToast ? SuccessTick : ErrorIcon,
        message = successToast
            ? successToastMessage
            : failureToastMessage;
    return (
        <div className="toast-body-container">
            <div className="toast-success-image">
                <img src={icon} alt="" />
            </div>
            <div className="toast-message-text">
                {customToastMsg ? customToastMsg : message}
            </div>
        </div>
    );
};

class SelectLanguage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            languageSelected: [],
            successToastMessage: "",
            failureToastMessage: "",
            buttonTitle: "Select content language"
        };
    }

    componentDidMount = async () => {
        this.onSelectLanguageLoad();
    }

    onSelectLanguageLoad = async () => {
        let id = getKey(LOCALSTORAGE.ANONYMOUS_ID);
        let { getLanguageListing, isLanguageSelectFromSettings } = this.props;
        await getLanguageListing(id);
        this.getLanguageVerbiages();
        if (isLanguageSelectFromSettings) {
            let selectedLanguages = await getUserSelectedLanguages();
            this.getSelectedLanguage(selectedLanguages);
        }
        this.trackMixpanel();
    }

    trackMixpanel = () => {
        let source = (this.props.isLanguageSelectFromSettings ? MIXPANEL.VALUE.HAMBURGER : MIXPANEL.VALUE.NUDGE);
        mixPanelConfig.trackEvent(MIXPANEL.EVENT.CONTENT_LANG_OPEN, { [MIXPANEL.PARAMETER.SOURCE]: source });
    }

    check = (id) => {
        const { languageSelected } = this.state;
        return languageSelected.includes(id);
    };

    handleLanguageSelect = async (id, e) => {
        e.persist();
        const { isLanguageSelectFromMobile } = this.props;
        await this.languageHandle(id);
    };

    languageHandle = (id) => {
        const { languageSelected } = this.state;
        const { maxLanguageAllowed, maxLanguageErrorMessage } = this.props.languageData.data;
        if (languageSelected.some((d) => d === id)) {
            let data = languageSelected.filter((d) => d !== id);
            this.setState({
                languageSelected: data,
            });

        } else {
            if (this.state.languageSelected.length >= maxLanguageAllowed) {
                return this.showLangToast(maxLanguageErrorMessage);
            }
            this.setState({
                languageSelected: [...this.state.languageSelected, id]
            });
        }
    }

    mixpanelLanguage = (language) => {
        const selectedLanguage = {};
        language && language.forEach((data, key) => {
            selectedLanguage[`${MIXPANEL.VALUE.LANGUAGE}${key + 1}`] = data.name;
        })
        return selectedLanguage
    }

    getSelectedLanguageName = () => {
        const { languageList } = this.props;
        const { languageSelected } = this.state;
        return languageList.data?.contentList?.filter(data => ((languageSelected)?.includes(data?.id))).map(data => (data?.title))?.join(" , ")
    }

    showLangToast = (customToastMsg, successToast) => {
        const { successToastMessage, failureToastMessage } = this.state;
        toast(
            <SelectLanguageToast
                customToastMsg={customToastMsg}
                successToast={successToast}
                successToastMessage={successToastMessage}
                failureToastMessage={failureToastMessage}
            />,
            {
                position: toast.POSITION.BOTTOM_CENTER,
                className: `lang-toast-wrapper`,
                autoClose: 2000
            },
        );
    }

    saveLanguages = async () => {
        const { languageSelected } = this.state;
        const {
            savePreferredLanguages,
            fetchAnonymousId,
            closePopup,
            isLanguageSelectFromSettings,
            history,
            isLanguageSelectFromMobile,
        } = this.props;

        let body = {
            languageId: languageSelected,
        }, userPreferredLanguages = [],
            userInfo = JSON.parse(getKey(LOCALSTORAGE.USER_INFO)) || {};
        const { baId, rmn: mobileNumber, sId: bingeSubscriberId, profileId } = userInfo
        const data = { baId, mobileNumber, bingeSubscriberId, profileId};
        let id = getKey(LOCALSTORAGE.ANONYMOUS_ID);

        if (languageSelected.length === 0) {
            this.showLangToast(false, false);
        } else {
            if (isUserloggedIn()) {
                body = {
                    languageId: languageSelected,
                    ...data,
                };
            }
            await savePreferredLanguages(body, id);
            this.showLangToast(false, true);
            if (isUserloggedIn()) {
                userPreferredLanguages = await getUserSelectedLanguages();
            } else {
                await fetchAnonymousId();
                userPreferredLanguages = this.props.headerDetails?.anonymousUserData?.preferredLanguages;
            }
            this.setState({
                languageSelected: [],
            }, () => {
                this.getSelectedLanguage(userPreferredLanguages);
            })
            setKey(LOCALSTORAGE.PREFERRED_LANGUAGES, userPreferredLanguages);
            this.trackAnalytics(userPreferredLanguages);
            !isMobile.any() && !isLanguageSelectFromSettings && closePopup();
            (isLanguageSelectFromSettings || isLanguageSelectFromMobile) && safeNavigation(history, URL.DEFAULT);
        }
    };

    trackAnalytics = (userPreferredLanguages) => {
        const {
            isLanguageSelectFromSettings,
        } = this.props;
        let mixpanelData = this.mixpanelLanguage(userPreferredLanguages);
        let noLanguageSelected = JSON.parse(getKey(LOCALSTORAGE.NO_LANGUAGE_SELECTED));
        mixpanelData[`${MIXPANEL.PARAMETER.SOURCE}`] = !noLanguageSelected ? MIXPANEL.VALUE.APP_LAUNCH : (isLanguageSelectFromSettings ? '' : MIXPANEL.VALUE.NUDGE);
        mixPanelConfig.trackEvent(MIXPANEL.EVENT.CONTENT_LANG_SELECT, mixpanelData);
        mixPanelConfig.trackEvent(MIXPANEL.EVENT.CONTENT_LANG_PROCEED);
        dataLayerConfig.trackEvent(DATALAYER.EVENT.SETTING_CTAS,
            {
                [DATALAYER.PARAMETER.BUTTON_NAME]: DATALAYER.VALUE.PROCEED,
                [DATALAYER.PARAMETER.LANGUAGE_NAME]: this.getSelectedLanguageName()
            });
    }

    modalClose = () => {
        const { closePopup, history } = this.props;
        isMobile.any() ? safeNavigation(history, URL.DEFAULT) : closePopup();
        setKey(LOCALSTORAGE.NO_LANGUAGE_SELECTED, true);
        mixPanelConfig.trackEvent(MIXPANEL.EVENT.CONTENT_LANG_SKIP, "");

    };

    getSelectedLanguage = (value) => {
        if (value && value.length > 0) {
            const selectedLanguages = value && value.map(selectedLan => (selectedLan.id))
            this.setState({
                ...this.state,
                languageSelected: [...this.state.languageSelected, ...selectedLanguages],
            });
        }
    }

    languagePopup = () => {
        const { languageList } = this.props;
    
        return (
            languageList?.data?.contentList?.length > 0 &&
            languageList.data.contentList.map((lang) => (
                <div
                    className="languageId-innercontainer"
                    key={lang.id}
                    onClick={(e) => {
                        this.handleLanguageSelect(lang.id, e);
                    }}
                >
                    {lang?.celebrityImage && <div className="celebrity-image">
                        <img
                            src={`${cloudinaryCarousalUrl("", "", "auto", "140")}${(lang.celebrityImage)}`}
                            alt={lang.nativeName}
                            onError={(e) => {
                                e.target.className = "broken-image";
                            }}
                        />
                    </div>}
                    <div className={`language-contr ${isEmpty(lang?.vernacularLanguageImage) && "lang-contr-image"}`}>
                        <RadioButton showCheckIcon={true} checked={this.check(lang.id)} />
                        <div className="language-name-wrapper">
                            <p className="language-name">{lang.title}</p>
                            {lang.vernacularLanguageImage && 
                            <div className="language-image"><img
                                src={`${cloudinaryCarousalUrl("", "", "auto", "50")}${(lang.vernacularLanguageImage)}`}
                                alt={lang.nativeName}
                                onError={(e) => {
                                    e.target.className = "broken-image";
                                }}
                            /></div>}
                        </div>
                    </div>
                </div>
            ))
        );
    }

    getLanguageVerbiages = () => {
        let data;
        if (this.props.isLanguageSelectFromSettings) {
            data = getVerbiages(CATEGORY_NAME.LANGUAGE_SETTING);
        } else {
            data = getVerbiages(CATEGORY_NAME.LANGUAGE_DRAWER);
        }
        !isEmpty(data) && this.setState({
            buttonTitle: data?.others?.buttonTitle,
            successToastMessage: data?.others?.preferenceUpdatedMsg,
            failureToastMessage: data?.others?.preferenceErrorMsg,
        });
    }

    render() {
        const { isLanguageSelectFromSettings, headerDetails } = this.props;

        return (
            <div className="selection-container">
                <div className="languageId-container">{this.languagePopup()}</div>
                {this.state.buttonTitle &&
                    <Buttons
                        bValue={this.state.buttonTitle}
                        cName="save-prefernces-button selected-language-btn primary-btn"
                        bType="button"
                        clickHandler={this.saveLanguages}
                    />
                }
                {isLanguageSelectFromSettings ? null : <p
                    className={`not-click`}
                    onClick={this.modalClose}
                >
                    {get(headerDetails, 'configResponse.data.config.notNow') || "Not Now"}
                </p>}

            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        languageList: state.languageReducer.languageList,
        modal: state.modal,
        headerDetails: state.headerDetails,
        selectedLanguages: state.languages.selectedLanguage.data,
        languageData: state.languages.languageList,
        savedPreferredLanguages: state.languages.savedPreferredLanguages,
    };
};

function mapDispatchToProps(dispatch) {
    return {
        ...bindActionCreators(
            {
                getLanguageListing,
                openPopup,
                closePopup,
                savePreferredLanguages,
                fetchAnonymousId,
                closeMobilePopup,
                fetchUserSelectedData,
            },
            dispatch,
        ),
    };
}

SelectLanguage.propTypes = {
    languageList: PropTypes.object,
    modal: PropTypes.object,
    headerDetails: PropTypes.object,
    getLanguageListing: PropTypes.func,
    openPopup: PropTypes.func,
    closePopup: PropTypes.func,
    savePreferredLanguages: PropTypes.func,
    fetchAnonymousId: PropTypes.func,
    closeMobilePopup: PropTypes.func,
    isLanguageSelectFromSettings: PropTypes.bool,
    fetchUserSelectedData: PropTypes.func,
    selectedLanguages: PropTypes.object,
    savedPreferredLanguages: PropTypes.object,
    history: PropTypes.object,
    languageData: PropTypes.object,
};
export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
)(SelectLanguage);
