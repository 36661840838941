import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import RightArrow from "@assets/images/arrow-right.svg";
import LeftArrow from "@assets/images/left-arrow.svg";
import TicketCard from '../TicketCard';
import './style.scss'

let settings = {
    dots: true,
    autoplay: false,
    infinite: false,
    adaptiveHeight: false,
    swipeToSlide: true,
    variableWidth: false,
    appendDots: dots => <div>{dots}</div>,
    prevArrow: <img className="slick-prev" src={LeftArrow} />,
    nextArrow: <img className="slick-next" src={RightArrow} />,
    touchThreshold: 100,
    useTransform: false,
};

const sliceCount = 4
function HomeTicket({ ticketResponse, handleNavigate }) {
  const ticketRef = useRef(null)
  const expandRef = Array(sliceCount).fill(null).map(() => useRef(null));

  /**
   * @function handleExpand
   * @description -
   * Handles the behavior when the previous ticket's "View All" is open and the user
   * navigates to the next ticket, the "View All" state for the next ticket will also open. 
   *  @return {void}
  */

  const handleExpand = (prev, next) => {
    if (expandRef[prev].current?.isPrevExpand) {
      expandRef[prev].current?.listExpand(!expandRef[prev].current?.isPrevExpand)
      expandRef[next].current?.listExpand(true)
      expandRef[next].current?.isTransition()
      scrollToViewport(expandRef[next].current?.listRef)
    }
  }

  const scrollToViewport = (child) => {
    if (ticketRef.current && child) {
      const elementTop = child.getBoundingClientRect().top;
      const elementBottom = child.getBoundingClientRect().bottom;
      if (elementTop < 0 || elementBottom > window.innerHeight) {
        ticketRef.current.firstElementChild.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }

  return (
    <div className="hm-ticket-wrapper">
    <div className='container home-ticket-container' ref={ticketRef}>
    <div className='view-all'><p onClick={handleNavigate}>View All<img src={RightArrow} /></p></div>
    <Slider {...settings} 
    beforeChange={(prev,next) => handleExpand(prev,next)}
    >
      {ticketResponse?.slice(0, sliceCount)?.map( (data,index) => (
           <React.Fragment key = {data?.id}>
            <TicketCard
                isLandingPage={true}
                imageStatusWidth={"15"}
                ticketCardData={data}
                ref={expandRef}
                index={index}
            />
      </React.Fragment>
    ))
    }
    </Slider>
    </div>
    </div>

  )
}

HomeTicket.propTypes = {
  ticketResponse:PropTypes.array,
  handleNavigate:PropTypes.func
}

export default HomeTicket
