import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';

import mixPanelConfig from "@utils/mixpanel";
import MIXPANEL from "@constants/mixpanel";
import Button from '@common/Buttons';
import { cloudinaryCarousalUrl } from '@utils/common';
import { LOCALSTORAGE } from '@utils/constants';
import { setKey } from '@utils/storage';
import { closePopup } from '@common/Modal/action';

import CrownIcon from "@assets/images/crown-icon.svg";

import { getCurrentSubscriptionMyplanVerbiages, getWebPortalLink } from '../APIs/action';
import { JOURNEY_SOURCE } from '../APIs/constant';
import './style.scss';

function FlexiPlanModal({ AnalyticSource, source }) {
    const dispatch = useDispatch()
    const verbiages = useSelector(state => get(state.subscriptionDetails, 'myPlanVerbiages'));
    const currentSubscription = useSelector(state => get(state.subscriptionDetails, 'currentSubscription.data'));

    useEffect(() => {
        dispatch(getCurrentSubscriptionMyplanVerbiages());
        mixPanelConfig.trackEvent(MIXPANEL.EVENT.ZERO_APPS_NUDGE_VIEW, {
            [`${MIXPANEL.PARAMETER.SOURCE}`]: source
        });
    }, []);

    useEffect(() => {
        if (!currentSubscription?.appSelectionRequired) {
            dispatch(closePopup());
        }
    }, [currentSubscription])

    const handleChoose = () => {
        mixPanelConfig.trackEvent(MIXPANEL.EVENT.ZERO_APPS_NUDGE_CHOOSEAPPS, {
            [`${MIXPANEL.PARAMETER.SOURCE}`]: source
        })
        const headers = {
            initiateSubscription: JOURNEY_SOURCE.DRAWER_ZEROAPPSELECTION,
            journeySource: JOURNEY_SOURCE.DRAWER_ZEROAPPSELECTION,
            journeySourceRefId: "",
            analyticSource: AnalyticSource,
        };
        setKey(LOCALSTORAGE.ZERO_APP_SOURCE, source)
        dispatch(getWebPortalLink(headers))
    }

    return (
        <section className='flexi-plan-wrapper'>
            <figure className='crown-icon-wrapper'>
                <img src={CrownIcon} className="crown-img" alt="binge-crown-image" />
            </figure>
            <p className='heading'>{get(verbiages, "appSelectionPopupVerbiages.title")}</p>
            <p className='sub-title'>{get(verbiages, "appSelectionPopupVerbiages.subTitle")}</p>
            <section className='partner-image-wrapper'>
                {verbiages?.appSelectionPopupVerbiages?.partnerList?.map(imgUrl =>
                    <figure className='partner-image'>
                        <img
                            src={`${cloudinaryCarousalUrl("", "", 100, 100)}${imgUrl
                                }`}
                            alt="partner-image"
                            onError={(e) => {
                                e.target.onerror = null;
                                e.target.className = "broken-image";
                            }}
                        />
                    </figure>
                )}
            </section>
            <p className='footer'>{get(verbiages, "appSelectionPopupVerbiages.partnerListFooter")}</p>
            <Button
                clickHandler={handleChoose}
                bValue={get(verbiages, "appSelectionPopupVerbiages.primaryButtonText")}
                cName="btn primary-btn btn-wrapper"
            />
        </section>
    )
}

export default FlexiPlanModal