import React from 'react';
import appleLogo from "@assets/images/apple-logo.svg";
import { AppleKnowMore } from '../AppleKnowMore';
import { appleRedirection, cloudinaryCarousalUrl, getVerbiages, isMobile } from '@utils/common';
import diyPlayer from "@assets/images/diy-player.svg"
import { CATEGORY_NAME } from '@utils/constants';
import { playAppleHelpVideo } from '@containers/PIDetail/PIDetailCommon';
import MIXPANEL from "@constants/mixpanel";
import { get } from 'lodash';
import store from '@src/store';
import PlayImage from '@assets/images/Play.svg'


export const AppleActivate = (props) => {
    const state = store.getState();
    let appleKnowMoreVerbiages = get(state?.subscriptionDetails, "myPlanVerbiages.appleSubscriptionVerbiage.appleInterstitialScreenVerbiages");
    let appleActivatePopupCta = getVerbiages(CATEGORY_NAME.APPLE_ACTIVATION_POPUP_NEW),
    imageUrl = `${cloudinaryCarousalUrl("", "", 100, 100,)}${appleKnowMoreVerbiages?.others?.popUpAppleLogo}`,
    openKnowMorePopup = props?.openKnowMorePopup,
    thumbnailDimension = {
        width : isMobile.any() ? 360 : 520,
        height: isMobile.any() ? 204 : 293,
    },
    videoThumbnailImage = appleKnowMoreVerbiages?.others?.videoThumbnailImage && `${cloudinaryCarousalUrl('', '', thumbnailDimension.width, thumbnailDimension.height)}${appleKnowMoreVerbiages?.others?.videoThumbnailImage}`,
    appleHelpVideoUrl = appleKnowMoreVerbiages?.others?.videoUrl,
    data = openKnowMorePopup ? appleKnowMoreVerbiages : appleActivatePopupCta,
    viaPopup = openKnowMorePopup ? MIXPANEL.VALUE.HELP_POPUP : MIXPANEL.VALUE.ACTIVATION_POPUP;
    return (
        <>
            {imageUrl && <div className="alert-icon">
                <img src={imageUrl} alt='' />
            </div>}
            {data.header && <h1>{data?.header}</h1>}
            {
                props?.openKnowMorePopup &&  videoThumbnailImage &&
                <div className='video-thumbnail' onClick={() => playAppleHelpVideo(props)}>
                    <img src={videoThumbnailImage}/>
                    <img className='apple_play_image' src={PlayImage} />
                </div>
            }
            {data?.others?.steps && <ul className='steps-list'>
            {
                data?.others?.steps?.map((item, index) => {
                    return (
                        <li className='steps' key={index}>
                            <div>{item.text1}</div>
                            <span className='step-info'>{item.text2}</span>
                        </li>
                    )
                })
            }
            </ul>}
            {data?.others?.message && <div className='instructions'>{data?.others?.message}</div>}
            {!openKnowMorePopup && appleHelpVideoUrl && <AppleKnowMore openPopup={false} commonProps={props.commonProps}/>}
            {data?.others?.popUpCTA && <div className="btn-group">
                <button className="btn primary-btn" type="submit" onClick={() => appleRedirection(props.commonProps, viaPopup, MIXPANEL.VALUE.PI_PAGE)}>
                   {data?.others?.popUpCTA}
                </button>
            </div>}
        </>
    );
};

AppleActivate.propTypes  = {

};