import { get } from "lodash";
import { ACTION } from "./constant";

export default function seoStaticPage(state = {}, action) {
    switch (action.type) {
        case ACTION.FETCH_STATIC_PAGE_DATA:
            return {
                ...state,
                staticPageData: get(action, "apiResponse.data"),
            };
        default:
            return state;
    }
}