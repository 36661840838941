import React from 'react';
import './style.scss';

class Copyright extends React.Component {

    render() {
        return (
            <div className='copyright-text'>
                <span> @ 2021 Tata Play Limited.</span>
                <span> TATA® and TATA PLAY® are registered trademarks of Tata Sons Private Limited. All Sky Trademarks, and any intellectual property they contain, are owned by Sky International AG. Used under License by Tata Play Limited.
                </span>
            </div>
        )
    }
}

export default Copyright;