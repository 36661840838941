import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
// import { genreCardClass } from '@common/Listing/constants';
import './style.scss'

export default function GenreItem({ Img, title, isGenrePage }) {
  return (
    <React.Fragment>
      <div className={`genre-action-image image-handling`}>
        <LazyLoadImage
          src={Img}
          alt={title}
          onError={(e) => {
            e.target.onerror = null;
            e.target.className = "broken-image";
          }}
        />
      </div>
      <div className="genre-item-title isGenre">
        {isGenrePage ? <h1>{title}</h1> : title}
      </div>
    </React.Fragment>
  );
}
