import { DTH_TYPE, LOCALSTORAGE } from "@utils/constants";
import { CHATBOT_TYPE } from "../APIs/constants";
import { getKey } from "@utils/storage";

/**
 * @function getHCChatBotType
 * @returns string : chatbot type
 */
export const getHCChatBotType = () => {
    let userInfo = JSON.parse(getKey(LOCALSTORAGE.USER_INFO)) || {},
        chatbotType = userInfo?.dthStatus === DTH_TYPE.DTH_W_BINGE_OLD_USER ? CHATBOT_TYPE.S360 : CHATBOT_TYPE.ORISERVE;
    return chatbotType;
}