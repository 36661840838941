import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';
import { get, isEmpty } from "lodash";

import store from "@src/store";
import { LOCALSTORAGE, QUICK_RECHARGE_JOURNEY_SOURCES } from "@utils/constants";
import { setKey } from "@utils/storage";
import { isMobile, redirectToMangeApp, trackAppsflyerOnRechargeRedirection, trackRechargeFailedEvents, trackRechargeInitiateEvents } from "@utils/common";
import { hideMainLoader, showMainLoader } from "@src/action";

import mixPanelConfig from "@utils/mixpanel";
import MIXPANEL from "@constants/mixpanel";
import appsFlyerConfig from "@utils/appsFlyer";
import APPSFLYER from "@utils/constants/appsFlyer";
import FIREBASE from "@utils/constants/firebase";
import { URL } from "@constants/routeConstants";

import { quickRechargeForDTH, getBalanceInfo } from "@containers/SubscriptionPayment/APIs/action";
import { SUBSCRIPTION_STATUS } from "@containers/Subscription/APIs/constant";
import { renewSusbcription } from "@containers/Subscription/APIs/subscriptionCommon";
import { setRenewNudgeStatus } from "@containers/Subscription/APIs/action";

import CrossIcon from "@assets/images/cross-icon.svg";
import RenewIcon from "@assets/images/renew-nudge-icon.svg";
import RightArrow from '@assets/images/arrow-right-pink.svg';

import { NUDGE_ACTION } from "./constant";

import "./style.scss";

const RenewNudge = () => {
    const currentSubscription = useSelector(state => get(state.subscriptionDetails, 'currentSubscription.data'));
    const subscriptionNudgesDetails = useSelector(state => get(state.subscriptionDetails, 'currentSubscription.data.subscriptionNudgesDetails'));
    const freeTrialNudgesDetails = useSelector(state => get(state.subscriptionDetails, 'currentSubscription.data.freeTrialNudgesDetails'));
    const showRenewNudge = useSelector(state => get(state.subscriptionDetails, 'renewNudgeStatus'));

    const dispatch = useDispatch(),
        history = useHistory(),
        location = useLocation(),
        urlArr = location?.pathname?.split("/");

    const onNudgeClick = (closeNudge = false) => {
        const beforeExpiryNudge = currentSubscription?.subscriptionStatus?.toUpperCase() === SUBSCRIPTION_STATUS.ACTIVE;
        const paymentType = currentSubscription?.freeTrialStatus ? get(freeTrialNudgesDetails, 'paymentType') : get(subscriptionNudgesDetails, 'paymentType');
        setKey(LOCALSTORAGE.LAST_SHOWN_NUDGE_TIMESTAMP, Date.now());

        if (closeNudge) {
            mixPanelConfig.trackEvent(MIXPANEL.EVENT.RENEW_NUDGE_CLOSE, {
                [MIXPANEL.PARAMETER.NUDGE_TYPE]: beforeExpiryNudge ? MIXPANEL.VALUE.BEFORE_EXPIRY : MIXPANEL.VALUE.AFTER_EXPIRY,
                [MIXPANEL.PARAMETER.NUDGE_PAYMENT_TYPE]: paymentType
            });
        } else {
            mixPanelConfig.trackEvent(MIXPANEL.EVENT.RENEW_NUDGE_CLICK, {
                [MIXPANEL.PARAMETER.NUDGE_TYPE]: beforeExpiryNudge ? MIXPANEL.VALUE.BEFORE_EXPIRY : MIXPANEL.VALUE.AFTER_EXPIRY,
                [MIXPANEL.PARAMETER.NUDGE_PAYMENT_TYPE]: paymentType
            });
            handleNudgeAction();
        }

        dispatch(setRenewNudgeStatus(false));
    }

    const handleNudgeAction = async () => {
        let nudgeAction = currentSubscription?.freeTrialStatus ? freeTrialNudgesDetails?.nudgeAction : subscriptionNudgesDetails?.nudgeAction;

        if (nudgeAction === NUDGE_ACTION.RECHARGE) {
            await onRechargeClick();
        } else if (nudgeAction === NUDGE_ACTION.RENEW) {
            await renewSusbcription(history, false, true);
        } else if (nudgeAction === NUDGE_ACTION.SUBSCRIBE) {
            redirectToMangeApp(FIREBASE.VALUE.HAMBURGER_SUBSCRIBE);
        }

    }

    const onRechargeClick = async () => {
        let balanceInfo = await store.dispatch(getBalanceInfo());
        let recommendedAmount = balanceInfo?.data?.recommendedAmount;
        recommendedAmount = recommendedAmount === undefined ? MIXPANEL.VALUE.DEFAULT_AMOUNT_VALUE : recommendedAmount;
        store.dispatch(showMainLoader());
        trackRechargeInitiateEvents(APPSFLYER.VALUE.HAMBURGER, MIXPANEL.VALUE.HAMBURGER_MENU, recommendedAmount);
        let quickRechargeSelfCareUrl = await store.dispatch(quickRechargeForDTH(parseInt(recommendedAmount), "", false, QUICK_RECHARGE_JOURNEY_SOURCES.RECHARGE_NUDGE));
        if (quickRechargeSelfCareUrl?.code === 0 && !isEmpty(quickRechargeSelfCareUrl.data)) {
            trackAppsflyerOnRechargeRedirection();
            quickRechargeSelfCareUrl?.data?.rechargeUrl && window.location.assign(`${quickRechargeSelfCareUrl?.data?.rechargeUrl}`);
            store.dispatch(hideMainLoader());
        } else {
            trackRechargeFailedEvents(quickRechargeSelfCareUrl?.message)
            store.dispatch(hideMainLoader());
        }
    };

    const beforeExpiryNudge = currentSubscription?.subscriptionStatus?.toUpperCase() === SUBSCRIPTION_STATUS.ACTIVE,
        expiryDaysLeft = currentSubscription?.freeTrialStatus ? parseInt(freeTrialNudgesDetails?.expiryDaysLeft || 0) : parseInt(subscriptionNudgesDetails?.expiryDaysLeft || 0),
        nudgeToShowOnWhichDayBeforeExpiry = currentSubscription?.freeTrialStatus ? parseInt(freeTrialNudgesDetails?.nudgeToShowOnWhichDayBeforeExpiryTrial || 0) : parseInt(subscriptionNudgesDetails?.nudgeToShowOnWhichDayBeforeExpiry || 0);

    const circleRadius = isMobile.any() ? 42 : 41.67,
        progressPercent = (Math.abs(expiryDaysLeft) / nudgeToShowOnWhichDayBeforeExpiry) * 100,
        strokeDasharray = 2 * 3.14 * circleRadius,
        strokeDashoffset = strokeDasharray * ((100 - progressPercent) / 100);

    const checkIsRouteValid = () => {
        const isInvalidRoute = [URL.HELP_CENTER, URL.ACTIVATE, URL.TRANSACTIONS, URL.SUBSCRIPTION_TRANSACTION, URL.PLAYER, URL.BALANCE_INFO, URL.PAYMENT, URL.TRAILER].includes(urlArr[1]);

        if (isInvalidRoute) {
            dispatch(setRenewNudgeStatus(false));
            return false;
        } else {
            return true;
        }
    }

    const isValidRoute = checkIsRouteValid();
    const nudgeTitle = currentSubscription?.freeTrialStatus ? get(freeTrialNudgesDetails, "freeNudgeTitle") : get(subscriptionNudgesDetails, "renewNudgeTitle"),
        nudgeMessage = currentSubscription?.freeTrialStatus ? get(freeTrialNudgesDetails, "freeNudgeMessage") : get(subscriptionNudgesDetails, "renewNudgeMessage"),
        nudgeCTA = currentSubscription?.freeTrialStatus ? get(freeTrialNudgesDetails, "freeNudgeButton") : get(subscriptionNudgesDetails, "renewNudgeButton"),
        isNudgeVisible = showRenewNudge && isValidRoute && nudgeTitle && nudgeMessage && nudgeCTA;

    return isNudgeVisible ? (
        <div className="renew-nudge-container">
            {beforeExpiryNudge
                ? <svg width={isMobile.any() ? "40" : "50"} height={isMobile.any() ? "40" : "50"} viewBox="0 0 100 100" style={{ transform: "rotate(-90deg)" }}>
                    <circle r={circleRadius} cx="50" cy="50" fill="transparent" stroke="#564372" stroke-width="5"></circle>
                    <circle r={circleRadius} cx="50" cy="50" fill="transparent" stroke="#E10092" stroke-width="5" stroke-dasharray={strokeDasharray} stroke-dashoffset={strokeDashoffset}></circle>
                    <text x={isMobile.any() ? "40" : "38"} y={isMobile.any() ? "59" : "60"} fill="#FFFFFF" className="circular-text-count">{expiryDaysLeft}</text>
                    <text x="46" y="55" fill="#FFFFFF" class="circular-text-days">days</text>
                </svg>
                : <span className="img-container"><img src={RenewIcon} alt="icon" /></span>
            }
            <div className="text-container">
                <p>{nudgeTitle}</p>
                <p>{nudgeMessage}</p>
                <span onClick={() => onNudgeClick()} >
                    <p>{nudgeCTA}</p>
                    <img src={RightArrow} alt="icon" />
                </span>
            </div>
            <div className="cross-icon-container">
                <div className="cross-icon" onClick={() => onNudgeClick(true)}>
                    <img src={CrossIcon} />
                </div>
            </div>
        </div>
    ) : null;
}

RenewNudge.propTypes = {
    currentSubscription: PropTypes.object,
    subscriptionNudgesDetails: PropTypes.object,
    nudgesDetails: PropTypes.bool,
};

export default React.memo(RenewNudge);