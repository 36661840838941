import React, { Fragment,useEffect,useState } from "react";
import { useDispatch } from "react-redux";
import get from "lodash/get";
import { getKey } from "@utils/storage";
import { cloudinaryCarousalUrl } from "@utils/common";
import { SUBSCRIPTION_EXTERNAL_SOURCES, LOCALSTORAGE } from "@constants";
import Button from "@common/Buttons";
import { getFsPortalLink } from "@containers/Subscription/APIs/action";
import mixPanelConfig from "@utils/mixpanel";
import MIXPANEL from "@constants/mixpanel";
import "./style.scss";

const FsInstallation = ({ onStartClick, sourceIsMSales, handleMSalesLogoutJourney, handleDeleteLocalStorageKeys }) => {
	const [isMsalesClick, setMSalesClick] = useState(false)
	let sourceISMService = getKey(LOCALSTORAGE.SUBSCRIPTION_EXTERNAL_SOURCE) === SUBSCRIPTION_EXTERNAL_SOURCES.M_SERVICE;
	let selectedPack = JSON.parse(getKey(LOCALSTORAGE.SUBSCRIPTION_SELECTED_PACK));
	let ftvOfferVerbiage = get(selectedPack,'ftvOfferVerbiage');
    const dispatch = useDispatch();

	useEffect(() => {
		mixPanelConfig.trackEvent(MIXPANEL.EVENT.SUBSCRIBE_SUCCESS_FTV_UPSELL_VIEW);
	},[])

	const handleFsNotNow = async () => {
		mixPanelConfig.trackEvent(MIXPANEL.EVENT.SUBSCRIBE_SUCCESS_FTV_UPSELL_NOTNOW);
		sourceISMService && await setMSalesClick(true)
		if (sourceIsMSales){
			setMSalesClick(true)
			await handleMSalesLogoutJourney()
			return
		}
		onStartClick()
	}

	const handleSchedule = async () => {
		mixPanelConfig.trackEvent(MIXPANEL.EVENT.SUBSCRIBE_SUCCESS_FTV_UPSELL_PROCEED);
		let userInfo = JSON.parse(getKey(LOCALSTORAGE.USER_INFO)) || {},
		payload = {
			cartId: getKey(LOCALSTORAGE.CART_ID),
			subscriberId: userInfo?.sId,
			paymentTransactionId: getKey(LOCALSTORAGE.TRANSACTION_ID),
			accessToken: userInfo?.accessToken
		};
		if (sourceIsMSales || sourceISMService) {
			setMSalesClick(true)
			await handleMSalesLogoutJourney()
		}
		dispatch(getFsPortalLink(payload))
		handleDeleteLocalStorageKeys()
	}


	return (
		<Fragment>
			{!isMsalesClick ?
				<div className="payment-container-flow">
					{get(ftvOfferVerbiage, 'title') && <div className="payment-heading">{get(ftvOfferVerbiage, 'title')}</div>}
					<div className="fire-stick-prompt">
						{get(ftvOfferVerbiage, 'imageUrl') && <img
							src={`${cloudinaryCarousalUrl("", "", 144, 100)}${get(ftvOfferVerbiage, 'imageUrl')}`}
							alt="Bandage Image"
							className="bandage-image"
						/>}
						{get(ftvOfferVerbiage, 'description') && <span className="fire-stick-prompt-text"
							dangerouslySetInnerHTML={{ __html: get(ftvOfferVerbiage, 'description') }} />}
					</div>
					{get(ftvOfferVerbiage, 'note') && <div className="payment-note">
						<p className="note" dangerouslySetInnerHTML={{ __html: get(ftvOfferVerbiage, 'noteTitle') }} />
						<p>{get(ftvOfferVerbiage, 'note')}</p>
					</div>}
					{get(ftvOfferVerbiage, 'ctaPrimary') && <Button
						bValue={get(ftvOfferVerbiage, 'ctaPrimary')}
						cName="btn primary-btn btn-wrapper-installation"
						clickHandler={handleSchedule}
					/>
					}
					{get(ftvOfferVerbiage, 'ctaSecondary') &&
						<p className="not-now" onClick={handleFsNotNow}>{get(ftvOfferVerbiage, 'ctaSecondary')}</p>
					}
				</div> : null}
		</Fragment>
	);
};

export default FsInstallation;
