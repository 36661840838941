import { io } from "socket.io-client";
import { getKey, setKey } from "@utils/storage";
import { EVENT_ROVER_ERROR_MESSAGE, LOCALSTORAGE } from "@constants";
import ENV_CONFIG from '@config/environment/index';
import { getEventRoverCommonHeaders, getPubnubChannelName, handlePubnubData, isUserloggedIn } from "@utils/common";
import { fetchJWTTokenForEventRover, fetchLatestEventFromEventRover } from "@containers/Login/LoginCommon";
import mixPanelConfig from "@utils/mixpanel";
import MIXPANEL from "@constants/mixpanel";

let eventRoverSocket = null;

export default (channel, callback, login) => {
    init(channel, callback, login);
}

const init = async (channel, callback, login) => {
    const currentTime = new Date().getTime(),
        authToken = getKey(LOCALSTORAGE.EVENT_ROVER_AUTH_TOKEN);
    if (authToken) {
        eventRoverSocket = io(
            ENV_CONFIG.EVENT_ROVER_HOST_URL, {
            transports: ['websocket'],
            path: "/event-rover/subscriber/socket.io",
            // reconnection: true,
            // reconnectionAttempts: 5,
            // reconnectionDelay: 1000, (in ms)
            // reconnectionDelayMax: 5000,(in ms) 
            // timeout: 20000,
            auth: {
                "token": authToken,
                "X-Dev": getEventRoverCommonHeaders(),
                "X-Ws-Request-Time": currentTime,
                "X-Channels": JSON.stringify([channel]),
            },
        });
        eventRoverSocket && setEventRoverListener(channel, callback, login);
    }
    else {
        isUserloggedIn() && !login && await retryConnectionWithEventRover(channel, callback, login);
    }
};

const retryConnectionWithEventRover = async (channel, callBack, login) => {
    const response = await fetchJWTTokenForEventRover();
    response?.token && init(channel, callBack, login);
}

const setEventRoverListener = (channel, callback, login) => {
    eventRoverSocket.on('connect_error', async (error) => {
        console.log("Event Rover connect_error event :", error?.message);
        if (error?.message === EVENT_ROVER_ERROR_MESSAGE.JWT_EXPIRED) {
            retryConnectionWithEventRover(channel, callback, login);
        }
    });

    eventRoverSocket.on('connect_failed', async (error) => {
        console.log("Event Rover connect_failed event :", error?.message);
    });

    eventRoverSocket.on('connect', function () {
        if (eventRoverSocket.connected) {
            console.log('Event Rover connected successfully on channel :', channel);
            setKey(LOCALSTORAGE.CURRENT_PUBNUB_CHANNEL, channel);
            mixPanelConfig.trackEvent(MIXPANEL.EVENT.EVENT_ROVER_CONNECTED, {
                [MIXPANEL.PARAMETER.CHANNEL_NAME]: channel,
                [MIXPANEL.PARAMETER.DTH_STATUS]: JSON.parse(getKey(LOCALSTORAGE.USER_INFO))?.dthStatus,
                [MIXPANEL.PARAMETER.X_DEV]: getEventRoverCommonHeaders(),
            });
            isUserloggedIn() && !login && fetchMessages();
        } else {
            console.log('Event Rover not connected yet');
        }
    });

    eventRoverSocket.on('push', (data, publishMetaData) => {
        // console.log("Event Rover push event : ", data);
        const currentTime = new Date().getTime();
        let xDev = getEventRoverCommonHeaders(),
            ackMetaData = {
                eventId: publishMetaData?.eventId,
                channel: publishMetaData?.channel,
                pubAt: publishMetaData?.pubAt,
                ackAt: currentTime
            };
        eventRoverSocket.emit('ack', xDev, ackMetaData);
        callback(data);
    });

    eventRoverSocket.on('disconnect', (reason) => {
        console.log("Event Rover disconnect event :", reason);

        if (reason === EVENT_ROVER_ERROR_MESSAGE.MANUAL_DISCONNECT) {
            console.log("Event Rover server disconnected by client manually");
        } else {
            console.log("Event Rover Attempting to reconnect...");
        }

        mixPanelConfig.trackEvent(MIXPANEL.EVENT.EVENT_ROVER_DISCONNECTED, {
            [MIXPANEL.PARAMETER.CHANNEL_NAME]: channel,
            [MIXPANEL.PARAMETER.DTH_STATUS]: JSON.parse(getKey(LOCALSTORAGE.USER_INFO))?.dthStatus,
            [MIXPANEL.PARAMETER.X_DEV]: getEventRoverCommonHeaders(),
            [MIXPANEL.PARAMETER.REASON]: reason,
        });
    });

    eventRoverSocket.on("reconnect_attempt", (attemptNumber) => {
        console.log('Event Rover reconnect_attempt event :', attemptNumber);
    });

    eventRoverSocket.on("reconnect_error", (error) => {
        console.log("Event Rover reconnect_error event :", error);
    });

    eventRoverSocket.on("reconnect_failed", () => {
        console.log("Event Rover reconnect_failed after max attempts");
    });

    eventRoverSocket.on("reconnect", (attemptNumber) => {
        console.log(`Event Rover Successfully reconnected after ${attemptNumber} attempts`);
    });
};

export const removeEventRoverListener = (channelToRemove) => {
    if (eventRoverSocket) {
        eventRoverSocket.disconnect();
        eventRoverSocket.removeAllListeners();
    }
};

export const fetchMessages = async () => {
    let data = await fetchLatestEventFromEventRover();
    // console.log("Event Rover history push data :", data);
    data && handlePubnubData(data, 'from history for event rover app');
};