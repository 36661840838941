import React from 'react'
import FooterCard from '../FooterCard'
import './style.scss'

const GlobalFooter = ({ globalFooterData = [] }) => {
    return !!globalFooterData?.length ? (
        <article className='global-footer-container'>
            <div className="horizontal-line global" />
            <FooterCard footerData={globalFooterData} />
        </article>
    ) : null;
}

export default GlobalFooter;