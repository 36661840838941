// DUmmy data for dev
export const S360_TICKETS = {
    "code": 0,
    "message": "data fetched successfully",
    "data": {
        "SRDetails": [
            {
                "severity": "3-Medium",
                "srDueDate": "2010-04-12 11:47:52.0",
                "srOpenDate": "2010-04-1 11:47:52.0",
                "srType": "Billing",
                "srNumber": "1-91711319",
                "createdBy": "",
                "description": "/Tesss",
                "srSubArea": "Email statement-not received",
                "source": "BINGEFREEMIUM",
                "priority": "4-Low",
                "srArea": "Email statement",
                "status": "Closed",
                "srClosedDate": "05-APR-10 11:48:09"
            },
            {
                "severity": "Medium",
                "srDueDate": "2010-04-25 11:47:52.0",
                "srOpenDate": "2010-04-15 11:47:52.0",
                "srType": "Billing",
                "srNumber": "1-91711319",
                "createdBy": "",
                "description": "/Tesss",
                "srSubArea": "Email statement-not received",
                "source": "BINGEFREEMIUM",
                "priority": "4-Low",
                "srArea": "Email statement",
                "status": "open",
                "srClosedDate": "05-APR-10 11:48:09"
            }
        ],
        "transMessage": "SUCCESS",
        "srNumber": "1-91711319",
        "subscriberId": "3000078703",
        "source": "msales",
        "transStatus": "SUCCESS"
    },
};

export const ORISERVE_HC_TICKET_RES = {
    "code": 0,
    "message": "Response fetched successfully.",
    "data": [
        {// Open
            "source": "CHATBOT",
            "category": "Account and Profile",
            "subCategory": "Manage Subscription",
            "leafCategory": "Pack not updating",
            "deviceName": "Android TV",
            "id": "CMSPNU20230915375538",
            "description": "Additional comments: 234234",
            "externalId": "4029472859",
            "ticketType": "MSPNU",
            "creationDate": "2023-09-15T11:21:03.000Z",
            "lastUpdate": "2023-09-16T11:22:00.000Z",
            "priority": "NORMAL",
            "requestedResolutionDate": "2023-09-15T11:21:03.000Z",
            "expectedResolutionDate": "2023-09-17T11:22:00.000Z",
            "status": "OPEN",
            "closeComments": "",
            "resCodeDescription": "",
            "reOpenCounter": "0",
            "reopeningComments": [],
            "recloseComments": [],
            "openingComments": "Additional comments: 234234",
            "reopenComments": "",
            "reCloseComments": "",
            "relatedEntity": [
                {
                    "id": "4029472859",
                    "@referredType": "CUSTOMER",
                    "role": "CUSTOMER"
                }
            ],
            "channel": {
                "id": "MSPNU",
                "name": "CHATBOT",
                "@type": "Channel"
            },
            "statusChange": [
                {
                    "status": "FORWARD",
                    "changeReason": null,
                    "changeDate": "2023-09-15T11:21:02.000Z",
                    "@type": "STATUS"
                },
                {
                    "status": "UNATTENDED",
                    "changeReason": null,
                    "changeDate": "2023-09-15T11:21:02.000Z",
                    "@type": "STATUS"
                }
            ],
            "note": [],
            "relatedParty": [
                {
                    "id": "8089898989",
                    "@referredType": "CUSTOMER",
                    "role": "CUSTOMER"
                }
            ],
            "ticketRelationship": [],
            "attachment": [],
            "@type": "COMPLAINT"
        },
        {// close
            "source": "CHATBOT",
            "category": "Account and Profile",
            "subCategory": "Ticket Closed",
            "leafCategory": "Pack not updating",
            "deviceName": "Android TV",
            "id": "CMSPNU20230915375538",
            "description": "Additional comments: 234234",
            "externalId": "4029472859",
            "ticketType": "MSPNU",
            "creationDate": "2023-09-15T11:21:03.000Z",
            "lastUpdate": "2023-09-16T11:22:00.000Z",
            "priority": "NORMAL",
            "requestedResolutionDate": "2023-09-15T11:21:03.000Z",
            "expectedResolutionDate": "2023-09-17T11:22:00.000Z",
            "resolutionDate": "2023-09-15T11:29:25.000Z",
            "status": "CLOSE",
            "closeComments": "For issues related to FTV/Binge box/DTH, please go to https://bit.ly/3JeW89y",
            "resCodeDescription": "FTV/DTH/Binge box",
            "reOpenCounter": "0",
            "reopeningComments": [],
            "recloseComments": [],
            "openingComments": "Additional comments: 234234",
            "reopenComments": "",
            "reCloseComments": "",
            "relatedEntity": [
                {
                    "id": "4029472859",
                    "@referredType": "CUSTOMER",
                    "role": "CUSTOMER"
                }
            ],
            "channel": {
                "id": "MSPNU",
                "name": "CHATBOT",
                "@type": "Channel"
            },
            "statusChange": [
                {
                    "status": "CLOSE",
                    "changeReason": null,
                    "changeDate": "2023-09-15T11:29:25.000Z",
                    "@type": "STATUS"
                },
                {
                    "status": "FORWARD",
                    "changeReason": null,
                    "changeDate": "2023-09-15T11:21:02.000Z",
                    "@type": "STATUS"
                }
            ],
            "note": [],
            "relatedParty": [
                {
                    "id": "8089898989",
                    "@referredType": "CUSTOMER",
                    "role": "CUSTOMER"
                }
            ],
            "ticketRelationship": [],
            "attachment": [],
            "@type": "COMPLAINT"
        },
        { // Reopen Once and not closed
            "source": "CHATBOT",
            "category": "Account and Profile",
            "subCategory": "Manage Subscription",
            "leafCategory": "Pack not updating",
            "deviceName": "Android TV",
            "id": "CMSPNU20230915375538",
            "description": "Additional comments: 234234",
            "externalId": "4029472859",
            "ticketType": "MSPNU",
            "creationDate": "2023-09-15T11:21:03.000Z",
            "lastUpdate": "2023-09-16T11:30:00.000Z",
            "priority": "HIGH",
            "requestedResolutionDate": "2023-09-15T11:21:03.000Z",
            "expectedResolutionDate": "2023-09-16T11:30:00.000Z",
            "status": "REOPEN",
            "closeComments": "For issues related to FTV/Binge box/DTH, please go to https://bit.ly/3JeW89y",
            "resCodeDescription": "",
            "reOpenCounter": "1",
            "reopeningComments": [
                {
                    "comment": "Dear customer, your re-opened complaint for TATA Play Binge with complaint ID |CASE_ID|, \r\nis created and assigned to the concerned team. \r\nRest assured, your ticket will be resolved on or before |COMMIT_TIME|. \r\nYou can also track your complaint status at |LINK1|. Thank you",
                    "time": "2023-09-15T06:00:13.000+00:00"
                }
            ],
            "recloseComments": [],
            "openingComments": "Additional comments: 234234",
            "reopenComments": "reopen",
            "reCloseComments": "",
            "relatedEntity": [
                {
                    "id": "4029472859",
                    "@referredType": "CUSTOMER",
                    "role": "CUSTOMER"
                }
            ],
            "channel": {
                "id": "MSPNU",
                "name": "CHATBOT",
                "@type": "Channel"
            },
            "statusChange": [
                {
                    "status": "REOPEN",
                    "changeReason": null,
                    "changeDate": "2023-09-15T11:30:13.000Z",
                    "@type": "STATUS"
                },
                {
                    "status": "UNATTENDED",
                    "changeReason": null,
                    "changeDate": "2023-09-15T11:30:13.000Z",
                    "@type": "STATUS"
                },
                {
                    "status": "CLOSE",
                    "changeReason": null,
                    "changeDate": "2023-09-15T11:29:25.000Z",
                    "@type": "STATUS"
                },
                {
                    "status": "FORWARD",
                    "changeReason": null,
                    "changeDate": "2023-09-15T11:21:02.000Z",
                    "@type": "STATUS"
                }
            ],
            "note": [],
            "relatedParty": [
                {
                    "id": "8089898989",
                    "@referredType": "CUSTOMER",
                    "role": "CUSTOMER"
                }
            ],
            "ticketRelationship": [],
            "attachment": [],
            "@type": "COMPLAINT"
        },
        { // Reopen once and closed
            "source": "CHATBOT",
            "category": "Account and Profile",
            "subCategory": "Manage Subscription",
            "leafCategory": "Pack not updating",
            "deviceName": "Android TV",
            "id": "CMSPNU20230915375538",
            "description": "Additional comments: 234234",
            "externalId": "4029472859",
            "ticketType": "MSPNU",
            "creationDate": "2023-09-15T11:21:03.000Z",
            "lastUpdate": "2023-09-16T11:30:00.000Z",
            "priority": "HIGH",
            "requestedResolutionDate": "2023-09-15T11:21:03.000Z",
            "expectedResolutionDate": "2023-09-16T11:30:00.000Z",
            "resolutionDate": "2023-09-15T11:31:38.000Z",
            "status": "CLOSE",
            "closeComments": "For issues related to FTV/Binge box/DTH, please go to https://bit.ly/3JeW89y",
            "resCodeDescription": "FTV/DTH/Binge box",
            "reOpenCounter": "1",
            "reopeningComments": [
                {
                    "comment": "Dear customer, your re-opened complaint for TATA Play Binge with complaint ID |CASE_ID|, \r\nis created and assigned to the concerned team. \r\nRest assured, your ticket will be resolved on or before |COMMIT_TIME|. \r\nYou can also track your complaint status at |LINK1|. Thank you",
                    "time": "2023-09-15T06:00:13.000+00:00"
                }
            ],
            "recloseComments": [
                {
                    "comment": "For issues related to FTV/Binge box/DTH, please go to https://bit.ly/3JeW89y",
                    "time": "2023-09-15T06:01:38.000+00:00"
                }
            ],
            "openingComments": "Additional comments: 234234",
            "reopenComments": "reopen",
            "reCloseComments": "For issues related to FTV/Binge box/DTH, please go to https://bit.ly/3JeW89y",
            "relatedEntity": [
                {
                    "id": "4029472859",
                    "@referredType": "CUSTOMER",
                    "role": "CUSTOMER"
                }
            ],
            "channel": {
                "id": "MSPNU",
                "name": "CHATBOT",
                "@type": "Channel"
            },
            "statusChange": [
                {
                    "status": "CLOSE",
                    "changeReason": null,
                    "changeDate": "2023-09-15T11:31:38.000Z",
                    "@type": "STATUS"
                },
                {
                    "status": "REOPEN",
                    "changeReason": null,
                    "changeDate": "2023-09-15T11:30:13.000Z",
                    "@type": "STATUS"
                },
                {
                    "status": "CLOSE",
                    "changeReason": null,
                    "changeDate": "2023-09-15T11:29:25.000Z",
                    "@type": "STATUS"
                },
                {
                    "status": "FORWARD",
                    "changeReason": null,
                    "changeDate": "2023-09-15T11:21:02.000Z",
                    "@type": "STATUS"
                }
            ],
            "note": [],
            "relatedParty": [
                {
                    "id": "8089898989",
                    "@referredType": "CUSTOMER",
                    "role": "CUSTOMER"
                }
            ],
            "ticketRelationship": [],
            "attachment": [],
            "@type": "COMPLAINT"
        },
        { // Reopen twice and closed once
            "source": "CHATBOT",
            "category": "Account and Profile",
            "subCategory": "Manage Subscription",
            "leafCategory": "Pack not updating",
            "deviceName": "Android TV",
            "id": "CMSPNU20230915375538",
            "description": "Additional comments: 234234",
            "externalId": "4029472859",
            "ticketType": "MSPNU",
            "creationDate": "2023-09-15T11:21:03.000Z",
            "lastUpdate": "2023-09-16T11:35:00.000Z",
            "priority": "HIGH",
            "requestedResolutionDate": "2023-09-15T11:21:03.000Z",
            "expectedResolutionDate": "2023-09-16T11:35:00.000Z",
            "status": "REOPEN",
            "closeComments": "For issues related to FTV/Binge box/DTH, please go to https://bit.ly/3JeW89y",
            "resCodeDescription": "",
            "reOpenCounter": "2",
            "reopeningComments": [
                {
                    "comment": "Dear customer, your re-opened complaint for TATA Play Binge with complaint ID |CASE_ID|, \r\nis created and assigned to the concerned team. \r\nRest assured, your ticket will be resolved on or before |COMMIT_TIME|. \r\nYou can also track your complaint status at |LINK1|. Thank you",
                    "time": "2023-09-15T06:05:16.000+00:00"
                },
                {
                    "comment": "Dear customer, your re-opened complaint for TATA Play Binge with complaint ID |CASE_ID|, \r\nis created and assigned to the concerned team. \r\nRest assured, your ticket will be resolved on or before |COMMIT_TIME|. \r\nYou can also track your complaint status at |LINK1|. Thank you",
                    "time": "2023-09-15T06:00:13.000+00:00"
                }
            ],
            "recloseComments": [
                {
                    "comment": "For issues related to FTV/Binge box/DTH, please go to https://bit.ly/3JeW89y",
                    "time": "2023-09-15T06:01:38.000+00:00"
                }
            ],
            "openingComments": "Additional comments: 234234",
            "reopenComments": "Reoping twice",
            "reCloseComments": "For issues related to FTV/Binge box/DTH, please go to https://bit.ly/3JeW89y",
            "relatedEntity": [
                {
                    "id": "4029472859",
                    "@referredType": "CUSTOMER",
                    "role": "CUSTOMER"
                }
            ],
            "channel": {
                "id": "MSPNU",
                "name": "CHATBOT",
                "@type": "Channel"
            },
            "statusChange": [
                {
                    "status": "REOPEN",
                    "changeReason": null,
                    "changeDate": "2023-09-15T11:35:16.000Z",
                    "@type": "STATUS"
                },
                {
                    "status": "UNATTENDED",
                    "changeReason": null,
                    "changeDate": "2023-09-15T11:35:16.000Z",
                    "@type": "STATUS"
                },
                {
                    "status": "CLOSE",
                    "changeReason": null,
                    "changeDate": "2023-09-15T11:31:38.000Z",
                    "@type": "STATUS"
                },
                {
                    "status": "REOPEN",
                    "changeReason": null,
                    "changeDate": "2023-09-15T11:30:13.000Z",
                    "@type": "STATUS"
                },
                {
                    "status": "CLOSE",
                    "changeReason": null,
                    "changeDate": "2023-09-15T11:29:25.000Z",
                    "@type": "STATUS"
                },
                {
                    "status": "FORWARD",
                    "changeReason": null,
                    "changeDate": "2023-09-15T11:21:02.000Z",
                    "@type": "STATUS"
                }
            ],
            "note": [],
            "relatedParty": [
                {
                    "id": "8089898989",
                    "@referredType": "CUSTOMER",
                    "role": "CUSTOMER"
                }
            ],
            "ticketRelationship": [],
            "attachment": [],
            "@type": "COMPLAINT"
        },
        { // Reopen twice and closed twice
            "source": "CHATBOT",
            "category": "Account and Profile",
            "subCategory": "Manage Subscription",
            "leafCategory": "Pack not updating",
            "deviceName": "Android TV",
            "id": "CMSPNU20230915375538",
            "description": "Additional comments: 234234",
            "externalId": "4029472859",
            "ticketType": "MSPNU",
            "creationDate": "2023-09-15T11:21:03.000Z",
            "lastUpdate": "2023-09-16T11:35:00.000Z",
            "priority": "HIGH",
            "requestedResolutionDate": "2023-09-15T11:21:03.000Z",
            "expectedResolutionDate": "2023-09-16T11:35:00.000Z",
            "resolutionDate": "2023-09-15T11:36:06.000Z",
            "status": "CLOSE",
            "closeComments": "For issues related to FTV/Binge box/DTH, please go to https://bit.ly/3JeW89y",
            "resCodeDescription": "FTV/DTH/Binge box",
            "reOpenCounter": "2",
            "reopeningComments": [
                {
                    "comment": "Dear customer, your re-opened complaint for TATA Play Binge with complaint ID |CASE_ID|, \r\nis created and assigned to the concerned team. \r\nRest assured, your ticket will be resolved on or before |COMMIT_TIME|. \r\nYou can also track your complaint status at |LINK1|. Thank you",
                    "time": "2023-09-15T06:05:16.000+00:00"
                },
                {
                    "comment": "Dear customer, your re-opened complaint for TATA Play Binge with complaint ID |CASE_ID|, \r\nis created and assigned to the concerned team. \r\nRest assured, your ticket will be resolved on or before |COMMIT_TIME|. \r\nYou can also track your complaint status at |LINK1|. Thank you",
                    "time": "2023-09-15T06:00:13.000+00:00"
                }
            ],
            "recloseComments": [
                {
                    "comment": "For issues related to FTV/Binge box/DTH, please go to https://bit.ly/3JeW89y",
                    "time": "2023-09-15T06:06:06.000+00:00"
                },
                {
                    "comment": "For issues related to FTV/Binge box/DTH, please go to https://bit.ly/3JeW89y",
                    "time": "2023-09-15T06:01:38.000+00:00"
                }
            ],
            "openingComments": "Additional comments: 234234",
            "reopenComments": "Reoping twice",
            "reCloseComments": "For issues related to FTV/Binge box/DTH, please go to https://bit.ly/3JeW89y",
            "relatedEntity": [
                {
                    "id": "4029472859",
                    "@referredType": "CUSTOMER",
                    "role": "CUSTOMER"
                }
            ],
            "channel": {
                "id": "MSPNU",
                "name": "CHATBOT",
                "@type": "Channel"
            },
            "statusChange": [
                {
                    "status": "CLOSE",
                    "changeReason": null,
                    "changeDate": "2023-09-15T11:36:06.000Z",
                    "@type": "STATUS"
                },
                {
                    "status": "REOPEN",
                    "changeReason": null,
                    "changeDate": "2023-09-15T11:35:16.000Z",
                    "@type": "STATUS"
                },
                {
                    "status": "CLOSE",
                    "changeReason": null,
                    "changeDate": "2023-09-15T11:31:38.000Z",
                    "@type": "STATUS"
                },
                {
                    "status": "REOPEN",
                    "changeReason": null,
                    "changeDate": "2023-09-15T11:30:13.000Z",
                    "@type": "STATUS"
                },
                {
                    "status": "CLOSE",
                    "changeReason": null,
                    "changeDate": "2023-09-15T11:29:25.000Z",
                    "@type": "STATUS"
                },
                {
                    "status": "FORWARD",
                    "changeReason": null,
                    "changeDate": "2023-09-15T11:21:02.000Z",
                    "@type": "STATUS"
                }
            ],
            "note": [],
            "relatedParty": [
                {
                    "id": "8089898989",
                    "@referredType": "CUSTOMER",
                    "role": "CUSTOMER"
                }
            ],
            "ticketRelationship": [],
            "attachment": [],
            "@type": "COMPLAINT"
        },
    ]
};
