import ENV_CONFIG from '@config/environment/index';
import mixpanel from "mixpanel-browser";
import { getPubnubChannelName, handlePubnubData, isUserloggedIn, getMediaReadyChannelName, handleMediaReadyPubnubData } from "@utils/common";
import Pubnub, { removePubNubListener } from "@utils/pubnub";
import EventRover, { removeEventRoverListener } from "@utils/eventRover";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { LOCALSTORAGE, SESSION_STORAGE } from '@utils/constants';
import { getKey } from '@utils/storage';

const mixPanelLib = () => {
    if (mixpanel) {
        mixpanel.init(ENV_CONFIG.MIXPANEL.KEY, {
            debug: ENV_CONFIG.MIXPANEL.DEBUG,
            opt_out_tracking_by_default: false,
            ignore_dnt: true
        });
        mixpanel.reset();
    }
};

export const initiateAppEventListener = (login, mediaReadyPubnub) => {
    const enableEventRover = JSON.parse(getKey(LOCALSTORAGE.ENABLE_EVENT_ROVER));
    if (enableEventRover) {
        initiateEventRover(login);
    } else {
        iniiatePubnub(login, mediaReadyPubnub);
    }
}

export const initiateEventRover = async (login = false) => {
    let channelName = getPubnubChannelName();
    let isBingeTrialFlow = !!JSON.parse(sessionStorage.getItem(SESSION_STORAGE.IS_BINGE_TRIAL_FLOW)); // Pubnub Flow is restricted for Binge Trial Flow

    if (channelName && isUserloggedIn() && !isBingeTrialFlow) {
        let callbackFun = (data) => {
             handlePubnubData(data, 'from push for event rover app')
        };
        EventRover(channelName, callbackFun, login);
    }
}

export const iniiatePubnub = (login = false, mediaReadyPubnub = false) => {
    let loggedInChannelName = getPubnubChannelName(),
        mediaReadyChannelName = getMediaReadyChannelName(),
        channelName = mediaReadyPubnub ? mediaReadyChannelName : loggedInChannelName;
    let isBingeTrialFlow = !!JSON.parse(sessionStorage.getItem(SESSION_STORAGE.IS_BINGE_TRIAL_FLOW)); // Pubnub Flow is restricted for Binge Trial Flow

    if ((loggedInChannelName && isUserloggedIn() && !isBingeTrialFlow) || (mediaReadyChannelName && mediaReadyPubnub)) {
        let callbackFun = (data) => {
            mediaReadyPubnub ? handleMediaReadyPubnubData(data, 'from push for media ready pubnub') : handlePubnubData(data, 'from push for pubnub app')
        };
        Pubnub(channelName, callbackFun, login, mediaReadyPubnub);
    }
}

export const removeAppEventListener = (channelName) => {
    const enableEventRover = JSON.parse(getKey(LOCALSTORAGE.ENABLE_EVENT_ROVER));
    if (enableEventRover) {
        removeEventRoverListener(channelName);
    } else {
        removePubNubListener(channelName)
    }
}


export const firebaseAnalyticsInfo = () => {
    // Initialize Firebase
    const app = initializeApp(ENV_CONFIG.FIREBASE_CONFIG);
    getAnalytics(app);
}

export default () => {
    mixPanelLib();
    initiateAppEventListener();
    firebaseAnalyticsInfo();
};
