import React, { useCallback, useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { isMobile } from '@utils/common';

import dataLayerConfig from "@utils/dataLayer";
import DATALAYER from "@utils/constants/dataLayer";

import FooterArrow from '@assets/images/footer-arrow.svg';

import './style.scss';

function FooterCard({ footerData }) {
    const [activeIndex, setActiveIndex] = useState('-1')
    const listRef = useRef([]);
    const [getTitleWidth, setGetTitleWidth] = useState('')

    const handleCurrentIndex = useCallback((index) => {
        isMobile.any() && setActiveIndex(previndex => previndex !== index ? index : '-1')
    }, [activeIndex]);

    const trackDataLayer = (rowData, linkData) => {
        dataLayerConfig.trackEvent(DATALAYER.EVENT.FOOTER_CLICKS, {
            [DATALAYER.PARAMETER.RAIL_TITLE]: rowData?.footerTitle,
            [DATALAYER.PARAMETER.FOOTER_BUTTON_NAME]: linkData?.title,
        })
    }
    useEffect(() => {
        const getTitleWidth = Math.floor(document.querySelector('.footer-card-container')?.getBoundingClientRect()?.width) - 10
        setGetTitleWidth(getTitleWidth);
        window.addEventListener('resize', (e)=>{
            e.preventDefault()
            const getTitleWidth = Math.floor(document.querySelector('.footer-card-container')?.getBoundingClientRect()?.width) - 10
            setGetTitleWidth(getTitleWidth);
        })
        return () => {
            window.removeEventListener('resize', () => {
            });
        };
    },[])

    const handleUrl = (url) => {
        if (!url?.startsWith('/')) {
            return '/' + url;
        }
        return url;
    }

    return (
        <article className='footer-container'>
            {footerData?.map((data, index) =>
                <section key={index} className={`footer-card-container ${(isMobile.any() && activeIndex == index) ? 'active' : ''}`}>
                    <section className='title-wrapper'>
                        <p onClick={() => handleCurrentIndex(index)} style={{ width: `${getTitleWidth}px` }} className={getTitleWidth ? 'ellipsis-text' :''}>{data?.footerTitle}</p>
                        {isMobile.any() && <figure onClick={() => handleCurrentIndex(index)}><img src={FooterArrow} alt="arrow" className={activeIndex == index ? 'active' : ''} /></figure>}
                    </section>
                    <section className='link-wrapper' ref={ref => listRef.current[index] = ref}
                        style={
                            isMobile.any() && (activeIndex == index
                                ? { height: listRef.current?.[activeIndex].scrollHeight }
                                : { height: "0px" })}>
                        {data?.footerItemsList?.map((linkData, index) => <p
                            key={index}
                            className={`link-name ${getTitleWidth ? 'ellipsis-text' :''}`}
                            onClick={() => trackDataLayer(data, linkData)}
                            style={{ width: `${getTitleWidth}px` }}
                        ><Link to={handleUrl(linkData?.link)}>{linkData?.title}</Link></p>)}
                    </section>
                </section>
            )}
        </article>
    )
}

FooterCard.propTypes = {
    footerData: PropTypes.array
}

export default FooterCard
