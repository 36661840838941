import { get } from "lodash";

import store from "@src/store";
import { DTH_TYPE, LOCALSTORAGE } from "@utils/constants";
import { getKey } from "@utils/storage";
import { isAtvFsUser } from "@utils/common";

import mixPanelConfig from "@utils/mixpanel";
import MIXPANEL from "@constants/mixpanel";

import { setRenewNudgeStatus } from "@containers/Subscription/APIs/action";
import { SUBSCRIPTION_STATUS } from "@containers/Subscription/APIs/constant";
import { isSubscriptionStatusEmpty } from "@containers/Subscription/APIs/subscriptionCommon";

import { NUDGE_ACTION } from "./constant";

const changeDaysToMillis = (days) => {
    return parseInt(days) * 24 * 60 * 60 * 1000;
}

const hasLastNudgeShownTimePassed = (lastNudgeTimeMillis, nextNudgeGapMillis) => {
    let currentTimeinMilisec = Date.now();
    return nextNudgeGapMillis >= 0 && currentTimeinMilisec - lastNudgeTimeMillis >= nextNudgeGapMillis;
}

export const checkNudgeVisiblity = () => {
    let state = store.getState();
    let currentSubscription = get(state.subscriptionDetails, 'currentSubscription.data');
    let currentSubscriptionStatus = get(currentSubscription, 'subscriptionStatus')?.toUpperCase();

    if (currentSubscriptionStatus === SUBSCRIPTION_STATUS.ACTIVE) {
        conditionsToShowRenewPlanBeforeExpiryNudge(); // Nudge Before expiry (d-x)
    } else {
        conditionsToShowRenewPlanAfterExpiryNudge(); // Nudge After Expiry (d+x)
    }
}

/**
 * checkNudgeActionCondition : Checks action specific condition to show nudge based on nudge action
 * @param {*} nudgeAction 
 * @returns boolean 
 */
export const checkNudgeActionCondition = (nudgeAction) => {
    let nudgeActionCondition = false;
    let state = store.getState();
    let currentSubscription = get(state.subscriptionDetails, 'currentSubscription.data');
    let userInfo = JSON.parse(getKey(LOCALSTORAGE.USER_INFO)) || {};

    if (nudgeAction === NUDGE_ACTION.RECHARGE) {
        nudgeActionCondition = userInfo?.dthStatus !== DTH_TYPE.NON_DTH_USER;
    } else if (nudgeAction === NUDGE_ACTION.SUBSCRIBE) {
        nudgeActionCondition = isSubscriptionStatusEmpty();
    } else if (nudgeAction === NUDGE_ACTION.RENEW) {
        nudgeActionCondition = !!currentSubscription?.planOption?.renewButtonOption || isSubscriptionStatusEmpty();
    }

    return nudgeActionCondition;
}

/**
 * conditionsToShowRenewPlanBeforeExpiryNudge : Check conditions to show nudge befor expiry (RENEW / RECHARGE)
 */
const conditionsToShowRenewPlanBeforeExpiryNudge = () => {
    const state = store.getState(),
        currentSubscription = get(state.subscriptionDetails, 'currentSubscription.data'),
        subscriptionNudgesDetails = get(currentSubscription, 'subscriptionNudgesDetails'),
        nudgesDetails = get(state.headerDetails, "configResponse.data.app.nudgesDetails.web.renewNudgeActive");

    let nudgeActionCondition = !!subscriptionNudgesDetails?.nudgeAction && checkNudgeActionCondition(subscriptionNudgesDetails?.nudgeAction),
        currentDay = Math.abs(parseInt(subscriptionNudgesDetails?.currentDay || 0)),
        nudgeToShowOnWhichDayBeforeExpiry = parseInt(subscriptionNudgesDetails?.nudgeToShowOnWhichDayBeforeExpiry || 0);

    let checkDaysLogic = nudgeToShowOnWhichDayBeforeExpiry >= currentDay;

    let packConditions = !currentSubscription?.fdoRequested && !currentSubscription?.freeTrialStatus
        && checkDaysLogic
        && nudgeActionCondition;

    let lastShowTime = JSON.parse(getKey(LOCALSTORAGE.LAST_SHOWN_NUDGE_TIMESTAMP)),
        timeGap = lastShowTime === null ? nudgesDetails?.timeFrequency : nudgesDetails?.launchFrequency,
        timeGapinMilisec = changeDaysToMillis(timeGap),
        timeMatched = hasLastNudgeShownTimePassed(lastShowTime, timeGapinMilisec);

    let showRenewNudge = nudgesDetails?.enableNudge && packConditions && timeMatched;

    if (showRenewNudge) {
        store.dispatch(setRenewNudgeStatus(true));
        mixPanelConfig.trackEvent(MIXPANEL.EVENT.RENEW_NUDGE_VIEW, {
            [MIXPANEL.PARAMETER.NUDGE_TYPE]: MIXPANEL.VALUE.BEFORE_EXPIRY,
            [MIXPANEL.PARAMETER.NUDGE_PAYMENT_TYPE]: get(subscriptionNudgesDetails, 'paymentType')
        });
    } else {
        store.dispatch(setRenewNudgeStatus(false));
    }
}

/**
 * conditionsToShowRenewPlanAfterExpiryNudge : Check conditions to show nudge after expiry (RENEW / RECHARGE / SUSBCRIBE)
 */
const conditionsToShowRenewPlanAfterExpiryNudge = () => {
    const state = store.getState(),
        currentSubscription = get(state.subscriptionDetails, 'currentSubscription.data'),
        subscriptionNudgesDetails = get(currentSubscription, 'subscriptionNudgesDetails'),
        freeTrialNudgesDetails = get(currentSubscription, 'freeTrialNudgesDetails'),
        nudgesDetails = get(state.headerDetails, "configResponse.data.app.nudgesDetails.web.renewNudgeExpired");

    let nudgeDataToBeUsed = currentSubscription?.freeTrialStatus ? freeTrialNudgesDetails : subscriptionNudgesDetails;

    let nudgeActionCondition = !!nudgeDataToBeUsed?.nudgeAction && checkNudgeActionCondition(nudgeDataToBeUsed?.nudgeAction),
        currentDay = Math.abs(parseInt(nudgeDataToBeUsed?.currentDay || 0)),
        nudgeToShowOnWhichDayAfterExpiry = currentSubscription?.freeTrialStatus ? parseInt(nudgeDataToBeUsed?.nudgeToShowOnWhichDayAfterExpiryTrial || 0) : parseInt(nudgeDataToBeUsed?.nudgeToShowOnWhichDayAfterExpiry || 0);

    let checkDaysLogic = nudgeDataToBeUsed?.nudgeAction === NUDGE_ACTION.RENEW
        ? (nudgeToShowOnWhichDayAfterExpiry >= currentDay)
        : true

    let paidPackConditions = !currentSubscription?.fdoRequested && !currentSubscription?.combo
        && checkDaysLogic
        && nudgeActionCondition;

    let freeTrialPackCondition = !isAtvFsUser() && paidPackConditions

    let packConditions = currentSubscription?.freeTrialStatus && nudgeDataToBeUsed?.nudgeAction !== NUDGE_ACTION.RECHARGE ? freeTrialPackCondition : paidPackConditions;

    let lastShowTime = JSON.parse(getKey(LOCALSTORAGE.LAST_SHOWN_NUDGE_TIMESTAMP)),
        timeGap = lastShowTime === null ? nudgesDetails?.timeFrequency : nudgesDetails?.launchFrequency,
        timeGapinMilisec = changeDaysToMillis(timeGap),
        timeMatched = hasLastNudgeShownTimePassed(lastShowTime, timeGapinMilisec);

    let showRenewNudge = nudgesDetails?.enableNudge && packConditions && timeMatched;

    if (showRenewNudge) {
        store.dispatch(setRenewNudgeStatus(true));
        mixPanelConfig.trackEvent(MIXPANEL.EVENT.RENEW_NUDGE_VIEW, {
            [MIXPANEL.PARAMETER.NUDGE_TYPE]: MIXPANEL.VALUE.AFTER_EXPIRY,
            [MIXPANEL.PARAMETER.NUDGE_PAYMENT_TYPE]: get(nudgeDataToBeUsed, 'paymentType')
        });
    } else {
        store.dispatch(setRenewNudgeStatus(false));
    }
}