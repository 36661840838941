import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'

function SoftwareApplicationSchema() {
    return (
        <Fragment>
        <Helmet>
        <script type="application/ld+json">
            {`
                {
                   "@context": "https://schema.org",
                   "@type": "MobileApplication",
                   "name": "Tata Play Binge",
                   "url": "https://www.tataplaybinge.com/",
                   "operatingSystem": "Android",
                   "applicationCategory": "EntertainmentApplication",
                   "aggregateRating": {
                      "@type": "AggregateRating",
                      "ratingValue": "4.2",
                      "ratingCount": "48116"
                    }
                }
            `}
            </script>
        </Helmet>
        </Fragment>
    )
}

export default React.memo(SoftwareApplicationSchema)