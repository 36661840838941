export const ACTION = {
    SET_CONTINUE_WATCHING: 'SET_CONTINUE_WATCHING',
    ADD_WATCHLIST_DATA: 'ADD_WATCHLIST_DATA',
    CHECK_WATCHLIST: 'CHECK_WATCHLIST',
    CHECK_NEXT_PREV_EPISODE: 'CHECK_NEXT_PREV_EPISODE',
    GET_VOOT: 'GET_VOOT',
    ADD_WATCHLIST_ERROR_DATA: 'ADD_WATCHLIST_ERROR_DATA',
    CHECK_WATCHLIST_ERROR: 'CHECK_WATCHLIST_ERROR',
    CHECK_NEXT_PREV_ERROR: 'CHECK_NEXT_PREV_ERROR',
    SET_LA_DATA: 'SET_LA_DATA',
    SET_PLAYER_API_ERROR: 'SET_PLAYER_API_ERROR',
    GET_ZEE5: 'GET_ZEE5',
    GENERATE_TOKEN: 'GENERATE_TOKEN',
    RESEND_LICENSE: 'RESEND_LICENSE',
    SET_VIEW_COUNT_LA_DATA: 'SET_VIEW_COUNT_LA_DATA',
    SET_EPCION_DOCUBAY_ANALYTICS_DATA: 'SET_EPCION_DOCUBAY_ANALYTICS_DATA',
    SET_LIONSGATE_ANALYTICS_DATA: 'SET_LIONSGATE_ANALYTICS_DATA',
    GET_SONY_TOKEN: 'GET_SONY_TOKEN',
    GET_HOI_CHOI_TOKEN: 'GET_HOI_CHOI_TOKEN',
    GET_VIDEO_QUALITY: 'GET_VIDEO_QUALITY',
    GET_PLANET_MARATHI: 'GET_PLANET_MARATHI',
    GET_CHAUPAL: 'GET_CHAUPAL',
    GET_CHAUPAL_ERROR: 'GET_CHAUPAL_ERROR',
    GET_LIONS_GATE_DATA: 'GET_LIONSGATE_DATA',
    GET_LIONS_GATE_ERROR: 'GET_LIONS_GATE_ERROR',
    GET_GENERIC_DRM: 'GET_GENERIC_DRM',
    GET_GENERIC_DRM_ERROR: 'GET_GENERIC_DRM_ERROR',
    GET_DIGITAL_FEED: 'GET_DIGITAL_FEED',
    GET_DIGITAL_FEED_ERROR: 'GET_DIGITAL_FEED_ERROR',
    ADD_GAMEZOP_WATCHLIST_DATA: 'ADD_GAMEZOP_WATCHLIST_DATA',
    GET_APPLE_DEEPLINK_REDEMPTION_URL: 'GET_APPLE_DEEPLINK_REDEMPTION_URL',
    PARTNER_CW_DATA: 'PARTNER_CW_DATA',
    PARTNER_CW_ERROR_DATA: 'PARTNER_CW_ERROR_DATA',
    FETCH_LIVE_PLAYBACK_URL: 'FETCH_LIVE_PLAYBACK_URL',
    RESET_GENERIC_DRM: 'RESET_GENERIC_DRM',
    SEND_DISTRO_TRACK_EVENT: 'SEND_DISTRO_TRACK_EVENT',
    CLEAR_CHECK_PREV_NEXT_DATA: 'CLEAR_CHECK_PREV_NEXT_DATA',
};