export const ACTION = {
    OPEN_MOBILEPOPUP: "OPEN_MOBILEPOPUP",
    CLOSE_MOBILEPOPUP: "CLOSE_MOBILEPOPUP",
    FETCH_INVOICEPDF:"FETCH_INVOICEPDF"
};

export const TRANSACTIONSMESSAGES = {
    INVOICE_DOWNLOADED: "Invoice Downloaded",
    NO_INVOICE_DOWNLOADED: "No Invoice available",
    INVOICE_DOWNLOADED_ERROR: "Invoice download error"
};
