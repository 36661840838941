export const ACTION = {
    OPEN_MODAL: 'OPEN_MODAL',
    CLOSE_MODAL: 'CLOSE_MODAL',
    LOGIN_MODAL: "LOGIN_MODAL",
    ALERT_MODAL: "ALERT_MODAL",
    LOGIN_SIGNUP: 'LOGIN_SIGNUP',
    RESET: 'RESET',
};

export const MODALS = {
    ALERT_MODAL: 'ALERT_MODAL',
    CUSTOM_MODAL: 'CUSTOM_MODAL',
    SUBSCRIPTION_CHANGE_TENURE: 'SUBSCRIPTION_CHANGE_TENURE',
    LOGOUT_MODAL: 'LOGOUT_MODAL',
    COMMENT_MODAL: 'COMMENT_MODAL',
    FIBRE_MODAL: 'FIBRE_MODAL'
};
