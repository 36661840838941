import React from 'react';
import {PLAY_STORE_URL} from '@constants';
import './style.scss';
import GooglePlay from "@assets/images/play-store.png"
import AppleStore from "@assets/images/apple-store.png"
import {fireFooterClickEvent, downloadNudgeClickMixpanelEvent} from '@utils/common';
import DATALAYER from '@utils/constants/dataLayer';
import { useSelector } from 'react-redux';
import get from "lodash/get";
import MIXPANEL from "@constants/mixpanel";

const DownloadLink = (props) => {
    const downloadAppText = useSelector(state => get(state, "headerDetails.configResponse.data.config.hamburger.subTextCta"));

    const googlePlayClick = () => {
        fireFooterClickEvent(DATALAYER.VALUE.PLAY_STORE);
        downloadNudgeClickMixpanelEvent(MIXPANEL.VALUE.PLAYSTORE, props?.source);
    }

    const appleStoreClick = () => {
        fireFooterClickEvent(DATALAYER.VALUE.APP_STORE);
        downloadNudgeClickMixpanelEvent(MIXPANEL.VALUE.APPSTORE, props?.source);
    }

    return (
        <div className='download-link'>
            <span>{downloadAppText}</span>
            <span className={"app-store-btn"}>
                <a href={PLAY_STORE_URL.ANDROID} onClick={() => googlePlayClick()}
                   target="_blank" rel="noreferrer">
                    <img src={GooglePlay} className='play-store' alt="google-play-store" loading='lazy'/>
                </a>
                <a href={PLAY_STORE_URL.IOS} target="_blank" rel="noreferrer" 
                onClick={() => appleStoreClick()}>
                    <img src={AppleStore} className='apple-store' alt="app-store" loading='lazy' />
                </a>
            </span>
        </div>
    )
};

export default DownloadLink;