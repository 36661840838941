import { setLoginManual } from '@containers/Login/APIs/actions';
import { SUBSCRIPTION_STATUS } from '@containers/Subscription/APIs/constant';
import { getSearchParam, isHelpCenterWebView, isMobile, isUTMWebDeeplinkUrl, isUserloggedIn } from '@utils/common';
import { LOCALSTORAGE, SESSION_STORAGE } from '@utils/constants';
import MIXPANEL from '@utils/constants/mixpanel';
import mixPanelConfig from '@utils/mixpanel';
import moengageConfig from '@utils/moengage';
import { getKey } from '@utils/storage';
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fetchMixpanelId } from '@src/action';
import { URL } from '@utils/constants/routeConstants';
import { isSubscriptionStatusEmpty } from '@containers/Subscription/APIs/subscriptionCommon';
import { getCurrentSubscriptionInfo } from '@containers/PackSelection/APIs/action';
import { useLocation } from 'react-router';
import moment from "moment";
import { isEmpty } from 'lodash';

export default function useMixpanel() {
  const [distinctId, setDistinctId] = useState();

  const location = useLocation();

  const dispatch = useDispatch();

  const loggedStatus = useSelector(state => state.commonContent?.loggedStatus);
  const isManualLogin = useSelector(state => state.loginReducer?.isManualLogin);
  const loginSource = useSelector(state => state.loginReducer.manualLoginSource);
  const currentSubscription = useSelector(state => state.subscriptionDetails?.currentSubscription?.data);

  const initialMount = useRef(true);

  /** Handle When user manually logs in */
  useEffect(() => {
    if (loggedStatus && isManualLogin && !initialMount.current) {
      isEmpty(currentSubscription) && dispatch(getCurrentSubscriptionInfo());
      setUserProperties();
      triggerLoginEvent();
    }
  }, [loggedStatus, isManualLogin])

  /** Handle When user currentSubscription updates */
  useEffect(() => {
    !isSubscriptionStatusEmpty() && setUserProperties();
  }, [currentSubscription])

  /** Handle when user logs out */
  useEffect(() => {
    if (!loggedStatus && !initialMount.current) {
      mixPanelConfig.resetUserType();
    }
  }, [loggedStatus])

  /** Handle on mount */
  useEffect(() => {
    const token = getSearchParam('token');
    let helpCenterToken = getKey(LOCALSTORAGE.HELP_CENTER_TOKEN),
      utmDeeplinkUrl = isUTMWebDeeplinkUrl();
    initialMount.current = false;
    if (isMobile.any() && token && window.location.pathname.includes(URL.HELP_CENTER)) {
      // do nothing if user redirected from app to helpcenter 
      if (token !== helpCenterToken?.slice(7))
        return;
    } else if (utmDeeplinkUrl) {
      // Store a session for utm url so that mixpanel property can be tracked only for that particular session
      sessionStorage.setItem(SESSION_STORAGE.UTM_DEEPLINK_URL, true);
    }

    setUserProperties();
  }, [])

  const triggerLoginEvent = () => {
    if (!isHelpCenterWebView()) {
      let mixpanel = {
        [`${MIXPANEL.PARAMETER.USER_STATE}`]: currentSubscription?.subscriptionStatus === SUBSCRIPTION_STATUS.ACTIVE ? MIXPANEL.VALUE.SUBSCRIBED : MIXPANEL.VALUE.FREEMIUM,
        [`${MIXPANEL.PARAMETER.SOURCE}`]: loginSource || '',
      }
      loginSource === MIXPANEL.VALUE.DISCOUNTING_PAGE && (mixpanel = { ...mixpanel, ...{ [`${MIXPANEL.PARAMETER.PAGE_NAME}`]: loginSource || '', } })
      mixPanelConfig.trackEvent(MIXPANEL.EVENT.LOGIN_SUCCESS, mixpanel);
    }
    dispatch(setLoginManual(false))
  }


  const setUserProperties = async () => {
    
    if (isUserloggedIn()) {
      mixPanelConfig.unsetSuperProperties();
    }
    let userInfo = JSON.parse(getKey(LOCALSTORAGE.USER_INFO)) || {};
    if (isUserloggedIn() && !userInfo?.mixpanelId) {
      await dispatch(fetchMixpanelId());
    }
    mixPanelConfig.unsetUTMSuperProperties();  // Unset Mixpanel super properties for utm if already set
    mixPanelConfig.setSuperProperties();
    mixPanelConfig.setGroup();
    mixPanelConfig.setGroupProperties();
    mixPanelConfig.setPeopleProperties();
    moengageConfig.setUserAttributes(uniqueId);
    const uniqueId = mixPanelConfig.identifyUser();
    setDistinctId(uniqueId);
    if (!isHelpCenterWebView()) {
      const mixpanel = {
        [`${MIXPANEL.PARAMETER.TIMESTAMP}`]: moment().valueOf(),
        [MIXPANEL.PARAMETER.FIRST_TIME]: userInfo.firstTimeLoginDate || "",
      };
    }
  }

  return distinctId;
}
