export default {
    EVENT: {
        APP_LAUNCH: 'APP-LAUNCH',
        LOGIN_ENTER: 'LOGIN-ENTER',
        LOGIN_OTP_INVOKE: 'LOGIN-OTP-INVOKE',
        LOGIN_OTP_ENTER: 'LOGIN-OTP-ENTER',
        LOGIN_SUCCESS: 'LOGIN-SUCCESS',
        LOGIN_FAILED: 'LOGIN-FAILED',
        MOE_LOGOUT: 'MOE_LOGOUT',
        HOME_PAGE_VIEW: 'HOME-PAGE-VIEW',
        HOME_CLICK: 'HOME-CLICK',
        SEE_ALL: 'SEE-ALL',
        VIEW_CONTENT_DETAIL: 'VIEW-CONTENT-DETAIL',
        ADD_CONTENT_FAVOURITE: 'ADD-CONTENT-FAVOURITE',
        PLAY_TRAILER: 'PLAY-TRAILER',
        VIEW_WATCHLIST: 'VIEW-WATCHLIST',
        VIEW_FAVORITE: "VIEW-FAVORITE",
        DELETE_FAVORITE: "DELETE-FAVORITE",
        LOGIN_OTP_RESEND: 'LOGIN-OTP-RESEND',
        LOGIN_PASSWORD: 'LOGIN-PASSWORD',
        PLAY_CONTENT: 'PLAY-CONTENT',
        INITIAL_BUFFER_TIME: 'INITIAL-BUFFER-TIME',
        PAUSE_CONTENT: 'PAUSE-CONTENT',
        RESUME_CONTENT: 'RESUME-CONTENT',
        PLAYBACK_FAILURE: 'PLAYBACK-FAILURE',
        LIST_DEVICES: 'LIST-DEVICES',
        MAX_DEVICE_LIMIT: 'MAX-DEVICE-LIMIT',
        REMOVE_DEVICE: 'REMOVE-DEVICE',
        DEVICE_REMOVE_CONFIRM: "DEVICE-REMOVE-CONFIRM",

        DELETE_FAVOURITE: 'DELETE-FAVOURITE',
        FORGOT_PASSWORD: 'FORGOT-PASSWORD',
        PASSWORD_RESET_SUCCESS: 'PASSWORD-RESET-SUCCESS',
        PASSWORD_RESET_FAILED: 'PASSWORD-RESET-FAILED',
        UPDATE_PASSWORD_INVOKED: 'UPDATE-PASSWORD-INVOKED',
        UPDATE_PASSWORD_SUCCESS: 'UPDATE-PASSWORD-SUCCESS',
        UPDATE_PASSWORD_FAILED: 'UPDATE-PASSWORD-FAILED',
        CREATE_PASSWORD_INVOKED: 'CREATE-PASSWORD-INVOKED',
        CREATE_PASSWORD_OTP_RECEIVED: 'CREATE-PASSWORD-OTP-RECEIVED',
        CREATE_PASSWORD_SUCCESS: 'CREATE-PASSWORD-SUCCESS',
        CREATE_PASSWORD_FAILED: 'CREATE-PASSWORD-FAILED',
        SEARCH_START: 'SEARCH-START',
        SEARCH: 'SEARCH',
        NO_SEARCH_RESULT: 'NO-SEARCH-RESULT',
        SEARCH_RESULT: 'SEARCH-RESULT',
        SEARCH_HOME: "SEARCH-HOME",
        SEARCH_RESULT_CLICKS: "SEARCH-RESULT-CLICKS",
        SEARCH_HOME_CLICKS:'SEARCH-HOME-CLICKS',
        PACK_SELECTION_INITIATE: 'PACK-SELECTION-INITIATE',
        ADD_PACK_SKIP: 'ADD-PACK-SKIP',
        ADD_PACK_CONTINUE: 'ADD-PACK-CONTINUE',
        PACK_SELECTED: 'PACK-SELECTED',
        SUBSCRIBE_SUCCESS: 'SUBSCRIBE-SUCCESS',
        START_FREE_TRIAL:'START-FREE-TRIAL',

        
        SUBSCRIBE_FAILED: 'SUBSCRIBE-FAILED',
        CONTACT_US: 'CONTACT-US',
        NOTIFICATION_SETTINGS:'NOTIFICATION-SETTINGS',

        TERMS_CONDITIONS: 'TERMS-CONDITIONS',
        PRIVACY_POLICY: 'PRIVACY-POLICY',
        EDIT_PROFILE: 'EDIT-PROFILE',
        TRANSACTION_HISTORY: 'TRANSACTION-HISTORY',
        RECHARGE_INITIATE: 'RECHARGE-INITIATE',
        RECHARGE_FAILED: 'RECHARGE-FAILED',
        FAQ_VIEW: 'FAQ-VIEW',
        UPDATE_PROFILE: 'UPDATE-PROFILE',
        HOME_ACCOUNT: 'HOME_ACCOUNT',
        SWITCH_PROFILE: 'SWITCH-PROFILE',
        FTV_UPSELL_VIEW: 'FTV-UPSELL-VIEW',
        FTV_UPSELL_CONVERTED: 'FTV-UPSELL-CONVERTED',
        FTV_WO: 'FTV-WO',
        MODIFY_PACK_INITIATE: 'MODIFY-PACK-INITIATE',
        MODIFY_PACK_SUCCESS: 'MODIFY-PACK-SUCCESS',
        NOT_A_TS_USER_BUTTON_CLICK: 'NOT-A-TS-USER-BUTTON-CLICK',
        NON_TS_RMN: 'NON-TS-RMN',
        ERROR: 'ERROR',
        FREE_TRIAL_LATER: 'FREE-TRIAL-LATER',
        PACK_CANCELLATION: 'PACK-CANCELLATION',
        CANCELLATION_REVOKED: 'CANCELLATION-REVOKED',
        PACK_CANCEL_SKIP: 'PACK-CANCEL-SKIP',
        PACK_REVOKE_SKIP: 'PACK-REVOKE-SKIP',
        NUDGE_CLICK: 'NUDGE-CLICK',
        HC_SEARCH_START: 'HC-SEARCH-START',
        HC_SEARCH: 'HC-SEARCH',
        HC_NO_SEARCH_RESULT: 'HC-NO-SEARCH-RESULT',
        HC_SEARCH_RESULT: 'HC-SEARCH-RESULT',
        HC_FAQ_CLICK: 'HC-FAQ-CLICK',
        HC_LIKE:'HC-LIKE',
        HC_DISLIKE:'HC-DISLIKE',
        HC_CHAT_FAQ:'HC-CHAT-FAQ',
        HC_CHAT_VIDEO:'HC-CHAT-VIDEO',
        HC_CHAT_DEFAULT:'HC-CHAT-DEFAULT',
    },
    VALUE: {
        TATA_SKY_SUBSCRIBER: "TATA SKY SUBSCRIBER",
        GUEST: "GUEST",
        YES: "YES",
        NO: "NO",
        HOME: 'Home',
        MOVIES: 'Movies',
        TV_SHOWS: 'Tv Shows',
        SPORTS: 'Sports',
        WATCHLIST: 'Watchlist',
        OTP: 'OTP',
        PASSWORD: 'Password',
        HERO: 'Hero',
        RAIL: 'Rail',
        EDITORIAL: 'EDITORIAL',
        RECOMMENDATION: 'Recommendation',
        BRAND: 'Brand',
        SERIES: 'Series',
        TVOD: 'TVOD',
        WEB_SHORTS: 'Web Shorts',
        CONTENT_DETAIL: 'Content-Detail',
        PARTNER_HOME: 'Partner Home',
        MIX: "MIX",
        SEARCH_TYPE: 'Text',
        SEARCH_SOURCE_MANUAL: 'Manual',
        SEARCH_SOURCE_RECENT_SEARCH: 'Recent Search',
        SEARCH_PAGE: 'Search Page',
        RESULT_PAGE: 'Result Page',
        APP_LAUNCH: "App Launch",
        RMN: 'RMN',
        SID: 'SID',
        SEARCH: 'Search',
        FAILED: 'Failed',
        LOGIN: 'Login',
        CONTENT: 'Content',
        MY_ACCOUNT: 'My Account',
        SEE_ALL: 'See-All',
        BROWSE_BY_LANGUAGE: 'Browse-By-Language',
        BROWSE_BY_GENRE: 'Browse-By-genre',
        BRAND_SEASON: 'Brand-Season',
        UNSUBSCRIBED: 'UNSUBSCRIBED',
        INSTALLER_FSD: 'FSD',
        INSTALLER_DIY: 'DIY',
        MBR: 'MBR',
        DBR: 'DBR',
        RENEW: 'Renew',
        UPGRADE: 'UPGRADE',
        DOWNGRADE: 'DOWNGRADE',
        PI_PAGE: 'PI-PAGE',
        PLAYER: 'PLAYER',
        SUBSCRIBED: 'Subscribed',
        UNSUBSCRIBED_SMALL: 'Unsubscribed',
        MY_SUBSCRIPTION: 'My Subscription',
        HOMESCREEN: 'Homescreen',
        HC_RESENT_SEARCH: 'Recent Search',
        HC_AUTO_COMPLETE: 'Auto-Complete',
        HC_MANUAL: 'Manual',
    },
    PARAMETER: {
        SOURCE: 'SOURCE',
        TITLE_SECTION: 'TITLE-SECTION',
        TYPE: 'TYPE',
        NAME: 'NAME',
        SECTION: 'SECTION',
        HERO_BANNER_NUMBER: 'HERO-BANNER-NUMBER',
        CONFIG_TYPE: 'CONFIG-TYPE',
        CONTENT_TYPE: 'CONTENT-TYPE',
        PARTNER: 'PARTNER',
        RAIL_TITLE: 'RAIL-TITLE',
        CONTENT_TITLE: 'CONTENT-TITLE',
        GENRE: 'GENRE',
        RAIL_POSITION: 'RAIL-POSITION',
        CONTENT_POSITION: 'CONTENT-POSITION',
        CONTENT:'CONTENT',
        POSITION:'POSITION',
        PARTNER_HOME: 'PARTNER-HOME',
        PAGE_NAME: 'PAGE-NAME',
        REASON: 'REASON',
        CONTENT_GENRE: 'CONTENT-GENRE',
        DURATION_MINUTES: 'DURATION-MINUTES',
        DURATION_SECONDS: 'DURATION-SECONDS',
        NUMBER_OF_RESUME: 'NUMBER-OF-RESUME',
        NUMBER_OF_PAUSE: 'NUMBER_OF_PAUSE',
        INITIAL_BUFFER_DURATION_MINUTES: 'INITIAL-BUFFER-DURATION-MINUTES',
        INITIAL_BUFFER_TIME_SECONDS: 'INITIAL-BUFFER-TIME-SECONDS',
        START_TIME: 'START-TIME',
        STOP_TIME: 'STOP-TIME',
        KEYWORD: 'KEYWORD',
        SEARCH_TYPE: 'SEARCH-TYPE',
        SEARCH_SOURCE: 'SOURCE',
        SCREEN_NAME: 'SCREEN-NAME',
        SEARCH_COUNT: 'SEARCH-COUNT',
        AUTH: 'AUTH',
        SUBSCRIBED: 'SUBSCRIBED',
        RAIL: 'RAIL',
        ORIGIN: 'ORIGIN',
        PACK_NAME: 'PACK-NAME',
        PACK_TYPE: 'PACK-TYPE',
        PACK_PRICE: 'PACK-PRICE',
        PARTNER_NAME: 'PARTNER-NAME',
        AMOUNT: 'AMOUNT',
        VALUE: 'VALUE',
        TO_BAID: 'TO-BAID',
        SID: 'SID',
        CONTENT_LANGUAGE: 'CONTENT-LANGUAGE',
        FILTER_LANGUAGE: "FILTER-LANGUAGE",
        UPGRADED_PACK_PRICE: 'UPGRADED-PACK-PRICE',
        ORDERED_FTV: 'ORDERED-FTV',
        FTV_ORDER_TYPE: 'FTV-ORDER-TYPE',
        MOD_TYPE: 'MOD-TYPE',
        ERROR_CODE: 'ERROR-CODE',
        ERROR_MESSAGE: 'ERROR-MESSAGE',
        VIEW_TYPE: 'VIEW-TYPE',
        IS_FROM_NUDGE: 'IS-FROM-NUDGE',
        FDO_REGISTERED: 'FDO-REGISTERED',
        FDR_RAISED_ON_DAY: 'FDR-RAISED-ON-DAY',
        BINGE_RAISED_ON: 'BINGE-RAISED-ON',
        PRIME_RAISED_ON: 'PRIME-RAISED-ON',
        BINGE_CANCELLATION_DATE: 'BINGE-CANCELLATION-DATE',
        PRIME_CANCELLATION_DATE: 'PRIME-CANCELLATION-DATE',
        BINGE_CANCELLATION: 'BINGE-CANCELLATION',
        PRIME_CANCELLATION:'PRIME-CANCELLATION',
        REVOKED_ON: 'REVOKED-ON',
        DTH_STATUS: 'DTH-STATUS',
        COUNTER_VALUE: 'COUNTER-VALUE',
        FILTER_GENRE: "FILTER-GENRE",
        DISPLAYED_ON_DAY: 'DISPLAYED-ON-DAY',
        FREE_TRIAL_AVAILED: 'FREE-TRIAL-AVAILED',
        FAQ_ID: 'FAQ-ID',
        VIDEO_ID: 'VIDEO-ID'
    },
    ANALYTICS: "MOENGAGE",
};
