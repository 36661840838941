import React from "react";
import PropTypes from "prop-types";

import openTicket from "@assets/images/open-ticket.svg";

import { TICKET_STATE, TICKET_TAG } from "../../APIs/constants";

let MAX_TICKET_CHAR = 9;
const TicketHeaderDescription = ({
    status,
    id,
    imageStatusWidth,
    isLandingPage,
}) => {

    /**
     * @function getTicketStatus
     * @params 
     * @return string
     */
    const getTicketStatus = () => {
        return [TICKET_STATE.CLOSE, TICKET_STATE.CLOSED].includes(status?.toLowerCase())
            ? TICKET_TAG.CLOSED
            : status?.toLowerCase() == TICKET_STATE.OPEN
                ? TICKET_TAG.OPEN
                : TICKET_TAG.REOPENED;
    };

    const getTicketStatusClassName = () => {
        if ([TICKET_STATE.OPEN, TICKET_STATE.REOPEN].includes(status?.toLowerCase())) {
            return "active";
        } else {
            return "done";
        }
    };

    let isStatusClose = status?.toLowerCase() == TICKET_STATE.CLOSE;
    let hoverOnMarquee = id?.toString()?.length > MAX_TICKET_CHAR;
    return (<div className="request-top">
        <div className="ticket-no">
            <div className="label">
                {!isStatusClose && (
                    <img
                        src={openTicket}
                        width={imageStatusWidth}
                        className="mR5"
                    />
                )}
                <div className={`marquee ${hoverOnMarquee && 'marquee-hover'}`}>
                    <span>{`Request #${id}`}</span>
                </div>
            </div>
        </div>
        <div
            className={`request-status ${isLandingPage ? "active-status" : ""
                }`}
        >
            <div className={`data ${getTicketStatusClassName()} `}>
                {getTicketStatus()}
            </div>
        </div>
    </div>)
}

export default TicketHeaderDescription;