import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from 'react-redux';


import MobileLogo from "@assets/images/newLogoSmall.svg";
import OTPComponent from "@containers/Login/OTPComponent";
import { VALIDATE_SOURCE } from "@utils/constants";
import { setKey } from "@utils/storage";
import { LOCALSTORAGE } from "@constants";
import { hideFooter, hideHeader } from "@src/action";
import { isUserloggedIn, callLogOut, isMobile, cloudinaryCarousalUrl } from "@utils/common";

import './style.scss';

export default function ValidateSmartTv(props) {
    const { webSmallRouteToken, wsRoute } = props;
    const configResponse = useSelector(state => state.headerDetails?.configResponse?.data?.config);
    const { image, numericCodeOfsFooter, numericCodeOfsHeader, numericCodeOfsFooterWebSmall } = configResponse?.numericCodeOfs;
    const [isCodeValidated, setIsCodeValidated] = useState(false);
    const validateSmartTvResp = useSelector(state => state?.loginReducer?.validateOfsSmartTvCode);
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        setKey(LOCALSTORAGE.IS_SMART_TV_VALIDATED, JSON.stringify(false));
        dispatch(hideHeader(true));
        dispatch(hideFooter(true));

        return () => {
            dispatch(hideHeader(false));
            dispatch(hideFooter(false));
        }
    }, []);

    useEffect(() => {
        if (isCodeValidated) {
            setKey(LOCALSTORAGE.IS_SMART_TV_VALIDATED, JSON.stringify(true));
            (async () => {
                if (isUserloggedIn()) {
                    await callLogOut(false, history, false);
                    history.replace(`/${validateSmartTvResp?.data?.shortUrl}`);
                }
                else {
                    history.replace(`/${validateSmartTvResp?.data?.shortUrl}`);
                }

            })();
        }
    }, [isCodeValidated])

    return (
        <div>
            <React.Fragment>
                <div className='validate-smart-tv-container'>
                    <div className="logo-header">
                        <img src={MobileLogo}></img>
                    </div>
                    <div className='smart-tv-logo'>
                        <img src={`${cloudinaryCarousalUrl("", "", 0, 0, false, true)}${image}`} alt='smart-tv-img'/>
                    </div>
                    <div>
                        <h4>{numericCodeOfsHeader}</h4>
                        {isMobile.any() ? <h5 dangerouslySetInnerHTML={{ __html: numericCodeOfsFooterWebSmall }}/> : <h5>{numericCodeOfsFooter}</h5>}
                    </div>
                    <div>
                        <OTPComponent
                            searchPath={wsRoute}
                            otpValidateSource={VALIDATE_SOURCE.SMART_TV}
                            webSmallRouteToken={webSmallRouteToken}
                            handleCodeValidate={() => setIsCodeValidated(true)}
                        />
                    </div>
                </div>
            </React.Fragment>
        </div>
    )
}

ValidateSmartTv.props = {
    webSmallPaymentRouteKey: PropTypes.string,
    webSmallRouteToken: PropTypes.string,
    searchPath: PropTypes.string,
    isUnlockFsFlow: PropTypes.bool,
};