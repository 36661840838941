import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';

import { URL } from '@utils/constants/routeConstants';
import mixPanelConfig from "@utils/mixpanel";
import MIXPANEL from "@constants/mixpanel";

import './style.scss';
import { isHelpCenterWebView, isMobile } from '@utils/common';
import { getKey } from '@utils/storage';
import { LOCALSTORAGE } from '@utils/constants';
import dataLayerConfig from "@utils/dataLayer";
import DATALAYER from "@utils/constants/dataLayer";

let player;

const YouTubePlayer = (props) => {
    const { videoLink, contentId, videoTitle, youtubeVideoId } = props;
    const [autoplayAllowed, setAutoPlayedAllowed] = useState(false);
    const [embededVideoId, setEmbededVideoId] = useState("");
    const history = useHistory();
    let { type } = useParams();

    useEffect(() => {
        let autoplay = shouldAutoPlayVideo();
        let videoId = youtubeVideoId ? youtubeVideoId : videoLink?.split('/')?.[4];
        setAutoPlayedAllowed(autoplay);
        setEmbededVideoId(videoId);
    }, [contentId])

    useEffect(() => {
        if (!window?.YT) {
            // If not, load the script asynchronously
            const tag = document.createElement('script');
            tag.src = 'https://www.youtube.com/iframe_api';

            // onYouTubeIframeAPIReady will load the video after the script is loaded
            window.onYouTubeIframeAPIReady = loadVideo;

            const firstScriptTag = document.getElementsByTagName('script')[0];
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

        } else {
            // If script is already there, load the video directly
            loadVideo();
        }
        return () => {
            player && player.destroy();
        }
    }, [embededVideoId, autoplayAllowed]);

    const loadVideo = () => {
        // the Player object is created uniquely based on the id in props
        player = new window.YT.Player(`youtube-player-${embededVideoId}`, {
            height: '390',
            width: '640',
            videoId: embededVideoId,
            playerVars: { 'autoplay': autoplayAllowed, "playsinline": 1 },
            events: {
                onReady: onPlayerReady,
                onStateChange: onPlayerStateChange
            },
        });
        autoplayAllowed && trackMixpanelOnLoad();
    };

    const shouldAutoPlayVideo = () => {
        let { location: { pathname } } = history;
        let urlArr = pathname?.split('/'),
            isHCVideoDetailPage = [URL.HC_VIDEO_DETAIL, URL.HC_SEARCH_RESULT].includes(urlArr[2]) && type === "HELP_VIDEO";
        return isHCVideoDetailPage;
    }

    const onPlayerReady = (event) => {
        let isHelpCenterInMobileApp = JSON.parse(getKey(LOCALSTORAGE.IS_HELP_CENTER_IN_MOBILE_APP)) === true;
        if (autoplayAllowed && (isMobile.any() || isHelpCenterWebView() || isHelpCenterInMobileApp)) {
            forceAutoPlay(event);
        }
    }

    const onPlayerStateChange = event => {
        let duration = event.target.getCurrentTime(),
            totalDuration = event.target.getDuration();
        if (event.data === -1) {// Unstarted
            autoplayAllowed && forceAutoPlay(event);
        }
        else if (event.data === 0) { // Ended
            trackMixpanelOnPlay(duration, totalDuration);
        }
        else if (event.data === 1) {// Playing
            trackDatalayerOnVideoPlay(DATALAYER.VALUE.PLAY)
        }
        else if (event.data === 2) {// Paused
            trackMixpanelOnPlay(duration, totalDuration);
            trackDatalayerOnVideoPlay(DATALAYER.VALUE.PAUSE)
        }
        else if (event.data === 3) {// Buffering
        }
    }

    const forceAutoPlay = (event) => {
        event.target.mute();
        event.target.playVideo();
    }

    const trackMixpanelOnLoad = () => {
        let isHCDeeplinkUrl = history?.location?.state?.isHCDeeplinkUrl;
        mixPanelConfig.trackEvent(MIXPANEL.EVENT.HC_VIDEO_AUTO_PLAY, {
            [`${MIXPANEL.PARAMETER.TITLE}`]: videoTitle,
            [`${MIXPANEL.PARAMETER.SOURCE}`]: isHCDeeplinkUrl ? MIXPANEL.VALUE.DEEPLINK : MIXPANEL.VALUE.VIDEO_DETAIL_SCREEN,
        });
    }

    const trackMixpanelOnPlay = (watchedDuration, totalDuration) => {
        mixPanelConfig.trackEvent(MIXPANEL.EVENT.HC_VIDEO_PLAY_DURATION, {
            [`${MIXPANEL.PARAMETER.WATCHED_DURATION}`]: Math.round(watchedDuration),
            [`${MIXPANEL.PARAMETER.TOTAL_VIDEO_DURATION}`]: Math.round(totalDuration),
            [`${MIXPANEL.PARAMETER.TITLE}`]: videoTitle,
        });
    }

    const trackDatalayerOnVideoPlay = (buttonName) => {
        dataLayerConfig.trackEvent(DATALAYER.EVENT.VIDEOS, {
            [DATALAYER.PARAMETER.BUTTON_NAME]: buttonName,
            [DATALAYER.PARAMETER.VIDEO_NAME]: videoTitle,
        });
    }

    return (
        <div className='you-tube-contr'>
            <div id={`youtube-player-${embededVideoId}`} />
        </div>
    )
};

YouTubePlayer.propTypes = {
    height: PropTypes.number,
    videoLink: PropTypes.string,
};

export default YouTubePlayer;