export const MULTIPLE_SID_ACTION = {
    SID_LISTING: 'SID_LISTING',
    ADD_PACK: 'ADD_PACK',
    ERROR: 'ERROR'
};

export const MOBILE_ERROR_MESSAGE = 'Please enter the correct mobile number.'

export const ACCOUNT_STATUS_KEY = 'ACCOUNT_STATUS';
export const PRODUCT_STATUS_KEY = 'PRODUCT_STATUS';

export const ERROR_MEESAGE = {
    ALREADY_HAVE_BINGE_SUBSCRIPTION: 'You already have a Binge subscription',
    DESCRIPTION: "Enjoy watching your favourite movies & shows on the Binge mobile app",
    ON_API_FAILURE: 'Please try again in sometime',
    CTA: 'Take me to the app',
}

export const BINGE_TRIAL_SOURCE = 'Binge_Trial';

export const APPSFLYER_REDIRECTION_LINK = "https://tpbng.onelink.me/xESc/exem2j3i";