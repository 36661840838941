import get from "lodash/get";
import { ACTION } from './constants';

const initialState = {
    continuePlayingItems: [],
    gamezopWatchlistItems: [],
    watchlistItems: [],
    loading: true,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ACTION.FETCH_WATCHLIST_ITEMS:
            return {
                ...state,
                watchListData: get(action, "apiResponse.data", {}),
                watchlistItems: [
                    ...state.watchlistItems,
                    ...get(action, "apiResponse.data.list", []),
                ],
                loading: false,
            };
        case ACTION.CLEAR_WATCHLIST_DATA:
            return {
                ...initialState,
            };

        case ACTION.UPDATE_WATCHLIST_DATA:
            return {
                ...state,
                watchlistItems: action.payload,
            };
        case ACTION.FETCH_GAMEZOP_WATCHLIST_ITEMS:
            return {
                ...state,
                gamezopWatchListData: get(action, "apiResponse.data", {}),
                gamezopWatchlistItems: [
                    ...state.gamezopWatchlistItems,
                    ...get(action, "apiResponse.data.contentList", []),
                ],
            };
        case ACTION.FETCH_CONTINUE_PLAYING_ITEMS:
            return {
                ...state,
                continuePlayingData: get(action, "apiResponse.data", {}),
                continuePlayingItems: [
                    ...state.continuePlayingItems,
                    ...get(action, "apiResponse.data.contentList", []),
                ],
            };
        default:
            return state;
    }
};
