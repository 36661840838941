export const ACTION = {
    FETCH_WATCHLIST_ITEMS: "FETCH_WATCHLIST_ITEMS",
    CLEAR_WATCHLIST_DATA: "CLEAR_WATCHLIST_DATA",
    UPDATE_WATCHLIST_DATA: "UPDATE_WATCHLIST_DATA",
    FETCH_GAMEZOP_WATCHLIST_ITEMS: "FETCH_GAMEZOP_WATCHLIST_ITEMS",
    FETCH_CONTINUE_PLAYING_ITEMS: 'FETCH_CONTINUE_PLAYING_ITEMS',
};

export const DEFAULT_VALUE = {
    LIMIT: "12",
    SELECTALL: "Select All ",
    DESELECTALL: 'Deselect All',
    CANCEL: "Cancel",
    SELECT: "Select",
};

export const BINGE_LIST_VERBIAGES = {
    EMPTY: '"Your Binge List is empty!"',
    SUB_HEADER: "Browse and save exciting movies &amp; TV shows to your list",
    CTA: "Discover to Add",
}