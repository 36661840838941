import React from 'react';
import appleKnowMoreThumbnail from '@assets/images/apple-know-more-thumbnail.svg';

import './style.scss';
import { cloudinaryCarousalUrl, getVerbiages, isMobile } from '@utils/common';
import { CATEGORY_NAME } from '@utils/constants';
import { knowMoreClick } from '@containers/PIDetail/PIDetailCommon';
import PlayImage from '@assets/images/Play.svg'

export const AppleKnowMore = (props) => {
    let appleKnowMoreCTA = getVerbiages(CATEGORY_NAME.APPLE_KNOW_MORE_CTA),
    imageUrl = appleKnowMoreCTA?.others?.imageURL ? `${cloudinaryCarousalUrl('', '', 0, 0, false, true)}${appleKnowMoreCTA?.others?.imageURL}` : appleKnowMoreThumbnail;
    
    return (
        <div className='apple-know-more-block'>
            <div className='thumbnail' onClick={() => knowMoreClick(props)}>
                <img src={imageUrl} />
                <img className='apple_play_image' src={PlayImage} />
            </div>
            <div className='text-block'>
                <div>{appleKnowMoreCTA?.header}</div>
                <div className='know-more-link' onClick={() => knowMoreClick(props)}>{appleKnowMoreCTA?.others?.message}</div>
            </div>
        </div>
    )
};

AppleKnowMore.propTypes = {

}