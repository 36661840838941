export default {
  EVENT: {
    HEADER_CLICKS: "HEADER-CLICKS",
    FOOTER_CLICKS: "FOOTER-CLICKS",
    PRODUCT_LANDING_CTAS: "PRODUCT-LANDING-CTAS",
    BANNER_CLICKS: "BANNER-CLICKS",
    VIDEO_TILES_HOMEPAGE_CTAS: "VIDEO-TILES-HOMEPAGE-CTAS",
    VIDEO_TILE_CLICK: "VIDEO-TILE-CLICK",
    PROCEED_SUB_JOURNEY: "PROCEED-SUB-JOURNEY",
    PROCEED_TO_PAY_SUB_JOURNEY: "PROCEED-TO-PAY-SUB-JOURNEY",
    GET_OTP_SUB_JOURNEY: "GET-OTP-SUB-JOURNEY",
    RESEND_OTP_SUB_JOURNEY: "RESEND-OTP-SUB-JOURNEY",
    MAKE_PAYMENT_SUB_JOURNEY: "MAKE-PAYMENT-SUB-JOURNEY",
    START_WATCHING_SUB_JOURNEY: "START-WATCHING-SUB-JOURNEY",
    LOGIN_HEADER: "LOGIN-HEADER",
    GET_OTP_LOGIN_JOURNEY: "GET-OTP-LOGIN-JOURNEY",
    NOT_NOW_LOGIN_JOURNEY: "NOT-NOW-LOGIN-JOURNEY",
    RESEND_OTP_LOGIN_JOURNEY: "RESEND-OTP-LOGIN-JOURNEY",
    SETTING_CTAS: "SETTINGS-CTAS",
    SUB_JOURNEY: "SUB-JOURNEY",
    LOGIN_JOURNEY: "LOGIN-JOURNEY",
    SUBSCRIPTION_SUCCESS: "SUBSCRIPTION-SUCCESS",
    SUBSCRIPTION_SUCCESS_NEW: "SUBSCRIPTION-SUCCESS-NEW",
    BROWSING_RAIL: "BROWSING-RAIL",
    VIEW_SEARCH_RESULT: "VIEW-SEARCH-RESULT",
    FAQ_SECTION: "FAQ-SECTION",
    VIDEOS: "VIDEOS",
    SUBSCRIPTION_FAILED: "SUBSCRIBE-FAIL",
    PURCHASE: "purchase",
  },
  PARAMETER: {
    HEADER_NAME: "HEADER-NAME",
    TS_SID: "TS-SID",
    C_ID: "C-ID",
    SUB_HEADER_NAME: "SUB-HEADER-NAME",
    CONTENT_TITLE: "CONTENT-TITLE",
    CONTENT_TYPE: "CONTENT-TYPE",
    PAGE_NAME: "PAGE-NAME",
    BUTTON_NAME: "BUTTON-NAME",
    LANGUAGE_NAME: "LANGUAGE-NAME",
    PACK_NAME: "PACK-NAME",
    PACK_PRICE: "PACK-PRICE",
    FOOTER_BTN_NAME: "FOOTER-BUTTON-NAME",
    RAIL_TITLE: "RAIL-TITLE",
    CTA_NAME: "CTA-NAME",
    EPISODE_NUMBER: "EPISODE-NUMBER",
    MORE_BUTTON_NAME: "MORE-BUTTON-NAME",
    SOURCE: "SOURCE",
    VIDEO_NAME: "VIDEO-NAME",
    POPULAR_SHOWS: "POPULAR-SHOWS",
    SEARCH_TERM: "SEARCH-TERM",
    REASON: "REASON",
    CUID: 'CU-ID',
    FOOTER_BUTTON_NAME: "FOOTER-BUTTON-NAME",
  },
  VALUE: {
    CATEGORIES: "Categories",
    PROCEED: "Proceed",
    PLAY_STORE: "playstore",
    APP_STORE: "appstore",
    FACEBOOK: "facebook",
    INSTAGRAM: "instagram",
    WHATSAPP: "whatsApp",
    TWITTER: 'twitter',
    YOUTUBE: 'youtube',
    SHARE: "Share",
    ADD_TO_BINGE_LIST: "Add to binge list",
    BINGE_LIST: "bingelist",
    PREV_EPISODE: "PREV-EPISODE",
    NEXT_EPISODE: "NEXT-EPISODE",
    CLOSE: "CLOSE",
    THREE_DOTS: "THREE-DOTS",
    PLAY: "PLAY",
    SAVE_CHANGES: "Save Changes",
    CONFIRM: "Confirm",
    MSALES: "mSales",
    RESEND_OTP: "Resend Otp",
    GET_OTP: "Get Otp",
    START_WATCHING: "Start Watching",
    BROWSE_BY_APP: "App",
    BROWSE_BY_GENRE: "Genre",
    BROWSE_BY_LANGUAGE: "language",
    PAUSE: "PAUSE",
  },
};