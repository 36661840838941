import React from 'react';
import PropTypes from "prop-types";

import {SOCIAL_MEDIA_URL} from '@constants';

import Facebook from "@assets/images/facebook.svg";
import Twitter from "@assets/images/Twitter_outlined.svg";
import Instagram from "@assets/images/instagram.svg";
import YouTube from "@assets/images/Youtube.svg";
import './style.scss';
import DATALAYER from '@utils/constants/dataLayer';
import {fireFooterClickEvent} from '@utils/common';

class ConnectUs extends React.Component {
    render() {
        const {showTitle} = this.props;
        return (
            <div className='connect-link'>
                {showTitle && <span className='connect-us-title'>Connect with us</span>}
                <span className={"connect-us-btn"}>
                    <a href={SOCIAL_MEDIA_URL.FACEBOOK} onClick={() => fireFooterClickEvent(DATALAYER.VALUE.FACEBOOK)}
                       target="_blank" rel="noreferrer">
                        <img src={Facebook} alt="facebook-image" className='social-images'/>
                    </a>
                    <a href={SOCIAL_MEDIA_URL.INSTAGRAM} onClick={() => fireFooterClickEvent(DATALAYER.VALUE.INSTAGRAM)}
                       target="_blank" rel="noreferrer">
                        <img src={Instagram} alt="instagram-image" className='social-images'/>
                    </a>
                    <a href={SOCIAL_MEDIA_URL.TWITTER} onClick={() => fireFooterClickEvent(DATALAYER.VALUE.TWITTER)}
                       target="_blank" rel="noreferrer">
                        <img src={Twitter} alt="twitter-image"/>
                    </a>
                    <a href={SOCIAL_MEDIA_URL.YOUTUBE} onClick={() => fireFooterClickEvent(DATALAYER.VALUE.YOUTUBE)}
                       target="_blank" rel="noreferrer">
                        <img src={YouTube} alt="youtube-image"/>
                    </a>
                </span>
            </div>
        )
    }
}

ConnectUs.propTypes = {
    showTitle: PropTypes.bool,
}

export default ConnectUs;