import React, { useEffect, useState } from "react";
import agentLogo from "@assets/images/agent-logo.svg";
import get from "lodash/get";
import { isEmpty } from "lodash";
import * as moment from "moment";

import { LOCALSTORAGE } from "@constants";
import { getKey } from "@utils/storage";

import UnknownUser from "@assets/images/profile-avatar-white.png";

import { REOPEN, CLOSE, COMMENT_DETAILS, COMMENT_TYPE } from "./constant";
import { KEY_TO_MAP, getTicketkey } from "../TicketCard/TickeCardCommon";

import "./style.scss";

const getfullname = () => {
    let userInfo = JSON.parse(getKey(LOCALSTORAGE.USER_INFO));
    const fullname = userInfo?.firstName + " " + userInfo?.lastName;
    return fullname;
};

const getinitials = () => {
    let userInfo = JSON.parse(getKey(LOCALSTORAGE.USER_INFO));
    const initials = userInfo?.firstName.charAt(0) + userInfo?.lastName.charAt(0);
    const userinitials = initials?.toUpperCase();
    return userinitials;
};

const getStatusDate = (data, ticketCardData) => {
    const { filteredData, commentDate, commentType } = data;
    let statusData;
    if (filteredData) {
        const status = getTicketkey(ticketCardData, KEY_TO_MAP.STATUS_CHANGE).filter((data) => data.status === commentDate);
        if ([CLOSE, REOPEN].includes(commentDate) && status?.length > 1) {
            if (status?.length === 3) { // 3 times close
                statusData = commentType === COMMENT_TYPE.CLOSE_COMMENT
                    ? dateFormat(getTicketkey(status[2], KEY_TO_MAP.CHANGE_DATE))
                    : commentType === COMMENT_TYPE.RECLOSE_COMMENT
                        ? dateFormat(getTicketkey(status[1], KEY_TO_MAP.CHANGE_DATE))
                        : dateFormat(getTicketkey(status[0], KEY_TO_MAP.CHANGE_DATE));
            }
            else if (status?.length === 2) {  // 2 times close || 2 times reopen
                statusData = [COMMENT_TYPE.CLOSE_COMMENT, COMMENT_TYPE.REOPEN_COMMENT].includes(commentType)
                    ? dateFormat(getTicketkey(status[1], KEY_TO_MAP.CHANGE_DATE))
                    : dateFormat(getTicketkey(status[0], KEY_TO_MAP.CHANGE_DATE));
            }
        }
        else { // 1 times close || 1 times reopen
            statusData = dateFormat(getTicketkey(status[0], KEY_TO_MAP.CHANGE_DATE));
        }
    }
    else if (!filteredData) { // Creation Date
        statusData = dateFormat(get(ticketCardData, `${commentDate}`));
    }

    return statusData;
};

const dateFormat = (date) => {
    return moment.utc(date).format("lll");
};

const getComments = (ticketCardData, data) => {
    let commentData = get(ticketCardData, `${data.commentKey}`), commentToShow,
        reopenCounter = Number(ticketCardData?.reOpenCounter);
    let firstTimeComments = [COMMENT_TYPE.REOPEN_COMMENT, COMMENT_TYPE.RECLOSE_COMMENT].includes(data.commentType);
    let secondTimeComments = [COMMENT_TYPE.SECONDTIME_REOPEN_COMMENT, COMMENT_TYPE.SECONDTIME_RECLOSE_COMMENT].includes(data.commentType);

    if (firstTimeComments && commentData?.length) {
        commentToShow =
            reopenCounter === 1 && commentData?.length === 1
                ? commentData[0]?.comment
                : reopenCounter === 2 && commentData?.length > 1
                    ? commentData[1]?.comment
                    : commentData[0]?.comment;
    } else if (secondTimeComments && commentData?.length) {
        commentToShow = commentData.length === 2 ? commentData[0]?.comment : null;
    } else {
        commentToShow = commentData
    }
    return commentToShow;
}

function CommentCard({ ticketCardData }) {
    const [firstName, setFirstName] = useState();

    useEffect(() => {
        let userInfo = JSON.parse(getKey(LOCALSTORAGE.USER_INFO));
        setFirstName(userInfo?.firstName.charAt(0));
    }, [])

    return (
        <>
            {COMMENT_DETAILS &&
                COMMENT_DETAILS.map((data, key) => {
                    let commentPresent = getComments(ticketCardData, data);
                    if (!isEmpty(commentPresent) && !isEmpty(ticketCardData)) {
                        return (
                            <div
                                className={`agent-comment1 ${data.isUser ? "" : "light"}`}
                                key={key}
                            >
                                <div className="top">
                                    <div className="user-shortname">
                                        {data.isUser
                                            ? firstName
                                                ? (<div className="username-text">{getinitials()}</div>)
                                                : <img className="logged-out-profile" src={UnknownUser} />
                                            : (<img src={agentLogo} />)}
                                    </div>
                                    <div className="user-name">
                                        {data?.isUser ? getfullname() : "Binge Support"}
                                    </div>
                                </div>
                                <div className="comments" dangerouslySetInnerHTML={{ __html: commentPresent }}>
                                </div>
                                <div className="time">{getStatusDate(data, ticketCardData)}</div>
                            </div>
                        );
                    }
                })}
        </>
    );
}

export default CommentCard;
