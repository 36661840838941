import { getSubscribeText,getMyPlanText } from '@utils/common';
import React, { Fragment, useMemo } from 'react'
import { Helmet } from 'react-helmet';
import { URL } from '@utils/constants/routeConstants';

function SiteNavigationElement({ headerMenu }) {

   const graphSchema = useMemo(() => {
       const schema = (headerMenu || [])?.reduce((acc, item) => {
           if (![getSubscribeText(), getMyPlanText()].includes(item.pageName)) {
               acc.push({
                   "@context": "http://schema.org/",
                   "@type": "SiteNavigationElement",
                   "@id": "https://www.tataplaybinge.com/#table-of-contents",
                   "name": `${item?.pageName}`,
                   "url": `${window.location.origin}/${item?.linkToRedirect == URL.HOME ? '' : item?.linkToRedirect }`
               });
           }
           return acc; 
       }, []); 
       return schema;
   }, [headerMenu])

    return (
        <Fragment>
        <Helmet>
            <script type="application/ld+json">
                {`
                {
                 "@context":"http://schema.org/",
                 "@graph": ${JSON.stringify(graphSchema, null, 2)}
                }     
             `}
            </script>
        </Helmet>
        </Fragment>
    )
}

export default React.memo(SiteNavigationElement)