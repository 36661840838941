import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import isEmpty from 'lodash/isEmpty';
import get from "lodash/get";

import { closePopup, openPopup } from "@common/Modal/action";
import { MODALS } from "@common/Modal/constants";
import mixPanelConfig from "@utils/mixpanel";
import MIXPANEL from "@constants/mixpanel";

import { getTicket, postReopenTicket } from "../../APIs/action";
import { MAX_CHAR } from "../../APIs/constants";
import "./style.scss";


const ticketPayload = { limit: 50, offset: 0 }

const ReOpenTicket = ({
    ticketCardData,
    id,
    isLandingPage
}) => {
    const [remainingChar, setRemainingChar] = useState(MAX_CHAR);
    const [show, setShow] = useState(false);
    const [textData, setTextData] = useState("");

    const wrapperRef = useRef(null);
    const dispatch = useDispatch();

    useEffect(() => {
        document.addEventListener("click", handleClickOutside);

        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    const handleClickOutside = (event) => {
        if (!event.target?.className.includes('reopen-btn') && (wrapperRef.current && !wrapperRef.current.contains(event.target))) {
            closeModal();
        }
    };

    const handleWordCount = (event) => {
        const charCount = event.target.value.length;
        const charLeft = MAX_CHAR - charCount;

        setRemainingChar(charLeft);
        setShow(false);
        setTextData(event.target.value);
    };

    const reopenButtonHandler = async () => {
        if (textData.length >= MAX_CHAR) {
            const payload = {
                externalId: ticketCardData?.externalId,
                status: "REOPEN",
                sourceApp: "BINGE",
                ticketId: id,
                reopenComments: textData,
            };

            let postReqResponse = await dispatch(postReopenTicket(payload));
            if (isEmpty(get(postReqResponse, "data")) && get(postReqResponse, "message")) {
                responseAlert(postReqResponse);
            } else {
                isLandingPage && dispatch(getTicket(ticketPayload))
                mixPanelConfig.trackEvent(MIXPANEL.EVENT.HC_REOPEN_TICKET, {
                    [`${MIXPANEL.PARAMETER.TICKET_ID}`]: id,
                });
                closeModal();
            }
        } else {
            setShow(true);
        }
    };

    const responseAlert = (postReqResponse) => {
        dispatch(
            openPopup(MODALS.ALERT_MODAL, {
                modalClass: "alert-modal",
                instructions: get(postReqResponse, "message"),
                primaryButtonAction: () => {
                    closeModal();
                },
                closeModal: false,
                hideCloseIcon: false,
            })
        );
    };

    const closeModal = async () => {
        dispatch(closePopup());
    };

    return (
        <div className="modal-body-container">
            <div className="popup-body" ref={wrapperRef}>
                <div className="modal-header ticketinfo">
                    <div className="marquee">
                        <span className="requestId">{`Request - #${id}`}</span>
                    </div>
                </div>
                <div className="modal-fullbody">
                    <div className="ticketpara">
                        Are you sure you want to reopen the ticket?
                    </div>
                    <div className="reopencomment">Reopen Comment*</div>

                    <div>
                        <textarea
                            className="form-control"
                            id="field"
                            type="text"
                            rows="4"
                            cols="50"
                            value={textData}
                            onChange={(e) => handleWordCount(e)}
                        ></textarea>
                    </div>
                    <div className="suggestion">
                        *comments are mandatory{" "}
                        {textData.length < MAX_CHAR && `(${remainingChar} characters are left)`}
                    </div>
                    <div>
                        {show && <div className="error">Please add your comment</div>}
                    </div>
                </div>
                <div className="bottom">
                    <button
                        className="button reopen"
                        onClick={(e) => reopenButtonHandler(e, id)}
                    >
                        Reopen
                    </button>
                    <button className="button close" onClick={closeModal}>
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ReOpenTicket;

