import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from "prop-types";

import { MODALS } from './constants';
import { closePopup, loginSignUp, resetInput } from './action';
import AlertModal from "@common/Modal/components/AlertModal";
import FibreModal from "@common/Modal/components/FibreModal";

import { get } from 'lodash';
import './style.scss'
import { loginPopupState } from "@components/Header/APIs/actions";
import { safeNavigation, scrollToTop } from "@utils/common";
import CustomModal from "@common/Modal/components/CustomModal";
import ChangeTenureModal from '@containers/Subscription/ChangeTenureModal';
import CommentModal from "@common/Modal/components/CommentModal";
import BackgroundImage from "@assets/images/tick-tick-drawer.png";
import { deleteKey } from '@utils/storage';
import { LOCALSTORAGE } from '@utils/constants';
import mixPanelConfig from '@utils/mixpanel';
import MIXPANEL from '@utils/constants/mixpanel';
import { URL } from '@utils/constants/routeConstants';

class Modal extends Component {
    constructor(props) {
        super(props);
        this.state = {}
        if (typeof document !== "undefined" && this.props.modal.showModal === true) {
            document.body.style.overflowY = 'scroll';
            document.body.style.width = '100%';
            document.body.style.position = 'fixed';
        }
        this.myRef = React.createRef();
    }

    componentDidMount = () => {
        const { modal: { modalParameters } } = this.props;
        modalParameters.isAppleModal && document.addEventListener("mousedown", this.handleClickOutside);
        modalParameters.isAppleModal && document.addEventListener("touchstart", this.handleClickOutside);
    }

    componentWillUnmount() {
        const { modal: { modalParameters } } = this.props;
        modalParameters.isAppleModal && document.removeEventListener("mousedown", this.handleClickOutside);
        modalParameters.isAppleModal && document.removeEventListener("touchstart", this.handleClickOutside);
        document.body.style.overflowY = 'unset';
        document.body.style['-ms-overflow-style'] = 'unset'
        document.body.style.width = '';
        document.body.style.position = 'initial';
        scrollToTop();
    }

    handleClickOutside = (e) => {
        if (this.myRef && !this.myRef.current.contains(e.target)) {
            this.props.closePopup();
        }
    }

    showModalContent = () => {
        const { modal: { modalName, modalParameters } } = this.props;

        if (modalName) {
            switch (modalName) {
                case MODALS.ALERT_MODAL:
                    return (
                        <AlertModal {...modalParameters} />
                    );
                case MODALS.CUSTOM_MODAL:
                    return (
                        <CustomModal {...modalParameters} />
                    )
                case MODALS.SUBSCRIPTION_CHANGE_TENURE:
                    return (
                        <ChangeTenureModal {...modalParameters} />
                    )
                case MODALS.COMMENT_MODAL:
                    return (
                        <CommentModal {...modalParameters} />
                    )
                case MODALS.FIBRE_MODAL:
                    return (
                        <FibreModal {...modalParameters} />
                    )
            }
        }
    };

    closeModal = () => {
        const { modal: { modalParameters }, closePopup, isAppleActivationPopup } = this.props;
        modalParameters?.onCloseAction && modalParameters?.onCloseAction()
        if(modalParameters?.isAppleActivationPopup){ // to delete local storage key to show activation popup one time to user on home page
            mixPanelConfig.trackEvent(MIXPANEL.EVENT.APPS_ACTIVATION_POPUP_EXIT,{
                [`${MIXPANEL.PARAMETER.ACTIVATION_PENDING}`]: modalParameters?.providerName,
            })
            deleteKey(LOCALSTORAGE.IS_PARTNER_POPUP_CLICK)
            deleteKey(LOCALSTORAGE.START_WATCHING_NOW)
            safeNavigation(modalParameters?.history, `${URL.DEFAULT}`)
        }
        closePopup();
    };
    render = () => {
        const { modal: { modalParameters } } = this.props;
        const gradiantBG = modalParameters?.modalClass.indexOf("gradiant-bg") > -1;
        const content = this.showModalContent();
        const isTickTick = modalParameters.modalClass.includes('selection-drawer');
        
        return (
            <div className={modalParameters?.modalClass ? modalParameters.modalClass : ''}>
                <div className='popupOuter'>

                    <div className='popupSec' ref={this.myRef}>
                        {gradiantBG && <div className='bg-wrapper' />}
                        {isTickTick && <div className="tick-tick-background-img"><img src={BackgroundImage} alt="modal-background" loading='lazy'/></div>}
                        <div>
                            {modalParameters?.heading && <div className="modal-header">
                                <h3>{modalParameters.heading}</h3>
                            </div>}
                            {
                                modalParameters?.htmlHeading && <div className='modal-header'>
                                    <h3 dangerouslySetInnerHTML={{  __html:modalParameters.htmlHeading.replace(/\n/g, "<br>")}}/>
                                    </div>
                            }
                            {modalParameters && !modalParameters.hideCloseIcon &&
                                <span onClick={() => this.closeModal()}><i className="icon-close" /></span>}
                            {content}
                        </div>
                    </div>
                </div>
                <div className='popup_overlay black_overlay' />

            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    modal: state.modal,
    backgroundImage: get(state.headerDetails, "configResponse.data.config.FreemiumBackgroundPoster.web.otherPackPoster"),
});

const mapDispatchToProps = (dispatch) => ({
    ...bindActionCreators({
        closePopup,
        loginPopupState,
        loginSignUp,
        resetInput,
    }, dispatch),
});

Modal.propTypes = {
    modalState: PropTypes.object,
    modalName: PropTypes.string,
    closePopup: PropTypes.func,
    loginPopupState: PropTypes.func,
    loginSignUp: PropTypes.func,
    resetInput: PropTypes.func,
    modal: PropTypes.object,
};

const ModalContainer = connect(mapStateToProps, mapDispatchToProps)(Modal);

export default ModalContainer;
