import {URL} from "@constants/routeConstants";

export const MESSAGES = {
    SIGN_OUT: {
        HEADING: 'Sign Out',
        INSTRUCTIONS: 'Are you sure that you want to sign out?',
        PRIMARY_BTN_TEXT: 'Yes',
        SECONDARY_BTN_TEXT: 'No',
    },
};

export const VIEW = {
    WEB_SMALL: 'web-small',
};

export const ACTION_BTN_NAME = {
    RENEW: 'Renew',
    RECHARGE: 'Recharge',
    GET_A_PLAN :'Get a Plan',
};

export const BOTTOM_LIST = {
    TERMS_AND_CONDITIONS : 'Terms & Conditions g',
    PRIVACY_POLICY : 'Privacy Policy g',
};

export const BOTTOM_LIST_NAME = {
    TERMS_AND_CONDITIONS: 'termsAndConditions',
    PRIVACY_POLICY: 'privacyPolicy',
};

export const BOTTOM_CONTACT_LIST = [
    {
        'name': BOTTOM_LIST_NAME.TERMS_AND_CONDITIONS,
        'displayName': BOTTOM_LIST.TERMS_AND_CONDITIONS,
        'reDirectTo': `/${URL.TERMS_AND_CONDITIONS}`,
        'showDot': false,
    },
    {
        'name': BOTTOM_LIST_NAME.PRIVACY_POLICY,
        'displayName': BOTTOM_LIST.PRIVACY_POLICY,
        'reDirectTo': `/${URL.PRIVACY_POLICY}`,
        'showDot': true,
    },
];