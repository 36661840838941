import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import get from "lodash/get";
import { LOCALSTORAGE } from '@utils/constants';
import { getKey } from "@utils/storage";
import { triggerChatFlag } from '../APIs/action';
import { TRIGGER_CHAT_LOC_CONST } from '../APIs/constants';
class ProActiveChat extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isTimerStarted: false,
        };

        this.proActiveTimeCounter = 0;
    }

    componentDidMount = () => {

        let proaCativeChatTriggerCount = JSON.parse(window.sessionStorage.getItem(LOCALSTORAGE.PRO_ACTIVE_CHAT_TRIGGER_COUNT));
        if (!proaCativeChatTriggerCount){
           this.handleScroll()
        }
    };

    componentWillUnmount = () => {
        clearInterval(this.proACtiveTimeInterval);
        this.proACtiveTimeInterval = null
    };


    handleScroll = (e) => {
        let { configResponse, triggerChatFlag, triggerChatFlagVal } = this.props;
        let configProactiveChatTime = parseInt(configResponse?.cms_constants?.proactiveChatTime);
        window?.Simplify360Chat?.isChatIFrameOpen() && this.setState({isTimerStarted : true});

        if (!this.state.isTimerStarted && !window?.Simplify360Chat?.isChatIFrameOpen() && !triggerChatFlagVal){
            this.setState({isTimerStarted : true});

            this.proACtiveTimeInterval = setInterval(() => {
                if (this.proActiveTimeCounter === configProactiveChatTime && !window?.Simplify360Chat?.isChatIFrameOpen()) {
                    clearInterval(this.proACtiveTimeInterval);
                    let payload = {
                        location: TRIGGER_CHAT_LOC_CONST.PRO_ACTIVE,
                        value: true,
                    }
                    this.proACtiveTimeInterval = null
                    triggerChatFlag(payload);
                }
                else if(window?.Simplify360Chat?.isChatIFrameOpen()){
                    clearInterval(this.proACtiveTimeInterval);
                }
                else {
                    this.proActiveTimeCounter++ ;
                    console.log('proActiveTimeCounter', this.proActiveTimeCounter);
                }
            }, 1000);
        };
    };

    render() {
        return(
            <>
            </>
        );
    }
}


const mapStateToProps = (state) => ({
    configResponse: get(state.headerDetails, 'configResponse.data.config'),
    triggerChatFlagVal: get(state.helpCenterReducer, "triggerChatFlagVal"),
    triggerChatLocation: get(state.helpCenterReducer, "triggerChatLoc"),
});

function mapDispatchToProps(dispatch) {
    return {
        ...bindActionCreators({
            triggerChatFlag,
        }, dispatch),
    }
}

ProActiveChat.propTypes = {
    history: PropTypes.object,
    configResponse: PropTypes.object,
    triggerChatFlagVal: PropTypes.bool,
    triggerChatFlag: PropTypes.func,
    triggerChatLocation: PropTypes.string,
};

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
)(ProActiveChat);