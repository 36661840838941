import React from "react";
import * as moment from "moment";

import { TICKET_STATUS, TICKET_STATE, CHATBOT_TYPE } from "../../APIs/constants";
import { KEY_TO_MAP, TICKET_DATE_EXTENDED_MSG, checkIfDateExceededExpectedDate, getTicketkey } from "./TickeCardCommon";
import { getHCChatBotType } from "@containers/HelpCenter/utils/index.js";
import { isEmpty } from "lodash";

const TicketDateDescription = ({
    status,
    isLandingPage,
    raisedDate,
    expectedResolutionDate,
    statusChange,
    reCloseComments,
    closeDate,
    handleNavigate,
    ticketAPIResponse,
    reOpenCounter
  }, ref) => {
  const getStatusDate = (statusCode, isReclose) => {
    let date,
      chatbotType = getHCChatBotType(),
      filteredData = statusChange?.filter(
        (data) => getTicketkey(data, KEY_TO_MAP.STATUS)?.toLowerCase() === statusCode.toLowerCase()
      );

    if (chatbotType === CHATBOT_TYPE.ORISERVE) {
      if (
        [TICKET_STATE.CLOSE].includes(statusCode.toLowerCase()) && reCloseComments?.length
      ) {
        // if a ticket is reclosed then 2 close entry will come in statusChange array and the latest one will be the latest close i.e reclose
        // if a ticket is reclosed 2 time then 3 close entry will come in statusChange array and the latest one will be the latest close i.e reclose
        date = isReclose
          ? getTicketkey(filteredData?.[0], KEY_TO_MAP.CHANGE_DATE)
          : reOpenCounter === 2 && filteredData.length === 3
          ? getTicketkey(filteredData?.[2], KEY_TO_MAP.CHANGE_DATE)
          : getTicketkey(filteredData?.[1], KEY_TO_MAP.CHANGE_DATE);
      } 
      else {
        date = getTicketkey(filteredData?.[0], KEY_TO_MAP.CHANGE_DATE);
      }
    } else {
      date = closeDate;
    }
    return dateFormat(date);
  };

  React.useImperativeHandle(ref, () => {
      return {
        getStatusDate: (statusCode, isReclose) => getStatusDate(statusCode, isReclose),
      }
    },[status]);

  const dateFormat = (date) => {
    if (isLandingPage) {
      return moment.utc(date).format("DD/MM/YY, h a");
    }
    return moment.utc(date).format("lll");
  };

  /**
   * @function getTicketViewText - return button text to be shown at the bottom of ticket card
   */
  const getTicketViewText = () => {
    // let openTicketData = ticketAPIResponse?.filter((data) => (getTicketkey(data, KEY_TO_MAP.STATUS)?.toLowerCase() == TICKET_STATE.OPEN || getTicketkey(data, KEY_TO_MAP.STATUS)?.toLowerCase() == TICKET_STATE.REOPEN));
    // return openTicketData?.length === 1 ? TICKET_STATUS.VIEW_DETAIL : TICKET_STATUS.VIEW_MORE;
    return TICKET_STATUS.VIEW_DETAIL;
  };

  let isTicketStatusReOpen = status?.toLowerCase() == TICKET_STATE.REOPEN,
    isTicketStatusClose = status?.toLowerCase() == TICKET_STATE.CLOSE,
    isTicketStatusOpen = status?.toLowerCase() == TICKET_STATE.OPEN,
    showRequestDetail = isTicketStatusReOpen || (isTicketStatusClose && !isEmpty(reCloseComments));

  let isTicketStatusOpenAndDateExpired = ((isTicketStatusOpen) || isTicketStatusReOpen) && checkIfDateExceededExpectedDate(expectedResolutionDate);

  return (isLandingPage ? (
    <div className="wrapper">
      <div className="request-detail">
        <div className={`req-contr req-date req-contr-active ${ref.current?.reOpenTicket ? "is-reopen" : isTicketStatusOpenAndDateExpired ? "date-extended-msg-home" : ""}`}>
          {isTicketStatusOpenAndDateExpired ? (
            <div className="label">{TICKET_DATE_EXTENDED_MSG}</div>
          ) : (
            <React.Fragment>
              <div className="label">
                {(isTicketStatusOpen || isTicketStatusReOpen) ? TICKET_STATUS.SOLVED_BY : TICKET_STATUS.RESOLVED}
              </div>
              <div className="data">
                {(isTicketStatusOpen || isTicketStatusReOpen) ? dateFormat(expectedResolutionDate) : getStatusDate(TICKET_STATE.CLOSE, !!showRequestDetail)}
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  ) : (
    <React.Fragment>
      {showRequestDetail && (
        <div className="request-detail">
          <div className="req-contr req-date">
            <div className="label">
              {TICKET_STATUS.REOPEN}
              </div>
            <div className="data">
              {getStatusDate(TICKET_STATE.REOPEN)}
              </div>
          </div>
          <div className={`req-contr req-type ${isTicketStatusOpenAndDateExpired ? "date-extended-msg" : ""}`}>
            {isTicketStatusOpenAndDateExpired ? (
              <div className="label">
                {TICKET_DATE_EXTENDED_MSG}
              </div>
            ) : (
              <React.Fragment>
                <div className="label">
                  {isTicketStatusReOpen
                    ? TICKET_STATUS.SOLVED_BY
                    : TICKET_STATUS.RECLOSED}
                </div>
                <div className="data">
                  {isTicketStatusReOpen
                    ? dateFormat(expectedResolutionDate)
                    : getStatusDate(TICKET_STATE.CLOSE, true)}
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      )}
      <div className="request-detail">
        <div className="req-contr req-date">
          <div className="label">
            {TICKET_STATUS.CREATED}
            </div>
          <div className="data">
            {dateFormat(raisedDate)}
            </div>
        </div>
        <div className={`req-contr req-type ${isTicketStatusOpenAndDateExpired && !showRequestDetail ? "date-extended-msg" : ""}`}>
          {isTicketStatusOpenAndDateExpired && !showRequestDetail ? (
            <div className="label">
              {TICKET_DATE_EXTENDED_MSG}
            </div>
          ) : (
            <React.Fragment>
              <div className="label">
                {isTicketStatusOpen
                  ? TICKET_STATUS.SOLVED_BY
                  : TICKET_STATUS.RESOLVED}
              </div>
              <div className="data">
                {isTicketStatusOpen
                  ? dateFormat(expectedResolutionDate)
                  : getStatusDate(TICKET_STATE.CLOSE)}
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    </React.Fragment>
  ));
};

export default React.forwardRef(TicketDateDescription);