import { doRequest } from "@src/services/api";
import { serviceConstants } from "@utils/apiService";
class LoginByService {

    generateOtpWithRMN(rmn, isForValidateIdentity) {
        return doRequest(serviceConstants.rmnGetOtp(rmn, isForValidateIdentity))
    }

    validateOtp(mobile, otp, source) {
        return doRequest(serviceConstants.verifyRmnOtp(mobile, otp, source))
    }

    getSubscriberListing(mobile, isSourceSwitchAcc) {
        return doRequest(serviceConstants.subscriberListing(mobile, isSourceSwitchAcc))
    }

    createNewBingeUser(payload, params, registerWebSmall) {
        return doRequest(serviceConstants.createNewBingeUser(payload, params, registerWebSmall))
    }

    updateBingeUser(payload, params, registerWebSmall) {
        return doRequest(serviceConstants.updateBingeUser(payload, params, registerWebSmall))
    }

    validateSsoToken(token) {
        return doRequest(serviceConstants.validateSsoToken(token));
    }

    getClientIP() {
        return doRequest(serviceConstants.getClientIP());
    }

    forceLogout() {
        return doRequest(serviceConstants.forceLogout());
    }

    validateOfsSmartTvCode(payload) {
        return doRequest(serviceConstants.validateOfsSmartTvCode(payload));
    }
}

const LoginByServiceInstance = new LoginByService();
export default LoginByServiceInstance;
