import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import get from "lodash/get";

import Button from "@common/Buttons";
import RadioButton from "@common/RadioButton";
import { ACCOUNT_STATUS } from "@containers/BingeLogin/APIs/constants";
import { notNow } from "@containers/Login/LoginCommon";
import MIXPANEL from "@constants/mixpanel";

import "./style.scss";
import { isMobile } from '@utils/common';

class MultipleBingeSubscriptions extends Component {

    render() {
        let { subscriptionsList, selectedSubscriber, handleProceedBtn, configResponse, radioButtonClickHandler } = this.props;
        return (
            <React.Fragment>
                <p className='subscriber-list-header'>{get(configResponse, "data.config.loginScreen.planListingHeader")}</p>
                <p className='subscriber-subheader'>{get(configResponse, "data.config.loginScreen.subHeader1") || "Please select one to start watching"}</p>
                <ul className={`subscriber-list 
                 ${isMobile.any() && subscriptionsList?.length > 4 ? `sub-swipe-screen` : null}`}>
                    {subscriptionsList.map((item, index) => {
                        let inactiveClass = item?.statusType?.toUpperCase() === ACCOUNT_STATUS.INACTIVE;

                        return (
                            <li key={index} onClick={() => radioButtonClickHandler('selectedSubscriber', item, index)}>
                                <div className='sudId-wrapper'>
                                    {item?.statusType && <span className={inactiveClass ? "inactive" : "active"}>{item?.statusType}</span>}
                                    {item?.subscriptionProfileName && <span className='plan-name'>{item?.subscriptionProfileName}</span>}
                                    {item?.aliasName && <span className={"alias-name"}>{item?.aliasName}</span>}
                                </div>
                                <div>
                                    <RadioButton
                                        id={item.subscriberId}
                                        name={item.subscriberId}
                                        value={item.subscriberId}
                                        chandler={() => radioButtonClickHandler('selectedSubscriber', item, index)}
                                        showLabel={false}
                                        checked={selectedSubscriber?.selectedItemIndex === index}
                                    />
                                </div>
                            </li>
                        )
                    })}
                </ul>
                <div className='button-container'>
                    <Button
                        cName={`btn primary-btn login-btn`}
                        bType="button"
                        bValue={get(configResponse, "data.config.loginScreen.cta") || "Proceed To Login"}
                        clickHandler={handleProceedBtn}
                        disabled={!selectedSubscriber}
                    />
                </div>
                <div className="sub-not-now" onClick={() => notNow(this.props, MIXPANEL.VALUE.LOGIN_BINGESELECT_PAGE)} >
                    {get(configResponse, "data.config.notNow") || "Not now"}
                </div>
            </React.Fragment>
        )
    }
}
MultipleBingeSubscriptions.propTypes = {
    radioButtonClickHandler: PropTypes.func,
    handleProceedBtn: PropTypes.func,
    closeLoginModel: PropTypes.func,
    subscriptionsList: PropTypes.array,
    selectedSubscriber: PropTypes.object,
    barStatus: PropTypes.bool,
    touchStatus: PropTypes.bool,
    configResponse: PropTypes.object,
}

export default withRouter(MultipleBingeSubscriptions);