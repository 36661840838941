import { URL } from "@constants/routeConstants";

export const ACTION = {
  GET_FAQ_LIST: "GET_FAQ_LIST",
  GET_HELP_VIDEO: "GET_HELP_VIDEO",
  GET_CATEGORY_LIST: "GET_CATEGORY_LIST",
  GET_SEARCH_RESULT: "GET_SEARCH_RESULT",
  GET_SEARCH_AUTO_COMPLETE: "GET_SEARCH_AUTO_COMPLETE",
  GET_CATEGORY_DEATILS: "GET_CATEGORY_DEATILS",
  CLEAR_CATEGORY_DEATILS: "CLEAR_CATEGORY_DEATILS",
  HC_VIEW_MORE_DATA: "HC_VIEW_MORE_DATA",
  HC_SEE_ALL_DATA: "HC_SEE_ALL_DATA",
  CLEAR_HC_SEE_ALL_DATA: "CLEAR_HC_SEE_ALL_DATA",
  RESET_HC_SEE_ALL_DATA: "RESET_HC_SEE_ALL_DATA",
  CLEAR_SEARCH_AUTO_COMPLETE: "CLEAR_SEARCH_AUTO_COMPLETE",
  HC_SEARCH_DETAIL_VIEW_MORE: "HC_SEARCH_DETAIL_VIEW_MORE",
  GET_OUTAGE_BANNER_DATA: "GET_OUTAGE_BANNER_DATA",
  VALIDATE_HELP_CENTER_TOKEN: "VALIDATE_HELP_CENTER_TOKEN",
  HC_POPULARITY_TRACK: "HC_POPULARITY_TRACK",
  HC_GET_CHAT_NOW_URL: "HC_GET_CHAT_NOW_URL",
  ORISERVE_HC_TICKET: "ORISERVE_HC_TICKET",
  S360_HC_TICKET: "S360_HC_TICKET",
  HC_TICKETDETAILS: "HC_TICKETDETAILS",
  RENDER_HC_VIEW: "RENDER_HC_VIEW",
  HC_REOPEN_TICKET: "HC_REOPEN_TICKET",
  CLEAR_HC_TICKET: "CLEAR_HC_TICKET",
  SET_DEEPLINK_FLAG: "SET_DEEPLINK_FLAG",
  CLEAR_REOPEN_HC_TICKET: "CLEAR_REOPEN_HC_TICKET",
  TRIGGER_CHAT_FLAG: 'TRIGGER_CHAT_FLAG',
  HC_DELETE_ACCOUNT:'HC_DELETE_ACCOUNT'
};

export const TRENDING_TYPE = {
  FAQ: "faq",
  HELP_VIDEO: "help_video",
};

export const CATEGORY_LIST = [
  {
    name: "Default-icon",
    leftIcon: "icon-Partner-apps",
    isLayeredIcon: false,
  },
  // {
  //     'name': 'Category-1',
  //     'leftIcon': 'icon-Platform-support',
  //     'isLayeredIcon': false
  // },
  // {
  //     'name': 'Category-12',
  //     'leftIcon': 'icon-Billing-plans',
  //     'isLayeredIcon': false
  // },
  {
    name: "Account and Profile",
    leftIcon: "icon-bingeHelp-Account-and-Profile",
    isLayeredIcon: false,
  },
  {
    name: "Plans & Payment",
    leftIcon: "icon-bingehelp-plans-and-payment",
    isLayeredIcon: false,
  },
  {
    name: "Platform Support",
    leftIcon: "icon-Platform-support",
    isLayeredIcon: false,
  },
  {
    name: "Content and Viewing",
    leftIcon: "icon-bingehelp-content-and-viewing",
    isLayeredIcon: false,
  },
  {
    name: "Troubleshooting",
    leftIcon: "icon-bingehelp-troubleshooting",
    isLayeredIcon: false,
  },
  {
    name: "Getting Started",
    leftIcon: "icon-bingehelp-getting-started",
    isLayeredIcon: false,
  },
  {
    name: "Referrals Related",
    leftIcon: "icon-Referrals",
    isLayeredIcon: false,
  },
  {
    name: "Consumer Corner – T&C",
    leftIcon: "icon-Terms-and-conditions",
    isLayeredIcon: false,
  },
  {
    name: "Videos",
    leftIcon: "icon-video-icon",
    isLayeredIcon: false,
  },
];

export const OFFSET_DEFAULT_VALUE = 0;
export const FAQ_DEFAULT_LIMIT = 5;
export const CATEGORY_DEFAULT_LIMIT = 15;
export const HELP_VIDEO_CARD_DEFAULT_LIMIT = 7;
export const ACCORDION_DEFAULT_LIMIT = 4;

export const USER_TYPE = {
  LOGGED_IN: "LOGGED_IN",
  GUEST: "GUEST",
};

export const checkEmbedLink = (data) => {
  let link = data?.videoLink ? data.videoLink : data?.diyVideoLink;
  return !!(link && link.includes("embed"));
};

export const HC_SCREEN_NAME = {
  LANDING_SCREEN: "Landing Screen",
  CATEGORY_DETAIL_SCREEN: "Category Detail Screen",
  FAQ_DETAIL_SCREEN: "FAQ Detail Screen",
  VIDEO_DETAIL_SCREEN: "Video Detail Screen",
  HC_SEARCH: "Help Center Search Page",
  HC_SEE_ALL: "Help Center See All Screen",
};

export const MAX_CHAR = 5;

export const TICKET_STATUS = {
  REOPEN: "Reopened:",
  CREATED: "Created:",
  RECLOSED: "Re-resolved:",
  SOLVED_BY: "Will be solved by:",
  RESOLVED: "Resolved:",
  VIEW_DETAIL: "View Detail",
  VIEW_MORE: "View More",
  IN_PROGRESS: "In Progress",
  COMPLETED: "Completed",
  RE_OPEN: "Reopen",
};

export const CHATBOT_TYPE = {
  ORISERVE: 'ORISERVE',
  S360: 'S360',
};

export const TICKET_STATE = {
  OPEN: 'open',
  CLOSE: 'close',
  REOPEN: 'reopen',
  CLOSED: 'closed',
};

export const TICKET_TAG = {
  OPEN: 'Open',
  CLOSED: 'Closed',
  REOPENED: 'Reopened',
};

export const ERROR_MSG = {
  CHATBOT_ERROR: 'Restart the chat again'
}

export const PRIVATE_DEEPLINK_URLS = [URL.HC_TICKET];

export const PUBLIC_DEEPLINK_URLS = [URL.HC_SEARCH_RESULT, URL.HC_VIDEO_DETAIL];

export const TRIGGER_CHAT_LOC_CONST = {
  PRO_ACTIVE: 'PRO_ACTIVE',
  BTN_CLK: 'BTN_CLK',
};

export const ACCORDION_DELETE_ACCOUNT = {
  DELETE_ACCOUNT : 'DELETE_ACCOUNT'
}