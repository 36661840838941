import ENV_CONFIG from '@config/environment/index';

export default {
  EVENT: {
    LOGIN_SUCCESS: ENV_CONFIG.GOOGLE_CONVERSION.EVENT.LOGIN_SUCCESS,
    LOGIN_GET_OTP: ENV_CONFIG.GOOGLE_CONVERSION.EVENT.LOGIN_GET_OTP,
    PACK_SELECTION_INITIATE: ENV_CONFIG.GOOGLE_CONVERSION.EVENT.PACK_SELECTION_INITIATE,
    PAYMENT_INITATION_PAGE: ENV_CONFIG.GOOGLE_CONVERSION.EVENT.PAYMENT_INITATION,
    SUBSCRIPTION_SUCCESS: ENV_CONFIG.GOOGLE_CONVERSION.EVENT.SUBSCRIPTION_SUCCESS,
    SUBSCRIBE_SUCCESS_NEW: ENV_CONFIG.GOOGLE_CONVERSION.EVENT.SUBSCRIBE_SUCCESS_NEW,
  },
  VALUE: {
    CURRENCY: "INR",
    MSALES: 'mSales',
  },
  PARAMETER: {
    VALUE: "value",
    CURRENCY: "currency",
    SOURCE: "SOURCE",
    PACK_NAME: "PACK-NAME",
    PACK_PRICE: "PACK-PRICE",
    REVENUE: 'REVENUE',
    PACK_ID: "PACK-ID",
    PROMO_CODE: "PROMO-CODE",
    PACK_TYPE: "PACK-TYPE",
    PAYMENT_MODE: "PAYMENT-METHOD",
    CUID: 'CU-ID',
    C_ID: "C-ID",
  },
  ANALYTICS: "CONVERSION",
};