import React from "react";
import { useDispatch, useSelector } from "react-redux";
import get from "lodash/get";
import { useHistory } from "react-router";

import { closePopup } from "@common/Modal/action";
import Button from "@common/Buttons";
import { cloudinaryCarousalUrl, safeNavigation, showFireStickPopup } from "@utils/common";
import { URL } from "@constants/routeConstants";
import { getComponentList } from "@containers/Subscription/APIs/subscriptionCommon";
import placeHolderImage from "@assets/images/app-icon-place.svg";

import "./style.scss";
import { isEmpty } from "lodash";

const FibreModal = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const currentSubscription = useSelector(state => state.subscriptionDetails?.currentSubscription?.data);

	const handleClose = async () => {
		await dispatch(closePopup());
		safeNavigation(history, `${URL.DEFAULT}`);
		showFireStickPopup(history);
	};

	const renderPartners = (partnerList) => {
		return (
			<React.Fragment>
				{partnerList &&
					partnerList.map((image, index) => (
						<React.Fragment key={index}>
							{index < 10 && (
								<li className="img-container" key={index}>
									<img
										src={`${cloudinaryCarousalUrl(
											"",
											"",
											120,
											120
										)}${image.iconUrl}`}
										alt=""
										onError={(e) => {
											e.target.onerror = null;
											e.target.src = placeHolderImage;
											e.target.className = "broken-image";
										}}
									/>
								</li>
							)}
						</React.Fragment>
					))}
			</React.Fragment>
		);
	};

	const getFibrePartnerSequence = (list) => {
		let partnerList = list;
		partnerList = partnerList.filter(partner => !isEmpty(partner?.fibrePosition)).sort((partnerA, partnerB) => {
			return parseInt(partnerA?.fibrePosition) - parseInt(partnerB?.fibrePosition);
		});
		return partnerList;
	}


	const getPartnerDetails = () => {
		let componentList = getComponentList(currentSubscription),
			partnerList = getFibrePartnerSequence(componentList?.partnerList),
			firstHalf = partnerList?.slice(0, 5),
			secondHalf = partnerList?.slice(5, 10);

		return (
			<React.Fragment>
				{!!firstHalf?.length && <ul>{renderPartners(firstHalf)}</ul>}
				{!!secondHalf?.length && <ul>{renderPartners(secondHalf)}</ul>}
			</React.Fragment>
		);
	};

	return (
		<div className="modal-body clearfix align-center">
			<div className="congratulation-msg-wrapper">
				{get(currentSubscription, "fibreDetails.logo") && (
					<div className="logo">
						<img src={get(currentSubscription, "fibreDetails.logo")} />
					</div>
				)}
				{get(currentSubscription, "fibreDetails.header") &&
					<div className="heading">
						{get(currentSubscription, "fibreDetails.header")}
					</div>}
				<div className="text">
					{get(currentSubscription, "fibreDetails.message1") && (
						<span>
							{get(currentSubscription, "fibreDetails.message1")}
						</span>
					)}
					{get(currentSubscription, "fibreDetails.message2") &&
						<div className="second-text">
							{get(currentSubscription, "fibreDetails.message2")}
						</div>}
				</div>
				<div className="img-partner">
					{getPartnerDetails()}
					{get(currentSubscription, 'fibreDetails.fiberMoreAppsVerbiage') &&
						<p>{get(currentSubscription, 'fibreDetails.fiberMoreAppsVerbiage')}</p>
					}
				</div>
				{get(currentSubscription, "fibreDetails.button") &&
					<div className="do-later">
						<Button
							bValue={get(currentSubscription, "fibreDetails.button")}
							cName="btn primary-btn btn-wrapper"
							clickHandler={() => handleClose()}
						/>
					</div>}
				{get(currentSubscription, "fibreDetails.fiberPackValidity") &&
					<div className="expiry">
						{get(currentSubscription, "fibreDetails.fiberPackValidity")}
					</div>}
			</div>
		</div>
	);
};

export default FibreModal;
