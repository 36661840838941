import React, { Component } from 'react';
import PropTypes from "prop-types";
import get from "lodash/get";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { isEmpty } from 'lodash';
import queryString from "querystring";

import { hideFooter, hideHeader, hideMainLoader, showMainLoader } from "@src/action";
import { updateUser } from '@containers/Login/LoginCommon';
import { URL } from "@constants/routeConstants";
import { logOut, resetValidateWebSmallResponse, validateWebSmallUrl } from "@containers/BingeLogin/APIs/action";
import { validateSsoToken, setLoginManual } from '@containers/Login/APIs/actions';
import { getKey, setKey, deleteKey } from "@utils/storage";
import { LOCALSTORAGE, WEB_SMALL_PAYMENT_SOURCE, SILENT_LOGIN_PLATFORM } from "@constants";
import { safeNavigation, getAnonymousId, redirectToMangeApp } from "@utils/common";
import { getProfileDetails } from '@containers/Profile/APIs/action';
import MIXPANEL from "@constants/mixpanel";
import { managedAppPushChanges } from '@components/Header/APIs/actions';
import { checkFallbackFlow } from "@containers/Subscription/APIs/action";
import {getCurrentSubscriptionInfo} from "@containers/Subscription/APIs/action";
import { renewSusbcription } from '@containers/Subscription/APIs/subscriptionCommon';
class WebSmallValidate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message: '',
        }
    }

    componentDidMount = async () => {
        this.updateKeysInLocal();
    };

    componentWillUnmount() {
        this.props.resetValidateWebSmallResponse();
    };

    loadHandler = async () => {
        let { validateSsoToken, setLoginManual, history, hideFooter, hideMainLoader,
            showMainLoader, existingUser, userProfileDetails, getProfileDetails, webSmallPaymentRouteKey, webSmallRouteToken,
            isUnlockFsFlow, checkFallbackFlow, fsPlatformList, zeroAppParams, isRenewNow } = this.props;
       
        hideFooter(true);

        const infoUrl = webSmallPaymentRouteKey === WEB_SMALL_PAYMENT_SOURCE.INFO,
              mSalesUrl = webSmallPaymentRouteKey === WEB_SMALL_PAYMENT_SOURCE.NON_BINGE,
              silentLoginPlatform = getKey(LOCALSTORAGE.SILENT_LOGIN_PLATFORM) || '',
              isBingeOpenFS = silentLoginPlatform === SILENT_LOGIN_PLATFORM.BINGE_OPEN_FS,
              isBingeLsSilentUser = fsPlatformList.includes(silentLoginPlatform);

            if (get(existingUser, 'data')) {
                safeNavigation(history, `${URL.DEFAULT}`)
                return false
            };

            showMainLoader();
            isUnlockFsFlow ? setKey(LOCALSTORAGE.UNLOCKED_FS_FLOW, isUnlockFsFlow) : deleteKey(LOCALSTORAGE.UNLOCKED_FS_FLOW);

            await getAnonymousId();
            await validateSsoToken(webSmallRouteToken);
            let { validateSsoResp, getCurrentSubscriptionInfo } = this.props;

            if (!isEmpty(validateSsoResp) && validateSsoResp.code === 0) {
                let data = {
                    baId: get(validateSsoResp, "data.baId"),
                    bingeSubscriberId: get(validateSsoResp, "data.bingeSubscriberId"),
                    dthStatus: get(validateSsoResp, "data.dthStatus"),
                    subscriberId: get(validateSsoResp, "data.sId"),
                    rmn: get(validateSsoResp, "data.mobileNumber"),
                    otp: get(validateSsoResp, "data.login"),
                    userAuthenticateToken: get(validateSsoResp, "data.userAuthenticateToken"),
                    deviceAuthenticateToken: get(validateSsoResp, "data.deviceAuthenticateToken"),
                    helpCenterSilentLogin: true,
                    temporaryId: isBingeOpenFS ? '' : webSmallRouteToken,
                    referenceId: get(validateSsoResp, "data.referenceId"),
                };
                await updateUser(data);

                let { existingUser } = this.props;
                
                if (!isEmpty(existingUser) && existingUser?.code === 0) {
                    let externalSource = existingUser?.data?.externalSource,
                        externalSourceMSales = existingUser?.data?.externalSourceMSales,
                        mixpanelProperties = {
                            channel : get(existingUser, 'data.mixpanelProperties.channel','' ),
                            subscriptionPackType : get(existingUser, 'data.mixpanelProperties.subscriptionPackType','' ),
                            modificationChangeType :  get(existingUser, 'data.mixpanelProperties.modificationChangeType','' ),
                            selectedTenurePackPriceMobile :  get(existingUser, 'data.mixpanelProperties.selectedTenurePackPriceMobile','' ),
                            selectedTenureTypeMobile :  get(existingUser, 'data.mixpanelProperties.selectedTenureTypeMobile','' ),
                        };
                    
                    (externalSource) && (setKey(LOCALSTORAGE.SUBSCRIPTION_EXTERNAL_SOURCE, externalSource?.toLowerCase()));
                    (externalSourceMSales || mSalesUrl) && (setKey(LOCALSTORAGE.PAYMENT_SOURCE_KEY, WEB_SMALL_PAYMENT_SOURCE.NON_BINGE), this.props.managedAppPushChanges(false));
                    
                    setLoginManual(true);
                    await getCurrentSubscriptionInfo();
                    await checkFallbackFlow();
                    hideMainLoader();
                    setKey(LOCALSTORAGE.SSO_LOGIN_JOURNEY, JSON.stringify(true));
                    isEmpty(userProfileDetails) && await getProfileDetails(false);
                     //to set selected pack details received in login API for subscription payment flow
                    setKey(LOCALSTORAGE.SUBSCRIPTION_SELECTED_PACK, JSON.stringify({...existingUser?.data, ...mixpanelProperties}));
                    { get(existingUser?.data, 'cartId') && setKey(LOCALSTORAGE.CART_ID, get(existingUser?.data, 'cartId'))}
                    if (get(existingUser, 'data.dth') && !infoUrl) {
                        safeNavigation(history, `${URL.BALANCE_INFO}`)
                    }
                    else if (!isEmpty(get(existingUser, 'data.paymentPayload')) && !infoUrl) {
                        safeNavigation(history, `${URL.SUBSCRIPTION_TRANSACTION}`)
                    } 
                    else if (isRenewNow && isBingeOpenFS) {
                        setKey(LOCALSTORAGE.IS_RENEW_SILENT_LOGIN, true);
                        return renewSusbcription(history, false, false)
                    }
                    else if((isBingeLsSilentUser || !isEmpty(zeroAppParams)) && !isUnlockFsFlow) {
                        let source = !isEmpty(silentLoginPlatform) ? silentLoginPlatform : MIXPANEL.VALUE.LARGE_SCREEN;
                        redirectToMangeApp(source, false, zeroAppParams);
                    }
                    else {
                        // if infoUrl : should land on subscription screen 
                        if (existingUser?.data?.externalSourceMSales || isUnlockFsFlow || mSalesUrl) {
                            return safeNavigation(history, `${URL.SUBSCRIPTION}`)
                        } 
                        let analyticSource = mSalesUrl ? MIXPANEL.VALUE.M_SALES : MIXPANEL.VALUE.OTHERS;
                        !this.props.isManagedApp ? safeNavigation(history, `${URL.SUBSCRIPTION}`) : redirectToMangeApp(analyticSource);
                    }
                }
                else {
                    hideMainLoader();
                    this.setState({
                        message: get(existingUser, 'message', 'Something Went Wrong. Please try again later'),
                    });
                }
            }
            else {
                hideMainLoader();
                this.setState({
                    message: get(validateSsoResp, 'message', 'Something Went Wrong. Please try again later'),
                });
            }
    };

    updateKeysInLocal = async () => {
        let { webSmallPaymentRouteKey, webSmallRouteToken, webSmallRouteParam, wsRoute, history } = this.props;
        setKey(LOCALSTORAGE.PAYMENT_SOURCE_KEY, webSmallPaymentRouteKey);
        setKey(LOCALSTORAGE.PAYMENT_SOURCE_TOKEN, webSmallRouteToken);
        setKey(LOCALSTORAGE.SILENT_LOGIN_PLATFORM, queryString.parse(this.props?.wsRoute)?.platform);
        setKey(LOCALSTORAGE.PAYMENT_SOURCE_PARAM, webSmallRouteParam);
        setKey(LOCALSTORAGE.REFERRED_PLATFORM, queryString.parse(this.props?.wsRoute)?.referredPlatform); // TSF-33073
        let tickTickFlag = queryString.parse(wsRoute)?.tickTick;
        !isEmpty(tickTickFlag) && JSON.parse(tickTickFlag) ? safeNavigation(history, URL.DEFAULT) :  this.loadHandler();
    }

    render() {
        return (
            <div className='fs-message'>{this.state.message}</div>
        )
    }
}

WebSmallValidate.propTypes = {
    history: PropTypes.object,
    validateWebSmallUrl: PropTypes.func,
    validateSsoToken: PropTypes.func,
    setLoginManual: PropTypes.func,
    resetValidateWebSmallResponse: PropTypes.func,
    wsRoute: PropTypes.string,
    hideHeader: PropTypes.func,
    hideFooter: PropTypes.func,
    logOut: PropTypes.func,
    validateWebSmallUrlResponse: PropTypes.object,
    logOutResponse: PropTypes.object,
    newFsJourney: PropTypes.bool,
    oldFsJourney: PropTypes.bool,
    showMainLoader: PropTypes.func,
    hideMainLoader: PropTypes.func,
    infoUrl: PropTypes.bool,
    isManagedApp: PropTypes.bool,
    managedAppPushChanges: PropTypes.func,
    isUnlockFsFlow: PropTypes.bool,
    checkFallbackFlow: PropTypes.func,
    getCurrentSubscriptionInfo: PropTypes.func,
};


function mapStateToProps(state) {
    return {
        validateWebSmallUrlResponse: get(state.bingeLoginDetails, 'validateWebSmallUrlResponse'),
        validateSsoResp: get(state.loginReducer, 'validateSsoResp'),
        logOutResponse: get(state.bingeLoginDetails, 'logOutResponse'),
        existingUser: get(state.loginReducer, 'existingUser'),
        userProfileDetails: get(state.profileDetails, 'userProfileDetails'),
        fsPlatformList: get(state.headerDetails, "configResponse.data.config.fsPlatform"),
        isManagedApp: get(state.headerDetails, "isManagedApp"),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        ...bindActionCreators({
            validateWebSmallUrl,
            validateSsoToken,
            setLoginManual,
            resetValidateWebSmallResponse,
            hideHeader,
            hideFooter,
            logOut,
            showMainLoader,
            hideMainLoader,
            getProfileDetails,
            managedAppPushChanges,
            checkFallbackFlow,
            getCurrentSubscriptionInfo,
        }, dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WebSmallValidate))