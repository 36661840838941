import { ACTION, FOOTER_TYPE } from "./constants";
import MainServiceInstance from "./service";

import { hideMainLoader, showMainLoader } from '@src/action';

export const getAppFooterList = (type, ids) => {
    return dispatch => {
        dispatch(showMainLoader());
        return MainServiceInstance.getAppFooterData(type, ids).then(function (response) {
            // response =  type === FOOTER_TYPE.GLOBAL ? GLOBAL_LEVEL_FOOTERS : PAGE_LEVEL_FOOTERS;
            dispatch(hideMainLoader());
            dispatch({
                type: type === FOOTER_TYPE.GLOBAL ? ACTION.GET_GLOBAL_FOOTER : ACTION.GET_LOCAL_FOOTER,
                apiResponse: response,
            });
            return response;
        }).catch((error) => {
            dispatch(hideMainLoader());
            dispatch({
                type: type === FOOTER_TYPE.GLOBAL ? ACTION.GET_GLOBAL_FOOTER : ACTION.GET_LOCAL_FOOTER,
                apiResponse: error,
            });
            console.log("Error while fetching footers:- " + error);
            return error;
        });
    }
};

export const getPageLevelFAQsList = (data) => {
    return dispatch => {
        dispatch(showMainLoader());
        return MainServiceInstance.getPageLevelFAQs(data).then(function (response) {
            // response = PAGE_LEVEL_FAQs;
            dispatch(hideMainLoader());
            dispatch({
                type: ACTION.GET_PAGE_LEVEL_FAQ,
                apiResponse: response,
            });
            return response;
        }).catch((error) => {
            dispatch(hideMainLoader());
            dispatch({
                type: ACTION.GET_PAGE_LEVEL_FAQ,
                apiResponse: error,
            });
            console.log("Error while fetching page level faqs:- " + error);
            return error;
        });
    }
};

export const resetLocalFooters = () => {
    return dispatch => dispatch({ type: ACTION.RESET_LOCAL_FOOTER });
};

export const resetPageLevelFaqs = () => {
    return dispatch => dispatch({ type: ACTION.RESET_PAGE_LEVEL_FAQ });
};